import { Dialog, DialogType, PrimaryButton, TextField } from "@fluentui/react";
import React, { useCallback, useEffect, useState } from "react";
import {
    MfaWizardStage,
    MfaWizardProps as Props,
    MfaWizardState as State,
} from "./MfaWizard.types";
import { localize } from "src/l10n";
import PopupHeader from "src/ui/components/PopupHeader";
import SpintrLoader from "src/ui/components/Loader";
import Api from "src/spintr/SpintrApi";
import Axios, { AxiosError, AxiosResponse } from "axios";
import { FormControl } from "src/ui/components/Forms";
import api from "src/spintr/SpintrApi";
import { Label } from "src/ui";

type TotpChangeHandler = React.FormEventHandler<HTMLInputElement>;
type Action = () => void;

const renderInformationView = (onNextClick: Action, enforced?: boolean | undefined): React.ReactElement | null => {
    return (
        <div className="information-view">
            {enforced && (
                <Label>
                    {localize("MFA_ENCFORCED")}
                </Label>
            )}
            <Label>
                {localize("TOTP_AUTHENTICATOR_INTRODUCTION")}
            </Label>
            <div className="links">
                <a
                    href="https://apps.apple.com/us/app/google-authenticator/id388497605"
                    target="_blank"
                >
                    <img
                        style={{
                            display: "inline-block"
                        }}
                        src="https://spintrsite.blob.core.windows.net/newsletter/badges/en-gb_apple_badge.png"
                        height="40"
                    />
                </a>
                <a
                    href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                    target="_blank"
                >
                    <img
                        style={{
                            display: "inline-block"
                        }}
                        src="https://spintrsite.blob.core.windows.net/newsletter/badges/en-gb_android_badge.png"
                        height="40"
                    />
                </a>
            </div>
            <div className="actions">
                <PrimaryButton onClick={onNextClick}>
                    {localize("Nasta")}
                </PrimaryButton>
            </div>
        </div>
    );
};

const renderScanningStage = (state: State, onNextClick: Action): React.ReactElement | null => {
    return (
        <div className="scanning-stage">
            <Label>
                {localize("RFC6238_SCAN")}
            </Label>
            <div className="qr-code">
                <img alt="Scan me!"
                    height="256"
                    src={"data:" + state.otpQrCode!}
                    width="256" />
            </div>
            <div className="actions">
                <PrimaryButton onClick={onNextClick}>
                    {localize("Nasta")}
                </PrimaryButton>
            </div>
        </div>
    );
};

const renderVerifyStage = (state: State, onTotpChange: TotpChangeHandler, onSubmit: Action): React.ReactElement | null => (
    <div className="verificastion-stage">
        <Label>
            {localize("RFC6238_VERIFY")}
        </Label>
        <FormControl>
            <TextField
                label={localize("ONE_TIME_PASSWORD")}
                aria-label={localize("ONE_TIME_PASSWORD")}
                onChange={onTotpChange}
                value={state.totp}
                errorMessage={state.errorMessage}
            />
            <div className="actions">
                <PrimaryButton onClick={onSubmit}>
                    {localize("Spara")}
                </PrimaryButton>
            </div>
        </FormControl>
    </div>
);

const MfaWizard: React.FunctionComponent<Props> = (props) => {
    const {
        onClose,
        show
    } = props;

    const [state, setState] = useState<State>({
        isLoading: false,
        currentStage: MfaWizardStage.Information,
        totp: "",
    });

    useEffect(() => {
        if (!show) {
            return;
        }

        const cancelTokenSource = Axios.CancelToken.source();

        Api.get("/api/v1/profile/settings/mfa", { cancelToken: cancelTokenSource.token })
            .then((response) => {
                setState((s) => ({
                    ...s,
                    isLoading: false,
                    otpQrCode: response.data.qrCode as string,
                    otpUri: response.data.uri as string,
                }))
            })
            .catch((error) => console.error(error));

        return () => cancelTokenSource.cancel();
    }, [setState, show]);

    const goToNextStage = useCallback(
        () => setState((s) => {
            let nextStage: MfaWizardStage;
            switch (s.currentStage) {
                case MfaWizardStage.Information:
                    nextStage = MfaWizardStage.Scanning;
                    break;
                    
                case MfaWizardStage.Scanning:
                    nextStage = MfaWizardStage.Verifying;
                    break;
                    
                case MfaWizardStage.Verifying:
                    nextStage = MfaWizardStage.Completed;
                    break;

                default:
                    nextStage = MfaWizardStage.Information;
                    break;
            }

            return {
                ...s,
                currentStage: nextStage
            };
        }),
        [setState],
    );

    const onTotpChanged = useCallback<TotpChangeHandler>(
        (event) => {
            const target = event?.target as HTMLInputElement;
            if (!target) {
                return;
            }

            const value = target.value || "";

            setState((s) => ({
                ...s,
                totp: value,
            }));
        },
        [setState],
    );

    const onDismiss = useCallback(
        () => {
            if (!onClose) {
                return;
            }

            onClose(false);
        },
        [onClose],
    );

    const onCompleted = useCallback(
        () => {
            if (!onClose) {
                return;
            }

            onClose(true);
        },
        [onClose],
    );

    const onVerify = useCallback(
        async () => {
            setState((s) => ({
                ...s,
                isLoading: true,
            }));

            let response: AxiosResponse;
            try {
                response = await api.post(
                    "/api/v1/profile/settings/mfa",
                    { otp: state.totp }
                );
            } catch (thrownError) {
                const err = thrownError as AxiosError;
                if (!err?.isAxiosError) {
                    setState((s) => ({
                        ...s,
                        isLoading: false,
                    }));
                    return;
                }
                
                const message = err.response?.data?.timeStepMatched
                    ? localize("RFC6238_EXPIRES")
                    : localize("RFC6238_INVALID");

                setState((s) => ({
                    ...s,
                    isLoading: false,
                    errorMessage: message,
                }));
                
                return;
            }

            if (!response || response.status !== 200) {
                setState((s) => ({
                    ...s,
                    isLoading: false,
                }));

                return;
            }

            setState((s) => ({
                ...s,
                isLoading: false,
                currentStage: MfaWizardStage.Completed,
            }));
        },
        [state.totp, setState],
    );

    const renderBody = (): React.ReactElement | null => {
        switch (state.currentStage) {
            case MfaWizardStage.Information:
                return renderInformationView(goToNextStage, props.enforced);

            case MfaWizardStage.Scanning:
                return renderScanningStage(state, goToNextStage);
    
            case MfaWizardStage.Verifying:
                return renderVerifyStage(state, onTotpChanged, onVerify);

            case MfaWizardStage.Completed:
                return (
                    <div className="mfa-completed">
                        <Label>
                            {localize("MFA_ADDED")}
                        </Label>
                        <div className="actions">
                            <PrimaryButton onClick={onCompleted}>
                                {localize("Stang")}
                            </PrimaryButton>
                        </div>
                    </div>
                )
    
            default:
                return null;
        }
    }

    if (!show) {
        return null;
    }

    const content = state.isLoading
        ? <SpintrLoader />
        : renderBody();

    return (
        <Dialog
            onDismiss={onDismiss}
            hidden={false}
            dialogContentProps={{
                type: DialogType.normal,
                title: localize("ADD_MULTI_FACTOR_AUTHENTICATION"),
                closeButtonAriaLabel: localize("Stang"),
                className: "spintrCustomDialogInner"
            }}
            modalProps={{
                isBlocking: true,
                className: "spintrCustomDialog",
                containerClassName: "dialogWithPopupHeader",
                allowTouchBodyScroll: true
            }}
            styles={{ main: { minHeight: 161 } }}
        >
            <div id="MfaWizard">
                <PopupHeader onClose={state.currentStage === MfaWizardStage.Completed ? onCompleted : onDismiss}
                            hideCloseButton={props.enforced && state.currentStage !== MfaWizardStage.Completed}
                            text={localize("ADD_MULTI_FACTOR_AUTHENTICATION")} />
                <div className="popup-body">
                    {content}
                </div>
            </div>
        </Dialog>
    );
};

export default MfaWizard;
