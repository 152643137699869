import { TooltipHost } from '@fluentui/react/lib/Tooltip';
import React, { Component, createRef, RefObject } from 'react';
import { localize } from "src/l10n";
import FilePicker from "src/spintr/components/FilePicker";
import InteractiveTextInput from "src/spintr/components/InteractiveTextInput";
import { SpintrTypes } from 'src/typings';
import { SpintrUser, UnstyledButton } from "src/ui";
import generateUniqueId from "src/utils/uniqueId";
import './ChatComposer.scss';
import api from "src/spintr/SpintrApi";
import Message from "src/chat/Message";
import { saveMessage } from "src/chat/redux";
import { connect } from "react-redux";
import Visage2Icon from 'src/visage2/Visage2Icon/Visage2Icon';

interface IProps {
    currentUser: any,
    conversationId: number,
    parentId?: number,
    group?: any;
    dispatch?: any;
}

interface IState {
    hasContent: boolean;
    isFilePickerOpen: boolean;
    inputKey: string;
}

class ChatComposer extends Component<IProps, IState> {
    protected textInputRef: RefObject<InteractiveTextInput>;
    protected formRef: RefObject<HTMLFormElement>;

    constructor(props) {
        super(props);

        this.state = {
            hasContent: false,
            isFilePickerOpen: false,
            inputKey: generateUniqueId()
        }

        this.textInputRef = createRef();
        this.formRef = createRef();
    }

    public giveFocus(): void {
        this.textInputRef.current.giveFocus();
    }

    public clearContent(): void {
        this.textInputRef.current.clearContent();
    }

    public getContent(): string {
        return this.textInputRef.current.getContent();
    }

    isContentEmpty(content) {
        return !content ||
            content.length === 0 ||
            content === "<br />";
    }

    postMessage(e) {
        if (e) {
            e.preventDefault();
        }

        const content = this.textInputRef.current.getContent();

        if (this.isContentEmpty(content)) {
            return;
        }

        this.textInputRef.current.clearContent();

        const message = Message
            .createLocal(
                this.props.conversationId,
                content,
                {
                    ...this.props.currentUser,
                    imageUrl: this.props.currentUser.images["250x999"]
                },
                !!this.props.parentId ? {
                    id: this.props.parentId
                } : undefined
            )
            .merge({
                parentId: this.props.parentId
            });

        this.props.dispatch(saveMessage(message, this.props.currentUser.id));

        this.setState({
            inputKey: generateUniqueId()
        }, () => {
            this.textInputRef.current.giveFocus();
        });
    }

    onChange() {
        const content = this.textInputRef.current.getContent();
        const contentIsEmpty = this.isContentEmpty(content);

        if ((contentIsEmpty && this.state.hasContent) ||
            (!contentIsEmpty && !this.state.hasContent)) {
            this.setState({
                hasContent: !this.state.hasContent
            });
        }
    }

    toggleFilePicker() {
        this.setState({
            isFilePickerOpen: !this.state.isFilePickerOpen
        });
    }

    onFileSelect(data, source) {
        if (source === 1) {
            for (let o of data) {
                api.post(`/api/files/${o.id}/copy`, {
                    id: this.props.group.folderId
                });
            }
        } else if (source === 4 || source === 5) {
            for (let o of data) {
                api.post("/api/v1/conversations/" + this.props.conversationId + "/externalfile", {
                    conversationType: 3,
                    file: {
                        id: o.id,
                        source
                    }
                });
            }
        }
    }

    getFileSourcesToDisplay = () => {
        let fileSources = [
            SpintrTypes.FolderRootSource.Upload
        ];

        if (this.props.group) {
            fileSources = [
                ...fileSources,
                SpintrTypes.FolderRootSource.SpintrPrivate,
                SpintrTypes.FolderRootSource.SpintrPublic,
                SpintrTypes.FolderRootSource.Office365Public
            ]
        }

        return fileSources;
    }

    render() {
        let classes : string[] = [
            "ChatComposer"
        ];

        let wrapperClasses : string[] = [
            "ChatComposer-wrapper"
        ]

        const isReplyComposer = !!this.props.parentId &&
            this.props.parentId > 0;

        const placeholder = isReplyComposer ?
            (localize("Svara") + "...") :
            localize("StartaEnNyKonversation");

        if (isReplyComposer) {
            classes.push("ChatComposer-reply");
            wrapperClasses.push("ChatComposer-reply-wrapper")
        } else {
            classes.push("ChatComposer-main");
        }

        if (this.state.hasContent) {
            classes.push("ChatComposer-has-content");
        }

        return (
            <div className={wrapperClasses.join(" ")}>
                <div className={classes.join(" ")}>
                    <form ref={this.formRef} onSubmit={this.postMessage.bind(this)}>
                        <div className="ChatComposer-inner-wrapper">
                            <div className="ChatComposer-profile-image">
                                <SpintrUser
                                    name={this.props.currentUser.name}
                                    imageUrl={this.props.currentUser.images.Message}
                                    hideText={true}
                                    personalName={true}
                                    size={this.props.parentId ? 32 : null}
                                />
                            </div>
                            <div className="ChatComposer-right">
                                <div className="ChatComposer-text-input">
                                    <InteractiveTextInput
                                        bigChatViewPopoutPosition={true}
                                        key={this.state.inputKey}
                                        onEnter={this.postMessage.bind(this)}
                                        onChange={this.onChange.bind(this)}
                                        placeholder={placeholder}
                                        ref={this.textInputRef}
                                    />
                                </div>
                                <div className="ChatComposer-actions">
                                    {
                                        this.props.parentId ?
                                            null :
                                            <TooltipHost content={localize("BifogaFil")}>
                                                <UnstyledButton ariaLabel={localize("BifogaFil")} onClick={() => {
                                                    this.toggleFilePicker();
                                                }}>
                                                    <div className="ChatComposer-attach-button">
                                                        <Visage2Icon className="ChatComposer-icon-inactive" icon="folder" color="dusk-grey" />
                                                        <Visage2Icon className="ChatComposer-icon-active" icon="folder" color="light-blue" />
                                                    </div>
                                                </UnstyledButton>
                                            </TooltipHost>
                                    }
                                    <TooltipHost content={this.state.hasContent ? localize("Skicka") : ""}>
                                        <UnstyledButton ariaLabel={localize("Skicka")} onClick={() => {
                                            this.formRef.current.dispatchEvent(new Event("submit", { cancelable: true }));
                                        }}>
                                            <div className="ChatComposer-send-button">
                                                <Visage2Icon icon="send-2" color={this.state.hasContent ? "white" : "dusk-grey"} />
                                            </div>
                                        </UnstyledButton>
                                    </TooltipHost>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                {
                    this.state.isFilePickerOpen ?
                        <FilePicker
                            onClose={this.toggleFilePicker.bind(this)}
                            onSelect={this.onFileSelect.bind(this)}
                            showFiles
                            sourcesToDisplay={this.getFileSourcesToDisplay()}
                            source={SpintrTypes.FolderRootSource.Upload}
                            getUploadPromise={(file, ticket, config) => {
                                let body = new FormData();

                                body.append("file", file);
                                body.append("ticket", ticket);
                                body.append("type", "4");
                                body.append("conversationId", this.props.conversationId.toString());

                                if (this.props.group) {
                                    body.append("feedId", this.props.group.feedId.toString());
                                    body.append("folderId", this.props.group.folderId.toString());
                                }

                                return api.post("/api/uploads", body, config);
                            }}
                        /> :
                        null
                }
            </div>
        )
    }
}

const mapStateToProps = (state, props) => ({
    ...props,
});

export default connect(mapStateToProps, null, null, {forwardRef: true})(ChatComposer);