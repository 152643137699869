import { DetailsListLayoutMode, IColumn, Image } from "@fluentui/react";
import { CancelToken } from "axios";
import moment from "moment";
import React, { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { Link } from "react-router-dom";
import { localize } from "src/l10n";
import { queryProductsAsync } from "src/products/productsApi";
import { Product } from "src/products/types";
import { Breadcrumbs, PageHeader } from "src/ui";
import SpintrLoader from "src/ui/components/Loader";
import SpintrList from "src/ui/components/SpintrList/SpintrList";
import { ProductsListViewState, ProductsListViewProps as Props } from "./ProductsListView.types";
import { SpintrTypes } from "src/typings";

const { ContentStatus } = SpintrTypes;

function getColumns(isAdminView: boolean): (Omit<IColumn, "minWidth"> | { minWidth?: number | undefined})[] {
    const productBasePath = isAdminView
        ? "/admin/products/"
        : "/products/";

    return [{
        key: "imageUrl",
        name: localize("Bild"),
        fieldName: "imageUrl",
        minWidth: 36,
        flexGrow: 0,
        onRender: (item: Product) => !item.imageUrl ? null : (
            <div className="image-wrapper">
                <Image
                    alt={item.name}
                    className="product-image"
                    src={item.imageUrl}
                />
            </div>
        ),
    }, {
        key: "articleId",
        name: localize("ARTICLE_ID"),
        fieldName: "articleId",
        flexGrow: 1,
        minWidth: 100,
        onRender: (item: Product) => (
            <Link to={productBasePath + item.id}>{item.articleId}</Link>
        ),
    }, {
        key: "name",
        name: localize("Namn"),
        fieldName: "name",
        flexGrow: 1,
        minWidth: 100,
        onRender: (item: Product) => (
            <Link to={productBasePath + item.id}>{item.name}</Link>
        ),
    }, {
        key: "lastModified",
        name: localize("SenastAndrad"),
        fieldName: "lastModified",
        flexGrow: 1,
        minWidth: 100,
        onRender: (item: Product) => moment(item.lastModified).format("YYYY-MM-DD HH:mm"),
    }, {
        key: "status",
        name: localize("SenastAndrad"),
        fieldName: "status",
        flexGrow: 1,
        minWidth: 100,
        onRender: (item: Product) => {
            switch (item.status) {
                case ContentStatus.Published:
                    return localize("Publicerad");
                    
                case ContentStatus.Draft:
                    return localize("Utkast");

                case ContentStatus.Deleted:
                    return localize("Borttagen");

                case ContentStatus.Expired:
                    return localize("Utganget");

                default:
                    return null;
            }
        }
    }];
}

const getSearchQuery = (search: string): string => {
    const query = (search.startsWith("?") ? search.substring(1) : search)
        .split("&")
        .map((s) => s.split("="))
        .filter(([key]) => key === "search")
        .map(([, value]) => decodeURIComponent(value))[0];

    return query || "";
}

function ProductsListView({ listRefreshRef }: Props): ReactElement {
    const listRef = useRef<SpintrList>();
    const { pathname, search } = useLocation();
    const [state, setState] = useState<ProductsListViewState>({
        query: getSearchQuery(search),
        totalCount: 0,
    });
    const { query } = state;

    useEffect(() => {
        listRefreshRef.current = () => {
            if (!listRef.current) {
                return;
            }

            listRef.current.reFetch()
        };

        return () => {
            listRefreshRef.current = null;
        };
    }, [listRef, listRefreshRef]);

    const isAdministrator = useSelector<Spintr.AppState, boolean>(
        (state) => state.profile.active.roles?.includes("administrators"),
    );
    const history = useHistory();
    const isAdministrativeView = pathname.startsWith("/admin/");

    const breadcrumbs = useMemo(() => {
        const isAdministrativeView = pathname.startsWith("/admin/");
        const basePath = isAdministrativeView ? "/admin" : "";

        const crumbs = [];
        if (isAdministrativeView) {
            crumbs.push({
                key: "1",
                text: localize("Administration"),
                link: basePath,
            });
        }

        crumbs.push({
            key: "2",
            text: localize("PRODUCTS"),
            link: basePath + "/products",
        });

        return crumbs;
    }, [pathname]);

    const onListFetch = useCallback(async (
        offset: number,
        limit: number,
        orderBy: string,
        orderAscending: boolean,
        _: never,
        cancelToken: CancelToken,
        // more: boolean,
    ): Promise<{ data: Product[], totalCount: number} | undefined> => {
        try {
            const envelope = await queryProductsAsync(
                offset,
                limit,
                query,
                orderBy,
                orderAscending,
                cancelToken,
            );

            setState((prevState) => ({
                ...prevState,
                totalCount: envelope.totalCount,
            }));

            return {
                data: envelope.items,
                totalCount: envelope.totalCount,
            };
        } catch (err) {
            // TODO: Handle error in some way?
            console.error(err);
        }
    }, [query, setState]);

    const columns = useMemo(() => getColumns(isAdministrativeView), [isAdministrativeView]);

    useEffect(() => {
        const searchText = getSearchQuery(search);

        setState((prevState) => searchText === prevState.query ? prevState : ({
            ...prevState,
            query: searchText,
        }));
    }, [search, setState]);

    useEffect(() => {
        if (!listRef.current) {
            return;
        }

        listRef.current.reFetch();
    }, [listRef?.current, query])

    if (!isAdministrator && isAdministrativeView) {
        history.push("/products");

        return <SpintrLoader />
    }

    return (
        <div id="ProductsListView">
            <Breadcrumbs
                useGlobalBreadcrumbs={true}
                displayInstance={true}
                items={breadcrumbs}
            />
            <PageHeader
                hits={state.totalCount}
                subText={localize("PRODUCTS_HELP")}
                title={localize("PRODUCTS")}
            />
            <SpintrList
                columns={columns}
                disableSearch={true}
                disableCommandBar={true}
                fetch={onListFetch}
                layoutMode={DetailsListLayoutMode.fixedColumns}
                orderByColumn="lastModified"
                isDescending={true}
                ref={listRef}
            />
        </div>
    );
}

export default ProductsListView;
