import { AxiosResponse } from 'axios';
import { DefaultButton, Icon, PrimaryButton, TextField } from '@fluentui/react';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { RouteChildrenProps, withRouter } from 'react-router';
import { localize } from 'src/l10n';
import { ActionMenu, Breadcrumbs, ContentWithInfoPanel, ContentWithSubmenu, Label, Loader, PageHeader, Submenu, UnstyledButton, setDisableWrapperBackground } from 'src/ui';
import { FormControl } from 'src/ui/components/Forms';
import SpintrHelpText from 'src/ui/components/SpintrHelpText';
import SpintrList from 'src/ui/components/SpintrList/SpintrList';
import TinyFormattedContent from 'src/ui/components/Tiny/displayment/TinyFormattedContent';
import './QAView.scss';
import api from 'src/spintr/SpintrApi';
import { IVisageSidebarMenuItem, VisageSidebarMenuItemActiveMode, VisageSidebarMode } from 'src/sidebar';
import { expandToActiveItem, setSidebarItems, setSidebarMode } from 'src/sidebar/actions';
import TinyEditorComponent from 'src/ui/components/Tiny/TinyEditorComponent';
import Visage2Icon from 'src/visage2/Visage2Icon/Visage2Icon';

interface IPageRouteParams {
    id: string;
}

interface IProps extends RouteChildrenProps<IPageRouteParams> {
    isAdmin: boolean;
    isEditor: boolean;
    dispatch?: any;
}

interface IState {
    isLoading: boolean;
    open: any[];
    canCreate: boolean;
    canAnswer: boolean;
    editing: number[];
    tagId: number;
    editedAnswer: any;
    showDeleted: boolean;
    title: string;
    tags: any[];
}

class QAView extends Component<IProps, IState> {
    private listRef = React.createRef<SpintrList>();
    private tinyRef = React.createRef<any>();
    private _isMounted: boolean = false;

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            open: [],
            canCreate: false,
            canAnswer: false,
            editing: [],
            tagId: undefined,
            editedAnswer: {},
            showDeleted: false,
            title: "FragorOchSvar",
            tags: []
        };
    }

    isOpen = (id) => {
        const { open } = this.state;
        if (open.includes(id)) return true;
        else return false;
    };

    open = (id) => {
        let { open } = this.state;
        open.push(id);
        this.setState({ open });
    };

    close = (id) => {
        let { open } = this.state;
        open = open.filter((f) => {
            return f !== id;
        });
        this.setState({ open });
    };

    formatDate(dateString) {
        const d = new Date(dateString);
        return d.toLocaleString();
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.search !== prevProps.location.search) {
            let tagId = "0";

            if (!!this.props.location.search &&
                this.props.location.search.length > 0 &&
                this.props.location.search.indexOf("=") > -1) {
                tagId = this.props.location.search.split("=")[1];
            }

            this.setState({
                tagId: parseInt(tagId, 0)
            }, () => {
                this.listRef.current.fetch();
            });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.props.dispatch(setDisableWrapperBackground(false));
    }

    componentDidMount = () => {
        this._isMounted = true;

        this.props.dispatch(setDisableWrapperBackground(true));

        api.get("/api/v1/qa/settings").then((response) => {
            const { data } = response;
            const { isAdmin, isEditor } = this.props;

            let canCreate =
                data.createPrivilegesLevel === 0 ||
                (data.createPrivilegesLevel === 1 && (isEditor || isAdmin)) ||
                (data.createPrivilegesLevel === 2 && isAdmin);

            let canAnswer =
                data.answerPrivilegesLevel === 0 ||
                (data.answerPrivilegesLevel === 1 && (isEditor || isAdmin)) ||
                (data.answerPrivilegesLevel === 2 && isAdmin);

            this.setState({
                canCreate,
                canAnswer,
                title: data.name
            });
        });

        this.fetchTags();
    };

    editAnswer = (id) => {
        let { editing } = this.state;
        editing.push(id);
        this.setState({ editing });
    };

    fetchTags() {
        api.get("/api/v1/qa/tags").then(response => {
            if (!this._isMounted) {
                return;
            }

            this.setState({
                tags: [
                    {
                        id: 0,
                        title: localize("Alla")
                    },
                    ...response.data
                ]
            });
        }).catch(() => { });
    }

    renderInfoPanel() {
        return (
            <div className="qa-info-panel">
                <Label
                    size="body-3"
                    weight="medium"
                    uppercase
                    className={"primaryFGColor"}>
                    {localize("Taggar")}
                </Label>
                {this.state.tags.map((item: any, index: number) => {
                    const isActive = item.id === this.state.tagId ||
                        (!item.id && !this.state.tagId);

                    return (
                        <UnstyledButton key={index} onClick={() => {
                            this.setState({
                                tagId: item.id
                            }, () => {
                                this.listRef.current.fetch();
                            });
                        }}>
                            <Label color="light-blue" size="body-2" weight={isActive ? "bold" : "normal"}>
                                {item.title}
                            </Label>
                        </UnstyledButton>
                    )
                })}
            </div>
        )
    }

    render = () => {
        const { canCreate, canAnswer, isLoading } = this.state;
        let { editing } = this.state;
        const { isAdmin, isEditor } = this.props;

        if (isLoading) return <Loader />;

        return (
            <ContentWithInfoPanel
                visagePageStyle
                template={!!this.state.tags && this.state.tags.length > 0 ? 1 : 3}
                renderInfoPanel={this.renderInfoPanel.bind(this)}
            >
                <div className="qa-view">
                    <Helmet>
                        <title>{localize(this.state.title, true)}</title>
                    </Helmet>
                    <Breadcrumbs
                        displayInstance
                        items={[
                            {
                                key: "0",
                                text: localize(this.state.title, true),
                                link: "/qa",
                            },
                        ]}
                    />
                    <PageHeader
                        title={localize(this.state.title, true)}
                        actionMenuCategories={[
                            {
                                items: [
                                    {
                                        text: localize("LaggTillFraga"),
                                        onClick: () => {
                                            this.props.history.push({
                                                pathname: `/qa/create`,
                                            });
                                        },
                                    },
                                    {
                                        text: localize("Installningar"),
                                        onClick: () => {
                                            this.props.history.push({
                                                pathname: `/qa/settings`,
                                            });
                                        },
                                    },
                                    {
                                        text: this.state.showDeleted
                                            ? localize("DoljBorttagna")
                                            : localize("VisaBorttagna"),
                                        onClick: () => {
                                            this.setState({ showDeleted: !this.state.showDeleted }, () => {
                                                this.listRef.current.fetch();
                                            });
                                        },
                                    },
                                ],
                            },
                        ]}
                        helpTextId={9}
                    />
                    <SpintrList
                        ref={this.listRef}
                        fetch={(skip, take, columnId, isAscending, searchQuery) => {
                            const { id } = this.props.match.params;

                            if (id) {
                                return new Promise((resolve, reject) => {
                                    api.get(`/api/v1/qa/${id}`).then((response: AxiosResponse) => {
                                        resolve({ data: [response.data], totalCount: 1 });
                                        this.setState({ open: [Number(id)] });
                                    });
                                });
                            } else {
                                return new Promise((resolve, reject) => {
                                    api
                                        .get(`/api/v1/qa`, {
                                            params: {
                                                includeDeleted: this.state.showDeleted,
                                                isAscending: false,
                                                orderByColumn: columnId,
                                                searchText: searchQuery,
                                                take: take,
                                                skip: skip,
                                                ...(this.state.tagId && { tagId: this.state.tagId }),
                                            },
                                        })
                                        .then((response: AxiosResponse) => {
                                            resolve({ data: response.data.qas, totalCount: response.data.totalCount });
                                        });
                                });
                            }
                        }}
                        buttons={canCreate && [{
                            key: "add",
                            text: localize("LaggTillFraga"),
                            onClick: () => {
                                this.props.history.push({
                                    pathname: `/qa/create`,
                                });
                            },
                            iconProps: { iconName: "Add" },
                            className: "commandBarAddButton",
                        },
                        ]}
                        columns={[
                            {
                                name: "",
                                fieldName: "",
                                minWidth: 20,
                                maxWidth: 20,
                                onRender: (item: any) => {
                                    if (!item.isDeleted && item.answer) {
                                        if (this.isOpen(item.id)) {
                                            return (
                                                <span className="QAicon primaryFGColor">
                                                    <UnstyledButton
                                                        onClick={() => {
                                                            this.close(item.id);
                                                        }}>
                                                        <Visage2Icon icon={"message"} />
                                                    </UnstyledButton>
                                                </span>
                                            );
                                        } else {
                                            return (
                                                <span className="QAicon primaryFGColor">
                                                    <UnstyledButton
                                                        onClick={() => {
                                                            this.open(item.id);
                                                        }}>
                                                        <Visage2Icon icon={"message"} />
                                                    </UnstyledButton>
                                                </span>
                                            );
                                        }
                                    } else return "";
                                },
                            },
                            {
                                name: localize("Fraga"),
                                fieldName: "question",
                                minWidth: 260,
                                isResizable: true,
                                isMultiline: true,
                                onRender: (item) => {
                                    if (item.isDeleted)
                                        return (
                                            <span>
                                                <s>{item.question}</s>
                                            </span>
                                        );
                                    else {
                                        if (this.isOpen(item.id)) {
                                            return (
                                                <>
                                                    <b>
                                                        <span className="q">
                                                            {item.question}{" "}
                                                            <span>
                                                                <UnstyledButton
                                                                    onClick={() => {
                                                                        this.close(item.id);
                                                                    }}
                                                                >
                                                                    {localize("StangSvar")}
                                                                </UnstyledButton>
                                                            </span>
                                                        </span>
                                                    </b>
                                                    <div className="answer-drawer">
                                                        {item.answer && (
                                                            <>
                                                                <div className="answer">
                                                                    <TinyFormattedContent content={item.answer ?? ""} />
                                                                </div>
                                                                <div className="last-modified">
                                                                    {localize("SenastAndrad")}{" "}
                                                                    {localize("Av_By").toLowerCase()}{" "}
                                                                    <UnstyledButton
                                                                        className="primaryFGColor primaryBorderColorHover closeedit"
                                                                        onClick={() => {
                                                                            this.props.history.push({
                                                                                pathname: `/profile/${item.answererId}`,
                                                                            });
                                                                        }}
                                                                    >
                                                                        {item.answererName}
                                                                    </UnstyledButton>
                                                                    {" "}{this.formatDate(item.lastAnswered)}<br />
                                                                    <span className="options">
                                                                        <UnstyledButton
                                                                            className="primaryFGColor primaryBorderColorHover closeedit"
                                                                            onClick={() => {
                                                                                this.close(item.id);
                                                                            }}
                                                                        >
                                                                            {localize("Stang")}
                                                                        </UnstyledButton>{" "}
                                                                        |{" "}
                                                                        {canAnswer && (
                                                                            <UnstyledButton
                                                                                className="primaryFGColor primaryBorderColorHover closeedit"
                                                                                onClick={() => {
                                                                                    this.editAnswer(item.id);
                                                                                }}
                                                                            >
                                                                                {localize("RedigeraSvar")}
                                                                            </UnstyledButton>
                                                                        )}
                                                                    </span>
                                                                </div>
                                                            </>
                                                        )}

                                                        {editing.includes(item.id) && (
                                                            <div>
                                                                <FormControl>
                                                                    <TinyEditorComponent
                                                                        label={localize("Svar")}
                                                                        ref={this.tinyRef}
                                                                        id={"23"}
                                                                        content={item.answer == undefined ? "" : item.answer}
                                                                    />
                                                                </FormControl>

                                                                <FormControl>
                                                                    <DefaultButton
                                                                        onClick={() => {
                                                                            editing = editing.filter(
                                                                                (f) => f !== item.id
                                                                            );
                                                                            this.setState({ editing });
                                                                            this.close(item.id);
                                                                        }}
                                                                    >
                                                                        {localize("Avbryt")}
                                                                    </DefaultButton>

                                                                    <PrimaryButton
                                                                        style={{ marginLeft: "12px" }}
                                                                        onClick={() => {
                                                                            editing = editing.filter(
                                                                                (f) => f !== item.id
                                                                            );

                                                                            const text = this.tinyRef.current.getContent();

                                                                            if (text) {
                                                                                this.setState(
                                                                                    { isLoading: true },
                                                                                    () => {
                                                                                        api
                                                                                            .post("/api/v1/qa/answer", {
                                                                                                questionId: item.id,
                                                                                                text
                                                                                            })
                                                                                            .then((response) => {
                                                                                                this.setState({
                                                                                                    isLoading: false,
                                                                                                    editing,
                                                                                                });
                                                                                            });
                                                                                    }
                                                                                );

                                                                            } else {
                                                                                editing = editing.filter(
                                                                                    (f) => f !== item.id
                                                                                );
                                                                                this.setState({ editing });
                                                                                this.close(item.id);
                                                                            }
                                                                        }}
                                                                    >
                                                                        {localize("Spara")}
                                                                    </PrimaryButton>
                                                                </FormControl>
                                                            </div>
                                                        )}
                                                    </div>
                                                </>
                                            );
                                        } else {
                                            return (
                                                <span className="q">
                                                    {item.question}{" "}
                                                    <span className="primaryFGColor primaryBorderColor">
                                                        <UnstyledButton
                                                            onClick={() => {
                                                                this.open(item.id);
                                                                if (!item.answer) {
                                                                    this.editAnswer(item.id);
                                                                }
                                                            }}
                                                        >
                                                            {item.answer ? localize("OppnaSvar") : canAnswer && localize("LaggTillSvar")}
                                                        </UnstyledButton>
                                                    </span>
                                                </span>
                                            );
                                        }
                                    }
                                },
                            },
                            {
                                name: localize("Taggar"),
                                fieldName: "tags",
                                minWidth: 130,
                                onRender: (item) => {
                                    return (
                                        <span>
                                            <i>{item.tags.join(", ")}</i>
                                        </span>
                                    );
                                },
                            },
                            {
                                name: "",
                                key: "actionmenu",
                                minWidth: 40,
                                onRender: (item: any) => {
                                    if (isAdmin)
                                        return (
                                            <ActionMenu
                                                categories={[
                                                    {
                                                        items: [
                                                            {
                                                                text: localize("Redigera"),
                                                                onClick: () => {
                                                                    this.props.history.push({
                                                                        pathname: `/qa/${item.id}/edit`,
                                                                    });
                                                                },
                                                            },
                                                            {
                                                                text: item.isDeleted
                                                                    ? localize("Aterstall")
                                                                    : localize("TaBort"),
                                                                onClick: () => {
                                                                    api
                                                                        .delete(`/api/v1/qa/${item.id}`)
                                                                        .then((_response) => {
                                                                            this.listRef.current.fetch();
                                                                        });
                                                                },
                                                            },
                                                        ],
                                                    },
                                                ]}
                                            />
                                        );
                                },
                            },
                        ]}
                        orderByColumn={"question"}
                        disableCommandBar={!!this.props.match.params.id}
                    />
                </div>
            </ContentWithInfoPanel>
        );
    }
}

const mapStateToProps = (state, props) => {
    return {
        ...props,

        isAdmin: state.profile.active.isAdmin,
        isEditor: state.profile.active.isEditor,
    };
};

export default withRouter(connect(mapStateToProps)(QAView));
