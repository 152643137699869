import autosave from "./autosave";
import capitalizeFirstLetter from "./capitalizeFirstLetter";
import debounce from "./debounce";
import decapitalizeFirstLetter from "./decapitalizeFirstLetter";
import decodeHtmlEntities from "./decodeHtmlEntities";
import { draftToHtml } from "./draftjs-to-html";
import formatInteractiveText from "./formatInteractiveText";
import getInitialsFromString from "./getInitialsFromString";
import getTimeOrDateFromDate from "./getTimeOrDateFromDate";
import groupBy from "./group-by";
import isDev from "./isDev";
import possessiveName from "./possessiveName";
import scrollToTop from "./scrollToTop";
import shortenString from "./shortenString";
import uniqueId from "./uniqueId";
import validZohoFormat from "./validZohoFormat";
import isAnythingDirty from "./isAnythingDirty";
import validateRequiredTextField from "./validateRequiredTextField";
import fixImageMaps from "./fixImageMaps";
import getTargetForLink from "./getTargetForLink";
import getDomainFromUrl from "./getDomainFromUrl";
import isSidebarItemActive from "./isSidebarItemActive";
import getShortFromNow from "./getShortFromNow";

export * from "./spintrMixpanelFunctions";

const roundToNearest = (x: number, m: number = 5) => ((x % m) >= (m / 2))
    ? m * Math.round((x + (m / 2)) / m)
    : m * Math.round(x / m);

export * from "./autosave";
export * from "./contentStatusToText";
export * from "./date";
export * from "./dom";
export * from "./formatInteractiveText";
export * from "./location";
export * from "./noop";
export * from "./truncate";
export * from "./uploads";
export * from "./uuid";

export {
    autosave,
    decapitalizeFirstLetter,
    decodeHtmlEntities,
    draftToHtml,
    getInitialsFromString,
    groupBy,
    possessiveName,
    shortenString,
    uniqueId,
    debounce,
    capitalizeFirstLetter,
    getTimeOrDateFromDate,
    formatInteractiveText,
    validZohoFormat,
    isDev,
    scrollToTop,
    isAnythingDirty,
    validateRequiredTextField,
    fixImageMaps,
    getTargetForLink,
    getDomainFromUrl,
    isSidebarItemActive,
    getShortFromNow,
    roundToNearest,
};
