import Message from "../../Message";
import { IDateGroup } from "./types";

export type MessageGrouper = (messages: Message[]) => IDateGroup[];
export const groupMessages: MessageGrouper = (messages) => {
    if (!messages) {
        return [];
    }

    const threshold = 1000 * 60 * 60; // one hour

    const dateGroups: IDateGroup[] = [];
    messages
        .sort((a, b) => (a.date as Date).getTime() - (b.date as Date).getTime())
        .forEach((m) => {
            const date = m.date as Date;

            let dateGroup = dateGroups.reduce<IDateGroup>(
                (acc, dg) => {
                    const moreRecent = (
                        (dg.from.getTime() - threshold) < date.getTime()
                        &&
                        (dg.to.getTime() + threshold) > date.getTime()
                    );

                    return moreRecent ? dg : acc;
                },
                undefined
            );

            if (!dateGroup) {
                dateGroup = {
                    from: date,
                    to: date,
                    userGroups: [{
                        messages: [m],
                        user: m.user,
                    }]
                };
                dateGroups.push(dateGroup);
                return;
            }

            if (dateGroup.from > date) {
                dateGroup.from = date;
            }

            if (dateGroup.to < date) {
                dateGroup.to = date;
            }
            
            const lastUserGroup = dateGroup.userGroups.slice(-1)[0];
            if (!lastUserGroup || !lastUserGroup.user || lastUserGroup.user.id !== m.user.id) {
                dateGroup.userGroups.push({
                    messages: [m],
                    user: m.user,
                });
                return;
            }

            lastUserGroup.messages.push(m);
        });

    return dateGroups;
};
