import React, { Component, ReactNode } from "react";
import { Label, SpintrUser } from "src/ui";
import { decodeHtmlEntities, stripInteractiveText } from "src/utils";
import "./CommentPreview.scss";

interface ICommentPreviewProps {
    color?: spintrColors;
    comment: Spintr.IComment;
}

class CommentPreview extends Component<ICommentPreviewProps> {
    public render(): ReactNode {
        const color: spintrColors = this.props.color || "dark-grey";

        return (
            <div className="CommentPreview">
                <div className="commentor-image">
                    <SpintrUser
                        name={this.props.comment.author.name}
                        imageUrl={(this.props.comment.author as any).image}
                        size={16}
                        hideText
                        personalName />
                </div>
                <Label
                    as="div"
                    className="comment-text"
                    color={color}
                    size="body-2">
                    {stripInteractiveText(decodeHtmlEntities(this.props.comment.text))}
                </Label>
            </div>
        );
    }
}

export default CommentPreview;