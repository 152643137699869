import React, { Fragment, FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { getTypeName, localize } from "src/l10n";

interface IProps {
    notification: Spintr.INotification;
}

const ContentReviewAccepted: FunctionComponent<IProps> = (props) => {
    const notification = props.notification as Spintr.IContentReviewAccepted;
    if (!notification) {
        return null;
    }

    const typeText = getTypeName(notification.uberType, { case: "possesive" }).toLowerCase();

    return (
        <Fragment>
            {localize("Din")}
            {" " + typeText + " "}
            <Link to={"/publishing"}>{notification.uberName}</Link>
            {" " + localize("HarBlivitGodkand").toLowerCase()}.
        </Fragment>
    );
};

export default ContentReviewAccepted;
