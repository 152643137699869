import { Icon } from "@fluentui/react";
import { EditorState } from "draft-js";
import React, { FunctionComponent, useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { localize } from "src/l10n";
import { IActiveUserProfile } from "src/profile/reducer";
import { Label, SpintrUser, UnstyledButton } from "src/ui";
import { circleLarge, circleMedium } from "src/ui/helpers/style";
import {
    ComposerContextProvider,
    IComposerContext
} from "../ComposerContext";
import { ComposerTypeButton, ComposerTypeSelectorBar } from "../ComposerTypeSelector";
import SocialComposer from "../SocialComposer";
import SocialComposerTypes from "../SocialComposerTypes";
import { draftToHtml } from "../utils";
import Visage2Composer, { defaultFrameColors } from "../../Visage2Composer/Visage2Composer";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import { visage2SocialPostTypes } from "src/social-feed";
import { SpintrTypes } from "src/typings";

interface IStateProps {
    enableSharepoint: boolean;
    disableNewDealPostType?: boolean;
    user: IActiveUserProfile;
}

interface IProps {
    feedId?: number;
    flag?: number;
    galleryId?: number;
    identity?: any;
    isUnit?: boolean;
    isStartPage?: boolean;
}

const InlineComposer: FunctionComponent<IProps> = (props) => {
    const { disableNewDealPostType, enableSharepoint, user } = useSelector<Spintr.AppState, IStateProps>(
        (state) => ({
            enableSharepoint: state.instance.get("enableSharepoint") as boolean,
            disableNewDealPostType: state.instance.get("disableNewDealPostType"),
            user: state.profile.active,
        }),
    );

    const [isOpen, setIsOpen] = useState(false);
    const [type, setType] = useState(undefined);
    const [editorText, setEditorText] = useState<string | null>(null);
    const [viewState, setViewState] = useState<IComposerContext>({
        contentState: null,
        editorState: EditorState.createEmpty(),
        size: "default",
        type: "text",

        reset: () => setViewState((s: IComposerContext) => ({
            ...s,
            contentState: null,
            editorState: EditorState.createEmpty(),
            size: "default",
            type: "text",
        })),
        resetEditorState: () => setViewState((s: IComposerContext) => ({
            ...s,
            editorState: EditorState.createEmpty(),
        })),
        setContentState: (contentState, callback) => {
            setViewState((s: IComposerContext) => ({
                ...s,
                contentState,
            }));

            if (!callback) {
                return;
            }

            callback();
        },
        setEditorState: (es) => setViewState((s: IComposerContext) => ({
            ...s,
            editorState: es,
        })),
        setSize: (size) => setViewState((s: IComposerContext) => ({ ...s, size })),
        setType: (type) => setViewState((s: IComposerContext) => ({ ...s, type: s.type === type ? "text" : type })),
    });

    const onClick = useCallback(
        () => setIsOpen(true),
        [setIsOpen],
    );

    const onTypeClick = useCallback(
        (type: SocialComposerTypes) => {
            setViewState((s: IComposerContext) => ({ ...s, type }));
            setIsOpen(true);
        },
        [setIsOpen, setViewState],
    );

    const editorState = viewState.editorState;
    const onClosing = useCallback(
        () => {
            setViewState((s: IComposerContext) => ({
                ...s,
                size: "default",
            }));

            setIsOpen(false);

            const html = draftToHtml(
                editorState.getCurrentContent()
            );

            // TODO: Convert html to text

            setEditorText(html);
        },
        [setIsOpen, editorState],
    );

    const handleKeys = e => {
        if (e.key === "Enter") {
            onClick();
        } else if (e.key === "Escape") {
            setIsOpen(false);
        }
    }

    const types = useMemo<SocialComposerTypes[]>(
        () => {
            const retVal: SocialComposerTypes[] = [
                "text",
                "image",
                "frame",
                "poll",
                "video",

                // for testing only:
                "file",
            ];

            if (user.office365Connected && enableSharepoint) {
                retVal.push("external-file");
            }

            return retVal;
        },
        [enableSharepoint, user],
    );

    const userImage = props.identity
        ? props.identity.images.feedComposer
        : user.images.feedComposer;

    const postTypes = visage2SocialPostTypes
        .filter(
            (x) => x.type !== SpintrTypes.StatusType.Question
                && (!disableNewDealPostType || x.type !== SpintrTypes.StatusType.BusinessDeal)
        );

    return (
        <div id="InlineComposer">
            <ComposerContextProvider value={viewState}>
                <div
                    aria-label={localize("COMPOSER_PLACEHOLDER")}
                    className="opener visage-box-shadow"
                    onClick={onClick}
                    role="button"
                    tabIndex={0}
                    onKeyDown={handleKeys}
                >
                    <div className="top start-page-flexible-padding">
                        <div className="user">
                            <SpintrUser
                                hideText={true}
                                imageUrl={userImage}
                                name={user.name}
                                size={circleMedium}
                                personalName={!!!props.identity}
                            />
                        </div>
                        <div className="text-placeholder">
                            <Label
                                size="body-2"
                                color={"mid-grey"}
                            >
                                {localize("COMPOSER_PLACEHOLDER")}
                            </Label>
                        </div>
                    </div>
                    <div className="type-selector">
                        {postTypes.map((type: any, index: number) => {
                            return (
                                <UnstyledButton
                                    key={index}
                                    className="type-selector-type"
                                    onClick={() => {
                                        setType(type);
                                        setIsOpen(true);

                                        setTimeout(() => {
                                            setType(undefined);
                                        }, 1000);
                                    }}
                                    style={{
                                        backgroundColor: type.bgColor
                                    }}>
                                    <div className="icon-wrapper">
                                        <Visage2Icon icon={type.icon} hexColor={type.iconColor} type="custom" size="small" />
                                    </div>
                                    <Label size="body-2">
                                        {localize(type.titleTag)}
                                    </Label>
                                </UnstyledButton>
                            )
                        })}
                    </div>
                </div>
                {isOpen && (
                    <Visage2Composer
                        {...props}
                        types={types}
                        isUnit={props.isUnit}
                        type={type}
                        feedId={props.feedId}
                        onClosing={onClosing} />
                )}
            </ComposerContextProvider>
        </div>
    );
};

export default InlineComposer;
