import { Pivot, PivotItem } from "@fluentui/react";
import axios from "axios";
import dompurify from 'dompurify';
import React from 'react';
import { Helmet } from "react-helmet";
import { connect } from 'react-redux';
import { RouteChildrenProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { SocialBlock } from 'src/interactions/components';
import { localize } from 'src/l10n';
import { IVisageSidebarMenuItem, VisageSidebarMenuItemActiveMode, VisageSidebarMode } from "src/sidebar";
import { expandToActiveItem, setSidebarItems, setSidebarMode } from "src/sidebar/actions";
import { IApplicationState } from 'src/spintr/reducer';
import api from "src/spintr/SpintrApi";
import { ActionMenu, Breadcrumbs, Label, PageHeader, setShouldReloadFavourites } from 'src/ui';
import SpintrLoader from 'src/ui/components/Loader';
import SpintrPagination from 'src/ui/components/Pagination/SpintrPagination';
import { fromNow } from "src/utils";
import BlogsActionMenu from './BlogsActionMenu';
import './BlogsCatalogueView.scss';
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";

interface IPageRouteParams {
    path: string;
}

interface IBlogsViewProps extends RouteChildrenProps<IPageRouteParams> {
    match: any;
    isAdmin: boolean;
    isEditor: boolean;
    currentUserId: number;
    dispatch: any;
}

interface IBlogsViewState {
    isLoading: boolean;
    blog: Spintr.IBlog;
    posts: Spintr.IBlogPost[];
    isLoadingBlogPosts: boolean;
    totalCount: number;
    activePage: number;
    skip: number;
    sortOrder: string
}

class BlogsView extends React.Component<IBlogsViewProps, IBlogsViewState> {
    private _isMounted: boolean = false;

    constructor(props: IBlogsViewProps) {
        super(props);
        this.state = {
            blog: {} as Spintr.IBlog,
            posts: [],
            isLoading: true,
            isLoadingBlogPosts: false,
            totalCount: 0,
            skip: 0,
            sortOrder: "",
            activePage: 0
        };
    }

    private fetchBlogPosts = () => {
        let url = `/api/v1/blogposts?blogId=${this.state.blog.id}&includeDeleted=false&skip=${this.state.skip}&take=10`;

        if (this.state.sortOrder &&
            this.state.sortOrder.length > 0) {
            url += `&orderByColumn=${this.state.sortOrder}`;
        }

        this.setState({
            isLoadingBlogPosts: true
        }, () => {
            api.get(url).then((r) => {
                this.setState({ posts: r.data.posts, isLoadingBlogPosts: false, totalCount: r.data.totalCount });
            });
        });
    };

    fetch = () => {
        const { blogSlug } = this.props.match.params;
        const params = { route: `bloggar/${blogSlug}` };
        this.setState({ isLoading: true }, () => {
            api.get("/api/v1/routes", { params }).then((response) => {
                const blog = response.data;
                this.setState({ blog, isLoading: false }, () => {
                    this.fetchBlogPosts();
                });
            });
        });
    };

    componentWillUnmount() {
        this._isMounted = false;
    }

    public componentDidMount = () => {
        this._isMounted = true;
        this.fetch();
        this.updateSideBar();
    };

    updateSideBar() {
        let requests = [
            api.get("/api/v1/blogs/gettags"),
            api.get("/api/v1/blogs/userblogs"),
            api.get("/api/v1/blogs/0/archive")
        ];

        axios.all(requests).then((response) => {
            if (!this._isMounted) {
                return;
            }

            const tagsResponse = response[0];
            const userBlogsResponse = response[1];
            const archiveResponse = response[2];

            let items: IVisageSidebarMenuItem[] = [];

            items.push({
                key: "popular-blogs",
                name: localize("Popularaste"),
                icon: "",
                route: "/blogs/popular",
                routes: ["/blogs/popular"],
                search: ""
            });

            if (!!tagsResponse &&
                !!tagsResponse.data &&
                tagsResponse.data.length > 0) {
                items.push({
                    key: "blog-subjects",
                    name: localize("Amnen"),
                    icon: "",
                    search: "",
                    children: tagsResponse.data.map((t: any) => {
                        return {
                            key: "blog-subject-" + t.id,
                            name: t.text,
                            icon: "",
                            route: "/blogs/tags/" + t.text,
                            routes: ["/blogs/tags/" + t.text],
                            search: "",
                        }
                    })
                });
            }

            if (!!archiveResponse &&
                !!archiveResponse.data &&
                archiveResponse.data.length > 0) {
                items.push({
                    key: "blog-history",
                    name: localize("Historik"),
                    icon: "",
                    search: "",
                    children: archiveResponse.data.map((h: any) => {
                        return {
                            key: "blog-history-" + h,
                            name: h,
                            icon: "",
                            route: "/blogs/history/" + h,
                            routes: ["/blogs/history/" + h],
                            search: "",
                        }
                    })
                });
            }

            if (!!userBlogsResponse &&
                !!userBlogsResponse.data &&
                userBlogsResponse.data.length > 0) {
                items.push({
                    key: "user-blogs",
                    name: localize("MinaBloggar"),
                    icon: "",
                    search: "",
                    children: userBlogsResponse.data.map((u: any) => {
                        let blogSlug = u.url.split("/")[2];

                        return {
                            key: "user-blogs-" + u.id,
                            name: u.name,
                            icon: "",
                            route: "/blogs/" + blogSlug,
                            routes: ["/blogs/" + blogSlug],
                            search: "",
                            children: [{
                                key: "user-blogs-" + u.id + "-archive",
                                name: localize("Arkiv"),
                                icon: "",
                                route: "/blogs/" + blogSlug + "/archive",
                                routes: ["/blogs/" + blogSlug + "/archive"],
                                search: ""
                            }, {
                                key: "user-blogs-" + u.id + "-published",
                                name: localize("Publicerade") + " (" + u.articleCount.published + ")",
                                icon: "",
                                route: "/blogs/" + blogSlug + "/published",
                                routes: ["/blogs/" + blogSlug + "/published"],
                                search: ""
                            }, {
                                key: "user-blogs-" + u.id + "-drafts",
                                name: localize("Utkast") + " (" + u.articleCount.drafts + ")",
                                icon: "",
                                route: "/blogs/" + blogSlug + "/drafts",
                                routes: ["/blogs/" + blogSlug + "/drafts"],
                                search: ""
                            }, {
                                key: "user-blogs-" + u.id + "-planned",
                                name: localize("Planerade") + " (" + u.articleCount.scheduled + ")",
                                icon: "",
                                route: "/blogs/" + blogSlug + "/planned",
                                routes: ["/blogs/" + blogSlug + "/planned"],
                                search: ""
                            }, {
                                key: "user-blogs-" + u.id + "-expired",
                                name: localize("Forfallet") + " (" + u.articleCount.expired + ")",
                                icon: "",
                                route: "/blogs/" + blogSlug + "/expired",
                                routes: ["/blogs/" + blogSlug + "/expired"],
                                search: ""
                            }, {
                                key: "user-blogs-" + u.id + "-deleted",
                                name: localize("Borttagna") + " (" + u.articleCount.deleted + ")",
                                icon: "",
                                route: "/blogs/" + blogSlug + "/deleted",
                                routes: ["/blogs/" + blogSlug + "/deleted"],
                                search: ""
                            }, {
                                key: "user-blogs-" + u.id + "-create",
                                name: localize("NyBloggpost"),
                                icon: "add",
                                route: "/blogs/" + blogSlug + "/create",
                                routes: ["/blogs/" + blogSlug + "/create"],
                                search: ""
                            }]
                        }
                    })
                });
            }

            items.push({
                key: "all-blogs",
                name: localize("AllaBloggar"),
                icon: "",
                route: "/blogs/list",
                routes: ["/blogs/list"],
                search: ""
            });

            items.push({
                key: "create-blog",
                name: localize("Nyblogg"),
                route: "/blogs/create",
                routes: ["/blogs/create"],
                icon: "add",
                search: ""
            });

            const { blogSlug } = this.props.match.params;

            items.push({
                key: "create-blog-post",
                name: localize("NyBloggpost"),
                route: "/blogs/" + blogSlug + "/create",
                routes: ["/blogs/" + blogSlug + "/create"],
                icon: "add",
                search: ""
            });

            items = [{
                key: "blogs",
                name: localize("Bloggar"),
                icon: "Blog",
                iconStyle: "Custom",
                activeIcon: "Activity",
                // search: "",
                children: items,
                isExpanded: true,
                isLoading: false,
                moduleKey: "blogtop",
                // route: "/blogs",
                // routes: ["/blogs"],
                // activeMode: VisageSidebarMenuItemActiveMode.relative
            }];

            this.props.dispatch(setSidebarItems(items, "blogtop"));
            this.props.dispatch(setSidebarMode(VisageSidebarMode.submenu));
            this.props.dispatch(expandToActiveItem());
        });
    }

    public componentDidUpdate = (prevProps) => {
        if (prevProps.match.params.blogSlug !== this.props.match.params.blogSlug) {
            this.fetch();
        }
    };

    private renderBlogHeader = () => {
        const { blog } = this.state;
        const { blogSlug } = this.props.match.params;
        const { isAdmin, isEditor, currentUserId } = this.props;

        let authors = [];
        authors.push({ id: blog.author.id, name: blog.author.name });
        blog.coauthors.map((ca) => {
            authors.push({ id: ca.id, name: ca.name });
            return null;
        });

        const isAuthor = blog.author.id === currentUserId;
        const isCoauthor = blog.coauthors && blog.coauthors.some((ca) => ca.id === currentUserId);

        const categories : any[] = [
            {
                items: [
                    {
                        text: blog.isFavourite
                            ? localize("TaBortFranFavoriter")
                            : localize("LaggTillIFavoriter"),
                        onClick: blog.isFavourite
                            ? () => {
                                api.delete(`/api/v1/favorites/${blog.id}`).then((_response) => {
                                    this.setState({
                                        blog: { ...blog, isFavourite: !blog.isFavourite },
                                    });
                                    this.props.dispatch(setShouldReloadFavourites(true));
                                });
                            }
                            : () => {
                                api.post("/api/v1/favorites", { id: blog.id }).then((_response) => {
                                    this.setState({
                                        blog: { ...blog, isFavourite: !blog.isFavourite },
                                    });
                                    this.props.dispatch(setShouldReloadFavourites(true));
                                });
                            },
                        icon: "star",
                        onRenderIcon: () => {
                            return (
                                <Visage2Icon
                                    icon="star"
                                    //iconStyle={iconStyle}
                                    size="small"
                                    color="mid-grey" />
                            )
                        }
                    },
                    {
                        text: blog.isFollowing
                            ? localize("StangAvNotiser")
                            : localize("AktiveraNotiser"),
                        onClick: blog.isFollowing
                            ? () => {
                                api.delete(`/api/v1/follow/${blog.id}`).then((_response) => {
                                    this.setState({
                                        blog: { ...blog, isFollowing: !blog.isFollowing },
                                    });
                                });
                            }
                            : () => {
                                api.post("/api/v1/follow", { id: blog.id }).then((_response) => {
                                    this.setState({
                                        blog: { ...blog, isFollowing: !blog.isFollowing },
                                    });
                                });
                            },
                        icon: "flag",
                        onRenderIcon: () => {
                            return (
                                <Visage2Icon
                                    icon="flag"
                                    //iconStyle={iconStyle}
                                    size="small"
                                    color="mid-grey" />
                            )
                        }
                    },
                ],
            },

            ...(isAdmin || isEditor || isAuthor || isCoauthor
                ? [
                    {
                        title: localize("Atgarder"),
                        items: [
                            {
                                text: localize("NyBloggpost"),
                                onClick: () => {
                                    this.props.history.push({
                                        pathname: `/blogs/${blogSlug}/create`,
                                    });
                                },
                                icon: "add",
                                onRenderIcon: () => {
                                    return (
                                        <Visage2Icon
                                            icon="add-circle"
                                            //iconStyle={iconStyle}
                                            size="small"
                                            color="mid-grey" />
                                    )
                                }
                            },
                            ...(isAdmin || isEditor || isAuthor
                                ? [
                                    {
                                        text: localize("RedigeraBlogg"),
                                        onClick: () => {
                                            this.props.history.push({
                                                pathname: `/blogs/${blogSlug}/edit`,
                                            });
                                        },
                                        icon: "flag",
                                        onRenderIcon: () => {
                                            return (
                                                <Visage2Icon
                                                    icon="edit"
                                                    //iconStyle={iconStyle}
                                                    size="small"
                                                    color="mid-grey" />
                                            )
                                        }
                                    },
                                ]
                                : []),
                        ],
                    },
                ]
                : []),
        ];

        return (
            <div className="blog-header">
                <PageHeader title={blog.name} />
                <div className="actionMenuPosition">
                    <ActionMenu
                        menuClassName="hasSpintrIcons"
                        categories={categories}
                    />
                </div>
                <Label as="h3" className="blog-description">
                    {blog.description}
                </Label>
                <Label as="h3" className="blog-authors">
                    {localize("Forfattare")}:&nbsp;
                    {authors
                        .map((f) => {
                            return f.name;
                        })
                        .join(", ")}
                </Label>
            </div>
        );
    };

    public truncate = (str, toLength) => {
        return str.length > toLength ? str.substring(0, toLength - 3) + "..." : str;
    };

    public renderBlogPost = (post) => {
        const sanitizer = dompurify.sanitize;
        const key = `blogs.catalogueView.blogPost${post.id}`;
        const blogSlug = post.url.split("/")[2];
        const postSlug = post.url.split("/")[3];
        const blogLink = "/blogs/" + blogSlug;
        const postLink = "/blogs/" + blogSlug + "/" + postSlug;

        return (
            <div className="blogPost" key={key}>
                {post.image && (
                    <div className="postImage" style={{ backgroundImage: `url(${post.image})` }}>
                        <div className="gradient"></div>
                    </div>
                )}
                <div className={post.image ? "postText has-image" : "postText"}>
                    <Label as="h1" size="small-2" className="blog-title">
                        <Link to={blogLink}>{post.blog.name}</Link>
                    </Label>

                    <BlogsActionMenu
                        post={post}
                        callBack={(type) => {
                            var posts = [...this.state.posts];
                            let p = posts.find((p) => p.id === post.id);
                            switch (type) {
                                case "follow":
                                case "unfollow":
                                    p.isFollowing = !p.isFollowing;
                                    break;
                                case "favourite":
                                case "unfavourite":
                                    p.isFavourite = !p.isFavourite;
                                    break;
                                case "delete":
                                    this.fetch();
                            }
                            this.setState({ posts });
                        }}
                    />
                    <Label size="h4" as="h4" className="headline">
                        <Link to={postLink}>{post.headline}</Link>
                    </Label>

                    <div className="post-meta">
                        <Label size="body-3" color="visageGray3" title={""}>
                            {fromNow(post.publishDate, true)}
                        </Label>
                        <Label as="span" color="visageGray3" className="date-readtime-separator">|</Label>
                        <Label size="body-3" className="mini-title-readtime" title={localize("READ_TIME")}>
                            {post.readTimeMinutes + " min " + localize("READ_TIME").toLowerCase()}
                        </Label>
                    </div>

                    {post.preamble ? (
                        <Label as="p" className="preamble">
                            <Link to={postLink}>{post.preamble}</Link>
                        </Label>
                    ) : (
                        <Link to={postLink}>
                            <Label size="body-2">
                                <p
                                    className="preamble"
                                    dangerouslySetInnerHTML={{
                                        __html: this.truncate(sanitizer(post.text, { ALLOWED_TAGS: [] }), 250),
                                    }}
                                ></p>
                            </Label>
                        </Link>
                    )}
                    <SocialBlock
                        uberId={post.id}
                        interactionsBarCommentPreview={post.commentPreview}
                        staticCommentCount={post.commentCount}
                        comments={[]}
                        likers={post.likers}
                        onlyDisplayInteractionsBar={true}
                        onCommentIconClick={() => {
                            this.props.history.push({
                                pathname: `/blogs/${blogSlug}/${postSlug}`,
                            });
                        }}
                    />
                </div>
            </div>
        );
    };

    private renderBlogPosts = () => {
        if (this.state.isLoadingBlogPosts) return <SpintrLoader />;

        return (
            <div className="feed">
                {this.state.posts.length === 0 && (
                    <div className="spintr-list-empty-list">
                        <Label className="spintr-list-empty-list-label" as="p" size="body-2" color="dark-grey">
                            {localize("IngaPoster")}
                        </Label>
                    </div>
                )}

                {this.state.posts.map((post) => {
                    return this.renderBlogPost(post);
                })}
            </div>
        );
    };

    private onPivotItemClick = (item: PivotItem) => {
        const sortOrder = item.props.headerText === localize("Senaste") ?
            "" :
            "popular";

        this.setState({
            sortOrder,
            skip: 0,
            activePage: 0
        }, this.fetchBlogPosts.bind(this));
    };

    public render = () => {
        const { isLoading } = this.state;
        const { blogSlug } = this.props.match.params;

        return isLoading ? (
            <SpintrLoader />
        ) : (
            <>
                <Helmet>
                    <title>{this.state.blog.name}</title>
                </Helmet>
                <Breadcrumbs
                    displayInstance
                    items={[
                        {
                            text: localize("Bloggar"),
                            key: this.state.blog.id.toString() || 0,
                            link: "/blogs",
                        },
                        {
                            text: this.state.blog.name,
                            key: blogSlug,
                            link: `/blogs/${blogSlug}`,
                        },
                    ]}
                />
                <div className="BlogsView">
                    {this.renderBlogHeader()}
                    <Pivot overflowBehavior={"menu"} onLinkClick={this.onPivotItemClick}>
                        <PivotItem headerText={localize("Senaste")}>{this.renderBlogPosts()}</PivotItem>
                        <PivotItem headerText={localize("Popularaste")}>{this.renderBlogPosts()}</PivotItem>
                    </Pivot>
                    {
                        !this.state.isLoadingBlogPosts && (
                            <SpintrPagination
                                activePage={this.state.activePage}
                                totalCount={this.state.totalCount}
                                pageSize={10}
                                onPageChange={this.onBlogPageChange}
                            />
                        )
                    }
                </div>
            </>
        );
    };

    private onBlogPageChange = (index: number): void => {
        let skip = index * 10;

        this.setState({
            activePage: index,
            skip,
            isLoadingBlogPosts: true
        }, this.fetchBlogPosts.bind(this));
    };
}
const mapStateToProps = (state: IApplicationState, props) => {
    return {
        ...props,

        isAdmin: state.profile.active.isAdmin,
        isEditor: state.profile.active.isEditor,
        currentUserId: state.profile.active.id,
    };
};

export default withRouter(connect(mapStateToProps)(BlogsView));
