import classNames from "classnames";
import { Icon } from "@fluentui/react";
import React, { FunctionComponent, useCallback } from "react";
import { connect } from "react-redux";
import { Conversation } from "src/chat/conversation-types";
import { Label, UnstyledButton } from "src/ui";
import { setTabState, SetTabStateHandler } from "../../redux";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import "./HiddenChatTab.scss";

interface IDispatchProps {
    setTabState: SetTabStateHandler,
}

interface IOwnProps {
    conversation: Conversation;
}

type Props = IDispatchProps & IOwnProps;

const HiddenChatTab: FunctionComponent<Props> = (props) => {
    const bringToFront = useCallback(
        () => props.setTabState(props.conversation.id, true, false),
        [props.conversation.id, props.setTabState]
    );

    const close = useCallback(
        () => props.setTabState(props.conversation.id, false),
        [props.conversation.id, props.setTabState]
    );

    return (
        <div className={classNames(
            "HiddenChatTab",
            props.conversation.unread > 0
                ? "unread"
                : ""
        )}>
            <div className="name" role="button" onClick={bringToFront}>
                <Label size="body-2">
                    {props.conversation.title}
                </Label>
            </div>
            {props.conversation.unread > 0 && (
                <div className="unread-count">
                    <Label
                        as="span"
                        size="small-2"
                        weight="normal"
                    >
                        {props.conversation.unread}
                    </Label>
                </div>
            )}
            <div className="close-button">
                <UnstyledButton onClick={close}>
                    <Visage2Icon icon="close-circle" />
                </UnstyledButton>
            </div>
        </div>
    );
};

const ConnectedHiddenChatTab = connect<{}, IDispatchProps, IOwnProps>(
    () =>({}), { setTabState }
)(HiddenChatTab);

export default ConnectedHiddenChatTab;
