import { ContentState, DraftHandleValue, EditorState, getDefaultKeyBinding, Modifier } from "draft-js";
import createEmojiPlugin, {
    EmojiPlugin
} from "draft-js-emoji-plugin";
import createMentionPlugin, {
    DraftMentionObject,
    MentionSearchChangedEvent,
    MentionsPlugin
} from 'draft-js-mention-plugin';
import Editor from "draft-js-plugins-editor";
import React, { Component, createRef, ReactNode, RefObject } from "react";
import { Subject, Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { localize } from "src/l10n";
import { searchUsers } from "src/search";
import startDraftWcagCheck from "src/utils/draftWcagCheck";
import { getContentStateFromString } from "src/utils/getContentStateFromString";
import { draftToHtml } from "./Composer/utils";

interface IInteractiveTextInputProps {
    onFocus?: Spintr.CallbackAction;
    onEnter?: Spintr.CallbackAction;
    onChange?: Spintr.CallbackAction;
    onBlur?: Spintr.CallbackAction;
    placeholder?: string;
    initialContent?: string;
    bigChatViewPopoutPosition?: boolean;
}

interface IInteractiveTextInputState {
    editorState: EditorState;
    suggestions: DraftMentionObject[];
}

class InteractiveTextInput extends Component<IInteractiveTextInputProps, IInteractiveTextInputState> {
    protected mentionPlugin: MentionsPlugin;
    protected emojiPlugin: EmojiPlugin;
    protected editorRef: RefObject<Editor>;
    protected mentionSubject: Subject<string>;
    protected mentionSubscription: Subscription;

    constructor(props: IInteractiveTextInputProps) {
        super(props);

        this.state = {
            editorState: props.initialContent ?
                EditorState.createWithContent(getContentStateFromString(props.initialContent)) :
                EditorState.createEmpty(),
            suggestions: []
        }

        this.onAddMention = this.onAddMention.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onSearchChange = this.onSearchChange.bind(this);

        if (this.props.bigChatViewPopoutPosition) {
            const mentionPluginOptions = {
                positionSuggestions: (settings) => {
                    let transform;
                    let transition;

                    if (settings.state.isActive && settings.props.suggestions.length > 0) {
                        transform = 'scaleY(1)';
                        transition = 'all 0.25s cubic-bezier(.3,1.2,.2,1)';
                    } else if (settings.state.isActive) {
                        transform = 'scaleY(0)';
                        transition = 'all 0.25s cubic-bezier(.3,1,.2,1)';
                    }

                    return {
                        transform,
                        transition,
                        bottom: "50px",
                        left: '0px',
                    };
                }
            };

            const emojiPluginOptions = {
                positionSuggestions: (settings) => {
                    return {
                        transform: 'scaleY(1)',
                        transition: 'all 0.25s cubic-bezier(.3,1.2,.2,1)',
                        bottom: "50px",
                        left: '0px',
                    };
                }
            };

            this.mentionPlugin = createMentionPlugin(mentionPluginOptions);
            this.emojiPlugin = createEmojiPlugin(emojiPluginOptions);
        } else {
            this.mentionPlugin = createMentionPlugin();
            this.emojiPlugin = createEmojiPlugin();
        }

        this.editorRef = createRef();

        this.onMentionValue = this.onMentionValue.bind(this);
        this.mentionSubject = new Subject();
        this.mentionSubscription = this.mentionSubject
            .pipe(
                debounceTime(100)
            )
            .subscribe(this.onMentionValue);

        startDraftWcagCheck();
    }

    protected async onMentionValue(value: string): Promise<void> {
        try {
            const results = await searchUsers({ query: value });

            this.setState({
                suggestions: results.data.map(hit => ({
                    avatar: hit.imageUrl,
                    key: hit.id,
                    link: `/goto/1/${hit.id}`,
                    name: hit.name
                }))
            });
        } catch (err) {
            console.log(err);
        }
    }

    public clearContent(): void {
        let editorState = EditorState.push(this.state.editorState, ContentState.createFromText(''), 'remove-range');
        let contentState = editorState.getCurrentContent()
        editorState = EditorState.forceSelection(editorState, contentState.getSelectionAfter())

        this.setState({
            editorState
        });
    }

    public getContent(): string {
        let content = draftToHtml(
            this.state.editorState.getCurrentContent()
        );

        if (content === "<br />") {
            content = "";
        }

        return content;
    }

    public giveFocus(): void {
        setTimeout(() => { //Without this mentions don't work
            if (!this ||
                !this.editorRef ||
                !this.editorRef.current) {
                return;
            }

            this.editorRef.current.focus();
        }, 0);
    }

    public myKeyBindingFn(e): string {
        if (!!this.props.onEnter &&
            e.keyCode === 13 && /* `Enter` key */
            !e.nativeEvent.shiftKey) {
            return 'custom-enter';
        }

        return getDefaultKeyBinding(e);
    }

    public handleKeyCommand(command) {
        if (command === 'custom-enter') {
            this.props.onEnter();

            return 'handled';
        }

        return 'not-handled';
    }

    handlePaste = (text): DraftHandleValue => {
        this.onChange(EditorState.push(
            this.state.editorState,
            Modifier.replaceText(
                this.state.editorState.getCurrentContent(),
                this.state.editorState.getSelection(),
                text.replace(/\n/g, ' ')
            ), 'insert-characters'
        ));
        return 'handled';
    }

    public render(): ReactNode {
        const { MentionSuggestions } = this.mentionPlugin;
        const { EmojiSuggestions } = this.emojiPlugin;

        return (
            <div className="InteractiveTextInputs">
                <Editor
                    onBlur={this.props.onBlur}
                    handlePastedText={this.handlePaste}
                    ariaLabel={localize("SkrivEnKommentar")}
                    ref={this.editorRef}
                    editorState={this.state.editorState}
                    onChange={this.onChange}
                    onFocus={this.props.onFocus}
                    keyBindingFn={this.myKeyBindingFn.bind(this)}
                    handleKeyCommand={this.handleKeyCommand.bind(this)}
                    placeholder={this.props.placeholder ?
                        this.props.placeholder :
                        localize("COMPOSER_PLACEHOLDER")}
                    plugins={[
                        this.mentionPlugin,
                        this.emojiPlugin
                    ]}
                />
                <MentionSuggestions
                    onAddMention={this.onAddMention}
                    suggestions={this.state.suggestions}
                    onSearchChange={this.onSearchChange}
                />
                <EmojiSuggestions />
            </div>
        );
    }

    protected onAddMention(_: DraftMentionObject) {
        console.log(_)
        /* ignore */
    }

    protected onChange(editorState: EditorState) {
        this.setState(
            { editorState }, 
            () => {
                if (this.props.onChange) {
                    this.props.onChange();
                }
            },
        );
    }

    protected onSearchChange(event: MentionSearchChangedEvent): void {
        const { value } = event;

        if (value) {
            this.mentionSubject.next(value);
            return;
        }

        this.setState(
            { suggestions: [] },
            () => this.mentionSubject.next(value)
        );
    }
}

export default InteractiveTextInput;