import { Label } from "@fluentui/react";
import React, { Component, ReactNode } from "react";
import { localize } from "src/l10n";
import "./FormControl.scss";

export interface IFormControlProps {
    className?: string;
    children: ReactNode;
    htmlFor?: string;
    label?: string;
    hasTopSpacing?: boolean;
    required?: boolean;
    validationFailed?: boolean;
}

class FormControl extends Component<IFormControlProps> {
    public render() : ReactNode {
        let className = "FormControl";

        if (this.props.hasTopSpacing) {
            className += " spacingTop";
        }

        if (this.props.className) {
            className += " " + this.props.className;
        }

        if (this.props.validationFailed) {
            className += " " + "validationFailed";
        }

        return (
            <div className={className}>
                {
                    this.props.label ?
                        <div className="label">
                            <Label
                                required={this.props.required}
                                htmlFor={this.props.htmlFor}
                            >
                                {this.props.label}
                            </Label>
                        </div> :
                        null
                }
                <div className="control">
                    {
                        this.props.children
                    }
                </div>
                {
                    this.props.validationFailed && (
                        <Label>
                            <span className="required-field-text-replica">
                                {
                                    localize("VALIDATION_FIELDNAME_IS_REQUIRED")
                                }
                            </span>
                        </Label>
                    )
                }
            </div>
        );
    }
}

export default FormControl;