import axios, { CancelTokenSource } from "axios";
import { TextField } from "@fluentui/react";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import "src/groups/views/GroupPopupView.scss";
import { localize } from "src/l10n";
import { executeSearchAction, ISearchParams, SearchResultObjectHit } from "src/search";
import { SpintrTypes } from "src/typings";
import { Loader } from "src/ui";
import { Label } from "src/ui/components";
import { debounce } from "src/utils";

interface IProps {
    groupId: number;
    dispatch?: any;
    history?: any;
    location?: any;
}

interface IState {
    isLoading: boolean;
    query: string;
    results: Spintr.ISearchResult[];
    totalCount: number;
    errors: string[];
}

class GroupSearchPopupView extends Component<IProps, IState> {
    protected cancelTokenSource: CancelTokenSource;

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            query: "",
            results: [],
            totalCount: 0,
            errors: []
        }
    }

    protected onResultClick(item: Spintr.ISearchResult): void {
        let url = item.url;

        if (item.type === SpintrTypes.UberType.File) {
            url = "/groups/" +
                this.props.groupId +
                "/files/" +
                url.split("/").pop();
        }

        this.props.history.push(url);
    }

    renderResult() {
        if (this.state.isLoading) {
            return (
                <Loader />
            )
        }

        if (!this.state.query || this.state.query.length === 0) {
            return (
                <Label size="body-3">
                    {
                        localize("GROUP_SEARCH_PLACEHOLDER2")
                    }
                </Label>
            )
        }

        if (this.state.errors && this.state.errors.length > 0) {
            return (
                <div>
                    {
                        this.state.errors.map((error, index) => {
                            return (
                                <Label as="p" key={index}>
                                    {
                                        localize(error)
                                    }
                                </Label>
                            )
                        })
                    }
                </div>
            )
        }

        if (this.state.results.length === 0) {
            return (
                <Label>
                    {
                        localize("IngaTraffar")
                    }
                </Label>
            )
        }

        return (
            <div>
                <ul>
                    {this.state.results.slice(0, 5).map((result) => (
                        <li
                            className="item"
                            key={`HeaderSearch.Result${result.id}`}
                        >
                            <SearchResultObjectHit
                                item={result}
                                onClick={this.onResultClick.bind(this)}
                            />
                        </li>
                    ))}
                </ul>
                <Link
                    className="GroupSearchPopupView-button"
                    to={`/search?q=${this.state.query}&g=${this.props.groupId}`}>
                    {
                        localize("GROUP_SEARCH_SHOW_ALL") + " (" + this.state.totalCount + ")"
                    }
                </Link>
            </div>
        )
    }

    handleCatch = (saveError) => {
        let errors = [];

        if (saveError &&
            saveError.response &&
            saveError.response.data &&
            saveError.response.data.errorlist) {
            errors = saveError.response.data.errorlist;
        } else {
            errors.push(localize("TeknisktFel"));
        }

        this.setState({
            errors: errors,
            isLoading: false,
            results: [],
            totalCount: 0
        });
    }

    search() {
        if (!this.state.query ||
            this.state.query.length === 0) {
            return;
        }

        this.setState({
            isLoading: true,
            results: [],
            totalCount: 0,
            errors: []
        }, () => {
            const params : ISearchParams = {
                query: this.state.query,
                groupId: this.props.groupId,
                take: 5
            }

            if (typeof this.cancelTokenSource != typeof undefined) {
                this.cancelTokenSource.cancel("Operation canceled due to new request.")
            }
    
            this.cancelTokenSource = axios.CancelToken.source()

            this.props.dispatch(executeSearchAction(params, this.cancelTokenSource.token)).then((response) => {
                this.setState({
                    isLoading: false,
                    results: response.value.data,
                    totalCount: response.value.headers["x-total-count"],
                    errors: []
                });
            }).catch(this.handleCatch.bind(this));
        });
    }

    debouncedSearch = debounce(() => this.search(), 500);

    render() {
        return (
            <div className="GroupPopupView GroupSearchPopupView">
                <div role="search" className="GroupPopupView-inner">
                    <TextField
                        value={this.state.query}
                        onChange={(_ev, query) => {
                            this.setState({
                                query,
                                isLoading: !!query && query.length > 0,
                                results: [],
                                totalCount: 0,
                                errors: []
                            }, this.debouncedSearch);
                        }}
                        //label={localize("GROUP_SEARCH_PLACEHOLDER")}
                        placeholder={localize("GROUP_SEARCH_PLACEHOLDER")}
                    />
                    <div className="GroupSearchPopupView-content">
                        <Label size="body-3">
                        {
                            this.renderResult()
                        }
                        </Label>

                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, props) => ({
    ...props
});

export default withRouter(connect(mapStateToProps)(GroupSearchPopupView));
