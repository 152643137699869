import React, { Component } from "react";
import { connect } from "react-redux";
import { Action } from "redux";
import { localize } from "src/l10n";
import { fetchAttachedFolder } from "src/pages/page-actions";
import api from "src/spintr/SpintrApi";
import { IApplicationState } from "src/spintr/reducer";
import { Label, Loader, UnstyledButton } from "src/ui";
import { decodeHtmlEntities } from "src/utils";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import TinyFile from "./TinyFile";
import "./TinyFolder.scss";
import { SpintrTypes } from "src/typings";

interface IProps {
    item: any;
    minimizeAttachedFilelists?: boolean;
    dispatch: (action: Action) => void;
    attachedFolder: any;
}

interface IState {
    isExpanded: boolean;
    isLoading?: boolean;
    files?: any;
    isError?: boolean;
}

class TinyFolder extends Component<IProps, IState> {
    constructor(props) {
        super(props);

        this.state = {
            isExpanded: !props.minimizeAttachedFilelists,
            isLoading: !!props.item.externalId,
        };

        if (props.item.externalId) {
            this.fetchExternalFolder();
        } else if (!this.props.attachedFolder) {
            this.props.dispatch(
                fetchAttachedFolder(
                    this.props.item,
                    this.props.item.source,
                )
            );
        }
    }

    private fetchExternalFolder = async () => {
        try {
            const source = parseInt(this.props.item.source, 10);

            const response = await api.get("/api/v1/files", {
                params: {
                    folderId: this.props.item.externalId,
                    source: this.props.item.source,
                    includeThumbnail: false,
                },
            });

            this.setState({
                isLoading: false,
                files: response.data.data
                    .filter((file) => !file.isDirectory)
                    .map((file) => {
                        if (source === SpintrTypes.FolderRootSource.GoogleDrive) {
                            return {
                                id: file.id,
                                externalId: file.id,
                                name: file.name,
                                webLink: file.webViewLink,
                                downloadUrl: file.webContentLink,
                                type: file.fileType,
                                size: !file.size ? undefined : Math.round((file.size / 1024) * 10) / 10,
                                source,
                            };
                        }

                        return file;
                    }),
            });
        } catch {
            this.setState({
                isLoading: false,
                isError: true,
            });
        }
    };

    public render() {
        const { attachedFolder } = this.props

        if (this.state.isLoading || attachedFolder?.isLoading ) {
            return <Loader />;
        }

        if (this.state.isError || attachedFolder?.isError) {
            return <Label className="TinyFolder-MissingRights">{localize("YOU_LACK_FILE_RIGHTS")}</Label>;
        }

        let files = attachedFolder?.data || this.state.files || [];
        files = files?.filter(f => !f.isDirectory)?.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

        return (
            <div className="TinyFolder-wrapper">
                <UnstyledButton onClick={() => {
                    this.setState({
                        isExpanded: !this.state.isExpanded
                    });
                }}>
                    <div className="TinyFolder-header no-user-select">
                        <Visage2Icon icon={this.state.isExpanded ? "minus" : "add"} />
                        <Label className="TinyFolder-header-text" weight="medium">
                            {
                                decodeHtmlEntities(!!this.props.attachedFolder ? this.props.attachedFolder.name : this.props.item.name)
                            }
                        </Label>
                    </div>
                </UnstyledButton>
                {this.state.isExpanded &&
                    (files.length === 0 ? (
                        <Label>{localize("IngaFilerHittades")}</Label>
                    ) : (
                        <div className="TinyFolder-content">
                            {files.map((f, idx) => (
                                // sharepoint list searches don't have ids.. using index instead, bad?
                                <TinyFile
                                    key={idx}
                                    item={f}
                                    external={this.props.item.external}
                                />
                            ))}
                        </div>
                    ))}
            </div>
        );
    }
}

const mapStateToProps = (state: IApplicationState, props) => {
    return {
        ...props,
        minimizeAttachedFilelists: state.instance.get("minimizeAttachedFilelists") as boolean,
        attachedFolder: state.pages.attachedFolders.find((f) => f.id == props.item.id)
    };
};

export default connect(mapStateToProps)(TinyFolder);