import React, { FunctionComponent } from "react";

import {
    BarChartWidget,
    GaugeWidget,
    KeyPointIndicatorWidget,
    LineChartWidget,
    ListWidget,
    PieChartWidget,
    MultiKeyPointIndicatorWidget
} from "src/marketplace/widgets";
import { SpintrTypes } from "src/typings";

interface IProps {
    config?: any;
    data: any;
    displayType: Spintr.WidgetType;
    variables: { [key: string]: string };
}

const MarketplaceWidgetData: FunctionComponent<IProps> = (props) => {
    const vars = props.variables || {};

    const getWidget = () => {
        switch (props.displayType) {
            case SpintrTypes.WidgetType.KeyPointIndicator:
                if (Array.isArray(props.data)) {
                    return (
                        <MultiKeyPointIndicatorWidget
                            config={props.config}
                            data={props.data}
                            variables={props.variables} />
                    )
                }

                return (
                    <KeyPointIndicatorWidget
                        primaryValue={
                            !props.data || typeof props.data.value === "undefined"
                                ? ""
                                : props.data.value
                        }
                        secondaryValue={
                            vars.text || props.data?.text || ""
                        }
                        difference={props.data?.difference}
                        config={props.config}
                        variables={props.variables}
                    />
                );

            case SpintrTypes.WidgetType.LineChart:
                return <LineChartWidget
                    data={props.data}
                    config={props.config}
                />

            case SpintrTypes.WidgetType.BarChart:
                return <BarChartWidget
                    data={props.data}
                    config={props.config}
                />;

            case SpintrTypes.WidgetType.PieChart:
                return <PieChartWidget
                    innerRadius={0}
                    outerRadius={45}
                    height={90}
                    width={90}
                    data={[{
                        value: 25,
                    }, {
                        value: 75,
                    }]}
                />;


            case SpintrTypes.WidgetType.List:
            case SpintrTypes.WidgetType.ListOneColumn:
                return <ListWidget
                    items={props.data}
                />;

            case SpintrTypes.WidgetType.GagueChart:
                return <GaugeWidget data={props.data} />

            default: return null;
        }
    };

    return (
        <div className="MarketplaceWidgetData">
            {getWidget()}
        </div>
    )
};

export default MarketplaceWidgetData;
