import React, { Component, RefObject } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { localize } from "src/l10n";
import { SpintrTypes } from 'src/typings';
import { EditableContentSectionsRow, FooterCreateButton, Label } from "src/ui";
import { Style } from "src/ui/helpers";
import { uniqueId } from "src/utils";
import { UberContentRow } from '../UberContent.types';

export type EditableContentSectionsProps = {
    contentRows:            UberContentRow[],
    onChange:               (rows: UberContentRow[]) => void,
    createBlockIfEmpty?:    boolean | undefined;
    enabledColumnTypes?:    SpintrTypes.UberContentSectionType[];
}

interface IState {
    isDisplayingWidthTypeSelector: boolean,
    contentRows: UberContentRow[]
}

class EditableContentSections extends Component<EditableContentSectionsProps, IState> {
    protected widthTypeSelector: RefObject<HTMLDivElement>;

    constructor(props: EditableContentSectionsProps) {
        super(props);

        this.onRowUpdated = this.onRowUpdated.bind(this);
        this.removeRow = this.removeRow.bind(this);

        this.state = {
            isDisplayingWidthTypeSelector: false,
            contentRows: props.contentRows.map((cr) => ({
                ...cr,
                tmpId: uniqueId(),
                uberContentColumns: cr.uberContentColumns.map((cc) => ({
                    ...cc,
                    tmpId: uniqueId()
                })),
                onChange: this.onRowUpdated,
                removeRow: this.removeRow,
            }))
        }

        this.widthTypeSelector = React.createRef();
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside.bind(this));

        if (this.props.createBlockIfEmpty &&
            (!this.props.contentRows || this.props.contentRows.length === 0)) {
            this.addRow(1);
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside.bind(this));
    }

    handleClickOutside(event) {
        if (this &&
            this.state &&
            this.props &&
            this.state.isDisplayingWidthTypeSelector &&
            this.widthTypeSelector &&
            this.widthTypeSelector.current &&
            !this.widthTypeSelector.current.contains(event.target)) {
            this.setState({
                isDisplayingWidthTypeSelector: false
            });
        }
    }

    postUpdatedListToParent() {
        console.log("post update from sections");
        this.props.onChange(this.state.contentRows);
    }

    onRowUpdated(data) {
        console.log("sections: row updated");
        this.setState({
            contentRows: this.state.contentRows.map(cr => {
                if (cr.tmpId === data.tmpId) {
                    return data;
                } else {
                    return cr;
                }
            })
        }, this.postUpdatedListToParent);
    }

    removeRow(rowTmpId) {
        this.setState({
            contentRows: this.state.contentRows.filter(contentRow => {
                return contentRow.tmpId !== rowTmpId
            })
        }, this.postUpdatedListToParent);
    }

    addRow(columnCount: number) {
        let columns = [];

        for (let i = 0; i < columnCount; i++) {
            columns.push({
                id: 0,
                tmpId: uniqueId(),
                index: i,
                type: SpintrTypes.UberContentSectionType.Text,
                state: SpintrTypes.UberContentSectionState.IsDisplayingPlusSign,
                content: '',
            });
        }

        this.setState({
            contentRows: [
                ...this.state.contentRows,
                {
                    id: 0,
                    tmpId: uniqueId(),
                    index: this.state.contentRows.length,
                    uberContentColumns: columns,
                    onChange: this.onRowUpdated,
                    removeRow: this.removeRow,
                }
            ],
            isDisplayingWidthTypeSelector: false
        }, this.postUpdatedListToParent);
    }

    renderWidthTypeSelector() {
        return (
            <div ref={this.widthTypeSelector} style={{
                position: "relative"
            }}>
                <div className="box-shadow-and-border" style={{
                    backgroundColor: "#FFF",
                    borderRadius: "200px",
                    padding: "2px",
                    margin: "0 auto",
                    width: "257px"
                }}>
                    <div style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        borderRadius: "200px",
                    }}>
                        <div
                            className='WidthTypeSelector-items'
                            onClick={() => {
                                this.addRow(1);
                            }}
                            style={{
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "column",
                                padding: "10px 8px 4px 8px",
                                width: "83px",
                                cursor: "pointer"
                            }}>
                            <div style={{
                                height: "16px",
                                width: "16px",
                                borderRadius: "2px",
                                backgroundColor: "#C9CBF1"
                                }}></div>

                            <div>
                                <Label as="span" weight='medium' size="body-3" color="dark-grey">
                                    {
                                        localize("Enkel")
                                    }
                                </Label>
                            </div>
                        </div>
                        <div
                            className='WidthTypeSelector-items'
                            onClick={() => {
                                this.addRow(2);
                            }}
                            style={{
                                cursor: "pointer",
                                width: "83px",
                                padding: "10px 8px 4px 8px",
                            }}>
                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                            }}>
                                <div style={{
                                    height: "16px",
                                    marginRight: Style.getSpacingStr(1),
                                    backgroundColor: "#C9CBF1",
                                    width: 'calc(25% - ' + 6 + 'px)',
                                    borderRadius: "2px",
                                }}></div>
                                <div style={{
                                    height: "16px",
                                    backgroundColor: "#C9CBF1",
                                    width: 'calc(25% - ' + 6 + 'px)',
                                    borderRadius: "2px",
                                }}></div>
                            </div>
                            <div style={{ textAlign: "center" }}>
                                <Label as="span" weight='medium' size="body-3" color="dark-grey">
                                    {
                                        localize("Dubbel")
                                    }
                                </Label>
                            </div>
                        </div>
                        <div
                            className='WidthTypeSelector-items'
                            onClick={() => {
                                this.addRow(3);
                            }}
                            style={{
                                cursor: "pointer",
                                width: "83px",
                                padding: "10px 8px 4px 8px",
                            }}>
                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                            }}>
                                <div style={{
                                    height: "16px",
                                    marginRight: Style.getSpacingStr(1),
                                    backgroundColor: "#C9CBF1",
                                    width: 'calc((100% / 5) - ' + ((Style.getSpacing(3) / 2)) + 'px)',
                                    borderRadius: "2px"
                                }}></div>
                                <div style={{
                                    height: "16px",
                                    marginRight: Style.getSpacingStr(1),
                                    backgroundColor: "#C9CBF1",
                                    width: 'calc((100% / 5) - ' + ((Style.getSpacing(3) / 2)) + 'px)',
                                    borderRadius: "2px"
                                }}></div>
                                <div style={{
                                    height: "16px",
                                    backgroundColor: "#C9CBF1",
                                    width: 'calc((100% / 5) - ' + ((Style.getSpacing(3) / 2)) + 'px)',
                                    borderRadius: "2px"
                                }}></div>
                            </div>
                            <div style={{ textAlign: "center" }}>
                                <Label as="span" weight='medium' size="body-3" color="dark-grey">
                                    {
                                        localize("Trippel")
                                    }
                                </Label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderAddNewContentRowButton() {
        return (
            <div>
                <a onClick={() => {
                    this.setState({
                        isDisplayingWidthTypeSelector: true
                    });
                }}>
                    <FooterCreateButton
                        isDisplayingWidthTypeSelector={this.state.isDisplayingWidthTypeSelector}>
                    </FooterCreateButton>
                </a>
            </div>
        )
    }

    renderFooter() {
        return (
            <div>
                {this.renderAddNewContentRowButton()}
                {
                    this.state.isDisplayingWidthTypeSelector &&
                    this.renderWidthTypeSelector()
                }
            </div>
        )
    }

    onDragEnd = (result) => {
        const { source, destination } = result;

        if (!destination) {
            return;
        }

        const updatedContentRows = [...this.state.contentRows];

        const fromIndex = source.index;
        const toIndex = destination.index;

        const c = updatedContentRows[fromIndex];

        updatedContentRows.splice(fromIndex, 1);
        updatedContentRows.splice(toIndex, 0, c);

        this.setState({
            contentRows: updatedContentRows,
        }, this.postUpdatedListToParent);
    }

    render() {
        return (
            <div className={"EditableContentSections"} style={{
                marginBottom: Style.getSpacingStr(5)
            }}>
                <DragDropContext
                    onDragEnd={this.onDragEnd}
                >
                    <Droppable droppableId="editableContentSections">
                        {(provided, snapshot) => (
                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                {this.state.contentRows.map(
                                    (item, index) => {
                                        return (
                                            <Draggable draggableId={item.tmpId.toString()} index={index} key={item.tmpId}>
                                                {(provided, _snapshot) => (
                                                    <div
                                                        className="draggable-item"
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                    >
                                                        <EditableContentSectionsRow contentRow={item} handleProps={provided.dragHandleProps} enabledColumnTypes={this.props.enabledColumnTypes} />
                                                    </div>
                                                )}
                                            </Draggable>
                                        );
                                    }
                                )}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
                {
                    this.renderFooter()
                }
            </div>
        )
    }
}

export default EditableContentSections;
