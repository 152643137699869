import { AxiosResponse } from "axios";
import api from "src/spintr/SpintrApi";

import * as Types from "../message-types";

const baseUrl = "/api/v1/messages";

export type QueryMessageApiHandler =
    (params: Types.IQueryMessagesParams) => Promise<AxiosResponse<Spintr.IChatMessage[]>>;

export const queryMessagesApi: QueryMessageApiHandler =
    (params) => api.get<Spintr.IChatMessage[]>(baseUrl, { params });

export type ReactToMessageApiHandler =
    (messageId: number, reactionId: number) => Promise<AxiosResponse<never>>;

export const postReactionToMessage: ReactToMessageApiHandler =
    (messageId, reactionId) => api.post<never>(
        baseUrl + "/" + messageId + "/reaction",
        { reactionId }
    );

export type RemoveReactionApiHandler =
    (messageId: number, reactionId: number) => Promise<AxiosResponse<never>>;

export const removeReactionFromMessage: RemoveReactionApiHandler =
    (messageId, reactionId) => api.delete<never>(
        baseUrl + "/" + messageId + "/reaction/" + reactionId
    );

export type PostMessageApiHandler =
    (message: Types.Message) => Promise<AxiosResponse<Spintr.IChatMessage>>;
export const postMessage: PostMessageApiHandler = 
    (message) => api.post<Spintr.IChatMessage>(
        baseUrl,
        message
    );