import React, { Component, ReactNode } from "react";
import { connect } from "react-redux";
import { IActiveUserProfile } from "src/profile/reducer";
import { IApplicationState } from "src/spintr/reducer";
import "./ReactionPicker.scss";
import CommentForm from "./CommentForm";
import { IReactionPickerConfig } from "src/ui/reducer";
import { likeTypes } from "../types";
import { Label, setReactionPickerConfig, UnstyledButton } from "src/ui";
import { Player } from "@lottiefiles/react-lottie-player";
import { localize } from "src/l10n";
import { mixpanelTrack } from "src/utils";
import { addLike, removeLike } from "../actions";
import { SpintrTypes } from "src/typings";

interface IProps {
    config?: IReactionPickerConfig;
    allInteractions?: any;
    dispatch?: any;
    currentUser?: any;
    isSmallViewMode?: boolean;
}

interface IState {
    pos?: any;
}

class ReactionPicker extends Component<IProps, IState> {
    private _isMounted: boolean = false;
    private wrapperRef = React.createRef<HTMLDivElement>();

    constructor(props: IProps) {
        super(props);

        this.state = {
            pos: undefined
        };

        this.wrapperRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        this._isMounted = false;
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside(event) {
        if (!this._isMounted) {
            return;
        }

        if (this.props.isSmallViewMode &&
            !!this.props.config &&
            this.props.config.isVisible &&
            this.wrapperRef &&
            !this.wrapperRef.current.contains(event.target)) {
            this.props.dispatch(setReactionPickerConfig(undefined));
        }
    }

    hasLiked(likers: Spintr.IUser[]) {
        if (!this._isMounted) {
            return false;
        }

        if (!this.props.config) {
            return false;
        }

        const hasLiked: boolean = this.props.config.identity ? likers.some(user => user.id === this.props.config.identity.id) :
            likers.some(user => user.id === this.props.currentUser.id);

        return hasLiked;
    }

    onLikeIconClick(likeType: number) {
        if (!this._isMounted) {
            return null;
        }

        if (!this.props.config) {
            return null;
        }

        const interactions = this.props.allInteractions[this.props.config.uberId];

        if (!interactions ||
            interactions.likers === undefined) {
            return;
        }

        const likers: Spintr.IUser[] = interactions.likers || [];
        const hasLiked = this.hasLiked(interactions.likers);

        let currentLikeType = 0;

        if (hasLiked) {
            const currentUserLike = likers.find((l: any) => l.id === this.props.currentUser.id);

            if (!!currentUserLike) {
                currentLikeType = currentUserLike.likeType || 0;
            }
        }

        // TODO: Add impersonating for unit pages (currently broken in backend)
        if (hasLiked && likeType === currentLikeType) {
            this.props.dispatch(removeLike(this.props.config.uberId, { ...this.props.currentUser, likeType }, this.props.config.identity));
        } else {
            this.props.dispatch(addLike(this.props.config.uberId, { ...this.props.currentUser, likeType }, this.props.config.identity));

            if (this.props.config.sendToMixpanel) {
                mixpanelTrack("Like");
            }
        }

        this.props.dispatch(setReactionPickerConfig(undefined));
    }

    updatePosition() {
        if (!this._isMounted) {
            return null;
        }

        if (!this.props.config) {
            return null;
        }

        if (!!this.props.config.elementId) {
            const el = document.getElementById(this.props.config.elementId);

            if (!el) {
                return;
            }

            const rect = el.getBoundingClientRect();

            this.setState({
                pos: rect
            });
        } else {
            this.setState({
                pos: undefined
            })
        }
    }

    componentDidUpdate(prevProps: IProps) {
        if (!this._isMounted) {
            return;
        }

        if (!!this.props.config !== !!prevProps.config ||
            (!!this.props.config &&
                !!prevProps.config &&
                (this.props.config.elementId !== prevProps.config.elementId || this.props.config.isVisible !== prevProps.config.isVisible))) {
            this.updatePosition();
        }
    }

    isHoveringCollection = (elements: HTMLCollectionOf<Element>) => {
        if (!this._isMounted) {
            return false;
        }

        let result = false;

        Array.from(elements).forEach(function (item) {
            const isHover = e => item.querySelector(':hover') === e;

            if (isHover) {
                result = true;
            } else if (!!item.children) {
                const childrenResult = this.isHoveringCollection(item.children);

                if (childrenResult) {
                    result = true;
                }
            }
        });

        return result;
    }

    isHovering = () => {
        if (!this._isMounted) {
            return false;
        }

        const elements = document.getElementsByClassName("ReactionPicker");

        if (!elements) {
            return false;
        }

        return this.isHoveringCollection(elements);
    }

    public render(): ReactNode {
        let classNames = ["ReactionPicker"];

        if (!this.props.config || !this.props.config.isVisible) {
            classNames.push("hidden");
        }

        return (
            <div
                ref={this.wrapperRef}
                className={classNames.join(" ")}
                style={{
                    top: !this.state.pos || !this.props.config ? -2000 : (this.state.pos.y + window.scrollY + this.props.config.yOffset),
                    left: !this.state.pos || !this.props.config ? -2000 : (this.state.pos.x + this.props.config.xOffset)
                }}
            >
                {
                    likeTypes.map((lt: any) => {
                        return (
                            <UnstyledButton className={"LikeType" + lt.id} key={lt.id} onClick={() => {
                                this.onLikeIconClick(lt.id);
                            }}>
                                <div className="reaction-tooltip">
                                    <Label color="white" size="small-1">{localize(lt.name, true)}</Label>
                                </div>
                                <Player
                                    style={{ height: "100%", width: "100%" }}
                                    src={lt.json}
                                    //src={animation}
                                    autoplay
                                    loop={true}
                                />
                            </UnstyledButton>
                        )
                    })
                }
            </div>
        );
    }
}

export default connect(
    (state: IApplicationState, props: IProps): IProps => ({
        ...props,
        config: state.ui.reactionPickerConfig,
        allInteractions: state.interactions,
        currentUser: state.profile.active,
        isSmallViewMode: state.ui.viewMode <= SpintrTypes.ViewMode.PhoneLandscape,
    })
)(ReactionPicker);
