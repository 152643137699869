import { DefaultButton, TooltipHost, TooltipHostBase } from "@fluentui/react";
import classNames from "classnames";
import React, { Component } from "react";
import { connect } from "react-redux";
import ShowMoreText from "react-show-more-text";
import { localize } from "src/l10n";
import { IApplicationState } from "src/spintr/index";
import { ActionMenu, Label } from "src/ui";
import StandardActionMenu from "./ActionMenu/StandardActionMenu";
import { MandatoryText } from "./Forms";
import "./PageHeader.scss";
import SpintrHelpText from "./SpintrHelpText";
import { decodeHtmlEntities } from "src/utils";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";

interface IButtonRightConfig {
    displayButtonRight: boolean;
    text: string;
    onClick: any;
}

export interface IPageHeaderProps {
    title: string;
    actionMenuCategories?: Spintr.IActionMenuCategory[];
    titleMenuCategories?: Spintr.IActionMenuCategory[];
    standardActionMenuProps?: any;
    renderTitle?: any;
    lang?: string;
    subText?: string;
    size?: spintrLabelSizes;
    displayMandatoryText?: boolean;
    helpTextId?: number;
    useShowMoreSubText?: boolean;
    formatSubTextHtml?: boolean;
    hits?: number;
    isSmallViewMode?: boolean;
    buttonRightConfig?: IButtonRightConfig;
    renderRightPart?: any;
}

class PageHeader extends Component<IPageHeaderProps> {
    getSubText() {
        return decodeHtmlEntities(this.props.subText);
    }

    render() {
        const isListHeader = this.props.hits !== undefined;

        const displayButtonRight = !!this.props.buttonRightConfig && this.props.buttonRightConfig.displayButtonRight;

        return (
            <div
                className={classNames("pageHeader " + this.props.size, {
                    "smallViewMode": this.props.isSmallViewMode
                })}
                lang={this.props.lang}
            >
                <div className="pageHeader-title">
                    <div className="pageHeader-title-left">
                        {!!this.props.renderTitle && this.props.renderTitle()}
                        {!this.props.renderTitle && (
                            <Label
                                size={!!this.props.size ? this.props.size : "h3"}
                                as="h1"
                                weight="medium"
                                className="general-row-break">
                                {this.props.title}
                                {this.props.titleMenuCategories && (
                                    <ActionMenu categories={this.props.titleMenuCategories} icon="arrow-down-1" />
                                )}
                            </Label>
                        )}
                        {!!this.props.subText && (
                            <TooltipHost
                                className="marketplace-tooltip"
                                content={this.getSubText()}
                                style={{
                                    maxWidth: 300
                                }}
                            >
                                <Visage2Icon icon="info-circle" size="small" />
                            </TooltipHost>
                        )}
                    </div>
                    {!displayButtonRight && !!this.props.actionMenuCategories && this.props.actionMenuCategories.length > 0 ? (
                        <div className="action-menu-wrapper">
                            <ActionMenu categories={this.props.actionMenuCategories} />
                        </div>
                    ) : null}
                    {!displayButtonRight && !!this.props.standardActionMenuProps && <StandardActionMenu {...this.props.standardActionMenuProps} />}
                    {this.props.renderRightPart && this.props.renderRightPart()}
                </div>
                {this.props.hits !== undefined && (
                    <Label size="body-2" className="pageHeader-hits">
                        {this.props.hits + " " + localize("Traffar").toLowerCase()}
                    </Label>
                )}
                {!!this.props.displayMandatoryText && (
                    <MandatoryText />
                )}
                {this.props.helpTextId !== undefined && (
                    <Label className={"pageHeader-helpText"} size="body-2" as="div">
                        <SpintrHelpText id={this.props.helpTextId} />
                    </Label>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state: IApplicationState, props) => ({
    ...props,
    isSmallViewMode: state.ui.isSmallViewMode,
});

export default connect(mapStateToProps)(PageHeader);