import React, { CSSProperties } from "react";
import { visage2SocialPostTypes } from "src/social-feed/types";
import "./SocialPostTypeContent.scss";
import { SpintrTypes } from "src/typings";
import SocialPostTypeContentKudos from "./SocialPostTypeContentKudos";
import SocialPostTypeContentBusinessDeal from "./SocialPostTypeContentBusinessDeal";
import { Label, UnstyledButton } from "src/ui";
import { localize } from "src/l10n";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import SocialPostTypeContentSomethingFun from "./SocialPostTypeContentSomethingFun";
import Visage2ComposerImages from "src/spintr/components/Visage2Composer/Visage2ComposerImages";
import { SocialImage } from "../SocialImage";
import SocialPostTypeContentConfetti from "./SocialPostTypeContentConfetti";

interface IProps {
    post: any;
    editMode?: boolean;
    onClose?: any;
    onUpdate?: any;
    goToEditUsers?: any;
    disableRemove?: boolean;
}

const SocialPostTypeContent = (props: IProps) => {
    const foundType = visage2SocialPostTypes.find(x => x.uberType === props.post.Type);
    const supportedTypes = [
        SpintrTypes.UberType.BusinessDeal,
        SpintrTypes.UberType.Kudos,
        SpintrTypes.UberType.SomethingFun,
    ];

    if (!foundType || supportedTypes.indexOf(foundType.uberType) === -1) {
        return null;
    }

    const classNames = [
        "SocialPostTypeContent",
        "SocialPostType-" + foundType.type
    ];

    if (props.editMode) {
        classNames.push("editMode");
    }

    const style: CSSProperties = {
        background: `linear-gradient(${props.post?.background?.start?.color}, ${props.post?.background?.stop?.color})`
    };

    if (foundType.type === SpintrTypes.StatusType.SomethingFun) {
        if (props.editMode) {
            return (
                <div className="SocialPostTypeContent-wrapper">
                    <Visage2ComposerImages
                        post={{
                            ...props.post,
                            photos: props.post.imageUrl ? [props.post.imageUrl] : (props.post.photos || [])
                        }}
                        isSomethingFun
                        disableEditing={props.disableRemove}
                        onAttachedContentUpdate={(updatedItem: any) => {
                            props.onUpdate(updatedItem);
                        }}
                        onRemove={() => {
                            props.onClose();
                        }}
                    />
                </div>
            )
        } else if (props.post.somethingFunImageUrl) {
            return (
                <div className="SocialPostTypeContent" style={{
                    position: "relative"
                }}>
                    <SocialImage post={{
                        ...props.post,
                        photos: [{
                            id: 0,
                            photoUrl: props.post.somethingFunImageUrl
                        }]
                    }} />
                    <SocialPostTypeContentConfetti displayEmojis />
                </div>
            );
        } else {
            return null;
        }
    }

    return (
        <div className="SocialPostTypeContent-wrapper">
            <div className={classNames.join(" ")} style={style}>
                {foundType.type === SpintrTypes.StatusType.BusinessDeal && (
                    <SocialPostTypeContentBusinessDeal
                        post={props.post}
                        editMode={props.editMode}
                        onUpdate={props.onUpdate} />
                )}
                {foundType.type === SpintrTypes.StatusType.Kudos && (
                    <SocialPostTypeContentKudos
                        post={props.post}
                        editMode={props.editMode}
                        onUpdate={props.onUpdate}
                        goToEditUsers={props.goToEditUsers} />
                )}
                {props.editMode && (
                    <div className="edit-controls">
                        <div className="decoration-button-small">
                            <UnstyledButton
                                onClick={() => {
                                    props.onUpdate({
                                        ...props.post,
                                        displayDecorations: !props.post.displayDecorations
                                    })
                                }}
                                title={localize(props.post.displayDecorations ? "HIDE_DECORATIONS" : "SHOW_DECORATIONS")}>
                                <Visage2Icon icon={props.post.displayDecorations ? "eye-slash" : "eye"} color="white" />
                            </UnstyledButton>
                        </div>
                        {!props.disableRemove && (
                            <UnstyledButton
                                className="close-button close-button-circle"
                                title={localize("TaBort")}
                                onClick={() => {
                                    if (props.onClose) {
                                        props.onClose();
                                    }
                                }}
                            >
                                <Visage2Icon icon="close-circle" />
                            </UnstyledButton>
                        )}
                    </div>
                )}
            </div>
        </div>
    )
}

export default SocialPostTypeContent;