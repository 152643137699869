import moment from "moment";
import { TooltipHost } from '@fluentui/react/lib/Tooltip';
import React, { Component, createRef, RefObject } from 'react';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { localize } from "src/l10n";
import {
    DivWithHoverActions,
    Icon,
    Label,
    Loader,
    SpintrUser,
    UnstyledButton
} from "src/ui";
import CustomDialog from "src/ui/components/Dialogs/CustomDialog";
import { UsersListPopup } from 'src/users/views';
import { formatInteractiveText } from "src/utils";
import Conversation from "../../Conversation";
import Message from "../../Message";
import { IQueryMessagesParams, MessageFetchType } from "../../message-types";
import {
    addReaction,
    AddReactionHandler,
    BoundQueryMessagesHandler,
    queryMessages,
    QueryMessagesHandler,
    removeReaction,
    RemoveReactionHandler
} from "../../redux";
import { ChatComposer } from "../ChatComposer";
import { ChatMessageContent } from "../ChatMessageContent";
import "./ChatMessage.scss";
import api from "src/spintr/SpintrApi";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import { IConfirmPopupState, IInfoPopupState } from "src/popups/reducer";
import { setConfirmPopup, setInfoPopup } from "src/popups/actions";
import { SpintrTypes } from "src/typings";
import classNames from "classnames";

interface IDispatchProps {
    addReaction: AddReactionHandler;
    queryMessages: BoundQueryMessagesHandler | QueryMessagesHandler;
    removeReaction: RemoveReactionHandler;
    setConfirmPopup: (props: IConfirmPopupState) => void;
    setInfoPopup: (props: IInfoPopupState) => void;
}

interface IOwnProps {
    message: Message,
    group?: any;
    readonly?: boolean;
}

interface IStateProps {
    currentUser: any;
    conversation: Conversation;
}

interface IState {
    isFetchingReplies: boolean;
    showDeleteDialog: boolean;
    messageIdToDelete?: number;
    showSeenByDialog: boolean;
    showReplyInput: boolean;
}

type Props = IDispatchProps & IOwnProps & IStateProps;

class ChatMessage extends Component<Props, IState> {
    protected composerRef: RefObject<any>;

    constructor(props: Props) {
        super(props);

        this.state = {
            isFetchingReplies: false,
            showDeleteDialog: false,
            showReplyInput: false,
            showSeenByDialog: false,
        }

        this.composerRef = createRef();
    }

    public shouldComponentUpdate(nextProps: Readonly<Props>, nextState: Readonly<IState>): boolean {
        const reactionsHasChanged = (
            !!nextProps.message.reactions &&
            (
                nextProps.message.reactions !== this.props.message.reactions ||
                (
                    !!this.props.message.reactions &&
                    this.props.message.reactions.length !== nextProps.message.reactions.length
                )
            )
        )

        const repliesHasChanged = (
            !!nextProps.message.replies &&
            (
                nextProps.message.replies !== this.props.message.replies ||
                this.props.message.replies.length !== nextProps.message.replies.length
            )
        )

        const seenByHasChanged = this.getSeenByNumber(this.props.conversation) !== this.getSeenByNumber(nextProps.conversation);

        const isPinnedHasChanged = nextProps.message.isPinned !== this.props.message.isPinned;

        if (reactionsHasChanged ||
            repliesHasChanged ||
            seenByHasChanged ||
            isPinnedHasChanged ||
            nextState.isFetchingReplies !== this.state.isFetchingReplies ||
            nextState.showDeleteDialog !== this.state.showDeleteDialog ||
            nextState.messageIdToDelete !== this.state.messageIdToDelete ||
            nextState.showSeenByDialog !== this.state.showSeenByDialog ||
            nextState.showReplyInput !== this.state.showReplyInput) {
            return true;
        }

        return false;
    }

    private fetchReplies(doAfter: () => void): void {
        this.setState({ isFetchingReplies: true }, async () => {
            const params: IQueryMessagesParams = {
                conversationId: this.props.message.conversationId,
                fetchType: MessageFetchType.Messages,
                take: 9999, // TODO: Page this
                threadId: this.props.message.id,
            };


            await (this.props.queryMessages as BoundQueryMessagesHandler)(params);
            this.setState({ isFetchingReplies: false }, () => {
                if (doAfter) {
                    doAfter();
                }
            })
        });
    }

    private getSeenBy(conversation?: Conversation) {
        if (!conversation) {
            conversation = this.props.conversation;
        }

        if (!conversation) {
            return [];
        }

        const users = [];
        const userIds = [];

        for (let [key, value] of Object.entries(conversation.lastRead)) {
            const userId = parseInt(key, 10);

            // @ts-ignore
            const messageId = parseInt(value, 10);

            if (messageId >= this.props.message.id) {
                userIds.push(userId);
            }
        }

        if (userIds.indexOf(this.props.currentUser.id) === -1) {
            userIds.push(this.props.currentUser.id);
        }

        for (const userId of userIds) {
            const participant = conversation.participants.find(p => p.id === userId);

            if (!participant) {
                continue;
            }

            users.push(participant);
        }

        return users;
    }

    getSeenByNumber(conversation?: Conversation) {
        const seenBy = this.getSeenBy(conversation);

        return seenBy.length;
    }

    getSeenByText() {
        const seenByNumber = this.getSeenByNumber();

        return localize('SettAv') + " " + seenByNumber;
    }

    hexToUnicode(input) {
        const code = input - 0x10000;
        const high = (code >> 10) + 0xD800;
        const low = (code & 0x3FF) + 0xDC00;
        const output = String.fromCharCode(high, low);

        return output;
    };

    addReaction(message, reactionId) {
        this.props.addReaction(message.id,
            reactionId,
            this.props.currentUser.id,
            this.props.currentUser.name);
    }

    removeReaction(message, reactionId) {
        this.props.removeReaction(message.id,
            reactionId,
            this.props.currentUser.id);
    }

    onReactionClick(message, reactionId) {
        const reactionExist = message.reactions.find(r =>
            r.reactionId === reactionId &&
            r.user.id === this.props.currentUser.id);

        if (!!reactionExist) {
            return this.removeReaction(message, reactionId)
        }

        const otherReactions = message.reactions.filter(r =>
            r.user.id === this.props.currentUser.id);

        if (!!otherReactions && otherReactions.length > 0) {
            for (let r of otherReactions) {
                this.props.removeReaction(message.id,
                    r.reactionId,
                    this.props.currentUser.id);
            }
        }

        this.addReaction(message, reactionId);
    }

    renderReactions(message) {
        if (!message.reactions ||
            message.reactions.length === 0) {
            return null;
        }

        let reactions = [];

        for (let r of message.reactions) {
            let alreadyAddedReactionId = reactions.find(rtd => rtd.reactionId === r.reactionId);

            if (!!alreadyAddedReactionId) {
                alreadyAddedReactionId.users.push(r.user);
            } else {
                reactions.push({
                    reactionId: r.reactionId,
                    users: [
                        r.user
                    ]
                })
            }
        }

        return (
            <div className="ChatMessage-reactions">
                <div className="ChatMessage-reactions-inner">
                    {
                        reactions.map((item, index) => {
                            let tooltipText = "";

                            for (const u of item.users) {
                                tooltipText += u.name + ", ";
                            }

                            tooltipText = tooltipText.substring(0, tooltipText.length - 2);

                            return (
                                <TooltipHost
                                    key={index}
                                    content={tooltipText}
                                >
                                    <UnstyledButton onClick={this.onReactionClick.bind(this, message, item.reactionId)}>
                                        <div className="ChatMessage-reaction no-user-select">
                                            <div className="ChatMessage-reaction-content">
                                                <Label size="small-1">
                                                    {
                                                        this.hexToUnicode("0x" + item.reactionId.toString(16))
                                                    }
                                                </Label>
                                            </div>
                                            <div className="ChatMessage-reaction-count">
                                                <Label
                                                    as="p"
                                                    color="light-blue"
                                                    size="small-1"
                                                >
                                                    {
                                                        item.users.length
                                                    }
                                                </Label>
                                            </div>
                                        </div>
                                    </UnstyledButton>
                                </TooltipHost>
                            )
                        })
                    }
                </div>
            </div>
        )
    }

    renderText(message) {
        return (
            <Label
                as="div"
                className="interactive-text"
            >
                {formatInteractiveText(message.text)}
            </Label>
        )
    }

    getReplyActions(reply) {
        let actions = [];

        if (reply.user.id !== this.props.currentUser.id &&
            !this.isUserGroupAdmin()) {
            return actions;
        }

        actions.push({
            icon: "more",
            title: localize("Alternativ"),
            children: [{
                icon: "trash",
                title: localize("TaBort"),
                onClick: () => {
                    this.setState({
                        messageIdToDelete: reply.id,
                        showDeleteDialog: true
                    });
                }
            }]
        });

        return actions;
    }

    renderReply(reply) {
        let replyRootClassName = "ChatMessage-reply";

        console.log(reply);

        if (reply.user.id === this.props.currentUser.id) {
            replyRootClassName += " from-current-user";
        }

        return (
            <DivWithHoverActions key={reply.id} actions={this.getActions(reply, true)}>
                <div className={replyRootClassName}>
                    <div className="ChatMessage-reply-left">
                        <SpintrUser
                            name={reply.user.name}
                            size={32}
                            imageUrl={reply.user.imageUrl}
                            hideText={true}
                            personalName={true}
                        />
                    </div>
                    <div className="ChatMessage-reply-right">
                        <div>
                            <div className="ChatMessage-inner">
                                <Label
                                    as="p"
                                    size="body-2"
                                >
                                    {
                                        reply.user.name
                                    }
                                </Label>
                                <div className="ChatMessage-Answer">
                                    {
                                        this.renderText(reply)
                                    }
                                </div>
                            </div>
                            <div className="ChatMessage-right-info">
                                <Label
                                    as="p"
                                    size="body-3"
                                    color="dark-grey"
                                >
                                    {
                                        moment(reply.date).format("LT")
                                    }
                                </Label>
                            </div>
                        </div>
                        {
                            this.renderReactions(reply)
                        }
                    </div>
                </div>
            </DivWithHoverActions>
        )
    }

    postReply() {
        const content = this.composerRef.current.getContent();

        if (!content ||
            content.length === 0) {
            return;
        }

        const data = {
            conversationId: this.props.message.conversationId,
            parentId: this.props.message.id,
            text: content
        }

        api.post(`/api/v1/messages`, data).then((response) => {
            this.composerRef.current.clearContent();
        });
    }

    renderReplyInput() {
        return (
            <div style={{
                display: this.state.showReplyInput ? 'block' : 'none' //TODO: Fix this and make sure mentions work
            }}>
                <ChatComposer
                    ref={this.composerRef}
                    currentUser={this.props.currentUser}
                    conversationId={this.props.message.conversationId}
                    parentId={this.props.message.id} />
            </div>
        )
    }

    renderRepliesSection() {
        const totalReplies = this.props.message.replyCount;
        const displayedReplies = !!this.props.message.replies ?
            this.props.message.replies.length :
            0;

        return (
            <div className="ChatMessage-replies-wrapper">
                {
                    this.state.isFetchingReplies ?
                        <Loader loaderSize="small" /> :
                        null
                }
                {
                    displayedReplies > 0 ?
                        <div className="ChatMessage-replies">
                            {
                                this.props.message.replies.map((reply) => {
                                    return this.renderReply(reply);
                                })
                            }
                        </div> :
                        null
                }
                {
                    !this.state.isFetchingReplies &&
                        (totalReplies > displayedReplies) &&
                        this.props.message.replyCount > 0 ?
                        <div className="ChatMessage-show-replies-button">
                            <UnstyledButton onClick={this.fetchReplies.bind(this)}>
                                <Label
                                    color="light-blue"
                                    size="body-2"
                                    weight="medium"
                                >
                                    {
                                        (displayedReplies > 0 ? localize("VisaMer") : localize("VisaSvar")) +
                                        " (" +
                                        (totalReplies - displayedReplies) +
                                        ")"
                                    }
                                </Label>
                            </UnstyledButton>
                        </div> :
                        null
                }
                {this.props.readonly ? null : (
                    <>
                        {this.renderReplyInput()}
                        {this.state.showReplyInput ? null : (
                            <div className="ChatMessage-answer-button">
                                <UnstyledButton
                                    onClick={() => {
                                        if (displayedReplies > 0 || this.props.message.replyCount === 0) {
                                            this.setState(
                                                {
                                                    showReplyInput: true,
                                                },
                                                () => {
                                                    this.composerRef.current.giveFocus();
                                                }
                                            );
                                        } else {
                                            this.fetchReplies(() => {
                                                this.setState(
                                                    {
                                                        showReplyInput: true,
                                                    },
                                                    () => {
                                                        this.composerRef.current.giveFocus();
                                                    }
                                                );
                                            });
                                        }
                                    }}
                                >
                                    <Visage2Icon icon="message" />
                                    <Label size="body-3" color="dark-grey">
                                        {localize("Svara")}
                                    </Label>
                                </UnstyledButton>
                            </div>
                        )}
                    </>
                )}
            </div>
        )
    }

    isUserGroupAdmin() {
        if (!this.props.group) {
            return false;
        }

        for (let m of this.props.group.members) {
            if (m.id === this.props.currentUser.id &&
                m.isAdministrator) {
                return true;
            }
        }

        return false;
    }

    canUserDeleteMessage() {
        const currentUserIsAuthor = this.props.message.user.id === this.props.currentUser.id;

        if (currentUserIsAuthor) {
            return true;
        }

        if (this.props.group) {
            const isAdmin = this.isUserGroupAdmin();

            return isAdmin;
        }

        return false;
    }

    canUserPinMessage() {
        if (this.props.group) {
            const isAdmin = this.isUserGroupAdmin();

            return isAdmin;
        }

        return false;
    }

    canUserReportMessage(message) {
        const currentUserIsAuthor = message.user.id === this.props.currentUser.id;

        return !currentUserIsAuthor;
    }

    getActions(message: any, isReply?: boolean) {
        let actions = [];

        if (this.props.readonly) {
            return actions;
        }

        const emojis = [{
            reactionId: 128077
        }, {
            reactionId: 128150
        }, {
            reactionId: 128512
        }, {
            reactionId: 128558
        }, {
            reactionId: 128546
        }, {
            reactionId: 128545
        }];

        for (let emoji of emojis) {
            actions.push({
                reactionId: emoji.reactionId,
                onClick: () => {
                    this.onReactionClick(message, emoji.reactionId);
                }
            })
        }

        const userCanPinMessage = !isReply && this.canUserPinMessage();
        const userCanDeleteMessage = this.canUserDeleteMessage();
        const userCanReportMessage = this.canUserReportMessage(message);

        if (userCanPinMessage ||
            userCanDeleteMessage) {
            let optionsButton = {
                icon: "more-square",
                title: localize("Alternativ"),
                children: []
            }

            if (userCanPinMessage) {
                optionsButton.children.push({
                    fabricIcon: "Pinned",
                    title: this.props.message.isPinned ? localize("Slapp") : localize("Fast"),
                    isActive: this.props.message.isPinned,
                    onClick: () => {
                        if (this.props.message.isPinned) {
                            api.delete("/api/v1/messages/" + this.props.message.id + "/pin");
                        } else {
                            api.post("/api/v1/messages/" + this.props.message.id + "/pin");
                        }
                    }
                });
            }

            if (userCanReportMessage) {
                optionsButton.children.push({
                    fabricIcon: "Flag",
                    title: localize("REPORT_CONTENT"),
                    onClick: async () => {
                        this.props.setConfirmPopup({
                            isOpen: true,
                            message: localize("REPORT_CONTENT_CONFIRMATION"),
                            onConfirm: () => {
                                api.post(`/api/v1/objects/reports/${message.id}`, null, {
                                    params: {
                                        reportType: SpintrTypes.ReportType.ChatMessage,
                                    },
                                });
                                this.props.setInfoPopup({
                                    isOpen: true,
                                    message: localize("REPORT_CONTENT_THANKS"),
                                });
                            },
                        });
                    },
                });
            }

            if (userCanDeleteMessage) {
                optionsButton.children.push({
                    fabricIcon: "Delete",
                    title: localize("TaBort"),
                    onClick: () => {
                        this.setState({
                            messageIdToDelete: this.props.message.id,
                            showDeleteDialog: true
                        });
                    }
                });
            }

            actions.push(optionsButton);
        }

        return actions;
    }

    renderSeenByDialog() {
        if (!this.state.showSeenByDialog) {
            return null;
        }

        return (
            <UsersListPopup
                users={this.getSeenBy()}
                title={localize("SettAv")}
                popupIsHidden={!this.state.showSeenByDialog}
                closePopup={() => {
                    this.setState({
                        showSeenByDialog: false
                    });
                }} />
        )
    }

    renderDeleteDialog() {
        if (!this.state.showDeleteDialog) {
            return null;
        }

        return (
            <CustomDialog
                message={localize("ArDuSakerAttDuVillRaderaDennaPost")}
                show={true}
                onDismiss={() => {
                    this.setState({
                        showDeleteDialog: false,
                        messageIdToDelete: 0
                    });
                }}
                onConfirm={() => {
                    this.setState({
                        showDeleteDialog: false,
                    }, () => {
                        api.delete("/api/v1/messages?ids=" + this.state.messageIdToDelete).then(() => { }).catch(() => { });

                        this.setState({
                            messageIdToDelete: 0
                        });
                    });
                }}
            />
        )
    }

    render() {
        let rootClassName = "ChatMessage ChatMessage-" + this.props.message.id; //the id is needed in Scrollable

        if (this.props.message.user.id === this.props.currentUser.id) {
            rootClassName += " from-current-user";
        }

        return (
            <div className={rootClassName}>
                <div className="ChatMessage-left">
                    <SpintrUser
                        id={this.props.message.user.id}
                        name={this.props.message.user.name}
                        imageUrl={this.props.message.user.imageUrl}
                        hideText={true}
                        personalName={true}
                    />
                </div>
                <div className="ChatMessage-right">
                    <DivWithHoverActions actions={this.getActions(this.props.message, false)}>
                        <div className={classNames("ChatMessage-message-wrapper", {
                            readonly: this.props.readonly
                        })}>
                            <div className="ChatMessage-inner">
                                <div className="ChatMessage-user-name" tabIndex={0}>
                                    <Label
                                        as="p"
                                        size="body-2"
                                        weight="medium"
                                    >
                                        {
                                            this.props.message.user.name
                                        }
                                    </Label>
                                </div>
                                <ChatMessageContent
                                    message={this.props.message}
                                />
                                {
                                    this.renderReactions(this.props.message)
                                }
                            </div>
                            <div className="ChatMessage-right-info">
                                <div tabIndex={0}>
                                    <Label
                                        className="ChatMessage-time"
                                        as="p"
                                        size="body-3"
                                        color="mid-grey"
                                    >
                                        {
                                            moment(this.props.message.date).format("LT")
                                        }
                                    </Label>
                                </div>
                                <div className="ChatMessage-seen-by" tabIndex={0}>
                                    <UnstyledButton onClick={() => {
                                        this.setState({
                                            showSeenByDialog: true
                                        });
                                    }}>
                                        <Label
                                            className="ChatMessage-seen-by-label"
                                            as="p"
                                            size="body-3"
                                            color="mid-grey"
                                        >
                                            {
                                                this.getSeenByText()
                                            }
                                        </Label>
                                    </UnstyledButton>
                                </div>
                            </div>
                        </div>
                    </DivWithHoverActions>
                    {
                        this.renderRepliesSection()
                    }
                </div>
                {
                    this.renderDeleteDialog()
                }
                {
                    this.renderSeenByDialog()
                }
            </div>
        )
    }
}

const mapStateToProps: MapStateToProps<IStateProps, IOwnProps, Spintr.AppState> =
    (state, props) => ({
        conversation: state.chat.conversations.items.find(
            (c) => c.id === props.message.conversationId,
        ),
        currentUser: state.profile.active,
    });

const mapDispatchToProps: MapDispatchToProps<IDispatchProps, IOwnProps> = {
    addReaction,
    queryMessages,
    removeReaction,
    setConfirmPopup,
    setInfoPopup,
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatMessage);
