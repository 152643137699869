import classNames from "classnames";
import React, { Component } from "react";
import { connect } from "react-redux";
import { VisageSidebarMode } from "src/sidebar";
import { setSidebarMode } from "src/sidebar/actions";
import { SocialFeedType } from "src/social-feed";
import { TeaserBoxLoader } from "src/teaser-box";
import { SpintrTypes } from "src/typings";
import { StandardErrorBoundary } from "src/ui";
import { mixpanelTrack } from "src/utils";
import { RightColumn, SpintrSocialFeed } from "../components";
import { NewsSpotlight } from "./../../news";
import { fetchTeaserBoxes } from "src/teaser-box/actions";
import VisageInformationFeed from "../components/InformationFeed/VisageInformationFeed";
import Color from "src/style/colors/Color";
import { Pivot, PivotItem } from "@fluentui/react";
import { localize } from "src/l10n";
import { StartPageView } from "../components/StartPageView";

interface IOwnProps { }

interface IStateProps {
    moveSocialFeed: boolean;
    userFeedId: number;
    userGalleryId: number;
    userId: number;
    blogsEnabled: boolean;
    newsEnabled: boolean;
    rssEnabled: boolean;
    smallViewMode: boolean;
    startViewKey: number;
    teaserBoxesFetchHasBeenInitialized?: boolean;
    instanceColor?: string;
    primaryAppFeedTab?: string;
    layout: { name: string, jsonData: string };
}

interface IDispatchActions {
    setSidebarMode: (mode: VisageSidebarMode) => void;
    fetchTeaserBoxes: () => void;
}

type Props = IOwnProps & IStateProps & IDispatchActions;

interface IState {
    layout: undefined | Spintr.IStartPageRow[];
}

class SpintrStartView extends Component<Props, IState> {
    protected previousRightColumnState: boolean;

    constructor(props: Props) {
        super(props);

        this.state = {
            layout: this.props.layout?.jsonData
                ? JSON.parse(this.props.layout.jsonData)
                : undefined
        }
    }

    public componentDidMount() {
        this.props.fetchTeaserBoxes();

        mixpanelTrack("Visited start page");
        window.addEventListener("scroll", this.scrollMixpanelEvent, { capture: true });

        this.props.setSidebarMode(VisageSidebarMode.menu);

    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<IState>, snapshot?: any): void {
        if (prevProps.startViewKey !== this.props.startViewKey) {
            this.props.fetchTeaserBoxes();
        }
    }

    public componentWillUnmount(): void {
        window.removeEventListener("scroll", this.scrollMixpanelEvent, { capture: true });
    }

    private scrollMixpanelEvent = () => {
        mixpanelTrack("Scrolled on start page");
        window.removeEventListener("scroll", this.scrollMixpanelEvent, { capture: true });
    }

    public render() {
        if (this.state.layout) {
            return (
                <StartPageView
                    responsive={this.props.smallViewMode}
                    rows={this.state.layout}
                />
            );
        }

        let defaultSelectedKey = "0";

        if (!!this.props.primaryAppFeedTab) {
            const useSecondTab = this.props.primaryAppFeedTab === "all" ||
                this.props.primaryAppFeedTab === "news" ||
                this.props.primaryAppFeedTab === "blogs" ||
                this.props.primaryAppFeedTab === "rss";

            if (useSecondTab) {
                defaultSelectedKey = "1";
            }
        }

        return (
            <div
                key={this.props.startViewKey}
                className={classNames("SpintrStartView no-right-column", {
                    "no-social-feed": this.props.moveSocialFeed,
                })}
            >
                {!this.props.moveSocialFeed && (
                    <div className="socialFeed">
                        <StandardErrorBoundary>
                            <SpintrSocialFeed
                                feedType={SocialFeedType.Combined}
                                feedId={this.props.userFeedId}
                                galleryId={this.props.userGalleryId}
                                userId={this.props.userId}
                                isStartPage
                            />
                        </StandardErrorBoundary>
                    </div>
                )}
                <div className="startModules">
                    <StandardErrorBoundary>
                        <TeaserBoxLoader position={SpintrTypes.TeaserBoxPosition.StartPage} />
                    </StandardErrorBoundary>

                    <StandardErrorBoundary>
                        <TeaserBoxLoader blogsOnly={false} />
                    </StandardErrorBoundary>

                    {this.props.newsEnabled && (
                        <div className="module-row">
                            <div className="module-cell cell-width3">
                                <StandardErrorBoundary>
                                    <NewsSpotlight />
                                </StandardErrorBoundary>
                            </div>
                        </div>
                    )}

                    <div className={"module-row margin-bottom no-right-column"}>
                        <div role="main"
                            className={classNames("module-cell main", {
                                "cell-width2": !this.props.smallViewMode,
                                "cell-width3": this.props.smallViewMode,
                            })}
                        >
                            <TeaserBoxLoader blogsOnly={true} />
                            {
                                !this.props.moveSocialFeed && (this.props.newsEnabled || this.props.blogsEnabled || this.props.rssEnabled) && (
                                    <StandardErrorBoundary>
                                        <VisageInformationFeed displayBlogTeaserBoxes />
                                    </StandardErrorBoundary>
                                )
                            }
                            {
                                this.props.moveSocialFeed && (
                                    <Pivot className="SpintrStartViewResponsivePivot" defaultSelectedKey={defaultSelectedKey}>
                                        <PivotItem headerText={localize("Flode")}>
                                            <StandardErrorBoundary>
                                                <SpintrSocialFeed
                                                    feedType={SocialFeedType.Combined}
                                                    feedId={this.props.userFeedId}
                                                    galleryId={this.props.userGalleryId}
                                                    userId={this.props.userId}
                                                    isStartPage
                                                />
                                            </StandardErrorBoundary>
                                        </PivotItem>
                                        {
                                            (this.props.newsEnabled || this.props.blogsEnabled || this.props.rssEnabled) && (
                                                <PivotItem headerText={this.props.newsEnabled && this.props.blogsEnabled ?
                                                    (localize("Nyheter") + " " + localize("Och_small") + " " + localize("Bloggar").toLowerCase()) :
                                                    this.props.newsEnabled ?
                                                        localize("Nyheter") :
                                                        this.props.blogsEnabled ?
                                                            localize("Bloggar") :
                                                            localize("appRSS")}>
                                                    <StandardErrorBoundary>
                                                        <VisageInformationFeed displayBlogTeaserBoxes />
                                                    </StandardErrorBoundary>
                                                </PivotItem>
                                            )
                                        }
                                    </Pivot>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect<IStateProps, IDispatchActions, IOwnProps, Spintr.AppState>(
    (state) => ({
        moveSocialFeed: state.ui.viewMode < SpintrTypes.ViewMode.SmallestLaptop,
        userFeedId: state.profile.active.feedId,
        userGalleryId: state.profile.active.galleryId,
        userId: state.profile.active.id,
        blogsEnabled: state.app.items.some((app) => app.enabled && app.id === 2),
        newsEnabled: state.app.items.some((app) => app.enabled && app.id === 5),
        rssEnabled: state.app.items.some((app) => app.enabled && app.id === 11),
        smallViewMode: state.ui.viewMode <= SpintrTypes.ViewMode.TabletPortrait,
        startViewKey: state.ui.startViewKey,
        teaserBoxesFetchHasBeenInitialized: state.teaserboxes.fetchHasBeenInitialized,
        instanceColor: state.instance.get("color"),
        primaryAppFeedTab: state.instance.get("primaryAppFeedTab"),
        layout: state.instance.get("defaultStartPageLayout"),
    }),
    { setSidebarMode, fetchTeaserBoxes }
)(SpintrStartView);
