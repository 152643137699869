import { Record } from "immutable";

/**
 * Represents a class-based immutable record.
 */
interface IStaticallyTypedRecord<T> {
    new (v: Partial<T>): this;
    merge(...collections: Array<Partial<T>>): this;
    withMutations(cb: (r: IStaticallyTypedRecord<T>) => IStaticallyTypedRecord<T>): this;
    toJS(): T;
    get<K extends keyof T>(key: K): T[K];
    set<K extends keyof T, V extends T[K]>(key: K, value: V);
    setIn<K1 extends keyof T, V extends T[K1]>(keys: [K1], val: V);
    setIn<K1 extends keyof T, K2 extends keyof T[K1], V extends T[K1][K2]>(keys: [K1, K2], val: V);
    setIn<K1 extends keyof T, K2 extends keyof T[K1], K3 extends keyof T[K1][K2], V extends T[K1][K2][K3]>(
        keys: [K1, K2, K3],
        val: V
    );
}

const RecordFactory = <T>(seed: T): IStaticallyTypedRecord<T> & Readonly<T> => {
    return (Record(seed) as any) as IStaticallyTypedRecord<T> & Readonly<T>;
};

export default RecordFactory;