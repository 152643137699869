import moment from "moment";
import qs from "qs";
import api from "src/spintr/SpintrApi";

import {
    ICombinedFeedParams,
    IGeneralFeedParams,
    IGroupFeedParams,
    IUserFeedParams
} from "./types";

const paramsSerializer = (p: any): string => qs.stringify(p, {
    arrayFormat: "indices",
    serializeDate: (date) => moment(date)
        .format("YYYY-MM-DDTHH:mm:ssZZ")
});

const baseUrl = "/api/v1/stream";

export const fetchCombinedFeed =
    (params: ICombinedFeedParams) => api.get<Spintr.ISocialPostBase[]>(
        baseUrl + "/getfaster", { params, paramsSerializer }
    );

export const fetchGeneralFeed =
    (params: IGeneralFeedParams) => api.get<Spintr.ISocialPostBase[]>(
        baseUrl + "/getfaster", { params, paramsSerializer }
    );

export const fetchGroupFeed =
    (params: IGroupFeedParams) => api.get<Spintr.ISocialPostBase[]>(
        baseUrl + "/getfaster", { params, paramsSerializer }
    );

export const fetchProfileFeed =
    (params: IUserFeedParams) => api.get<Spintr.ISocialPostBase[]>(
        baseUrl + "/getfaster", { params, paramsSerializer }
    );
