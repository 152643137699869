import { produce } from "immer";
import { SpintrTypes } from "src/typings";

import { UiAction, UiActionTypes as ActionTypes } from "./action-types";


export interface IResponsiveState {
    menuVisible: boolean;
    profileVisible: boolean;
    searchVisible: boolean;
    submenuVisible: boolean;
    shortcutsVisible: boolean;
}

export interface IReactionPickerConfig {
    isVisible: boolean;
    elementId: string;
    uberId: number;
    yOffset: number;
    xOffset: number;
    identity?: any;
    sendToMixpanel: boolean;
}

export interface IUiState {
    activeActionMenu: string;
    activeSidebarTab: number;
    displayAsOfflineInChat: boolean;
    hasChangedGroupHistoryExpanded: boolean;
    groupHistoryExpanded: boolean;
    isTinyInModal: boolean;
    isSmallViewMode: boolean;
    isWebViewMode: boolean;
    responsive: IResponsiveState;
    sidebarExpanded: boolean;
    formFooterBarVisible: boolean;
    formFooterBarTop: boolean;
    shouldReloadFavourites: boolean;
    currentlyShowingTeaserboxes: number[];
    viewMode: Spintr.ViewMode;
    startViewKey: number;
    focusCommentFieldForUberId: number;
    isInTeamsApp: boolean;
    appMode: boolean;
    visage2FullscreenMode: boolean;
    visage2FullscreenHeaderVisible: boolean;
    globalBreadcrumbs?: Spintr.IBreadcrumbItem[];
    uploadsInProgress: any[];
    public360: any;
    overlayId: string;
    displayHeaderSearch: boolean;
    headerSearchBarMode: SpintrTypes.HeaderSearchBarMode;
    useDocumentWidthMode: boolean;
    useNarrowFormWidthMode: boolean;
    disableWrapperBackground: boolean;
    reactionPickerConfig?: IReactionPickerConfig;
    startPageBuilderMode: SpintrTypes.StartPageBuilderMode;
    displayGreyBackground: boolean;
    adminNoPadding: boolean;
}

const initialState: IUiState = {
    headerSearchBarMode: SpintrTypes.HeaderSearchBarMode.Search,
    activeActionMenu: "",
    activeSidebarTab: 0,
    currentlyShowingTeaserboxes: [],
    displayAsOfflineInChat: false,
    hasChangedGroupHistoryExpanded: false,
    groupHistoryExpanded: false,
    isSmallViewMode: false,
    isWebViewMode: false,
    isTinyInModal: false,
    responsive: {
        menuVisible: false,
        profileVisible: false,
        searchVisible: false,
        submenuVisible: false,
        shortcutsVisible: false
    },
    formFooterBarVisible: false,
    formFooterBarTop: false,
    shouldReloadFavourites: false,
    sidebarExpanded: true,
    viewMode: 0,
    startViewKey: 0,
    focusCommentFieldForUberId: undefined,
    isInTeamsApp: window.location.href.indexOf("isInTeamsApp=true") > -1,
    appMode: window.location.href.indexOf("isInTeamsApp=true") > -1,
    visage2FullscreenMode: false,
    visage2FullscreenHeaderVisible: false,
    globalBreadcrumbs: [],
    uploadsInProgress: [],
    public360: {},
    overlayId: "",
    displayHeaderSearch: false,
    useDocumentWidthMode: false,
    useNarrowFormWidthMode: false,
    disableWrapperBackground: false,
    reactionPickerConfig: undefined,
    startPageBuilderMode: SpintrTypes.StartPageBuilderMode.Edit,
    displayGreyBackground: false,
    adminNoPadding: false,
};

const reducer = (state: IUiState = initialState, action: UiAction) => produce(state, draft => {
    switch (action.type) {
        case ActionTypes.SetHeaderSearchBarMode:
            draft.headerSearchBarMode = action.mode;
            break;
        case ActionTypes.SetSidebarExpanded:
            draft.sidebarExpanded = action.isExpanded;
            break;
        case ActionTypes.SetResponsiveMenuVisible:
            draft.responsive = {
                menuVisible: action.visible,
                profileVisible: false,
                searchVisible: false,
                submenuVisible: false,
                shortcutsVisible: false
            };
            break;
        case ActionTypes.SetResponsiveProfileVisible:
            draft.responsive = {
                menuVisible: false,
                profileVisible: action.visible,
                searchVisible: false,
                submenuVisible: false,
                shortcutsVisible: false
            };
            break;
            case ActionTypes.SetResponsiveShortcutsVisible:
                draft.responsive = {
                    menuVisible: false,
                    profileVisible: false,
                    searchVisible: false,
                    submenuVisible: false,
                    shortcutsVisible: action.visible
                };
                break;
        case ActionTypes.SetResponsiveSearchVisible:
            draft.responsive = {
                menuVisible: false,
                profileVisible: false,
                searchVisible: action.visible,
                submenuVisible: false,
                shortcutsVisible: false
            };
            break;
        case ActionTypes.SetResponsiveSubmenuVisible:
            draft.responsive = {
                menuVisible: false,
                profileVisible: false,
                searchVisible: false,
                submenuVisible: action.visible,
                shortcutsVisible: false
            };
            break;
        case ActionTypes.SetViewMode:
            draft.viewMode = action.viewMode;
            draft.isSmallViewMode = action.viewMode <= 1021;
            draft.isWebViewMode = action.viewMode <= 425;
            break;
        case ActionTypes.SetIsTinyInModal:
            draft.isTinyInModal = action.isTinyInModal;
            break;
        case ActionTypes.SET_ACTIVE_ACTION_MENU:
            draft.activeActionMenu = action.activeActionMenu;
            break;
        case ActionTypes.SetGroupHistoryExpanded:
            draft.hasChangedGroupHistoryExpanded = true;
            draft.groupHistoryExpanded = action.isExpanded;
            break;
        case ActionTypes.SetDisplayAsOfflineInChat:
            draft.displayAsOfflineInChat = action.displayAsOfflineInChat;
            break;
        case ActionTypes.SetActiveSidebarTab:
            draft.activeSidebarTab = action.activeSidebarTab;
            break;
        case ActionTypes.SetFormFooterBarVisible:
            draft.formFooterBarVisible = action.visible;
            break;
        case ActionTypes.SetFormFooterBarTop:
            draft.formFooterBarTop = action.top;
            break;
        case ActionTypes.SetShouldReloadFavourites:
            draft.shouldReloadFavourites = action.shouldReload;
            break;
        case ActionTypes.setCurrentlyShowingTeaserboxes:
            draft.currentlyShowingTeaserboxes = action.teaserboxes;
            break;
        case ActionTypes.IncrementStartViewKey:
            draft.startViewKey = draft.startViewKey ? (draft.startViewKey + 1) : 1;
            break;
        case ActionTypes.SetFocusCommentFieldForUberId:
            draft.focusCommentFieldForUberId = action.focusCommentFieldForUberId;
            break;
        case ActionTypes.SetIsInTeamsApp:
            draft.isInTeamsApp = action.isInTeamsApp;
            break;
        case ActionTypes.SetAppMode:
            draft.appMode = action.appMode;
            break;
        case ActionTypes.SetVisage2FullscreenMode:
            draft.visage2FullscreenMode = action.visage2FullscreenMode;
            break;
        case ActionTypes.SetVisage2FullscreenHeaderVisible:
            draft.visage2FullscreenHeaderVisible = action.visage2FullscreenHeaderVisible;
            break;
        case ActionTypes.SetGlobalBreadcrumbs:
            draft.globalBreadcrumbs = action.value;
            break;
        case ActionTypes.AddUploads:
            draft.uploadsInProgress = !draft.uploadsInProgress ?
                action.uploads :
                [...draft.uploadsInProgress, ...action.uploads];
            break;
        case ActionTypes.ClearUploads:
            draft.uploadsInProgress = [];
            break;
        case ActionTypes.UpdateUpload:
            draft.uploadsInProgress = draft.uploadsInProgress.map(uip => {
                return uip.uploadId === action.upload.uploadId ?
                    action.upload :
                    uip
            });
            break;
        case ActionTypes.RemoveUpload:
            let uploadsInProgress = [];

            for (let u of draft.uploadsInProgress) {
                if (u.uploadId !== action.uploadId) {
                    uploadsInProgress.push(u);
                }
            }

            draft.uploadsInProgress = uploadsInProgress;
            break;
        case ActionTypes.SetPublic360SelectedUnitLevel1:
            draft.public360.selectedUnitLevel1 = action.unit;
            break;
        case ActionTypes.SetPublic360SelectedUnitLevel2:
            draft.public360.selectedUnitLevel2 = action.unit;
            break;
        case ActionTypes.SetOverlayId:
            draft.overlayId = action.value;
            break;
        case ActionTypes.SetDisplayHeaderSearch:
            draft.displayHeaderSearch = action.value;
            break;
        case ActionTypes.SetUseDocumentWidthMode:
            draft.useDocumentWidthMode = action.value;
            break;
        case ActionTypes.SetUseNarrowFormWidthMode:
            draft.useNarrowFormWidthMode = action.value;
            break;
        case ActionTypes.SetDisableWrapperBackground:
            draft.disableWrapperBackground = action.value;
            break;
        case ActionTypes.SetReactionPickerConfig:
            draft.reactionPickerConfig = action.value;
            break;
        case ActionTypes.SetStartPageBuilderMode:
            draft.startPageBuilderMode = action.value;
            break;

        case ActionTypes.ToggleGreyBackground:
            draft.displayGreyBackground = action.displayGreyBackground;
            break;

        case ActionTypes.SetAdminNoPadding:
            draft.adminNoPadding = action.value;
            break;
    }
});

export default reducer;