import { ActionButton, TextField } from "@fluentui/react";
import React, { Component, ReactNode } from "react";
import { localize } from "src/l10n";
import Bookmark from "src/social-feed/components/SocialBookmark/Bookmark";
import { SpintrTypes } from "src/typings";
import { Label, UnstyledButton } from "src/ui";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";

interface IProps {
    post: Spintr.ISocialPostBase;
    onAttachedContentUpdate: any;
    onRemove: any;
    disableEditing?: boolean;
}

interface IState {}

class Visage2ComposerBookmark extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
    }

    onImageLeftButtonClick() {
        const currentIndex = this.props.post.BookmarkImages.indexOf(this.props.post.BookmarkImage);
        let newIndex = 0;

        if (currentIndex === 0) {
            newIndex = this.props.post.BookmarkImages.length - 1;
        } else {
            newIndex = currentIndex - 1;
        }

        this.props.onAttachedContentUpdate({
            ...this.props.post,
            BookmarkImage: this.props.post.BookmarkImages[newIndex]
        });
    }

    onImageRightButtonClick() {
        const currentIndex = this.props.post.BookmarkImages.indexOf(this.props.post.BookmarkImage);
        let newIndex = 0;

        if (currentIndex === (this.props.post.BookmarkImages.length - 1)) {
            newIndex = 0;
        } else {
            newIndex = currentIndex + 1;
        }

        this.props.onAttachedContentUpdate({
            ...this.props.post,
            BookmarkImage: this.props.post.BookmarkImages[newIndex]
        });
    }

    onRemoveBookmarkClick() {
        this.props.onRemove();
    }

    render(): ReactNode {
        const item = this.props.post;

        return (
            <div className="bookmark">
                <Bookmark
                    text={item.Text}
                    bookmarkImage={item.BookmarkImage}
                    bookmarkDescription={item.BookmarkDescription}
                    bookmarkTitle={item.BookmarkTitle}
                    bookmarkType={item.BookmarkType}
                    bookmarkUrl={item.BookmarkUrl}
                />
                <div className="bookmark-actions">
                    {item.BookmarkType === 1 && item.BookmarkImages.length > 0 && (
                        <div className="group">
                            <a
                                title={localize("Foregaende") + " " + localize("bild_inline")}
                                className="action"
                                onClick={this.onImageLeftButtonClick.bind(this)}
                            >
                                <Visage2Icon icon="arrow-left-2" />
                            </a>
                            <a
                                title={localize("Nasta") + " " + localize("bild_inline")}
                                className="action"
                                onClick={this.onImageRightButtonClick.bind(this)}
                            >
                                <Visage2Icon icon="arrow-right-3" />
                            </a>
                        </div>
                    )}
                    {!this.props.disableEditing && (
                        <div className="group right">
                            <a
                                title={localize("TaBortBild")}
                                className="action remove close-button-circle"
                                onClick={this.onRemoveBookmarkClick.bind(this)}
                            >
                                <Visage2Icon icon="close-circle" />
                            </a>
                        </div>
                    )}
                </div>
            </div>
        )
    }
}

export default Visage2ComposerBookmark;