import { Pivot, PivotItem } from "@fluentui/react";
import React, { Component } from "react";
import { connect } from "react-redux";
import { localize } from "src/l10n";
import { IApplicationState } from "src/spintr/reducer";
import { groupFunctions } from "../utils";

interface IProps {
    group: any;
    history: any;
    groupShowFiles?: boolean;
    groupShowNotes?: boolean;
    groupShowPlanning?: boolean;
}

interface IState {
    initialSelectedIndex: string;
}

class GroupMenu extends Component<IProps, IState> {
    constructor(props) {
        super(props);

        this.state = {
            initialSelectedIndex: this.getInitialSelectedIndex(),
        };
    }

    getItems() {
        if (this.props.group.version === 1) {
            const routeBase = `/groups/${this.props.group.id}`;

            let items = [
                {
                    route: routeBase,
                    text: localize("Chatt"),
                    enabled: this.props.group.version === 2,
                },
                {
                    route: `${routeBase}/planning`,
                    text: localize("Planering"),
                    enabled: this.props.group.version === 2 && this.props.groupShowPlanning,
                },
                {
                    route: `${routeBase}/notes`,
                    text: localize("appNotes"),
                    enabled: this.props.group.version === 2 && this.props.groupShowNotes,
                },
                {
                    route: routeBase,
                    text: localize("Startsida"),
                    enabled: this.props.group.version === 1,
                },
                {
                    route: `${routeBase}/todo`,
                    text: localize("AttGora"),
                    enabled: this.props.group.version === 1,
                },
                {
                    route: `${routeBase}/files`,
                    text: localize("Filer"),
                    enabled: this.props.groupShowFiles,
                },
                {
                    route: `${routeBase}/classic-notes`,
                    text: localize("appNotes"),
                    enabled: this.props.group.version === 1 && this.props.groupShowNotes,
                },
                {
                    route: `${routeBase}/calendar`,
                    text: localize("Kalender"),
                    enabled: true,
                },
                {
                    route: `${routeBase}/members`,
                    text: localize("Medlemmar"),
                    enabled: true,
                },
                {
                    route: `${routeBase}/chat`,
                    text: localize("Chatt"),
                    enabled: this.props.group.version === 1,
                },
            ];

            items = items.filter((item) => item.enabled);

            if (this.props.group.menu && this.props.group.menu.length > 0) {
                for (const mi of this.props.group.menu) {
                    items.push({
                        route: "/" + mi.url,
                        text: mi.name,
                        enabled: true,
                    });
                }
            }

            return items;
        }

        const routeBase = `/groups/${this.props.group.id}`;

        const items = this.props.group.tabOrder
            .filter((tab) => tab.enabled)
            .map((tab) => {
                const groupFunction = groupFunctions.find((gf) => gf.id === tab.id);

                return {
                    ...groupFunction,
                    text: localize(groupFunction.text),
                    route: `${routeBase}/${groupFunction.route}`,
                };
            });

        if (this.props.group.menu && this.props.group.menu.length > 0) {
            for (const mi of this.props.group.menu) {
                // @ts-ignore
                items.push({
                    route: "/" + mi.url,
                    text: mi.name,
                    id: -1,
                });
            }
        }

        return items;
    }

    public componentDidMount() {
        // @ts-ignore
        this.unlisten = this.props.history.listen((location) => {
            this.setState({
                initialSelectedIndex: this.getInitialSelectedIndex(),
            });
        });
    }

    public componentWillUnmount() {
        // @ts-ignore
        this.unlisten();
    }

    getInitialSelectedIndex() {
        const items = this.getItems();

        if (this.props.group.version === 1) {
            let initialSelectedIndex = "0";

            for (let i of items) {
                if (window.location.href.indexOf(i.route) > -1) {
                    initialSelectedIndex = items.indexOf(i).toString();
                }
            }

            return initialSelectedIndex;
        }

        for (let i of items) {
            if (window.location.href.indexOf(i.route) > -1) {
                return items.indexOf(i).toString();
            }
        }

        return "0";
    }

    render() {
        const items = this.getItems();

        return (
            <nav className="GroupMenu">
                <Pivot
                    defaultSelectedKey={this.state.initialSelectedIndex}
                    overflowBehavior={"menu"}
                    selectedKey={this.state.initialSelectedIndex}
                    onLinkClick={(item?: PivotItem, ev?: React.MouseEvent<HTMLElement>) => {
                        const selectedItem = items.find((s) => s.text === item.props.headerText);

                        this.props.history.push({
                            pathname: selectedItem.route,
                        });
                    }}
                >
                    {items.map((item, index) => {
                        return <PivotItem headerText={item.text} key={index} />;
                    })}
                </Pivot>
            </nav>
        );
    }
}

const mapStateToProps = (state: IApplicationState, props: IProps): IProps => ({
    ...props,
    groupShowFiles: state.instance.get("groupShowFiles"),
    groupShowNotes: state.instance.get("groupShowNotes"),
    groupShowPlanning: state.instance.get("groupShowPlanning"),
});

export default connect(mapStateToProps)(GroupMenu);
