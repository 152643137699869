import moment from "moment";
import { ComboBox, IComboBoxOption, Label as FabricLabel, PrimaryButton } from "@fluentui/react";
import { Checkbox } from '@fluentui/react/lib/Checkbox';
import { Rating } from '@fluentui/react/lib/Rating';
import { TextField } from "@fluentui/react/lib/TextField";
import React, { Component } from "react";
import { connect } from "react-redux";
import { localize } from "src/l10n";
import SpintrDatePicker from "src/spintr/components/SpintrDatePicker";
import { IApplicationState } from "src/spintr/reducer";
import { SpintrTypes } from "src/typings";
import { Label, Loader } from "src/ui";
import InfoDialog from "src/ui/components/Dialogs/InfoDialog";
import { Style } from "src/ui/helpers";
import { uniqueId, validateRequiredTextField } from "src/utils";
import { FormControl } from "../../Forms";
import ErrorMessagebar from "../../Messagebars/ErrorMessagebar";
import "./TinyForm.scss";
import api from "src/spintr/SpintrApi";

interface IProps {
    id: number;
    instance?: any;
    apps?: any;
    buttonMessage: string;
}

interface IState {
    form?: any,
    isLoading: boolean,
    answers: any[],
    dialogText?: any,
    displayInfoDialog: boolean,
    infoBoxText?: any,
    displayInfoBox: boolean,
    enableFieldValidation?: boolean,
    saveError?: any
    formName?: string;
    hideName?: boolean;
    unavailable: boolean;
    userAnswer?: any;
    viewFormAsInactive?: boolean;
    startDate?: boolean;
}

class TinyForm extends Component<IProps, IState> {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            answers: [],
            displayInfoDialog: false,
            displayInfoBox: false,
            unavailable: false,
        };
    }

    isAppEnabled(id) {
        return !!this.props.apps.items.find((a) => a.id === id && a.enabled);
    }

    componentDidMount() {
        if (!this.isAppEnabled(SpintrTypes.SpintrApp.Forms)) {
            return;
        }

        this.fetchForm();
    }

    fetchForm = (reFetch?: boolean) => {
        if (reFetch) {
            this.setState({
                isLoading: true,
            });
        }

        api.get(`/api/v1/survey/${this.props.id}?fetchAnswers=false&isEdit=false`).then((response) => {
            // Sort the potential answers
            let data = response.data

            // data.surveyquestions.forEach((q) => {
            //     q.alternatives = q.alternatives.sort((a, b) => (a.position > b.position ? 1 : -1))
            // })

            if (data.userAnswer) {
                const tempAnswers = [];

                for (const surveyQuestion of data.surveyquestions) {
                    const userAnswer = data.userAnswer.find((userAnswer) => userAnswer.questionId == surveyQuestion.id);

                    if (!userAnswer) {
                        // Ignore question if answer was not found,
                        // it was probably added to the survey after the survey was answered
                        continue;
                    }

                    tempAnswers.push({
                        questionId: surveyQuestion.id,
                        alternatives: userAnswer.alternatives.map((alternative) => ({
                            id: parseInt(alternative, 10),
                            alternativeId: parseInt(alternative, 10),
                        })),
                        text: surveyQuestion.type == 6 ? new Date(userAnswer.text) : userAnswer.text,
                        number: userAnswer.number,
                        fileUrl: userAnswer.fileUrl,
                    });
                }

                this.setState({ answers: tempAnswers });
            }

            this.setState({
                form: data,
                formName: data.name,
                hideName: data.hideName,
                userAnswer: data.userAnswer,
                // buttonMessage: data.buttonMessage,
                isLoading: false,
                viewFormAsInactive: data.viewFormAsInactive,
                startDate: moment(data.startdate).isBefore(),
                unavailable: moment(data.startdate).isAfter() || (!!data.enddate && moment(data.enddate).isBefore())
            });
        }).catch((error) => {
            if (error &&
                error.response &&
                error.response.data &&
                error.response.data.message &&
                error.response.data.message.length > 0) {
                this.setState({
                    isLoading: false,
                    displayInfoBox: true,
                    formName: error.response.data.name,
                    infoBoxText: localize(error.response.data.message[0]),
                });
            }
        });
    }

    allowEditing = () => {
        if (this.state.unavailable) {
            return false;
        }
        if (this.state.userAnswer && !this.state.form.allowUserAnswerChange) {
            return false;
        }
        return true;
    }

    questionTypes = [{
        typeId: 1,
        render: (q) => {
            let answerObj = this.state.answers.find(answer => answer.questionId === q.id);

            return (
                <div>
                    <TextField
                        disabled={!this.allowEditing()}
                        value={answerObj ? answerObj.text : ""}
                        className="textField question"
                        multiline={!q.singlerow}
                        required={q.isRequired}
                        label={q.question}
                        aria-required={q.isRequired}
                        validateOnFocusIn={q.isRequired}
                        validateOnFocusOut={q.isRequired}
                        validateOnLoad={q.isRequired && !!this.state.enableFieldValidation}
                        onGetErrorMessage={validateRequiredTextField}
                        onChange={(
                            ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
                            newValue?: string
                        ) => {
                            if (answerObj) {
                                let newAnswers = [...this.state.answers];

                                for (let a of newAnswers) {
                                    if (a.questionId === q.id) {
                                        a.text = newValue;
                                    }
                                }

                                this.setState({
                                    answers: newAnswers
                                });
                            } else {
                                this.setState({
                                    answers: [
                                        ...this.state.answers,
                                        {
                                            questionId: q.id,
                                            text: newValue
                                        }
                                    ]
                                });
                            }
                        }}
                    />
                </div>
            )
        }
    }, {
        typeId: 2,
        render: (q) => {
            let answerObj = this.state.answers.find(answer => answer.questionId === q.id);

            return (
                <div>
                    <ComboBox
                        disabled={!this.allowEditing()}
                        className="question"
                        dropdownWidth={630}
                        required={q.isRequired}
                        aria-required={q.isRequired}
                        label={q.question}
                        calloutProps={{ directionalHintFixed: true }}
                        selectedKey={answerObj && answerObj.alternatives && answerObj.alternatives.length === 1 ? answerObj.alternatives[0].id : null}
                        onChange={(event, item: IComboBoxOption) => {
                            if (answerObj) {
                                let newAnswers = [...this.state.answers];

                                this.setState({
                                    answers: newAnswers.map(na => {
                                        if (na.questionId === q.id) {
                                            return {
                                                questionId: q.id,
                                                alternatives: [
                                                    {
                                                        ...q.alternatives.find(a => a.id === item.key),
                                                        alternativeId: item.key
                                                    }
                                                ]
                                            };
                                        } else {
                                            return na;
                                        }
                                    })
                                });
                            } else {
                                this.setState({
                                    answers: [
                                        ...this.state.answers,
                                        {
                                            questionId: q.id,
                                            alternatives: [
                                                {
                                                    ...q.alternatives.find(a => a.id === item.key),
                                                    alternativeId: item.key
                                                }
                                            ]
                                        }
                                    ]
                                });
                            }
                        }}
                        options={q.alternatives.map(a => {
                            return {
                                key: a.id,
                                text: a.text
                            }
                        })}
                    />
                </div>
            );
        }
    }, {
        typeId: 3,
        render: (q) => {
            let answerObj = this.state.answers.find(answer => answer.questionId === q.id);
            return (
                <div>
                    {
                        q.alternatives.map((a, i) => {
                            return (
                                <div key={i} style={{
                                    marginTop: i === 0 ? 0 : Style.getSpacingStr(2)
                                }}>
                                    <Checkbox
                                        disabled={!this.allowEditing()}
                                        key={i}
                                        label={a.text}
                                        checked={answerObj && !!answerObj.alternatives.find((answer) => answer.id === a.id)}
                                        onChange={(ev: React.FormEvent<HTMLElement>, isChecked: boolean) => {
                                            if (answerObj) {
                                                let newAnswers = [...this.state.answers];

                                                this.setState({
                                                    answers: newAnswers.map(na => {
                                                        if (na.questionId === q.id) {
                                                            if (na.alternatives.find(alternative => alternative.id === a.id)) {
                                                                na.alternatives.splice(na.alternatives.indexOf(na.alternatives.find((naa) => naa.id == a.id)), 1);
                                                                return {
                                                                    ...na,
                                                                    alternatives: na.alternatives
                                                                }
                                                            } else {
                                                                return {
                                                                    ...na,
                                                                    alternatives: [
                                                                        ...na.alternatives,
                                                                        {
                                                                            ...a,
                                                                            alternativeId: a.id
                                                                        }
                                                                    ]
                                                                }
                                                            }
                                                        } else {
                                                            return na;
                                                        }
                                                    })
                                                });
                                            } else {
                                                this.setState({
                                                    answers: [
                                                        ...this.state.answers,
                                                        {
                                                            questionId: q.id,
                                                            alternatives: [
                                                                {
                                                                    ...a,
                                                                    alternativeId: a.id
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                })
                                            }
                                        }} />
                                </div>
                            )
                        })
                    }
                </div>
            );
        }
    }, {
        typeId: 4,
        render: (q) => {
            let answerObj = this.state.answers.find(answer => answer.questionId === q.id);

            return (
                <div>
                    <Rating
                        disabled={!this.allowEditing()}
                        min={1}
                        max={10}
                        rating={answerObj ? answerObj.number : null}
                        allowZeroStars={true}
                        icon="CircleFill"
                        unselectedIcon="CircleRing"
                        onChange={(ev: React.FocusEvent<HTMLElement>, rating: number) => {
                            if (answerObj) {
                                this.setState({
                                    answers: this.state.answers.map(a => {
                                        if (a.questionId === q.id) {
                                            return {
                                                questionId: q.id,
                                                number: rating.toString()
                                            }
                                        } else {
                                            return a;
                                        }
                                    })
                                });
                            } else {
                                this.setState({
                                    answers: [
                                        ...this.state.answers,
                                        {
                                            questionId: q.id,
                                            number: rating.toString()
                                        }
                                    ]
                                });
                            }
                        }}
                    />
                </div>
            );
        }
    }, {
        typeId: 5,
        render: (q) => {
            let answerObj = this.state.answers.find(answer => answer.questionId === q.id);
            return (
                <div>
                    {
                        (!!answerObj && (answerObj.fileTicket || answerObj.fileUrl)) ? <PrimaryButton onClick={() => {
                            this.setState((prevState) => ({
                                answers: prevState.answers.map((answer) => {
                                    if (answer.questionId == q.id) {
                                        return {
                                            questionId: q.id,
                                            fileTicket: "",
                                            fileDeleted: true,
                                            fileUrl: "",
                                        }
                                    } else {
                                        return answer;
                                    }
                                })
                            }))
                        }}>{localize("TaBort")}</PrimaryButton> :
                            <input
                                disabled={!this.allowEditing()}
                                className="file-selector"
                                onChange={(event) => {
                                    // @ts-ignore
                                    const file = event.target.files[0];

                                    let body = new FormData();
                                    let fileTicket = new Date().getTime().toString() + uniqueId();

                                    body.append("type", "7");
                                    body.append("file", file);
                                    body.append("thumbnailSize", "natural");
                                    body.append("ticket", fileTicket);
                                    body.append("anonymous", this.state.form.anonymousAnswers ? "true" : "false");

                                    api.post("/api/uploads", body).then((response) => {
                                        if (answerObj) {
                                            this.setState({
                                                answers: this.state.answers.map(a => {
                                                    if (a.questionId === q.id) {
                                                        return {
                                                            questionId: q.id,
                                                            fileTicket,
                                                            fileName: file.name,
                                                            fileDeleted: false,
                                                        }
                                                    } else {
                                                        return a;
                                                    }
                                                })
                                            });
                                        } else {
                                            this.setState({
                                                answers: [
                                                    ...this.state.answers,
                                                    {
                                                        questionId: q.id,
                                                        fileTicket,
                                                        fileName: file.name,
                                                        fileDeleted: false,
                                                    }
                                                ]
                                            });
                                        }
                                    });
                                }}
                                type="file"
                            />
                    }
                </div>
            );
        }
    }, {
        typeId: 6,
        render: (q) => {
            let answerObj = this.state.answers.find(answer => answer.questionId === q.id);

            return (
                <div>
                    <SpintrDatePicker
                        disabled={!this.allowEditing()}
                        hideTimeSelect={!q.pickTime}
                        value={answerObj ? answerObj.text : null}
                        onChangeHandler={(date) => {
                            if (answerObj) {
                                this.setState({
                                    answers: this.state.answers.map(a => {
                                        if (a.questionId === q.id) {
                                            return {
                                                questionId: q.id,
                                                text: date
                                            }
                                        } else {
                                            return a;
                                        }
                                    })
                                });
                            } else {
                                this.setState({
                                    answers: [
                                        ...this.state.answers,
                                        {
                                            questionId: q.id,
                                            text: date
                                        }
                                    ]
                                });
                            }
                        }}
                    />
                </div>
            );
        }
    },
    {
        typeId: 7,
        render: (q) => { return null }
    }];

    renderQuestion(q) {
        return (
            <FormControl>
                <div className="TinyForm-part">
                    {
                        q.type !== 1 &&
                        q.type !== 2 && (
                            <div className="TinyForm-part-question-text">
                                <FabricLabel disabled={!this.allowEditing()} className="surveyHeader" required={q.isRequired}>
                                    {
                                        q.question
                                    }
                                </FabricLabel>
                            </div>
                        )
                    }
                    <div className="TinyForm-part-input-wrapper">
                        {
                            this.questionTypes.find(qt => qt.typeId === q.type).render(q)
                        }
                    </div>
                    {
                        q.description &&
                            q.description.length > 0 &&
                            q.description.toLowerCase() !== "false" ?
                            <div className="TinyForm-part-description-text">
                                <FabricLabel disabled={!this.allowEditing()} className="description">
                                    {
                                        q.description.split('\n').map(function (item, key) {
                                            return (
                                                <span key={key}>
                                                    {item}
                                                    <br />
                                                </span>
                                            )
                                        })
                                    }
                                </FabricLabel>
                            </div> :
                            null
                    }
                </div>
            </FormControl>
        )
    }

    post() {
        let errorStrings = [];

        for (const q of this.state.form.surveyquestions) {
            if (q.isRequired) {
                const answer = this.state.answers.find((a) => a.questionId === q.id);

                if (!answer || (q.type === 1 && answer.text.length === 0)) {
                    errorStrings.push(localize("REQUIRED_FIELD_ERROR").replace("{0}", '"' + q.question + '"') + ".");
                }
            }
        };

        if (errorStrings.length > 0) {
            this.setState({
                isLoading: true
            }, () => {
                this.setState({
                    isLoading: false,
                    saveError: errorStrings,
                    enableFieldValidation: true
                });
            });

            return;
        }

        this.setState({
            isLoading: true,
            enableFieldValidation: true
        }, () => {
            const answers = this.state.answers.map((answer) => {
                const question = this.state.form.surveyquestions.find((sq) => sq.id === answer.questionId);

                if (question.type === 6) {
                    if (question.pickTime) {
                        answer.text = moment(answer.text).format("YYYY-MM-DD HH:mm");
                    } else {
                        answer.text = moment(answer.text).format("YYYY-MM-DD");
                    }
                }

                return answer;
            });

            const data = {
                formId: this.state.form.id,
                answers,
            };

            api.post("/api/survey/answer", data).then(() => { //TODO: DISPLAY SUCCESS
                this.setState({
                    isLoading: false,
                    displayInfoBox: true,
                    infoBoxText: this.state.form.confirmationMessage
                        ? this.state.form.confirmationMessage
                        : localize("TackForDittSvar")
                });
            }).catch((error) => {
                if (error &&
                    error.response &&
                    error.response.data &&
                    error.response.data.message &&
                    error.response.data.message.length > 0) {
                    this.setState({
                        isLoading: false,
                        dialogText: localize(error.response.data.message[0]),
                        displayInfoDialog: true
                    });
                }
            });
        });
    }

    render() {
        if (!this.isAppEnabled(SpintrTypes.SpintrApp.Forms)) {
            return (
                <div className="TinyForm-wrapper">
                    <div className="TinyForm-info">
                        <Label>
                            {
                                localize("RattigheterEllerInnehallSaknas")
                            }
                        </Label>
                    </div>
                </div>
            )
        }

        if (this.state.displayInfoBox) {
            return (
                <div className="TinyForm-wrapper">
                    {!this.state.hideName && (
                        <div className="TinyForm-header">
                            <Label as="h2" size="h2">
                                {
                                    this.state.formName
                                }
                            </Label>
                        </div>)}
                    <div className="TinyForm-info">
                        <Label>
                            {
                                this.state.infoBoxText
                            }
                        </Label>
                        {!!this.state.form && this.state.form.allowUserAnswerChange && <PrimaryButton onClick={() => {
                            this.setState({
                                displayInfoBox: false
                            });

                            this.fetchForm(true);
                        }} text={localize("CHANGE_ANSWER")} />}
                    </div>
                </div>
            )
        }

        if (this.state.isLoading) {
            return (
                <Loader />
            )
        }

        return (
            <div className="TinyForm-wrapper" role="form">
                <form>
                    {!this.state.hideName && (
                        <div className="TinyForm-header">
                            <Label as="h2" size="h2">
                                {this.state.formName}
                            </Label>
                        </div>
                    )}
                    {this.state.saveError && this.state.saveError.length > 0 && (
                        <ErrorMessagebar
                            errorList={this.state.saveError}
                            onDismiss={() => {
                                this.setState({
                                    saveError: [],
                                });
                            }}
                        />
                    )}
                    {!this.state.startDate || this.state.viewFormAsInactive && this.state.unavailable ?
                        <div className="TinyForm-info">
                            <Label style={{ fontStyle: "italic" }}>{localize("FormularInaktuell")}</Label>
                        </div>
                        : this.state.form.surveyquestions.map((q, i) => {
                            return (
                                <div key={i}>
                                    {
                                        this.renderQuestion(q)
                                    }
                                </div>
                            )
                        })}
                    {this.allowEditing() && <PrimaryButton aria-label={this.state.form.buttonMessage ?? localize("Skicka")} onClick={this.post.bind(this)}>
                        {
                            this.state.form.buttonMessage ?? localize("Skicka")
                        }
                    </PrimaryButton>}
                </form>
                {
                    !!this.state.displayInfoDialog && (
                        <InfoDialog
                            message={this.state.dialogText}
                            show={this.state.displayInfoDialog}
                            onDismiss={() => {
                                this.setState({
                                    displayInfoDialog: false
                                });
                            }}
                        />
                    )
                }
            </div>
        );
    }
}

const mapStateToProps = (state: IApplicationState, props) => {
    return {
        ...props,
        instance: state.instance,
        apps: state.app
    };
};

export default connect(mapStateToProps)(TinyForm);