import { DirectionalHint, TooltipDelay, TooltipHost } from '@fluentui/react';
import { List } from 'immutable';
import React, { ChangeEvent, Component, ReactNode } from 'react';
import { connect } from "react-redux";
import { localize } from 'src/l10n';
import { VisageSidebarMenuItemActiveMode, VisageSidebarMode } from 'src/sidebar';
import { setSidebarMode } from 'src/sidebar/actions';
import { IVisageSidebarMenuItem } from 'src/sidebar/reducer';
import { Label, UnstyledButton } from 'src/ui';
import Visage2Icon from 'src/visage2/Visage2Icon/Visage2Icon';
import './VisageSidebar.scss';
import VisageSidebarItem from './VisageSidebarItem';
import { VisageSidebarBackButton } from './VisageSidebarBackButton';
import SpintrSearch from 'src/ui/components/SpintrList/SpintrSearch';

interface IProps {
    sidebarMode?: VisageSidebarMode,
    menuItems?: IVisageSidebarMenuItem[];
    sidebarItems?: IVisageSidebarMenuItem[];
    dispatch?: any;
    enableCrocus?: boolean;
    currentUser?: any;
    displayBackButton?: boolean;
    displaySearch?: boolean;
}

interface IState {
    searchValue: string;
}

class VisageSidebarItems extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            searchValue: ""
        }
    }

    getItems() {
        if (!this.state.searchValue) {
            return this.props.sidebarItems;
        }

        const flatList = [];

        const addItemsToFlatList = (items: IVisageSidebarMenuItem[]) => {
            for (let item of items) {
                if (!!item.children) {
                    addItemsToFlatList(item.children);
                } else {
                    flatList.push(item);
                }
            }
        }

        addItemsToFlatList(this.props.sidebarItems);

        const searchResult = flatList.filter((i: IVisageSidebarMenuItem) => i.name.toLowerCase().indexOf(this.state.searchValue.toLowerCase()) > -1);

        return searchResult;
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
        if (prevProps.displaySearch && !this.props.displaySearch) {
            this.setState({
                searchValue: ""
            });
        }
    }

    render() {
        const items = this.getItems();

        return (
            <ul className="sidebar-menu">
                {this.props.sidebarMode === VisageSidebarMode.submenu && this.props.displaySearch && (
                    <SpintrSearch
                        classes="searchBox sidebar-top-search"
                        value={this.state.searchValue}
                        onClear={() => {
                            this.setState({
                                searchValue: ""
                            });
                        }}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            if (!event) {
                                return;
                            }

                            const { value } = event.target;

                            this.setState({
                                searchValue: value
                            });
                        }} />
                )}
                {this.props.sidebarMode === VisageSidebarMode.submenu && this.props.displayBackButton && !this.state.searchValue && (
                    <VisageSidebarBackButton onClick={() => {
                        this.props.dispatch(setSidebarMode(VisageSidebarMode.menu));
                    }} />
                )}
                {
                    items.map((item: any, index: number) => {
                        return (
                            // @ts-ignore
                            <VisageSidebarItem currentUser={this.props.currentUser} item={item} isChild={false} key={index} />
                        )
                    })
                }
                {this.props.sidebarMode === VisageSidebarMode.menu && (this.props.currentUser.isAdmin || this.props.currentUser.isEditor) && (
                    <div className="admin-items">
                        <div className="sidebar-admin-items-border" />
                        { /* @ts-ignore */}
                        <VisageSidebarItem currentUser={this.props.currentUser} item={{
                            key: "admin",
                            moduleKey: "admin",
                            name: localize("Administration"),
                            menuClassName: "sidebar-Administration-menuItem",
                            icon: "Setting-2",
                            activeIcon: "VisageSidebarSettingsFilled",
                            route: "/admin",
                            routes: ["/admin"],
                            activeMode: VisageSidebarMenuItemActiveMode.relative
                        }} isChild={false} key={"admin"} />
                        {
                            this.props.enableCrocus && (
                                // @ts-ignore
                                <VisageSidebarItem currentUser={this.props.currentUser} item={{
                                    key: "marketplace",
                                    moduleKey: "marketplace",
                                    name: localize("MARKETPLACE_LIBRARY"),
                                    icon: "Category",
                                    activeIcon: "VisageSidebarApps20Filled",
                                    route: "/marketplace/library",
                                    routes: ["/marketplace/library"],
                                    activeMode: VisageSidebarMenuItemActiveMode.relative
                                }} isChild={false} key={"marketplace"} />
                            )
                        }
                    </div>
                )}
            </ul>
        )
    }
}

const mapStateToProps = (state, props) => ({
    sidebarMode: state.sidebar.mode,
    menuItems: state.sidebar.menuItems,
    currentUser: state.profile.active,
    sidebarItems: state.sidebar.items,
    enableCrocus: state.instance.get("crocusAvailable"),
    displayBackButton: state.sidebar.displayBackButton,
    displaySearch: state.sidebar.displaySearch
});

export default connect(mapStateToProps)(VisageSidebarItems);