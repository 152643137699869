import { AxiosResponse } from "axios";
import React, { useCallback, useEffect, useState } from "react";
import AcademyLeaderboardCategory from "src/academy/AcademyLeaderboardCategory/AcademyLeaderboardCategory";
import { IAcademyLeaderboard, IAcademyLeaderboardCourse, IAcademyLeaderboardUser } from "src/academy/types";
import { localize } from "src/l10n";
import api from "src/spintr/SpintrApi";
import SpintrLoader from "src/ui/components/Loader";
import "./AcademyLeaderboard.scss";

interface IProps {

}

interface IState {
    isLoading: boolean;
    startDate: Date;
    data?: IAcademyLeaderboard;
}

const AcademyLeaderboard = (props: IProps) => {
    const getStartDate = (subtractMonths: number): Date => {
        const d = new Date();
        d.setMonth(d.getMonth() - subtractMonths);
        return d;
    }

    const [state, setState] = useState<IState>({
        isLoading: true,
        startDate: getStartDate(1)
    });

    const fetch = useCallback(() => {
        api.get("/api/v1/academy/leaderboard", {
            params: {
                startDate: state.startDate
            }
        }).then((response: AxiosResponse) => {
            setState((s: IState) => {
                return {
                    ...s,
                    data: response.data,
                    isLoading: false
                }
            });
        }).catch(() => { });
    }, []);

    useEffect(() => {
        fetch();
    }, []);

    if (state.isLoading) {
        return <SpintrLoader />
    }

    return (
        <div className="AcademyLeaderboard">
            <AcademyLeaderboardCategory
                name={localize("MOST_PASSED_COURSES")}
                columnNames={[localize("Namn"), localize("COURSES")]}
                rows={state.data.topUsers.map((x: IAcademyLeaderboardUser) => {
                    return [x.value.name, x.key.toString()]
                })} />
            <AcademyLeaderboardCategory
                name={localize("MOST_POPULAR_COURSES")}
                columnNames={[localize("Namn"), localize("Katagori"), localize("Deltagare_stor")]}
                rows={state.data.popularCourses.map((x: IAcademyLeaderboardCourse) => {
                    return [x.value.name, x.value.category?.name, x.key.toString()]
                })} />
            <AcademyLeaderboardCategory
                name={localize("HIGHEST_RATED_COURSES")}
                columnNames={[localize("Namn"), localize("Katagori"), localize("RATING")]}
                rows={state.data.highestRatedCourses.map((x: IAcademyLeaderboardCourse) => {
                    return [x.value.name, x.value.category?.name, x.key.toString()]
                })} />
        </div>
    )
}

export default AcademyLeaderboard;
