import React, { Component, ReactNode } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { Route, Switch } from "react-router";
import { markAsRead, updateGroupActivity } from "src/chat";
import { GroupHeader } from "src/groups/views";
import GroupCalendarView from "src/groups/views/GroupCalendarView";
import GroupChatView from "src/groups/views/GroupChatView";
import GroupFilesView from "src/groups/views/GroupFilesView";
import GroupHistoryView from "src/groups/views/GroupHistoryView";
import GroupMembersView from "src/groups/views/GroupMembersView";
import GroupNotesView from "src/groups/views/GroupNotesView";
import "src/groups/views/GroupView.scss";
import GroupPlanningView from "src/groups/views/planning/GroupPlanningView";
import { localize } from "src/l10n";
import { VisageSidebarDrawerId, VisageSidebarMode } from "src/sidebar";
import { setSidebarDrawerId, setSidebarMode } from "src/sidebar/actions";
import { IApplicationState } from "src/spintr/reducer";
import api from "src/spintr/SpintrApi";
import { SpintrTypes } from "src/typings";
import { Loader } from "src/ui";
import { setGroupHistoryExpanded } from "src/ui/actions";
import CustomDialog from "src/ui/components/Dialogs/CustomDialog";
import { groupFunctions } from "../utils";
import GroupClassicNotesView from "./GroupClassicNotesView";
import GroupClassicStartView from "./GroupClassicStartView";
import GroupClassicTodoView from "./GroupClassicTodoView";
import GroupFeedView from "./GroupFeedView";
import GroupPageView from "./GroupPageView";
import GroupSocialPostView from "./GroupSocialPostView";
import { GroupSupportView } from "./GroupSupportView";

interface IProps {
    group?: any;
    groupHistoryExpanded?: boolean;
    isSmallViewMode?: boolean;
    history: any;
    location: any;
    dispatch?: any;
}

interface IState {
    isJoiningGroup?: boolean;
}

class GroupView extends Component<IProps, IState> {
    constructor(props) {
        super(props);

        this.state = {};
    }

    getGroupWrapStyle(): React.CSSProperties {
        let s: React.CSSProperties = {};

        if (this.props.groupHistoryExpanded) {
            s.width = "calc(100% - 250px)";
        }

        return s;
    }

    componentDidMount() {
        const hideGroupHistory = this.props.isSmallViewMode ||
            (this.props.group.version === 1 && this.props.groupHistoryExpanded);

        if (hideGroupHistory) {
            this.props.dispatch(setGroupHistoryExpanded(false));
        }

        // TODO: Only if unread?

        this.props.dispatch(markAsRead(this.props.group.conversationId));
        this.props.dispatch(updateGroupActivity(this.props.group.id));
        this.props.dispatch(setSidebarMode(VisageSidebarMode.drawer));
        this.props.dispatch(setSidebarDrawerId(VisageSidebarDrawerId.groups));

        const hasNoFunctions = !this.props.group.tabOrder.some((tab) => tab.enabled);
        const hasMenuItems = this.props.group.menu?.length > 0;
        const isOnRootPage = this.props.location.pathname === "/groups/" + this.props.group.id;

        if (
            hasNoFunctions &&
            hasMenuItems &&
            isOnRootPage
        ) {
            this.props.history.replace({
                pathname: "/" + this.props.group.menu[0].url,
            });
        }
    }

    public renderComponent(RoutedComponent: any, props: any = {}): ReactNode {
        return (
            <RoutedComponent {...props} group={this.props.group} history={this.props.history} />
        );
    }

    getRoutes() {
        if (this.props.group.version === 1) {
            return (
                <Switch>
                    <Route exact path="/groups/:id" component={GroupClassicStartView} />
                    <Route exact path="/groups/:id/support" component={GroupSupportView} />
                    <Route exact path="/groups/:id/todo" component={GroupClassicTodoView} />
                    <Route exact path="/groups/:id/files" render={this.renderComponent.bind(this, GroupFilesView)} />
                    <Route
                        exact
                        path="/groups/:id/files/:filesId"
                        render={this.renderComponent.bind(this, GroupFilesView)}
                    />
                    <Route
                        exact
                        path="/groups/:id/files/:filesId/version"
                        render={this.renderComponent.bind(this, GroupFilesView)}
                    />
                    <Route
                        exact
                        path="/groups/:id/files/:filesId/edit"
                        render={this.renderComponent.bind(this, GroupFilesView)}
                    />
                    <Route exact path="/groups/:id/classic-notes" component={GroupClassicNotesView} />
                    <Route
                        exact
                        path="/groups/:id/calendar"
                        component={this.renderComponent.bind(this, GroupCalendarView)}
                    />
                    <Route
                        exact
                        path="/groups/:id/calendar/:eventId"
                        component={this.renderComponent.bind(this, GroupCalendarView)}
                    />
                    <Route exact path="/groups/:id/members" component={GroupMembersView} />
                    <Route exact path="/groups/:id/members/invite" component={GroupMembersView} />
                    <Route exact path="/groups/:id/chat" component={GroupChatView} />
                </Switch>
            );
        }

        const items = this.props.group.tabOrder
            .filter((tab) => tab.enabled)
            .map((tab) => ({
                ...groupFunctions.find((gf) => gf.id === tab.id),
            }));

        return (
            <Switch>
                {
                    !!items && items.length > 0 && (
                        <Route exact path="/groups/:id" component={this.renderComponent.bind(this, items[0].component)} />
                    )
                }
                <Route exact path="/groups/:id/chat" component={GroupChatView} />
                <Route exact path="/groups/:id/support" component={GroupSupportView} />
                <Route exact path="/groups/:id/planning" component={GroupPlanningView} />
                <Route exact path="/groups/:id/planning/:cardId" component={GroupPlanningView} />
                <Route exact path="/groups/:id/notes" component={GroupNotesView} />
                <Route exact path="/groups/:id/notes/:noteId" component={GroupNotesView} />
                <Route exact path="/groups/:id/files" render={this.renderComponent.bind(this, GroupFilesView)} />
                <Route
                    exact
                    path="/groups/:id/files/:filesId"
                    render={this.renderComponent.bind(this, GroupFilesView)}
                />
                <Route
                    exact
                    path="/groups/:id/files/:filesId/version"
                    render={this.renderComponent.bind(this, GroupFilesView)}
                />
                <Route
                    exact
                    path="/groups/:id/files/:filesId/edit"
                    render={this.renderComponent.bind(this, GroupFilesView)}
                />
                <Route
                    exact
                    path="/groups/:id/calendar"
                    component={this.renderComponent.bind(this, GroupCalendarView)}
                />
                <Route
                    exact
                    path="/groups/:id/calendar/:eventId"
                    component={this.renderComponent.bind(this, GroupCalendarView)}
                />
                <Route exact path="/groups/:id/members" component={GroupMembersView} />
                <Route exact path="/groups/:id/members/invite" component={GroupMembersView} />
                <Route exact path="/groups/:id/feed/:statusId" component={this.renderComponent.bind(this, GroupSocialPostView)} />
                <Route exact path="/groups/:id/feed" component={this.renderComponent.bind(this, GroupFeedView)} />
                <Route path="/groups/:id/:path" render={this.renderComponent.bind(this, GroupPageView)} />
            </Switch>
        );
    }

    render() {
        return (
            <div className="GroupView">
                <Helmet>
                    <title>{this.props.group.name}</title>
                </Helmet>
                <div style={this.getGroupWrapStyle()}>
                    <GroupHeader history={this.props.history} />
                </div>
                <div role="main" className="groupWrap" style={this.getGroupWrapStyle()}>
                    {this.getRoutes()}
                </div>

                {this.props.groupHistoryExpanded ? (
                    <GroupHistoryView history={this.props.history} conversationId={this.props.group.conversationId} />
                ) : null}
                {
                    !this.props.group.isMember &&
                    this.props.group.isOpen &&
                    !this.props.group.isSecret && (
                        <CustomDialog
                            message={localize("StangdGruppBeskrivning")}
                            show={true}
                            confirmMessage={localize("GaMed")}
                            onDismiss={() => {
                                this.props.history.push({
                                    pathname: "/groups",
                                });
                            }}
                            children={this.state.isJoiningGroup ? <Loader /> : null}
                            primaryButtonDisabled={this.state.isJoiningGroup}
                            onConfirm={() => {
                                this.setState({
                                    isJoiningGroup: true
                                }, () => {
                                    api.post("/api/groups/" + this.props.group.id + "/join").then(() => {
                                        window.location.reload();
                                    }).catch(() => {
                                        this.props.history.push({
                                            pathname: "/groups",
                                        });
                                    });
                                });
                            }} />
                    )
                }
            </div>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (
            nextProps.groupHistoryExpanded !== this.props.groupHistoryExpanded ||
            nextProps.group.id !== this.props.group.id ||
            nextState.isJoiningGroup !== this.state.isJoiningGroup
        ) {
            return true;
        }

        return false;
    }
}

const mapStateToProps = (state: IApplicationState, props: IProps): IProps => ({
    ...props,
    group: state.groups.group,
    groupHistoryExpanded: state.ui.groupHistoryExpanded,
    isSmallViewMode: state.ui.viewMode <= SpintrTypes.ViewMode.PhoneLandscape,
});

export default connect(mapStateToProps)(GroupView);
