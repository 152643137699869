import classNames from "classnames";
import { DraftHandleValue, EditorState, getDefaultKeyBinding, Modifier } from "draft-js";
import createEmojiPlugin, {
    EmojiPlugin
} from "draft-js-emoji-plugin";
import createMentionPlugin, {
    DraftMentionObject,
    MentionSearchChangedEvent,
    MentionsPlugin
} from "draft-js-mention-plugin";
import Editor from "draft-js-plugins-editor";
import { FontIcon, Icon } from "@fluentui/react";
import React, {
    ChangeEvent,
    Component,
    ContextType,
    createRef, ReactNode,
    RefObject
} from "react";
import { Subject, Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { searchUsers } from "src/search";
import { NoOp } from "src/utils";
import { ChatTabContext } from "../ChatTabContext";
import "./ChatTabComposer.scss";
import localize from "src/l10n/localize";
import { Label, UnstyledButton } from "src/ui";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";

interface IProps {
    editorState: EditorState;
    placeholder?: string;
    blocked?: boolean;
    blockedBy?: boolean;
    conversationTitle?: string;

    onChange(editorState: EditorState): void;
    onFilesSelected(files: File[]): void;
    onSend(): void;
    parentMessage?: any;
    onRemoveParentMessage?: any;
}

interface IState {
    suggestions: DraftMentionObject[];
}

const sendKeyCommand = "send-message";
const getKeyBinding = (e: any): string => {
    if (e.keyCode === 13 && !e.shiftKey) {
        return sendKeyCommand;
    }

    return getDefaultKeyBinding(e);
};

class ChatTabComposer extends Component<IProps, IState> {
    public static contextType = ChatTabContext;
    public context: ContextType<typeof ChatTabContext>;

    private readonly fileInputRef: RefObject<HTMLInputElement>;
    private readonly mentionsPlugin: MentionsPlugin;
    private readonly emojiPlugin: EmojiPlugin;
    private readonly mentionSubject: Subject<string>;
    private readonly mentionSubscription: Subscription;

    constructor(props: IProps) {
        super(props);

        this.state = {
            suggestions: [],
        }

        this.fileInputRef = createRef();
        this.handleKeyCommand = this.handleKeyCommand.bind(this);
        this.onFileChange = this.onFileChange.bind(this);
        this.onMentionValue = this.onMentionValue.bind(this);
        this.onSearchChange = this.onSearchChange.bind(this);

        this.emojiPlugin = createEmojiPlugin({
            positionSuggestions: () => ({
                bottom: "50px",
                left: "0px",
                transform: "scaleY(1)",
                transition: "all 0.25s cubic-bezier(.3,1.2,.2,1)",
            })
        });

        this.mentionsPlugin = createMentionPlugin({
            positionSuggestions: (plugin: any) => {
                const isActive: boolean = plugin.state.isActive;
                const hasSuggestions = plugin.props.suggestions.length > 0;

                return {
                    bottom: "50px",
                    left: "0px",
                    transform: (isActive && hasSuggestions)
                        ? "scaleY(1)"
                        : "",
                    transition: isActive
                        ? "all 0.25s cubic-bezier(.3,1,.2,1)"
                        : undefined,
                }
            }
        });

        this.mentionSubject = new Subject();
        this.mentionSubscription = this.mentionSubject
            .pipe(
                debounceTime(100)
            )
            .subscribe(this.onMentionValue);
    }

    public hasText() {
        if (this &&
            this.props &&
            this.props.editorState) {
            const currentContent = this.props.editorState.getCurrentContent();

            if (currentContent) {
                return currentContent.hasText()
            }
        }

        return false;
    }

    public componentWillUnmount() {
        try {
            this.mentionSubscription.unsubscribe();
            this.mentionSubject.unsubscribe();
        } catch (err) { /* ignored */ }
    }

    handlePaste = (text): DraftHandleValue => {
        this.props.onChange(EditorState.push(
            this.props.editorState,
            Modifier.replaceText(
                this.props.editorState.getCurrentContent(),
                this.props.editorState.getSelection(),
                text.replace(/\n/g, ' ')
            ), 'insert-characters'
        ));
        return 'handled';
    }

    renderReplyingToText() {
        return (
            <div className="replying-to">
                <Label size="body-2" weight="medium">
                    {
                        localize("REPLYING_TO") +
                        " " +
                        this.props.parentMessage.user.name
                    }
                </Label>
                <UnstyledButton className="close-button" title={localize("Stang")} onClick={this.props.onRemoveParentMessage}>
                    <Visage2Icon icon="close-circle" />
                </UnstyledButton>
            </div>
        )
    }

    public render(): ReactNode {
        const { editorState, onChange, onSend } = this.props;
        const { suggestions } = this.state;

        const hasText = editorState.getCurrentContent().hasText();

        const { MentionSuggestions } = this.mentionsPlugin;
        const { EmojiSuggestions } = this.emojiPlugin;

        const placeholder = this.props.blocked
            ? localize("YOU_HAVE_BLOCKED_X").replace("{{X}}", this.props.conversationTitle)
            : this.props.blockedBy
            ? localize("X_HAS_BLOCKED_YOU").replace("{{X}}", this.props.conversationTitle)
            : this.props.placeholder;

        return (
            <div className={classNames(
                "ChatTabComposer", "fs-body-2", (hasText ? "hasText" : ""),
            )}>
                {!!this.props.parentMessage && this.renderReplyingToText()}
                <div className="input">
                    <Editor
                        handlePastedText={this.handlePaste}
                        editorState={editorState}
                        keyBindingFn={getKeyBinding}
                        handleKeyCommand={this.handleKeyCommand}
                        onChange={onChange}
                        onFocus={this.context.clearUnread}
                        placeholder={placeholder}
                        plugins={[
                            this.mentionsPlugin,
                            this.emojiPlugin,
                        ]}
                        readOnly={this.props.blocked || this.props.blockedBy}
                    />
                    <MentionSuggestions
                        suggestions={suggestions}
                        onAddMention={NoOp}
                        onSearchChange={this.onSearchChange}
                    />
                    <EmojiSuggestions />
                </div>
                {!this.props.blocked && !this.props.blockedBy && (
                    <div className="actions">
                        {!hasText && (
                            <a className="primaryFGColor attach">
                                <input
                                    multiple={true}
                                    onChange={this.onFileChange}
                                    ref={this.fileInputRef}
                                    type="file"
                                />
                                <Visage2Icon icon="add-circle" />
                            </a>
                        )}
                        <a className="primaryFGColor send" onClick={onSend}>
                            <Visage2Icon icon="send-2" />
                        </a>
                    </div>
                )}
            </div>
        );
    }

    protected handleKeyCommand(command: string): DraftHandleValue {
        if (command !== sendKeyCommand) {
            return "not-handled";
        }

        this.props.onSend();

        return "handled";
    }

    protected onFileChange(event: ChangeEvent<HTMLInputElement>): void {
        const { files } = event.target;
        if (!files || !files.length) {
            return;
        }

        this.props.onFilesSelected(
            Array.prototype.slice.call(files) as File[],
        );
    }

    protected async onMentionValue(value: string): Promise<void> {
        if (!value) {
            return;
        }

        try {
            const results = await searchUsers({ query: value });

            this.setState({
                suggestions: results.data.map(hit => ({
                    avatar: hit.imageUrl,
                    key: hit.id,
                    link: `/goto/1/${hit.id}`,
                    name: hit.name
                }))
            });
        } catch (err) {
            console.log(err);
        }
    }

    protected onSearchChange(event: MentionSearchChangedEvent): void {
        const { value } = event;

        if (value) {
            this.mentionSubject.next(value);
            return;
        }

        this.setState(
            { suggestions: [] },
            () => this.mentionSubject.next(value)
        );
    }
}

export default ChatTabComposer;
