function debounce(fn: Function, time: number) {
    let timeout;

    return function() {
        const args = arguments;
        const functionCall = () => fn.apply(this, args);

        clearTimeout(timeout);

        timeout = setTimeout(functionCall, time);
    }
}

export default debounce;