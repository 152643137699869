import { PrimaryButton } from "@fluentui/react";
import React, { Component, createRef, ReactNode, RefObject } from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { localize } from "src/l10n";
import { fetchLibrary } from "src/marketplace/api";
import { PlacementHelpPopup, WidgetConfigurationPopup, WidgetLibrary } from "src/marketplace/components";
import { BackgroundImage, Label, LocalizedString } from "src/ui";
import { IMarketplaceLibraryViewState as State, MarketplaceLibraryViewProps as Props } from "./MarketplaceLibraryView.types";

class MarketplaceLibraryView extends Component<Props, State> {
    private readonly libraryRef: RefObject<WidgetLibrary>;

    constructor(props: RouteComponentProps) {
        super(props);

        this.state = {
            createdConfigurationId: null,
            createdConfigurationName: null,
            data: null,
            isLoading: true,
            selectedAppId: null,
            selectedWidgetConfigurationId: null,
            totalCount: -1,
        };

        this.libraryRef = createRef();

        this.goToDataMarket = this.goToDataMarket.bind(this);
        this.onConfigurationSaved = this.onConfigurationSaved.bind(this);
        this.onCreateConfigurationClicked = this.onCreateConfigurationClicked.bind(this);
        this.onEditConfigurationClicked = this.onEditConfigurationClicked.bind(this);
        this.onCreatePopupDismiss = this.onCreatePopupDismiss.bind(this);
        this.onPlacementPopupDismiss = this.onPlacementPopupDismiss.bind(this);
    }

    public componentDidMount(): void {
        this.fetchLibrary();
    }

    public goToDataMarket(): void {
        this.props.history.push("/marketplace");
    }

    public render(): ReactNode {
        const coverArtUrl: string = "/images/marketplace_coverart.png";

        return (
            <div id="MarketplaceLibraryView">
                <div className="view-header">
                    <div className="header-text">
                        <Link to="/marketplace">
                            <Label
                                as="h2"
                                className="app-title"
                                color="black"
                                size="h2"
                                weight="semi-bold"
                            >
                                <LocalizedString text="MARKETPLACE_HEADING" />
                            </Label>
                        </Link>
                    </div>
                    <PrimaryButton
                        onClick={this.goToDataMarket}
                        text={localize("MARKETPLACE_LIBRARY_CTA")}
                        style={{
                            background: "#22234A",
                            borderColor: "#22234A",
                        }}
                        styles={{flexContainer: {
                            flexDirection: "row-reverse",
                        }}}
                        iconProps={{
                            iconName: "Forward",
                            
                        }}
                    />
                </div>
                <div className="view-body">
                    <WidgetLibrary
                        onConfigurationClicked={
                            // tslint:disable-next-line: jsx-no-lambda
                            (configuration) => {
                                this.setState({
                                    createdConfigurationId: configuration.id,
                                    createdConfigurationName: configuration.name,
                                });
                            }
                        }
                        onCreateConfigurationClicked={this.onCreateConfigurationClicked}
                        onEditConfigurationClicked={this.onEditConfigurationClicked}
                        ref={this.libraryRef}
                        showOptions={true}
                    />
                    <WidgetConfigurationPopup
                        appId={this.state.selectedAppId}
                        widgetConfigurationId={this.state.selectedWidgetConfigurationId}
                        isShowing={!!this.state.selectedAppId}
                        onDismiss={this.onCreatePopupDismiss}
                        onSaved={this.onConfigurationSaved}
                    />
                    <PlacementHelpPopup
                        configurationId={this.state.createdConfigurationId}
                        configurationName={this.state.createdConfigurationName}
                        isShowing={!!this.state.createdConfigurationId}
                        onDismiss={this.onPlacementPopupDismiss}
                    />
                </div>
            </div>
        )
    }

    private onConfigurationSaved(data: Spintr.IMarketplaceLibraryWidgetResponse, isNew?: boolean) {
        if (this.libraryRef.current) {
            this.libraryRef.current.addNewEntry(
                this.state.selectedAppId, {
                    id: data.id,
                    name: data.name,
                    type: data.displayType,
                    widgetId: data.widgetId,
                },
            );
        }

        if (isNew) {
            this.setState({
                createdConfigurationId: data.id,
                createdConfigurationName: data.name,
                selectedAppId: null,
            });
        } else {
            this.setState({
                selectedAppId: null,
            });
        }
    }

    private onCreatePopupDismiss(): void {
        this.setState({
            selectedAppId: null,
            selectedWidgetConfigurationId: null
        });
    }

    private onPlacementPopupDismiss(): void {
        this.setState({
            createdConfigurationId: null,
            createdConfigurationName: null,
        });
    }

    private onCreateConfigurationClicked(app: Spintr.IMarketplaceAppResponse): void {
        this.setState({
            selectedAppId: app.id,
        });
    }

    private onEditConfigurationClicked(configuration: Spintr.IMarketplaceLibraryWidget): void {
        let appId;

        for (const data of this.state.data) {
            for (const w of data.widgets) {
                if (w.id === configuration.id) {
                    appId = data.app.id;
                }
            }
        }

        if (!appId) {
            return;
        }

        this.setState({
            selectedAppId: appId,
            selectedWidgetConfigurationId: configuration.id
        });
    }

    private async fetchLibrary(): Promise<void> {
        const result = await fetchLibrary();
        if (result == null) {
            // TODO: Think about if this could mean an error has occurred
            this.setState({
                data: [],
                isLoading: false,
                totalCount: 0,
            });
            return;
        }

        this.setState({
            data: result.data,
            isLoading: false,
            totalCount: result.totalCount,
        });
    }
}

const MarketplaceLibraryViewWithRouter = withRouter(MarketplaceLibraryView);

export default MarketplaceLibraryViewWithRouter;
