import { AxiosResponse } from "axios";
import React, { Component, ReactNode } from "react";
import { connect, MapDispatchToProps, MapStateToProps } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { createConversation } from "src/chat/api";
import { ChatTabContent } from "src/chat/components/ChatTabContent";
import { ChatTabContext } from "src/chat/components/ChatTabContext";
import { Conversation } from "src/chat/conversation-types";
import { Message } from "src/chat/message-types";
import { addRemoteConversation, AddRemoteConversationHandler } from "src/chat/redux";
import { localize } from "src/l10n";
import { SpintrTypes } from "src/typings";
import { FillHeight, Loader } from "src/ui";
import { TokenizedObjectInput } from "src/ui/components/Forms";
import ITokenizedInputItem from "src/ui/components/Forms/ITokenizedInputItem";
import "./NewConversationView.scss";

interface IDispatchProps {
    addRemoteConversation: AddRemoteConversationHandler;
}

interface IStateProps {
    currentUser: Spintr.IUser;
}

interface IState {
    conversation?: Conversation;
    isLoading: boolean;
    items: ITokenizedInputItem[];
}

type Props = IDispatchProps & IStateProps & RouteComponentProps;

class NewConversationView extends Component<Props, IState> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isLoading: false,
            items: [],
        };

        this.onCreateConversationSuccess = this.onCreateConversationSuccess.bind(this);
        this.onMessageSent = this.onMessageSent.bind(this);
        this.onRecipientsChange = this.onRecipientsChange.bind(this);
    }

    public render(): ReactNode {
        return (
            <div id="NewConversationView">
                <div className="header">
                    <TokenizedObjectInput
                        items={this.state.items}
                        onChange={this.onRecipientsChange}
                        placeholder={localize("Till")}
                        types={[SpintrTypes.UberType.User]}
                        excludeId={this.props.currentUser.id}
                    />
                </div>
                <div className="body">
                    <FillHeight>
                        {this.state.isLoading && (
                            <Loader />
                        )}
                        {this.state.conversation && !this.state.isLoading && (
                            <ChatTabContext.Provider
                                value={{
                                    clearUnread: () => {
                                        /* */
                                    },
                                    currentUser: this.props.currentUser,
                                    lastRead: {},
                                    loadMore: () => {

                                    },
                                }}
                            >
                                <ChatTabContent
                                    conversationId={this.state.conversation.id}
                                    lastRead={{}}
                                    minimized={false}
                                    onMessageSent={this.onMessageSent}
                                    pageSize={20}
                                />
                            </ChatTabContext.Provider>
                        )}
                    </FillHeight>
                </div>
            </div>
        );
    }

    protected onCreateConversationSuccess(response: AxiosResponse<Spintr.IConversation>): void {
        const conversation = new Conversation(response.data);

        this.setState({  
            conversation,
            isLoading: false,
        });
    }

    protected onMessageSent(message: Message): void {
        const url = "/messages/" + message.conversationId;

        this.props.addRemoteConversation(this.state.conversation);
        this.props.history.push(url);
    }

    protected onRecipientsChange(items: ITokenizedInputItem[]): void {
        this.setState(
            { conversation: null, isLoading: items.length > 0, items, },
            () => {
                if (items.length === 0) {
                    return;
                }

                const recipients: Spintr.IUser[] = this.state.items.map(
                    (item) => ({
                        id: parseInt(item.key, 10),
                        imageUrl: null,
                        info: null,
                        name: null,
                        type: null,
                        typeName: null,
                    }),
                );

                createConversation(recipients).then((response: AxiosResponse) => {
                    this.onCreateConversationSuccess(response);
                }).catch(() => {})
            },
        );
    }
}

const mapStateToProps: MapStateToProps<IStateProps, {}, Spintr.AppState> =
    (state) => ({
        currentUser: {
            id: state.profile.active.id,
            imageUrl: state.profile.active.images.Message,
            info: "",
            name: state.profile.active.name,
            type: 1,
            typeName: "",
        },
    });

const mapDispatchToProps: MapDispatchToProps<IDispatchProps, {}> = {
    addRemoteConversation,
};

const NewConversationViewWithRouter = withRouter(NewConversationView);

const ConnectedNewConversationViewWithRouter =
    connect(mapStateToProps, mapDispatchToProps)(NewConversationViewWithRouter);

export default ConnectedNewConversationViewWithRouter;
