enum SearchActionTypes {
    ClearCurrentResults = "SEARCH_CLEAR_RESULTS",

    ExecuteSearch = "SEARCH_EXECUTE",
    ExecuteSearchFulfilled = "SEARCH_EXECUTE_FULFILLED",
    ExecuteSearchPending = "SEARCH_EXECUTE_PENDING",
    ExecuteSearchRejected = "SEARCH_EXECUTE_REJECTED",
}

export default SearchActionTypes;
