import axios, { AxiosResponse } from "axios";
import { endOfDay } from "date-fns";
import moment from "moment";
import { Checkbox, DefaultButton, Dropdown, IconButton, TextField } from "@fluentui/react";
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { localize } from "src/l10n";
import SpintrDatePicker from "src/spintr/components/SpintrDatePicker";
import { IApplicationState } from "src/spintr/reducer";
import { ContentImageViewerAndSelector, ContentWithSubmenu, Label, PageHeader, Submenu, UnstyledButton } from "src/ui";
import { FormControl, FormFooterBar, FormSection } from "src/ui/components/Forms";
import SpintrLoader from "src/ui/components/Loader";
import ErrorMessagebar from "src/ui/components/Messagebars/ErrorMessagebar";
import { NoOp, scrollToTop, validateRequiredTextField } from "src/utils";
import "./ResourceView.scss";
import api from "src/spintr/SpintrApi";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";

interface IProps {
    history: any;
    match: any;
    isAdmin: boolean;
    isEditor: boolean;
    currentUserId: number;
    currentUserName: string;
    currentUserDepartmentName: string;
    currentUserImageUrl: string;
    requiredTagCount: number;
}

interface IState {
    isLoading: boolean;
    resource: any;
    saveError: string[];
    categories: any[];
    targets: any;
    newCategory: string;
    intervals: any;
    enableFieldValidation?: boolean;
}

class ResourceEditView extends Component<IProps, IState> {
    private isNew = false;

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            saveError: [],
            categories: [],
            resource: {},
            targets: [],
            newCategory: "",
            intervals: [],
        };
    }

    initPage(resource) {
        let values = [1, 2, 4, 8, 16, 32, 64];

        let newinterval = resource.intervals.map((obj) => {
            let days = values.map((item) => item & obj.availability);

            let init = {
                id: obj.id,
                resourceId: obj.resourceId,
                start: this.timeStringToDate(obj.start) as Date,
                end: this.timeStringToDate(obj.end) as Date,
                monday: days.includes(1),
                tuesday: days.includes(2),
                wednesday: days.includes(4),
                thursday: days.includes(8),
                friday: days.includes(16),
                saturday: days.includes(32),
                sunday: days.includes(64),
            };
            return init;
        });

        this.setState({
            isLoading: resource.isLoading,

            resource: {
                isNew: resource.isNew,
                id: resource.id,
                description: resource.description,
                name: resource.name,
                imageUrl: resource.imageUrl,
                categoryId: resource.categoryId,
                intervals: resource.intervals,
            },
            intervals: newinterval,
            saveError: [],
        });
    }

    fetch = () => {
        const resourceId = window.location.href.split("/").reverse()[1];

        axios
            .all([
                api.get("/api/bookingresources/" + resourceId).then((response) => {
                    this.initPage(response.data);
                }),
                this.getCategories(),
            ])
            .then(() => {
                this.setState({ isLoading: false });
            });
    };

    componentDidMount() {
        if (window.location.href.indexOf("booking/create") > -1) {
            this.isNew = true;
        } else {
            this.isNew = false;
        }

        if (this.isNew) {
            this.getCategories();

            this.initPage({
                isNew: true,
                intervals: [],
                id: 0,
                description: "",
                name: "",
                imageUrl: "",
            });
        } else {
            this.fetch();
        }
    }

    onCancelClick() {
        this.props.history.goBack();
    }

    onSaveClick = async () => {
        this.setState(
            {
                enableFieldValidation: true,
                isLoading: true,
            },
            () => {
                const saveErrors = [];
                const resource = { ...this.state.resource };

                if (!this.state.resource.name || this.state.resource.name.length === 0) {
                    saveErrors.push("DuMasteFyllaIEttNamn");
                }

                const hasCategory =
                    (resource.categoryId && resource.categoryId > 0) ||
                    (resource.categoryId === 0 && this.state.newCategory && this.state.newCategory.length > 0);

                if (!hasCategory) {
                    saveErrors.push("DuMasteAngeEnKategori");
                }

                if (!resource.description || resource.description.length === 0) {
                    saveErrors.push("DuMasteFyllaIEnBeskrivning");
                }

                if (saveErrors.length > 0) {
                    this.setState(
                        {
                            saveError: saveErrors,
                            isLoading: false,
                        },
                        () => {
                            scrollToTop();
                        }
                    );

                    return;
                }

                this.saveNewCategory().then((categoryId) => {
                    if (categoryId) {
                        resource.categoryId = categoryId;
                    }

                    const req = resource.isNew
                        ? api.post("/api/bookingresources", resource)
                        : api.put(`/api/bookingresources/${resource.id}`, resource);

                    req.then(async (response) => {
                        if (this.state.intervals.length > 0) {
                            await this.saveIntervals(response.data.id);
                        }

                        this.setState(
                            {
                                isLoading: false,
                                saveError: [],
                            },
                            () => {
                                this.props.history.push("/booking");
                            }
                        );
                    }).catch(this.handleCatch);
                });
            }
        );
    };

    handleCatch = (saveError) => {
        let errors = [];

        if (saveError && saveError.response && saveError.response.data && saveError.response.data.errorlist) {
            errors = saveError.response.data.errorlist;
        } else {
            errors.push(localize("TeknisktFel"));
        }

        this.setState({
            saveError: errors,
            isLoading: false,
        });
    };

    timeStringToDate = (time24: string) => {
        const today = new Date();
        const hours = Number(time24.substring(0, 2));
        const minutes = Number(time24.substring(3, 5));

        return new Date(today.setHours(hours, minutes));
    };

    saveIntervals = async (resourceId) => {
        let intervals = [...this.state.intervals];
        let amount;

        intervals.map(async (i) => {
            amount = 0;

            if (i.monday) amount += 1;
            if (i.tuesday) amount += 2;
            if (i.wednesday) amount += 4;
            if (i.thursday) amount += 8;
            if (i.friday) amount += 16;
            if (i.saturday) amount += 32;
            if (i.sunday) amount += 64;

            i.resourceId = resourceId;
            i.start = moment(i.start, ["HH:mm"]).format("HH:mm");
            i.end = moment(i.end, ["HH:mm"]).format("HH:mm");

            i.availability = amount;

            const isNew = i.id === 0;

            if (isNew) {
                await api.post("/api/v1/bookingintervals", i).catch(this.handleCatch);
            } else {
                await api.put(`/api/v1/bookingintervals/${i.id}`, i).catch(this.handleCatch);
            }
        });
    };

    deleteIntervals = (intervalIds) => {
        Promise.all(
            intervalIds.map((id) => {
                return this.deleteInterval(id);
            })
        ).then((responses) => {
            return responses;
        });
    };

    deleteInterval = (intervalId) => {
        return api.delete(`/api/bookingintervals/${intervalId}`).catch(this.handleCatch);
    };

    saveNewCategory = async () => {
        if (!this.state.newCategory) return undefined;

        let newType = {
            id: 0,
            name: this.state.newCategory,

            resources: [],
        };

        const postcategories = api.post("/api/bookingresourcecategories", newType);

        return await Promise.all([postcategories, this.getCategories()]).then(
            ([a, b]: [AxiosResponse, any]) => {
                return a.data.id;
            }
        );
    };

    getCategories = async () => {
        axios
            .get("/api/bookingresourcecategories")

            .then((pagesResponse: AxiosResponse) => {
                this.setState({
                    categories: pagesResponse.data,
                });
            });
    };

    addNewInterval() {
        let intervals = [...this.state.intervals];
        intervals.push({ id: 0, start: "12:00", end: "13:00" });
        this.setState({ intervals });
    }

    handleInputChange = (e, index, nameTime?) => {
        const intervals = [...this.state.intervals];
        if (e instanceof Date) {
            intervals[index][nameTime] = e;
        } else {
            const { name, value, type, checked } = e.target;

            type === "checkbox" ? (intervals[index][name] = checked) : (intervals[index][name] = value);
        }

        this.setState({ intervals });
    };

    toggleAll = (e, index) => {
        let intervals = [...this.state.intervals];
        const { checked } = e.target;

        intervals[index]["monday"] = checked;
        intervals[index]["tuesday"] = checked;
        intervals[index]["wednesday"] = checked;
        intervals[index]["thursday"] = checked;
        intervals[index]["friday"] = checked;
        intervals[index]["saturday"] = checked;
        intervals[index]["sunday"] = checked;

        this.setState({ intervals });
    };

    handleRemoveInterval = (index) => {
        let intervals = [...this.state.intervals];

        this.deleteInterval(intervals[index].id).then(() => {
            this.fetch();
        });

        intervals.splice(index, 1);
        this.setState({ intervals });
    };

    render() {
        if (this.state.isLoading) {
            return <SpintrLoader />;
        }

        const resource = this.state.resource;
        let categories = [{ key: 0, text: localize("AngeNy") + ": " }];

        this.state.categories.map((obj) => {
            categories.push({ key: obj.id, text: obj.name });
        });

        const icon = { iconName: "Delete" };
        return (
            <div>
                <Helmet>
                    <title>{localize("Resursbokning")}</title>
                </Helmet>
                <div className="ResourceEditView page-margin-bottom">
                    <PageHeader
                        title={this.isNew
                            ? `${localize("Skapa")} ${localize("NyResurs").toLowerCase()}`
                            : localize("Redigera") + " " + localize("Resurs").toLowerCase()}
                        displayMandatoryText />
                    {this.state.saveError.length > 0 && (
                        <ErrorMessagebar
                            errorList={this.state.saveError}
                            onDismiss={() => {
                                this.setState({
                                    saveError: [],
                                });
                            }}
                        />
                    )}
                    <form>
                        <FormControl>
                            <ContentImageViewerAndSelector
                                renderSizeString={() => {
                                    return (
                                        `${localize(
                                            "OptimalBildStorlek"
                                        )}: 36x36px <br />`
                                    )
                                }}
                                cropImage
                                cropAspect={1}
                                imageUrl={resource.imageUrl || resource.imageTicket}
                                editMode={true}
                                onChange={(result) => {
                                    this.setState({
                                        resource: {
                                            ...this.state.resource,
                                            imageUrl: result.thumbnailUrl,
                                            imageTicket: result.ticket,
                                        },
                                    });
                                }}
                            />
                        </FormControl>

                        <FormControl>
                            <TextField
                                value={resource.name}
                                className="textField"
                                label={localize("Namn")}
                                resizable={false}
                                maxLength={128}
                                required
                                aria-required
                                validateOnFocusIn
                                validateOnFocusOut
                                validateOnLoad={!!this.state.enableFieldValidation}
                                onGetErrorMessage={validateRequiredTextField}
                                onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, newValue?: string) => {
                                    this.setState({
                                        resource: {
                                            ...this.state.resource,
                                            name: newValue,
                                        },
                                    });
                                }}
                            />
                        </FormControl>
                        <FormControl className="description">
                            <TextField
                                value={resource.description}
                                className="textField"
                                label={localize("Beskrivning")}
                                required
                                maxLength={1024}
                                aria-required
                                validateOnFocusIn
                                validateOnFocusOut
                                validateOnLoad={!!this.state.enableFieldValidation}
                                onGetErrorMessage={validateRequiredTextField}
                                multiline
                                resizable={false}
                                onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, newValue?: string) => {
                                    this.setState({
                                        resource: {
                                            ...this.state.resource,
                                            description: newValue,
                                        },
                                    });
                                }}
                            />
                        </FormControl>
                        <FormControl>
                            <Dropdown
                                label={localize("Kategori")}
                                selectedKey={resource.categoryId}
                                required
                                aria-required={true}
                                onChange={(_e, v) => {
                                    this.setState((state) => ({
                                        resource: {
                                            ...state.resource,
                                            categoryId: Number(v.key),
                                        },
                                    }));
                                }}
                                options={categories}
                            />
                        </FormControl>

                        {resource.categoryId === 0 && (
                            <FormControl>
                                <TextField
                                    maxLength={64}
                                    value={this.state.newCategory}
                                    className="textField"
                                    label={localize("NyKategori")}
                                    resizable={false}
                                    required
                                    aria-required
                                    validateOnFocusIn
                                    validateOnFocusOut
                                    validateOnLoad={!!this.state.enableFieldValidation}
                                    onGetErrorMessage={validateRequiredTextField}
                                    onChange={(
                                        ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
                                        newCategory?: string
                                    ) => {
                                        this.setState({
                                            newCategory,
                                        });
                                    }}
                                />
                            </FormControl>
                        )}
                    </form>
                    <Label as="h3" size="h3">
                        {localize("BokningsbaraTider")}
                    </Label>

                    {this.state.intervals.map((x, i) => {
                        return (
                            <form>
                                <FormSection key={i}>
                                    <div className="interval" style={{ display: "flex", maxHeight: 50 }}>
                                        <div className="timeselect" style={{ display: "flex", flexDirection: "row" }}>
                                            <FormControl>
                                                <SpintrDatePicker
                                                    showTimeSelectOnly
                                                    value={
                                                        x.start instanceof Date
                                                            ? x.start
                                                            : this.timeStringToDate(x.start)
                                                    }
                                                    onChangeHandler={(e) => this.handleInputChange(e, i, "start")}
                                                />
                                            </FormControl>
                                            <FormControl>
                                                <SpintrDatePicker
                                                    minTime={
                                                        x.start instanceof Date
                                                            ? x.start
                                                            : this.timeStringToDate(x.start)
                                                    }
                                                    maxTime={endOfDay(new Date())}
                                                    showTimeSelectOnly
                                                    value={x.end instanceof Date ? x.end : this.timeStringToDate(x.end)}
                                                    onChangeHandler={(e) => this.handleInputChange(e, i, "end")}
                                                />
                                            </FormControl>
                                        </div>
                                        <div
                                            className="checkboxesDays"
                                            style={{
                                                display: "flex",
                                                width: "100%",
                                                flexWrap: "wrap",
                                                fontSize: 9,
                                                justifyContent: "space-evenly",
                                                alignItems: "baseline",
                                            }}
                                        >
                                            <FormControl>
                                                <Checkbox
                                                    name="monday"
                                                    label={moment().isoWeekday(1).format("ddd")}
                                                    checked={x.monday}
                                                    onChange={(
                                                        ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
                                                        checked?: boolean
                                                    ) => {
                                                        this.handleInputChange(ev, i);
                                                    }}
                                                />
                                            </FormControl>
                                            <FormControl>
                                                <Checkbox
                                                    name="tuesday"
                                                    label={moment().isoWeekday(2).format("ddd")}
                                                    checked={x.tuesday}
                                                    onChange={(
                                                        ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
                                                        checked?: boolean
                                                    ) => {
                                                        this.handleInputChange(ev, i);
                                                    }}
                                                />
                                            </FormControl>
                                            <FormControl>
                                                <Checkbox
                                                    name="wednesday"
                                                    label={moment().isoWeekday(3).format("ddd")}
                                                    checked={x.wednesday}
                                                    onChange={(
                                                        ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
                                                        checked?: boolean
                                                    ) => {
                                                        this.handleInputChange(ev, i);
                                                    }}
                                                />
                                            </FormControl>

                                            <FormControl>
                                                <Checkbox
                                                    name="thursday"
                                                    label={moment().isoWeekday(4).format("ddd")}
                                                    checked={x.thursday}
                                                    onChange={(
                                                        ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
                                                        checked?: boolean
                                                    ) => {
                                                        this.handleInputChange(ev, i);
                                                    }}
                                                />
                                            </FormControl>

                                            <FormControl>
                                                <Checkbox
                                                    name="friday"
                                                    label={moment().isoWeekday(5).format("ddd")}
                                                    checked={x.friday}
                                                    onChange={(
                                                        ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
                                                        checked?: boolean
                                                    ) => {
                                                        this.handleInputChange(ev, i);
                                                    }}
                                                />
                                            </FormControl>
                                            <FormControl>
                                                <Checkbox
                                                    name="saturday"
                                                    label={moment().isoWeekday(6).format("ddd")}
                                                    checked={x.saturday}
                                                    onChange={(
                                                        ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
                                                        checked?: boolean
                                                    ) => {
                                                        this.handleInputChange(ev, i);
                                                    }}
                                                />
                                            </FormControl>
                                            <FormControl>
                                                <Checkbox
                                                    name="sunday"
                                                    label={moment().isoWeekday(7).format("ddd")}
                                                    checked={x.sunday}
                                                    onChange={(
                                                        ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
                                                        checked?: boolean
                                                    ) => {
                                                        this.handleInputChange(ev, i);
                                                    }}
                                                />
                                            </FormControl>
                                            <br />
                                            <FormControl>
                                                <Checkbox
                                                    name="all"
                                                    label={localize("Alla")}
                                                    checked={
                                                        x.monday &&
                                                        x.tuesday &&
                                                        x.wednesday &&
                                                        x.thursday &&
                                                        x.friday &&
                                                        x.saturday &&
                                                        x.sunday
                                                    }
                                                    onChange={(
                                                        ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
                                                        checked?: boolean
                                                    ) => {
                                                        this.toggleAll(ev, i);
                                                    }}
                                                />
                                            </FormControl>

                                            <div className="button">
                                                <UnstyledButton onClick={() => this.handleRemoveInterval(i)}>
                                                    <Visage2Icon icon="trash" />
                                                </UnstyledButton>
                                            </div>
                                        </div>
                                    </div>
                                </FormSection>
                            </form>
                        );
                    })}
                    <DefaultButton text={localize("LaggTillIntervall")} onClick={() => this.addNewInterval()} />
                    <FormFooterBar
                        onCancelClick={this.onCancelClick.bind(this)}
                        onSaveClick={this.onSaveClick.bind(this)}
                        saveText={localize("Spara")}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: IApplicationState, props) => {
    return {
        ...props,
        currentUserId: state.profile.active.id,
        isAdmin: state.profile.active.isAdmin,
        isEditor: state.profile.active.isEditor,
    };
};

export default withRouter(connect(mapStateToProps)(ResourceEditView));
