import {
    DefaultButton,
    PrimaryButton,
    Stack,
    TooltipHost,
} from "@fluentui/react";
import { AxiosResponse } from "axios";
import React, { Component, createRef, ReactNode, RefObject } from "react";
import { connect } from "react-redux";
import { Action } from "redux";
import { addComment, updateCommentRealtime } from 'src/interactions/actions';
import { localize } from "src/l10n";
import { IActiveUserProfile } from "src/profile/reducer";
import { FileSelector } from "src/spintr/components";
import InteractiveTextInput from "src/spintr/components/InteractiveTextInput";
import { IApplicationState } from "src/spintr/reducer";
import api from "src/spintr/SpintrApi";
import { Icon, Label, Loader, setFocusCommentFieldForUberId, SpintrUser } from "src/ui";
import { circleSmall } from "src/ui/helpers/style";
import { mixpanelTrack } from "src/utils";
import { UnstyledButton } from "../../ui";
import "./CommentForm.scss";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";

interface ICommentFormProps {
    uberId: number;
    currentUser: any;
    user?: IActiveUserProfile;
    dispatch?: (action: Action) => void;
    comment?: any;
    displayButtons?: boolean;
    onClose?: any;
    autofocus?: boolean;
    placeholder?: string;
    focusCommentFieldForUberId?: number;
    identity?: any;
    sendToMixpanel: boolean;
}

interface ICommentFormState {
    placeholder: string;
    isPostingComment: boolean;
    displayImageSelector?: boolean;
    imageUrl?: string;
    imageTicket?: any;
}

class CommentForm extends Component<ICommentFormProps, ICommentFormState> {
    protected textInputRef: RefObject<InteractiveTextInput>;
    protected sendIconAnimationRef?: any;
    protected imageIconAnimationRef?: any;

    constructor(props: ICommentFormProps) {
        super(props);

        const imageUrl = props.comment &&
            props.comment.imageUrl &&
            props.comment.imageUrl.length > 0 ?
            props.comment.imageUrl :
            null;

        this.state = {
            isPostingComment: false,
            placeholder: (props.placeholder ? props.placeholder : (localize("SkrivEnKommentar") + "...")),
            imageUrl,
            imageTicket: imageUrl
        };

        this.textInputRef = createRef();
    }

    public clearContent(): void {
        this.textInputRef.current.clearContent();
    }

    public getContent(): string {
        if (!this ||
            !this.textInputRef ||
            !this.textInputRef.current) {
            return "";
        }

        return this.textInputRef.current.getContent();
    }

    public componentDidMount() {
        this.focusInputIfAvailable();
    }

    public focusInputIfAvailable = () => {
        if (this.props.uberId === this.props.focusCommentFieldForUberId &&
            this.textInputRef &&
            this.textInputRef.current) {
            this.textInputRef.current.giveFocus();
        }
    }

    public componentDidUpdate = (prevProps) => {
        if (prevProps.focusCommentFieldForUberId !== this.props.focusCommentFieldForUberId) {
            this.focusInputIfAvailable();
        }
    }

    public postComment() {
        if (!this.validateUserInput()) {
            return;
        }

        const text = this.getContent();

        this.setState({
            isPostingComment: true
        }, () => {
            const payload = {
                objectId: this.props.uberId,
                text,
                imageTicket: this.state.imageTicket
            };

            if (this.props.comment) {
                api.put("/api/comments/" + this.props.comment.id, payload).then((result) => {
                    this.props.dispatch(updateCommentRealtime(result.data));
                    this.props.onClose();
                });
            } else {
                const callback = (result: AxiosResponse<Spintr.IComment>) => {
                    this.setState({
                        isPostingComment: false,
                        imageTicket: null,
                        imageUrl: null
                    }, () => {
                        this.props.dispatch(addComment(result.data));
                        mixpanelTrack("Comment");
                        this.props.dispatch(setFocusCommentFieldForUberId(undefined))
                        this.clearContent();
                    });
                }

                api.post<Spintr.IComment>('/api/comments', payload, {
                    headers: {
                        "x-impersonate-user": this.props.identity || (this.props.currentUser.id !== this.props.user.id)
                            ? (this.props.identity?.id || this.props.currentUser.id)
                            : 0
                    }
                }).then(callback).catch(() => {
                    this.setState({
                        isPostingComment: false,
                        imageTicket: null,
                        imageUrl: null
                    });
                });
            }
        });
    }

    public render(): ReactNode {
        if (this.state.isPostingComment) {
            return (
                <div className="CommentCreator">
                    <Loader />
                </div>
            )
        }

        const imageSrc = this.props.identity?.images.feedComposer || this.props.user.images.feedComposer;
        const iconSize: number = 0.666667;

        return (
            <div className="CommentCreator primaryBorderColor">
                <div>
                    <div className="commentor-image">
                        <SpintrUser
                            name={this.props.identity?.name || this.props.user.name}
                            imageUrl={imageSrc}
                            size={circleSmall}
                            hideText={true}
                            personalName={!!!this.props.identity}
                        />
                    </div>
                    <div className="commentor-right">
                        <div className="commentor-right-top">
                            <div className="commentor-content">
                                <div className="CommentForm">
                                    <Label as="div" size="body-2">
                                        <InteractiveTextInput
                                            onEnter={this.onEnter.bind(this)}
                                            placeholder={this.state.placeholder}
                                            ref={this.textInputRef}
                                            initialContent={this.props.comment ?
                                                this.props.comment.text :
                                                null}
                                        />
                                    </Label>
                                </div>
                            </div>
                            <UnstyledButton
                                className="CommentForm-add-image-button"
                                data-type={"image"}
                                onClick={() => {
                                    this.setState({
                                        displayImageSelector: true
                                    });
                                }}
                                title={localize("LaddaUppNyBild")}
                                ariaLabel={localize("LaddaUppNyBild")}
                                onMouseEnter={() => {
                                    if (!!this.imageIconAnimationRef) {
                                        this.imageIconAnimationRef?.play();
                                    }
                                }}
                            >
                                <Visage2Icon icon="gallery" size="small" color="dark-grey" />
                            </UnstyledButton>
                            <UnstyledButton
                                className="CommentForm-send-button"
                                onClick={this.onEnter.bind(this)}
                                title={localize("Skapa")}
                                ariaLabel={localize("Skapa")}
                                onMouseEnter={() => {
                                    if (!!this.sendIconAnimationRef) {
                                        this.sendIconAnimationRef?.play();
                                    }
                                }}
                            >
                                <Visage2Icon icon="send-1" size="small" color="dark-grey" />
                            </UnstyledButton>
                        </div>
                        {
                            this.state.imageUrl && (
                                <div className="SocialComposerImages">
                                    <div className="images">
                                        <div className="image">
                                            <div className="image-wrapper">
                                                <img
                                                    alt=""
                                                    src={typeof this.state.imageUrl === "string"
                                                        ? this.state.imageUrl
                                                        : ""}
                                                />
                                                <div
                                                    className="remove"
                                                >
                                                    <div
                                                        className="icon-wrapper"
                                                        role="button"
                                                        onClick={() => {
                                                            this.setState({
                                                                imageUrl: null,
                                                                imageTicket: null
                                                            });
                                                        }}
                                                    >
                                                        <TooltipHost content={localize("TaBort")}>
                                                            <Visage2Icon icon="close-circle" />
                                                        </TooltipHost>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
                {
                    this.props.displayButtons && (
                        <div className="CommentForm-Buttons">
                            <Stack horizontal={true} horizontalAlign="end" tokens={{ childrenGap: 6 }}>
                                <DefaultButton onClick={this.props.onClose} text={localize("Avbryt")} />
                                <PrimaryButton onClick={this.postComment.bind(this)} text={localize("Spara")} />
                            </Stack>
                        </div>
                    )
                }
                {this.state.displayImageSelector && (
                    <FileSelector
                        hideMedia
                        onClose={() => {
                            this.setState({
                                displayImageSelector: false,
                            }, () => {
                                this.textInputRef.current.giveFocus();
                            });
                        }}
                        onSelect={(data) => {
                            this.setState({
                                displayImageSelector: false,
                                imageUrl: data[0].thumbnailUrl,
                                imageTicket: data[0].ticket
                            }, () => {
                                this.textInputRef.current.giveFocus();
                            });
                        }}
                        allowMultipleFiles={false}
                        fileTypesString={"image/png, image/jpeg, image/gif"}
                        fileTypes={["png", "jpeg", "jpg", "gif"]}
                        sourcesToDisplay={[0]}
                        fileUploadType={1}
                    />
                )}
            </div>
        );
    }

    private onEnter() {
        this.postComment();
    }

    private validateUserInput() {
        const text = this.getContent();

        const textIsEmpty = !text ||
            text.trim().length === 0;

        if (textIsEmpty && !this.state.imageTicket) {
            return false;
        }

        return true;
    }
}

export default connect(
    (state: IApplicationState, props: ICommentFormProps) => ({
        ...props,
        focusCommentFieldForUberId: state.ui.focusCommentFieldForUberId,
        user: state.profile.active,
    })
)(CommentForm);