import React, { Component, ReactNode } from "react";
import { connect } from "react-redux";
import { Action } from "redux";
import { ShareModal } from "src/chat";
import { fetchUserFavorites } from "src/favorites";
import localize from "src/l10n/localize";
import { setConfirmPopup, setInfoPopup } from "src/popups/actions";
import api from "src/spintr/SpintrApi";
import { ActionMenu, setShouldReloadFavourites } from "src/ui";
import { Style } from "src/ui/helpers";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";

interface IProps {
    objectId: number;
    canEdit?: boolean;
    canDelete?: boolean;
    canShare?: boolean;
    canFollow?: boolean;
    canHide?: boolean;
    canPin?: boolean;
    canReport?: boolean;
    isFollowing?: boolean;
    canAddToFavourites?: boolean;
    isFavourite?: boolean;
    onEditClick?: any;
    onDeleteClick?: any;
    onHideClick?: any;
    onPrintClick?: () => void;
    onPdfClick?: () => void;
    onPinClick?: () => void;
    isDeleted?: boolean;
    isPinned?: boolean;
    dispatch?: (action: Action) => void;
    customItems?: any[];
}

interface IState {
    showShareModal: boolean;
    isFollowing: boolean;
    isFavourite: boolean;
}

class StandardActionMenu extends Component<IProps, IState> {
    constructor(props) {
        super(props);

        this.state = {
            showShareModal: false,
            isFollowing: !!props.isFollowing,
            isFavourite: !!props.isFavourite
        };
    }

    renderIcon(iconName: string, iconStyle?: "Light" | "Bold" | "Custom", instanceColor?: boolean) {
        if (!iconStyle) {
            iconStyle = "Light";
        }

        return (
            <Visage2Icon
                className={instanceColor ? "primaryVisageIconColorFill" : undefined}
                icon={iconName}
                //iconStyle={iconStyle}
                size="small"
                color="mid-grey" />
        );
    }

    getCategories() {
        let categories = [];

        if (this.props.canAddToFavourites) {
            categories.push({
                items: [{
                    text: this.state.isFavourite ?
                        localize("TaBortFranFavoriter") :
                        localize("LaggTillIFavoriter"),
                    onClick: () => {
                        const req = this.state.isFavourite ?
                            api.delete(`/api/v1/favorites/${this.props.objectId}`) :
                            api.post("/api/v1/favorites", { id: this.props.objectId });

                        req.then(() => {
                            this.setState({
                                isFavourite: !this.state.isFavourite
                            });
                            this.props.dispatch(fetchUserFavorites());
                        })
                    },
                    icon: this.state.isFavourite ? "FavoriteStarFill" : "FavoriteStar",
                    onRenderIcon: () => {
                        return this.renderIcon("Star",
                            this.state.isFavourite ? "Bold" : "Light",
                            this.state.isFavourite);
                    }
                }]
            });
        }

        if (this.props.canFollow) {
            categories.push({
                items: [{
                    text: this.state.isFollowing ?
                        localize("StangAvNotiser") :
                        localize("AktiveraNotiser"),
                    onClick: () => {
                        const req = this.state.isFollowing ?
                            api.delete(`/api/v1/follow/${this.props.objectId}`) :
                            api.post("/api/v1/follow", { id: this.props.objectId });

                        req.then(() => {
                            this.setState({
                                isFollowing: !this.state.isFollowing
                            });
                        })
                    },
                    onRenderIcon: () => {
                        return this.renderIcon("Notification",
                            this.state.isFollowing ? "Bold" : "Light",
                            this.state.isFollowing);
                    }
                }]
            });
        }

        if (this.props.canShare) {
            categories.push({
                items: [{
                    text: localize("Dela"),
                    onClick: () => {
                        this.setState({
                            showShareModal: true
                        });
                    },
                    onRenderIcon: () => {
                        return this.renderIcon("share");
                    }
                }]
            });
        }

        if (this.props.onPrintClick) {
            categories.push({
                items: [{
                    text: localize("Skrivutsidan"),
                    onClick: () => {
                        this.props.onPrintClick();
                    },
                    icon: "Print",
                }]
            })
        }

        if (this.props.onPdfClick) {
            categories.push({
                items: [{
                    text: localize("HamtaSomPdf"),
                    onClick: () => {
                        this.props.onPdfClick();
                    },
                    icon: "PDF"
                }]
            })
        }

        if (this.props.canReport) {
            categories.push({
                items: [
                    {
                        text: localize("REPORT_CONTENT"),
                        onClick: async () => {
                            this.props.dispatch(
                                setConfirmPopup({
                                    isOpen: true,
                                    message: localize("REPORT_CONTENT_CONFIRMATION"),
                                    onConfirm: () => {
                                        api.post(`/api/v1/objects/reports/${this.props.objectId}`);

                                        this.props.dispatch(
                                            setInfoPopup({
                                                isOpen: true,
                                                message: localize("REPORT_CONTENT_THANKS"),
                                            })
                                        );
                                    },
                                })
                            );
                        },
                        onRenderIcon: () => {
                            return this.renderIcon("flag");
                        }
                    },
                ],
            });
        }

        if (this.props.customItems &&
            this.props.customItems.length > 0) {
            for (let item of this.props.customItems) {
                categories.push(item);
            }
        }

        if (this.props.canEdit ||
            this.props.canDelete ||
            this.props.canHide ||
            this.props.canPin) {
            let editorItem = {
                title: localize("Redaktor"),
                items: []
            };

            if (this.props.canPin) {
                editorItem.items.push({
                    text: this.props.isPinned ? localize("Slapp") : localize("Fast"),
                    onClick: this.props.onPinClick,
                    onRenderIcon: () => {
                        return this.renderIcon("ticket-star");
                    }
                });
            }

            if (this.props.canEdit) {
                editorItem.items.push({
                    text: localize("Redigera"),
                    onClick: this.props.onEditClick,
                    onRenderIcon: () => {
                        return this.renderIcon("edit");
                    }
                });
            }

            if (this.props.canDelete) {
                editorItem.items.push({
                    text: this.props.isDeleted ? localize("Aterstall") : localize("TaBort"),
                    onClick: this.props.onDeleteClick,
                    onRenderIcon: () => {
                        return this.renderIcon("trash");
                    }
                });
            }

            if (this.props.canHide) {
                editorItem.items.push({
                    text: localize("Gom"),
                    onClick: this.props.onHideClick,
                    onRenderIcon: () => {
                        return this.renderIcon("eye");
                    }
                });
            }

            categories.push(editorItem);
        }

        if (!this.props.canEdit && !this.props.canDelete && !this.props.canPin && this.props.canHide) {
            const editorItem = {
                title: localize("Atgarder"),
                items: [{
                    text: localize("Gom"),
                    onClick: this.props.onHideClick,
                }]
            };

            categories.push(editorItem)
        }

        return categories;
    }

    public render(): ReactNode {
        if (!this.props.canEdit &&
            !this.props.canDelete &&
            !this.props.canShare &&
            !this.props.canFollow &&
            !this.props.canAddToFavourites) {
            return null;
        }

        return (
            <div className="StandardActionMenu">
                <ActionMenu menuClassName="hasSpintrIcons" categories={this.getCategories()} />
                {this.state.showShareModal && (
                    <ShareModal
                        objectId={this.props.objectId}
                        onDismiss={() => {
                            this.setState({
                                showShareModal: false
                            });
                        }}
                    />
                )}
            </div>
        )
    }
}

const mapStateToProps = (state: Spintr.AppState, props) => ({
    ...props,
});


export default connect(mapStateToProps)(StandardActionMenu);