import React, { FunctionComponent, useEffect } from "react";
import { MapDispatchToProps, connect } from "react-redux";
import { Route, Switch } from "react-router";
import routes from "src/marketplace/routes";
import { setVisage2FullscreenMode } from "src/ui";
import {
    IMarketplaceRootViewComponentProps as OwnProps,
    IMarketplaceRootViewDispatchProps as DispatchProps,
    MarketplaceRootViewProps as Props,
} from "./MarketplaceRootView.types"
import { setDisplaySearch } from "src/sidebar";


const MarketplaceRootView: FunctionComponent<Props> = (props) => {
    const setFullscreen = props.setVisage2FullscreenMode;
    const setDisplaySearch = props.setDisplaySearch;

    useEffect(() => {
        setFullscreen(true);
        setDisplaySearch(true);

        return () => {
            setFullscreen(false);
            setDisplaySearch(false);
        }
    }, [setFullscreen]);

    return (
        <div id="MarketplaceRootView">
            <Switch>
                {routes.map((route, index) => (
                    <Route 
                        component={route.component}
                        exact={route.exact || false}
                        key={"MarketplaceRootView.Route" + index}
                        path={route.path}
                    />
                ))}
            </Switch>
        </div>
    );
};

const mapDispatch: MapDispatchToProps<DispatchProps, OwnProps> = {
    setVisage2FullscreenMode,
    setDisplaySearch
}

export default connect<{}, DispatchProps, OwnProps>(null, mapDispatch)(MarketplaceRootView);
