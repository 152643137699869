import React, { ReactElement } from "react";
import { DrawerFooterProps } from "./DrawerFooter.types";

function DrawerFooter(props: DrawerFooterProps): ReactElement {
    const Tag = props.as || "footer";

    return (
        <Tag className="DrawerFooter">
            {props.children}
        </Tag>
    );
}

export default DrawerFooter;
