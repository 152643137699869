import React, { Component } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { localize } from "src/l10n";
import { debounce } from "src/utils";
import { Label, Scrollable } from ".";
import { Style } from "../helpers";
import { UnstyledButton } from "./Buttons";

interface IProps {
    tableOfContentSearchElementRef: any;
    isSmallViewMode: boolean;
}

interface IState {
    isLoading: boolean;
    headers: any[];
    fixedMode: boolean;
    marginTop: number;
    wrapperClientHeight: number;
}

class TableOfContent extends Component<IProps, IState> {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            headers: [],
            fixedMode: false,
            marginTop: 0,
            wrapperClientHeight: 0
        };
    }

    getHeaders() {
        const wrapper = ReactDOM.findDOMNode(this.props.tableOfContentSearchElementRef.current);

        if (!wrapper) {
            return;
        }

        // @ts-ignore
        const headerElements = wrapper.querySelectorAll('h1, h2, h3, h4, h5, h6');

        let headers = [];

        for (let h of headerElements) {
            let text = h.innerText.replace(/(\r\n|\n|\r)/gm, '').trim();

            if (!text) {
                continue;
            }

            headers.push({
                text,
                el: h
            });
        }

        this.setState({
            isLoading: false,
            headers,
            // @ts-ignore
            wrapperClientHeight: wrapper.clientHeight
        });
    }

    listenToScroll() {
        const winScroll = document.body.scrollTop ||
            document.documentElement.scrollTop;

        const shouldBeFixedMode = winScroll > 300;

        if (winScroll > (this.state.wrapperClientHeight - 500)) {
            return;
        }

        this.setState({
            fixedMode: shouldBeFixedMode,
            marginTop: winScroll - 200
        });
    }

    debouncedListenToScroll = debounce(this.listenToScroll.bind(this), 50);

    componentDidMount() {
        this.getHeaders();

        setInterval(() => {
            if (!this ||
                !this.state) {
                return;
            }

            this.getHeaders();
        }, 5000);

        window.addEventListener('scroll', this.debouncedListenToScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.debouncedListenToScroll);
    }

    render() {
        if (this.state.isLoading ||
            !this.state.headers ||
            this.state.headers.length === 0) {
            return null;
        }

        return (
            <div style={{
                position: "relative"
            }}>
                <div className="TableOfContent" style={{
                    width: "100%",
                    position: this.state.fixedMode ? "absolute" : "relative",
                    marginTop: this.state.fixedMode ? this.state.marginTop : 0,
                    transition: "margin-top 0.25s linear"
                }}>
                    <Label
                        as="div"
                        size="small-2"
                        color="dark-grey"
                        weight="regular"
                        style={{
                            textTransform: "uppercase",
                            marginBottom: Style.getSpacingStr(1),
                        }}
                    >
                        {
                            localize("Innehallsforteckning")
                        }
                    </Label>
                    <div
                        className="TableOfContent-items"
                        style={{
                            ...(!this.props.isSmallViewMode ? { height: 300 } : {}),
                        }}
                    >
                        <Scrollable>
                            <div>
                                {
                                    this.state.headers.map((header, index) => {
                                        return (
                                            <div key={index} >
                                                <UnstyledButton onClick={() => {
                                                    window.scrollTo({
                                                        top: header.el.offsetTop,
                                                        left: 0,
                                                        behavior: 'smooth'
                                                    });
                                                }}>
                                                    <Label size="body-2" color="light-blue">
                                                        {
                                                            header.text
                                                        }
                                                    </Label>
                                                </UnstyledButton>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </Scrollable>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: Spintr.AppState, props) => {
    return {
        ...props,
        isSmallViewMode: state.ui.isSmallViewMode,
    };
};

export default connect(mapStateToProps)(TableOfContent);
