import React, { useCallback, useState } from "react";
import { IAcademyCourse } from "src/academy/types";
import "./AcademyCourseSidebarStatus.scss";
import { localize } from "src/l10n";
import { Label } from "src/ui";
import AcademyCourseProgress from "../../AcademyCourseProgress/AcademyCourseProgress";
import { DefaultButton, PrimaryButton } from "@fluentui/react";
import { useDispatch } from "react-redux";
import { setConfirmPopup } from "src/popups/actions";
import api from "src/spintr/SpintrApi";
import SpintrLoader from "src/ui/components/Loader";

interface IProps {
    course: IAcademyCourse;
    onOpenCourse: () => void;
    onJoinCourse: () => void;
    onCancelCourse: () => void;
    displayPreview?: boolean;
    onReviewCourse: () => void;
}

interface IState {
    isLoadingAttendance: boolean;
}

const AcademyCourseSidebarStatus = (props: IProps) => {
    const dispatch = useDispatch();

    const [state, setState] = useState<IState>({
        isLoadingAttendance: false
    });

    const onAttendClick = useCallback(() => {
        setState((s: IState) => ({
            ...s,
            isLoadingAttendance: true
        }));

        api.post("/api/v1/academy/courses/" + props.course.id + "/attend").then(() => {
            setState((s: IState) => ({
                ...s,
                isLoadingAttendance: false
            }));

            props.onJoinCourse();
        }).catch(() => { });
    }, []);

    const onCancelClick = useCallback(() => {
        dispatch(setConfirmPopup({
            isOpen: true,
            message: localize("CANCEL_COURSE_WARNING"),
            onConfirm: () => {
                setState((s: IState) => ({
                    ...s,
                    isLoadingAttendance: true
                }));

                api.delete("/api/v1/academy/courses/" + props.course.id + "/cancel").then(() => {
                    setState((s: IState) => ({
                        ...s,
                        isLoadingAttendance: false
                    }));

                    props.onCancelCourse();
                }).catch(() => { });
            }
        }));
    }, []);

    return (
        <div className="AcademyCourseSidebarStatus">
            <div className="top-row">
                <Label size="body-2">{localize("Status") + ":"}</Label>
                <div className={[
                    "status-label",
                    props.course.userProgress.isCompleted ?
                        "completed" :
                        props.course.userProgress.isInitialized ?
                            "initialized" :
                            ""
                ].join(" ")}>
                    <Label size="body-2" weight="medium">
                        {localize(props.course.userProgress.isCompleted ?
                            localize("Avklarad") :
                            props.course.userProgress.isInitialized ?
                                localize("IN_PROGRESS_SINGULAR") :
                                localize("EjPaborjad"))}
                    </Label>
                </div>
            </div>
            {props.course.userProgress.isInitialized && !props.course.userProgress.isCompleted && (
                <AcademyCourseProgress userProgress={props.course.userProgress} />
            )}
            {state.isLoadingAttendance && (
                <SpintrLoader />
            )}
            <div className="buttons">
                {props.displayPreview && !state.isLoadingAttendance && !props.course.userProgress.isCompleted && (
                    <PrimaryButton
                        text={localize(props.course.userProgress.isInitialized ? "CONTINUE_COURSE" : "START_COURSE")}
                        onClick={props.course.userProgress.isInitialized ? props.onOpenCourse : onAttendClick}
                    />
                )}
                {props.displayPreview && !state.isLoadingAttendance && props.course.userProgress.isInitialized && !props.course.userProgress.isCompleted && (
                    <DefaultButton
                        text={localize("CANCEL_COURSE")}
                        onClick={onCancelClick}
                    />
                )}
                {props.displayPreview && !state.isLoadingAttendance && props.course.userProgress.isCompleted && (
                    <DefaultButton
                        text={localize("VIEW_COURSE_CONTENT")}
                        onClick={props.onOpenCourse}
                    />
                )}
                {props.course.userProgress.isCompleted && (
                    <PrimaryButton
                        text={localize("RATE_THE_COURSE")}
                        onClick={props.onReviewCourse}
                    />
                )}
            </div>
        </div>
    )
}

export default AcademyCourseSidebarStatus;
