import { IIconProps, SearchBox } from "@fluentui/react";
import React, { ChangeEvent, Component, ReactNode } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Subject, Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { localize } from "src/l10n";
import SpintrSearch from "src/ui/components/SpintrList/SpintrSearch";
import "./ConversationsPanelHeader.scss";

interface IProps {
    onSearch(filter: string): void;
}

interface IState {
    value: string;
}

type Props = IProps & RouteComponentProps;

class ConversationsPanelHeader extends Component<Props, IState> {
    private readonly placeholder: string;
    private readonly createTitle: string;
    private readonly valueSubject: Subject<string>;
    private readonly valueSubscription: Subscription;

    constructor(props: Props) {
        super(props);

        this.onClearClick = this.onClearClick.bind(this);
        this.onCreateClick = this.onCreateClick.bind(this);
        this.onValueChange = this.onValueChange.bind(this);
        this.onValueNext = this.onValueNext.bind(this);

        // No need to redefine this
        this.placeholder = localize("Sok");
        this.createTitle = localize("CHAT_NEW_MESSAGE");

        this.valueSubject = new Subject();
        this.valueSubscription = this.valueSubject
            .pipe(debounceTime(250))
            .subscribe(this.onValueNext);

        this.state = { value: "" };
    }

    public componentWillUnmount(): void {
        try {
            this.valueSubscription.unsubscribe();
            this.valueSubject.unsubscribe();
        } catch (_) { /* ignored */ }
    }

    public render(): ReactNode {
        const filterIcon: IIconProps = { iconName: 'Search' };

        return (
            <div className="ConversationsPanelHeader">
                <div className="search-bar">
                    <SpintrSearch
                        classes="searchBox"
                        value={this.state.value}
                        placeholder={this.placeholder}
                        onClear={() => {
                            this.setState({
                                value: ""
                            }, () => {
                                this.valueSubject.next(this.state.value);
                            });
                        }}
                        onChange={this.onValueChange.bind(this)} />
                </div>
            </div>
        );
    }

    private onClearClick(): void {
        if (this.state.value.length === 0) {
            return;
        }

        this.setState({ value: "" });
    }

    private onCreateClick(): void {
        this.props.history.push({
            pathname: "/messages/new"
        });
    }

    private onValueChange(event: ChangeEvent<HTMLInputElement>): void {
        if (!event) {
            return;
        }

        const { value } = event.target;

        this.setState({ value }, () => {
            this.valueSubject.next(this.state.value);
        });
    }

    private onValueNext(value: string): void {
        this.props.onSearch(value);
    }
}

const ConversationsPanelHeaderWithRouter = withRouter(
    ConversationsPanelHeader
);

export default ConversationsPanelHeaderWithRouter;
