import classNames from "classnames";
import { Icon } from "@fluentui/react";
import React, { FunctionComponent, useCallback, useContext } from "react";
import { useDispatch } from "react-redux";
import { addReaction, ExternalFile, removeReaction } from "src/chat";
import { Message } from "src/chat/message-types";
import { localize } from "src/l10n";
import { setConfirmPopup, setInfoPopup } from "src/popups/actions";
import { SpintrTypes } from "src/typings";
import { DivWithHoverActions, ImageCore, Label, UnstyledButton } from "src/ui";
import { formatInteractiveText, possessiveName } from "src/utils";
import "./ChatTabMessage.scss";
import api from "src/spintr/SpintrApi";
import { ChatTabContext } from "../ChatTabContext";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";

interface IProps {
    isFirst?: boolean;
    isLast?: boolean;
    isSelf?: boolean;
    isParent?: boolean;
    message: Message;
    onRespondToMessage?: any;
    blocked?: boolean;
    blockedBy?: boolean;
}

const imageTypes: SpintrTypes.FileType[] = [
    SpintrTypes.FileType.Jpg,
    SpintrTypes.FileType.Png,
    SpintrTypes.FileType.Gif,
];

const getFileIconName = (type: SpintrTypes.FileType): string => {
    switch (type) {
        case SpintrTypes.FileType.Pdf:
        case SpintrTypes.FileType.Doc:
        case SpintrTypes.FileType.Docx:
        case SpintrTypes.FileType.Odt:
        case SpintrTypes.FileType.Sxw:
        case SpintrTypes.FileType.Txt:
            return "TextDocument";

        default:
            return "Document";
    }
}

const ChatTabMessage: FunctionComponent<IProps> = (props) => {
    const context = useContext(ChatTabContext);
    const dispatch = useDispatch();

    let classes = ["ChatTabMessage", "general-row-break", "interactive-text"];

    if (props.isParent) {
        classes.push("lightPrimaryBGColor");
    }

    if (props.isFirst || !props.isParent || !props.message.parent) {
        classes.push("first");
    }

    if (props.isLast || !props.isParent || !props.message.parent) {
        classes.push("last");
    }

    if (props.isSelf) {
        classes.push("self");
    }

    const getActions = (message: any, isReply: boolean) => {
        let actions = [];

        const emojis = [{
            reactionId: 128077
        }, {
            reactionId: 128150
        }, {
            reactionId: 128512
        }, {
            reactionId: 128558
        }, {
            reactionId: 128546
        }, {
            reactionId: 128545
        }];

        for (let emoji of emojis) {
            actions.push({
                reactionId: emoji.reactionId,
                onClick: () => {
                    const reactionExist = message.reactions.find(r =>
                        r.reactionId === emoji.reactionId &&
                        r.user.id === context.currentUser.id);

                    if (!!reactionExist) {
                        dispatch(removeReaction(message.id,
                            emoji.reactionId,
                            context.currentUser.id));
                    }

                    const otherReactions = message.reactions.filter(r =>
                        r.user.id === context.currentUser.id);

                    if (!!otherReactions && otherReactions.length > 0) {
                        for (let r of otherReactions) {
                            dispatch(removeReaction(message.id,
                                r.reactionId,
                                context.currentUser.id));
                        }
                    }

                    dispatch(addReaction(message.id, emoji.reactionId, context.currentUser.id, context.currentUser.name));
                }
            })
        }

        if (!message.parent && !props.blocked && !props.blockedBy) {
            actions.push({
                icon: "message",
                title: localize("Svara"),
                onClick: () => {
                    if (props.onRespondToMessage) {
                        props.onRespondToMessage(message);
                    }
                }
            });
        }

        if (props.isSelf) {
            actions.push({
                icon: "trash",
                title: localize("TaBort"),
                onClick: () => {
                    dispatch(setConfirmPopup({
                        isOpen: true,
                        title: localize("ArDuSakerAttDuVillRaderaDennaPost"),
                        onConfirm: async () => {
                            api.delete("/api/v1/messages?ids=" + message.id);
                        },
                    }));
                }
            });
        } else {
            actions.push({
                icon: "flag",
                title: localize("REPORT_CONTENT"),
                onClick: async () => {
                    dispatch(setConfirmPopup({
                        isOpen: true,
                        message: localize("REPORT_CONTENT_CONFIRMATION"),
                        onConfirm: () => {
                            api.post(`/api/v1/objects/reports/${message.id}`, null, {
                                params: {
                                    reportType: SpintrTypes.ReportType.ChatMessage,
                                },
                            });
                            dispatch(setInfoPopup({
                                isOpen: true,
                                message: localize("REPORT_CONTENT_THANKS"),
                            }));
                        },
                    }));
                }
            })
        }

        return actions;
    }

    const { message } = props;
    const { attachments, object } = message;

    const attachmentPath = ((attachments || [])[0] || {}).path || "";
    const openFile = useCallback(
        () => {
            if (!attachmentPath) {
                return;
            }

            window.open(
                attachmentPath,
                "_blank"
            );
        },
        [attachmentPath]
    )

    if (object && object.externalId) {
        return (
            <ExternalFile externalId={object.externalId} source={object.source} />
        )
    }

    if (!attachments || attachments.length !== 1) {
        return (
            <div>
                {!!props.message.parent && (
                    <Label size="small-1" weight="normal" className="responded-to" color="mid-grey">
                        {
                            props.message.user.name.split(" ")[0] +
                            " " +
                            localize("RESPONDED_TO") +
                            " " +
                            possessiveName(props.message.parent.user.name.split(" ")[0]) +
                            " " +
                            localize("Meddelande").toLowerCase()
                        }
                    </Label>
                )}
                {!!props.message.parent && (
                    <div className="parent-message">
                        <ChatTabMessage
                            isParent
                            message={props.message.parent}
                            isSelf={props.isSelf}
                            blocked={props.blocked}
                            blockedBy={props.blockedBy}/
                        >
                    </div>
                )}
                <DivWithHoverActions
                    displayBorder={false}
                    displayActionsAtBottom
                    actions={props.isParent ? [] : getActions(props.message, false)}>
                    <Label as="p" className={classNames(classes)} size="body-2">
                        {formatInteractiveText(props.message.text)}
                    </Label>
                </DivWithHoverActions>
            </div>
        );
    }

    // there will pretty much never be more than one attachment
    const attachment = attachments[0];
    const isImage = imageTypes.some((t) => t === attachment.typeId);

    classes.push("attachment");

    if (isImage) {
        classes.push("image");

        return (
            <DivWithHoverActions
                displayBorder={false}
                displayActionsAtBottom
                actions={props.isParent ? [] : getActions(props.message, false)}>
                <div className={classNames(classes)}>
                        <div className="image-wrap">
                            <ImageCore
                                openPhotoBoxOnClick
                                imageInChat={true}
                                imageId={attachment.id}
                                alt={attachment.name}
                                src={attachment.path}
                                title={attachment.name}
                            />
                        </div>
                </div>
            </DivWithHoverActions>
        );
    }

    classes.push("file");

    return (
        <div className={classNames(classes)}>
            <div className="icon">
                <Icon
                    iconName={getFileIconName(attachment.typeId)}
                />
            </div>
            <Label as="p" className="text" size="body-2">
                {attachment.name}
            </Label>
            <div className="download">
                <UnstyledButton onClick={openFile}>
                    <Visage2Icon icon="arrow-down" />
                </UnstyledButton>
            </div>
        </div>
    );
};

export default ChatTabMessage;