import { AxiosError, AxiosResponse } from "axios";
import { Action } from "redux";

import {
    postMessage,
    postReactionToMessage,
    queryMessagesApi,
    removeReactionFromMessage,
} from "../api";
import { IQueryMessagesParams, Message } from "../message-types";
import { MessageActionTypes } from "./message-action-types";

export interface IQueryMessagesAction extends Action {
    meta: IQueryMessagesParams;
    payload: Promise<AxiosResponse<Spintr.IChatMessage[]>>;
    type: MessageActionTypes.QueryMessages;
}

export interface IQueryMessagesFulFilledAction extends Action {
    meta: IQueryMessagesParams;
    payload: AxiosResponse<Spintr.IChatMessage[]>;
    type: MessageActionTypes.QueryMessagesFulFilled;
}

export interface IQueryMessagesPendingAction extends Action {
    meta: IQueryMessagesParams;
    payload: Promise<AxiosResponse<Spintr.IChatMessage[]>>;
    type: MessageActionTypes.QueryMessagesPending;
}

export interface IQueryMessagesRejectedAction extends Action {
    meta: IQueryMessagesParams;
    payload: AxiosError;
    type: MessageActionTypes.QueryMessagesRejected;
}

export type QueryMessagesAction = 
      IQueryMessagesAction
    | IQueryMessagesFulFilledAction
    | IQueryMessagesPendingAction
    | IQueryMessagesRejectedAction;

export type BoundQueryMessagesHandler =
    (params: IQueryMessagesParams) => Spintr.ThunkPromise<
        AxiosResponse<Spintr.IChatMessage[]> | AxiosError,
        IQueryMessagesFulFilledAction | IQueryMessagesRejectedAction
    >;
export type QueryMessagesHandler = (params: IQueryMessagesParams) => IQueryMessagesAction;
export const queryMessages: QueryMessagesHandler = (params) => ({
    meta: params,
    payload: queryMessagesApi(params),
    type: MessageActionTypes.QueryMessages,
});

export interface IMessageReceivedMeta {
    message: Spintr.IChatMessage;
    ownId: number;
}

export interface IMessageReceivedAction extends Action {
    meta: IMessageReceivedMeta;
    type: MessageActionTypes.AddMessageFulfilled,
}

export type MessageReceivedHandler =
    (message: Spintr.IChatMessage, ownId: number) => IMessageReceivedAction;
export const addMessageRealtime: MessageReceivedHandler = (message, ownId) => ({
    meta: { message, ownId },
    type: MessageActionTypes.AddMessageFulfilled,
});

export interface IMessageIdMeta {
    messageId: number;
}

export interface IMessageDeletedAction extends Action {
    meta: IMessageIdMeta;
    type: MessageActionTypes.RemoveMessageFulfilled;
}

export type MessageDeletedHandler = (messageId: number) => IMessageDeletedAction;
export const removeMessageRealtime: MessageDeletedHandler = (messageId) => ({
    meta: { messageId },
    type: MessageActionTypes.RemoveMessageFulfilled,
});

export interface IMessagePinnedAction extends Action {
    meta: IMessageIdMeta;
    type: MessageActionTypes.PinMessageFulfilled;
}

export type MessagePinnedHandler = (messageId: number) => IMessagePinnedAction;
export const pinMessageRealtime: MessagePinnedHandler = (messageId) => ({
    meta: { messageId },
    type: MessageActionTypes.PinMessageFulfilled,
});

export interface IMessageUnpinnedAction extends Action {
    meta: IMessageIdMeta;
    type: MessageActionTypes.UnpinMessageFulfilled;
}

export type MessageUnpinnedHandler = (messageId: number) => IMessageUnpinnedAction;
export const unpinMessageRealtime: MessageUnpinnedHandler = (messageId) => ({
    meta: { messageId },
    type: MessageActionTypes.UnpinMessageFulfilled,
});

export interface IAddReactionMeta {
    messageId: number;
    reactionId: number;
    userId: number;
    userName: string;
}

export interface IAddReactionAction extends Action {
    meta: IAddReactionMeta;
    payload?: Promise<AxiosResponse<never>>;
    type: MessageActionTypes.AddReaction;
}

export interface IAddReactionFulfilledAction extends Action {
    meta: IAddReactionMeta;
    payload: AxiosResponse<never>;
    type: MessageActionTypes.AddReactionFulfilled;
}

export interface IAddReactionPendingAction extends Action {
    meta: IAddReactionMeta;
    payload: Promise<AxiosResponse<never>>;
    type: MessageActionTypes.AddReactionPending;
}

export type AddReactionAction = 
    IAddReactionAction
    | IAddReactionFulfilledAction
    | IAddReactionPendingAction;

export type BoundAddReactionHandler =
    (
        messageId: number,
        reactionId: number,
        userId: number,
        userName: string,
    ) => Spintr.ThunkPromise<
        AxiosResponse<never> | AxiosError,
        IAddReactionFulfilledAction
    >;
export type AddReactionHandler = (
    messageId: number,
    reactionId: number,
    userId: number,
    userName: string,
) => IAddReactionAction;

export const addReaction: AddReactionHandler =
    (messageId, reactionId, userId, userName) => ({
        meta: {
            messageId,
            reactionId,
            userId,
            userName
        },
        payload: postReactionToMessage(messageId, reactionId),
        type: MessageActionTypes.AddReaction,
    });

export const addReactionRealtime: AddReactionHandler =
    (messageId, reactionId, userId, userName) => ({
        meta: {
            messageId,
            reactionId,
            userId,
            userName
        },
        type: MessageActionTypes.AddReaction,
    });

export interface IRemoveReactionMeta {
    messageId: number;
    reactionId: number;
    userId: number;
}

export interface IRemoveReactionAction extends Action {
    meta: IRemoveReactionMeta;
    payload?: Promise<AxiosResponse<never>>;
    type: MessageActionTypes.RemoveReaction;
}

export interface IRemoveReactionFulfilledAction extends Action {
    meta: IRemoveReactionMeta;
    payload: AxiosResponse<never>;
    type: MessageActionTypes.RemoveReactionFulfilled;
}

export interface IRemoveReactionPendingAction extends Action {
    meta: IRemoveReactionMeta;
    payload: Promise<AxiosResponse<never>>;
    type: MessageActionTypes.RemoveReactionPending;
}

export type RemoveReactionAction =
    IRemoveReactionAction
    | IRemoveReactionFulfilledAction
    | IRemoveReactionPendingAction;


export type BoundRemoveReactionHandler =
    (
        messageId: number,
        reactionId: number,
        userId: number,
    ) => Spintr.ThunkPromise<
        AxiosResponse<never> | AxiosError,
        IQueryMessagesFulFilledAction | IQueryMessagesRejectedAction
    >;
export type RemoveReactionHandler = (
    messageId: number,
    reactionId: number,
    userId: number,
) => IRemoveReactionAction;

export const removeReaction: RemoveReactionHandler =
    (messageId, reactionId, userId) => ({
        meta: {
            messageId,
            reactionId,
            userId
        },
        payload: removeReactionFromMessage(messageId, reactionId),
        type: MessageActionTypes.RemoveReaction,
    });

export const removeReactionRealtime: RemoveReactionHandler =
    (messageId, reactionId, userId) => ({
        meta: {
            messageId,
            reactionId,
            userId
        },
        type: MessageActionTypes.RemoveReaction,
    });

export interface ISaveMessageMeta {
    message: Message;
    ownId: number;
}

export interface ISaveMessageAction extends Action {
    meta: ISaveMessageMeta;
    payload: Promise<AxiosResponse<Spintr.IChatMessage>>;
    type: MessageActionTypes.SaveMessage;
}

export interface ISaveMessageFulfilledAction extends Action {
    meta: ISaveMessageMeta;
    payload: AxiosResponse<Spintr.IChatMessage>;
    type: MessageActionTypes.SaveMessageFulfilled;
}

export interface ISaveMessagePendingAction extends Action {
    meta: ISaveMessageMeta;
    payload: Promise<AxiosResponse<Spintr.IChatMessage>>;
    type: MessageActionTypes.SaveMessagePending;
}

export interface ISaveMessageRejectedAction extends Action {
    meta: ISaveMessageMeta;
    payload: AxiosError;
    type: MessageActionTypes.SaveMessageRejected;
}

export type SaveMessageHandler = (message: Message, ownId: number) => ISaveMessageAction;
export const saveMessage: SaveMessageHandler = (message, ownId) => ({
    meta: { message, ownId },
    payload: postMessage(message),
    type: MessageActionTypes.SaveMessage,
})

export type SaveMessageAction =
    ISaveMessageAction
    | ISaveMessageFulfilledAction
    | ISaveMessagePendingAction
    | ISaveMessageRejectedAction;

export type MessageAction =
    QueryMessagesAction
    | IMessageReceivedAction
    | IMessageDeletedAction
    | IMessagePinnedAction
    | IMessageUnpinnedAction
    | AddReactionAction
    | RemoveReactionAction
    | SaveMessageAction;