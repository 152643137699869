import { IIconProps } from "@fluentui/react";
import { INavButtonProps, INavLink, Nav } from "@fluentui/react/lib/Nav";
import classnames from "classnames";
import React from "react";
import { MapDispatchToProps, connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";
import { hitEnterOrSpace } from "src/files/utils";
import { localize } from "src/l10n";
import { Label, Loader, UnstyledButton, setResponsiveSubmenuVisible } from "src/ui";
import { debounce, uniqueId } from "src/utils";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import SpintrSearch from "../SpintrList/SpintrSearch";
import "./Submenu.scss";

interface IDispatchProps {
    setResponsiveSubmenuVisible: (visible: boolean) => void;
}

interface IProps extends IDispatchProps {
    fetch(params: submenuQueryParams, prevItems?: ISubmenuRow[]): any;
    search?: ISearchProps;
    take?: number;
    nameProp?: string;
    title?: string;
    autoExpand?: boolean;
    manageActiveManually?: boolean;
    isSmallViewMode: boolean;
    dontHideInSmallViewMode?: boolean;
    dontLocalizeItems?: boolean;
}

interface ISearchProps {
    enabled: boolean;
    placeholder?: string;
}

interface ISubmenuRow {
    id: number;
    name: string;
    active: boolean;
    isExpanded: boolean;
    hasNodes: boolean;
    url: string;
    nodes: ISubmenuRow[];
    key: string;
    hideActiveBold: boolean;
}

interface IState {
    items: ISubmenuRow[];
    isLoading: boolean;
    hasMore: boolean;
    searchText: string;
    skip: number;
    take: number;
    autoExpand: boolean;
    isHidden: boolean;
}

class Submenu extends React.Component<IProps, IState> {
    private readonly rootClassName = "submenu-component";

    constructor(props: any) {
        super(props);

        this.state = {
            items: [],
            isLoading: true,
            hasMore: false,
            searchText: "",
            skip: 0,
            take: !!props.take || props.take === 0 ? props.take : 8,
            autoExpand: props.autoExpand === undefined ? true : props.autoExpand,
            isHidden: false, //props.isSmallViewMode && !props.dontHideInSmallViewMode,
        };
    }

    componentDidMount(): void {
        this.fetch();
    }

    getKey(item) {
        if (item.key) {
            return item.key;
        }

        if (item.id) {
            return item.id.toString();
        }

        return uniqueId().toString();
    }

    fetch(silent?: boolean): void {
        if (!silent) {
            this.setState({
                isLoading: true,
            });
        }

        const state = this.state;
        const params: submenuQueryParams = {
            skip: state.skip,
            take: state.take,
            searchText: state.searchText,
        };
        const getKey = this.getKey;

        this.props.fetch(params, this.state.items).then((data: any) => {
            if (!data) return null;
            
            const hasMore = state.take > 0 && data?.length >= state.take;

            if (state.skip > 0) {
                data = state.items.concat(data);
            }

            data.map(function f(item) {
                item.key = getKey(item);
                item.nodes = (item.nodes || []).map(f, this);
                item.isExpanded =
                    typeof item.isExpanded !== "undefined"
                        ? item.isExpanded
                        : item.nodes.length > 0 && state.autoExpand;
                return item;
            });

            this.setState({
                items: data,
                isLoading: false,
                hasMore: hasMore,
            });
        });
    }

    debouncedFetchSearch = debounce(() => this.fetch(), 500);

    searchEvent = (event: React.ChangeEvent, value: string): void => {
        this.setState(
            {
                searchText: value,
                skip: 0,
            },
            () => this.debouncedFetchSearch()
        );
    }

    toggleHidden = () => {
        this.setState((prevState) => ({
            isHidden: !prevState.isHidden,
        }));
    };

    loadMore(): void {
        this.setState(
            {
                skip: this.state.skip + this.state.take,
            },
            () => this.fetch()
        );
    }

    activateLink = (props) => {
        const { manageActiveManually } = this.props;

        var items = this.state.items;

        if (props.link.specialAction) {
            props.link.action(this, props.link);
            return;
        }

        items.forEach(function f(i) {
            if (!manageActiveManually) {
                i.active = props.link.key == i.key && !i.active;
            }

            if (props.link.key == i.key) {
                i.isExpanded = !i.isExpanded;
            }

            i.nodes.forEach(f, this);
        });

        this.setState({
            items: items,
        });

        props.link.action(this, props.link);
        this.props.setResponsiveSubmenuVisible(false);
    };

    render(): JSX.Element {
        const state = this.state;
        const { manageActiveManually } = this.props;

        var navItems = state.items.map(function f(item: any) {
            var newItem: INavLink = {
                name: item[this.props.nameProp || "name"],
                url: item.url,
                key: item.key || item.id.toString(),
                isExpanded: item.isExpanded,
                links: item.nodes.map(f, this),
                action: item.action,
                active: item.active,
                count: item.count,
                icon: item.icon || (item.create && "CircleAdditionSolid"),
                hideActiveBold: item.hideActiveBold,
                create: item.create,
            };

            return newItem;
        }, this);

        if (!state.isLoading && state.hasMore) {
            navItems.push({
                name: localize("HamtaFler"),
                url: "",
                action: this.loadMore.bind(this),
                specialAction: true,
            });
        }

        if (state.isHidden) {
            return (
                <div className={classnames(this.rootClassName, "hidden")}>
                    {this.props.title && (
                        <Label as="h2" color="dark-grey" size="small-2" uppercase>
                            {this.props.title}
                        </Label>
                    )}
                    <UnstyledButton onClick={this.toggleHidden}>
                        <Label>{localize("VisaUndermeny")}</Label>
                    </UnstyledButton>
                </div>
            );
        }

        const filterIcon: IIconProps = { iconName: 'Search' };

        return (
            <div role="navigation" className={this.rootClassName}>
                {!!this.props.title ? (
                    <Label as="h2" color="dark-grey" size="small-2" uppercase>
                        {this.props.title}
                    </Label>
                ) : null}
                {!!this.props.search && this.props.search.enabled ? (
                    <SpintrSearch
                        classes="searchBox"
                        placeholder={this.props.search.placeholder || localize("SokIMeny")}
                        value={this.state.searchText}
                        onChange={this.searchEvent} />
                ) : null}
                {state.isLoading ? (
                    <Loader />
                ) : state.items.length === 0 ? (
                    <div className="spintr-list-empty-list">
                        <Label className="spintr-list-empty-list-label" as="p" size="body-2" color="dark-grey">
                            {localize("IngaPoster")}
                        </Label>
                    </div>
                ) : (
                    <Nav
                        className="primarySubmenuIconColor"
                        onLinkExpandClick={(ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
                            this.setState({
                                items: this.state.items.map(function f(i) {
                                    return {
                                        ...i,
                                        isExpanded: i.key === item.key ? !i.isExpanded : i.isExpanded,
                                        nodes: (i.nodes || []).map(f, this),
                                    };
                                }),
                            });
                        }}
                        linkAs={(props: INavButtonProps) => {
                            const className = classnames("row general-row-break", props.className, {
                                create: props.link.create,
                            });
                            const title = this.props.dontLocalizeItems ? props.title : localize(props.title, true);

                            const content = (
                                <div>
                                    {props.link.icon && <Visage2Icon icon={props.link.icon} />}
                                    <span>{title}</span>
                                    {!!props.link.count && <span>{props.link.count}</span>}
                                </div>
                            );

                            if (props.link.action) {
                                return (
                                    <a
                                        className={className}
                                        onKeyUp={(e) => {
                                            hitEnterOrSpace(e, () => this.activateLink(props));
                                        }}
                                        onClick={(a) => {
                                            this.activateLink(props);
                                        }}
                                        style={{
                                            fontWeight: props.link.active && !props.link.hideActiveBold ? 700 : 400,
                                        }}
                                    >
                                        {content}
                                    </a>
                                );
                            }

                            if (props.href.indexOf("http") === 0) {
                                return (
                                    <a className={className} href={props.href} target="_blank">
                                        {content}
                                    </a>
                                );
                            }

                            if (props.href.includes("#")) {
                                return (
                                    <NavHashLink
                                        to={props.href}
                                        className={className}
                                        title={localize(props.title, true)}
                                    >
                                        {content}
                                    </NavHashLink>
                                );
                            }

                            return (
                                <NavLink
                                    strict={true}
                                    className={className}
                                    to={props.href}
                                    title={title}
                                >
                                    {content}
                                </NavLink>
                            );
                        }}
                        groups={[
                            {
                                links: navItems,
                            },
                        ]}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state: Spintr.AppState, props) => {
    return {
        ...props,
        isSmallViewMode: state.ui.isSmallViewMode,
    };
};

const mapDispatchToProps: MapDispatchToProps<IDispatchProps, IProps> = {
    setResponsiveSubmenuVisible,
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Submenu);
