import { ChoiceGroup, Separator } from "@fluentui/react";
import { DefaultButton, PrimaryButton } from "@fluentui/react/lib/Button";
import { TextField } from "@fluentui/react/lib/components/TextField/TextField";
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { localize } from "src/l10n";
import api from "src/spintr/SpintrApi";
import { Breadcrumbs, Label, PageHeader } from "src/ui";
import { FormSection, FormControl } from "src/ui/components/Forms";
import SpintrLoader from "src/ui/components/Loader";
import ErrorMessagebar from "src/ui/components/Messagebars/ErrorMessagebar";
import SuccessMessagebar from "src/ui/components/Messagebars/SuccessMessagebar";
import { Style } from "src/ui/helpers";
import { validateRequiredTextField } from "src/utils";
import "./SuggestionsView.scss";

interface Props {
    pageId: number;
}

interface State {
    isLoading: boolean;
    form: {
        headline: string;
        files?: FileList;
        description: string;
        problem: string;
        impact: number;
        frequency: number;
        attachments?: any[];
    };
    displayError: boolean;
    isAllowed: boolean;
    enableFieldValidation: boolean;
    saveError: string[];
    displaySaveSuccess: boolean;
}

class SuggestionsView extends Component<Props, State> {
    private fileInput: HTMLInputElement;

    state = {
        isLoading: false,
        form: { headline: "", description: "", problem: "", attachments: [], impact: 0, frequency: 0 },
        displayError: false,
        isAllowed: false,
        enableFieldValidation: false,
        saveError: [],
        displaySaveSuccess: false,
    };

    submitForm = () => {
        this.setState({
            enableFieldValidation: true,
            isLoading: true
        }, () => {
            if (!this.state.form.impact || !this.state.form.frequency) {
                this.setState({
                    saveError: ["ObligatoriskInfoText"],
                    isLoading: false
                });

                return;
            }

            api.post("/api/v1/suggestions", this.state.form)
                .then(() => {
                    this.setState(
                        {
                            isLoading: false,
                            displaySaveSuccess: true,
                            form: { headline: "", description: "", problem: "", attachments: [], impact: 0, frequency: 0 },
                            saveError: [],
                            enableFieldValidation: false
                        },
                        () => {}
                    );
                })
                .catch(this.handleCatch.bind(this));
        });
    };

    handleCatch = (saveError) => {
        let errors = [];

        if (saveError &&
            saveError.response &&
            saveError.response.data &&
            saveError.response.data.errorlist) {
            errors = saveError.response.data.errorlist;
        } else {
            errors.push(localize("TeknisktFel"));
        }

        this.setState({
            saveError: errors,
            isLoading: false
        });
    }

    private renderAboutForm = () => {
        return (
            <div className="aboutForm">
                <img className="spintrLogo" src="/logo-start.png" />
                <Label size="body-1">{localize("SUGGESTIONS_INTRO_TEXT_1")}</Label>
                <Label size="body-2">{localize("SUGGESTIONS_INTRO_TEXT_2")}</Label>
                <Label size="body-2">{localize("SUGGESTIONS_INTRO_TEXT_3")}</Label>
                <ul>
                    <li>
                        <Label size="body-2">{localize("SUGGESTIONS_INTRO_TEXT_4")}</Label>
                    </li>
                    <li>
                        <Label size="body-2">{localize("SUGGESTIONS_INTRO_TEXT_5")}</Label>
                    </li>
                </ul>
                <Label size="body-2">{localize("SUGGESTIONS_INTRO_TEXT_6")}</Label>
                <Separator />
            </div>
        );
    };

    protected onFilesSelected(files: FileList) {
        Array.from(files).forEach(async (file) => {
            let formData = new FormData();

            formData.set("type", "1");
            formData.set("ticket", new Date().getTime().toString());
            formData.set("thumbnailSize", "natural");

            formData.append("file", file);

            const config = {
                headers: {
                    "content-type": "multipart/form-data; boundary=----WebKitFormBoundary5ieermsRmERasb8V",
                },
            };

            api.post("/api/v1/suggestions/upload", formData, config).then((response) => {
                this.setState(
                    {
                        form: {
                            ...this.state.form,
                            attachments: [
                                ...this.state.form.attachments,
                                {
                                    zendeskTicket: Object.keys(response.data)[0],
                                    thumbnail: response.data[Object.keys(response.data)[0]],
                                },
                            ],
                        },
                    },
                    () => {
                        console.log(this.state.form);
                    }
                );
            });
        });
    }

    renderAttachedImages = () => {
        return (
            <div className="attachedImages">
                {this.state.form.attachments.map((a) => {
                    return <img src={a.thumbnail} />;
                })}
            </div>
        );
    };

    private renderForm = () => {
        return (
            <div className="improvementsForm">
                <PageHeader title={localize("ForbattringsforslagForm")} />
                <FormSection>
                    <FormControl>
                        <TextField
                            label={localize("Rubrik")}
                            description={localize("BESKRIV_KORTFATTAT_FORANDRINGEN")}
                            value={this.state.form.headline}
                            onChange={(_e, headline) => {
                                this.setState((state) => ({ form: { ...state.form, headline } }));
                            }}
                            required
                            aria-required
                            validateOnFocusIn
                            validateOnFocusOut
                            validateOnLoad={!!this.state.enableFieldValidation}
                            onGetErrorMessage={validateRequiredTextField}
                        />
                    </FormControl>
                    <FormControl label={localize("chat_attach_image_file")}>
                        <div className="filesButton">
                            <DefaultButton
                                styles={{ root: { marginRight: "6px" } }}
                                disabled={this.state.isLoading}
                                onClick={() => {
                                    this.fileInput.click();
                                }}
                            >
                                {localize("ValjFiler")}
                            </DefaultButton>
                        </div>

                        <input
                            ref={(fileInput) => (this.fileInput = fileInput)}
                            type="file"
                            multiple
                            style={{ display: "none" }}
                            className="file-upload"
                            onChange={(e) => {
                                this.onFilesSelected(e.target.files);
                            }}
                        />
                    </FormControl>

                    {this.renderAttachedImages()}

                    <FormControl>
                        <TextField
                            label={localize("Beskrivning")}
                            description={
                                localize("GOR_UTFORLIG_BESKRIVNING")
                            }
                            value={this.state.form.description}
                            onChange={(_e, description) => {
                                this.setState((state) => ({ form: { ...state.form, description } }));
                            }}
                            placeholder={localize("Svara")}
                            multiline={true}
                            required
                            aria-required
                            validateOnFocusIn
                            validateOnFocusOut
                            validateOnLoad={!!this.state.enableFieldValidation}
                            onGetErrorMessage={validateRequiredTextField}
                        />
                    </FormControl>

                    <FormControl>
                        <TextField
                            label={localize("VILKET_PROBLEM")}
                            description={
                                localize("BESKRIV_PROBLEMET")
                            }
                            value={this.state.form.problem}
                            onChange={(_e, problem) => {
                                this.setState((state) => ({ form: { ...state.form, problem } }));
                            }}
                            placeholder={localize("Svara")}
                            required
                            aria-required
                            validateOnFocusIn
                            validateOnFocusOut
                            validateOnLoad={!!this.state.enableFieldValidation}
                            onGetErrorMessage={validateRequiredTextField}
                        />
                    </FormControl>

                    <div className="choiceGroups">
                        <FormControl>
                            <ChoiceGroup
                                name="impact"
                                label={localize("HUR_STOR_PAAVERKAN")}
                                selectedKey={this.state.form.impact.toString()}
                                options={[
                                    { key: "1", value: "1", text: localize("MYCKET_LAG") },
                                    { key: "2", value: "2", text: localize("DeviationPrioritet1") },
                                    { key: "3", value: "3", text: localize("DeviationPrioritet3") },
                                    { key: "4", value: "4", text: localize("MYCKET_HOG") },
                                ]}
                                onChange={(_e, option) => {
                                    this.setState((state) => ({
                                        form: { ...state.form, impact: option.value as number },
                                    }));
                                }}
                                required={true}
                                aria-required={true}
                            />
                        </FormControl>

                        <FormControl>
                            <ChoiceGroup
                                label={localize("HUR_OFTA_STOTER")}
                                selectedKey={this.state.form.frequency.toString()}
                                options={[
                                    { key: "1", value: "1", text: localize("DAGLIGEN") },
                                    { key: "2", value: "2", text: localize("NAGON_GANG_VECKAN") },
                                    { key: "3", value: "3", text: localize("NAGON_GANG_MANAD") },
                                    { key: "4", value: "4", text: localize("MER_SALLAN") },
                                ]}
                                onChange={(_e, option) => {
                                    this.setState((state) => ({
                                        form: { ...state.form, frequency: option.value as number },
                                    }));
                                }}
                                required={true}
                                aria-required={true}
                            />
                        </FormControl>
                    </div>

                    <PrimaryButton
                        className="submitButton"
                        type={"submit"}
                        onClick={() => {
                            this.submitForm();
                        }}
                        text={localize("Skicka")}
                    />
                </FormSection>
            </div>
        );
    };

    render() {
        const improvementsText = localize("ForbattringsforslagForm");

        if (this.state.isLoading) return <SpintrLoader />;

        // if (!this.state.isAllowed) return null;

        return (
            <div className="SuggestionsView">
                <Helmet>
                    <title>{improvementsText}</title>
                </Helmet>
                <Breadcrumbs
                    items={[
                        {
                            text: improvementsText,
                            key: 1,
                        },
                    ]}
                />
                <div className="suggestionsInner contentMiddle">
                    {(this.state.displaySaveSuccess || this.state.saveError?.length > 0) && (
                        <div
                            style={{
                                marginBottom: Style.getSpacing(5),
                            }}
                        >
                            {this.state.displaySaveSuccess && (
                            <SuccessMessagebar
                                message={"Tack för ditt bidrag, vi återkopplar så fort vi får möjlighet!"}
                                onDismiss={() => {
                                    this.setState({
                                        displaySaveSuccess: false,
                                    });
                                }}
                            />
                            )}
                            {this.state.saveError?.length > 0 && (
                                <ErrorMessagebar
                                    errorList={this.state.saveError}
                                    onDismiss={() => {
                                        this.setState({ saveError: [] });
                                    }}
                                />
                            )}
                        </div>
                    )}
                    {!this.state.isLoading && (
                        <div className="improvementsBody">
                            {this.renderAboutForm()}
                            {this.renderForm()}
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default SuggestionsView;
