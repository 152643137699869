import moment from "moment";
import React, { Component } from 'react';
import { ChatMessage } from "src/chat/components";
import { Label } from "src/ui/components";

interface IProps {
    dateGroup: any;
    group?: any,
}

class ChatDateGroup extends Component<IProps> {
    renderMessages(messages) {
        return (
            <div>
                {
                    messages.map((message, index) => {
                        return (
                            <ChatMessage
                                key={message.id}
                                message={message}
                                group={this.props.group}
                            />
                        );
                    })
                }
            </div>
        )
    }

    getDateGroupDateAsString(d) {
        const date = new Date(d);
        const now = new Date();
        const isToday = (date.toDateString() == now.toDateString());

        if (isToday) {
            return moment(d).format("LT");
        }

        return moment(d).format("LLL");
    }

    renderDateGroupDate(d) {
        return (
            <div className="ChatView-DateGroupName" tabIndex={0}>
                <Label
                    as="p"
                    size="body-3"
                    color="dark-grey"
                >
                    {
                        this.getDateGroupDateAsString(d)
                    }
                </Label>
            </div>
        )
    }

    render() {
        return (
            <div className="ChatView-date-group">
                {
                    this.renderDateGroupDate(this.props.dateGroup.from)
                }
                {
                    this.renderMessages(this.props.dateGroup.messages)
                }
            </div>
        )
    }
}

export default ChatDateGroup;
