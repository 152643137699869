import React, { Component } from "react";
import { IApplicationState } from "src/spintr/reducer";
import { connect } from 'react-redux';
import api from "src/spintr/SpintrApi";
import UserCard from "src/users/views/UserCard";
import { withRouter } from "react-router-dom";
import Label from "./Label/Label";
import SpintrLoader from "./Loader";
import "./UserList.scss";
import { localize } from "src/l10n";

interface IProps {
    data: string;
    history?: any;
    instanceColor?: string;
}

interface IState {
    data: any;
    title: string;
    users: any[];
    isLoading: boolean;
}

class UserList extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        const data = this.props.data ? JSON.parse(this.props.data) : {};

        this.state = {
            data,
            title: data.title,
            users: [],
            isLoading: true,
        };
    }

    componentDidMount() {
        let idArray: number[] = [];

        for (let i = 0; i < this.state.data.items.length; i++) {
            idArray.push(this.state.data.items[i].key);
        }

        if (!idArray ||
            idArray.length === 0) {
            this.setState({
                isLoading: false
            });

            return;
        }

        let fetchUrl = `/api/users/list?skip=0&take=999&userIds=${idArray.join("&userIds=")}`;

        api.get(fetchUrl).then((response) => {
            this.setState({
                users: response.data.users,
                isLoading: false
            });
        }).catch(() => { });
    }

    render() {
        return (
            <div className="UserList">
                {
                    !!this.state.title && (
                        <Label size="h2" className="UserList-title">{this.state.title}</Label>
                    )
                }
                {
                    this.state.isLoading && (
                        <SpintrLoader />
                    )
                }
                {
                    !this.state.isLoading && (!this.state.users || this.state.users.length === 0) && (
                        <Label size="body-2">{localize("IngaAnvandareHittades")}</Label>
                    )
                }
                {
                    !!this.state.users && this.state.users.length > 0 && (
                        <div className="UserList-users">
                            {
                                this.state.users.map(user => {
                                    return (
                                        <UserCard
                                            item={{
                                                ...user,
                                                rights: {}
                                            }}
                                            showBorder
                                            history={this.props.history}
                                            instanceColor={this.props.instanceColor}
                                        />
                                    )
                                })
                            }
                        </div>
                    )
                }
            </div>
        )
    }
}

const mapStateToProps = (state: IApplicationState, props) => {
    return {
        ...props,
        instanceColor: state.instance.get("color"),
    };
};

export default withRouter(connect(mapStateToProps)(UserList));
