import {
    Checkbox, DefaultButton, Dropdown, Modal,
    Pivot,
    PivotItem, PrimaryButton, Stack, TextField, Toggle
} from "@fluentui/react";
import React, { Component } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { connect } from "react-redux";
import { localize } from "src/l10n";
import { SpintrTypes } from "src/typings";
import { Breadcrumbs, ContentImageViewerAndSelector, Label, Loader, PageHeader } from "src/ui";
import CustomDialog from "src/ui/components/Dialogs/CustomDialog";
import { FormControl, FormTokenizedObjectInput } from "src/ui/components/Forms";
import ConfirmDialog from "src/ui/components/Forms/ConfirmDialog";
import ErrorMessagebar from "src/ui/components/Messagebars/ErrorMessagebar";
import ModalHeader from "src/ui/components/Modal/ModalHeader";
import { Style } from "src/ui/helpers";
import { validateRequiredTextField } from "src/utils";
import { groupFunctions } from "../utils";
import "./GroupEditView.scss";
import api from "src/spintr/SpintrApi";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";

interface IProps {
    dispatch: any;
    history: any;
    group: any;
    groupId: number;
    disableGroupCreation?: boolean;
    canCreateSupportGroups?: boolean;
    groupShowFiles?: boolean;
    groupShowNotes?: boolean;
    groupShowPlanning?: boolean;
}

interface IState {
    isNew: boolean;
    isLoading: boolean;
    group: any;
    saveError: string[];
    categories: any[];
    displayArchiveModal: boolean;
    displayRemoveModal: boolean;
    displayUpgradeModal: boolean;
    isUpgrading: boolean;
    showUpgradeCustomDialog: boolean;
    enableFieldValidation?: boolean;
    showConfirmDialog: boolean;
}

class GroupEditView extends Component<IProps, IState> {
    constructor(props) {
        super(props);

        let isNew = false;
        let groupId = 0;

        if (window.location.href.indexOf("/create") > -1) {
            isNew = true;
        } else {
            isNew = false;
            groupId = parseInt(window.location.href.split("/").reverse()[1]);
        }

        this.state = {
            isNew,
            isLoading: !isNew,
            group: {
                id: groupId,
                categoryId: -1,
                initialMembers: [],
                version: 2,
                availability: 1,
                membersCanInvite: false,
                targets: [],
                membershipType: 1,
                tabOrder: [
                    { id: SpintrTypes.GroupFunction.Chat, enabled: true },
                    { id: SpintrTypes.GroupFunction.Planning, enabled: true },
                    { id: SpintrTypes.GroupFunction.Notes, enabled: true },
                    { id: SpintrTypes.GroupFunction.Files, enabled: true },
                    { id: SpintrTypes.GroupFunction.Calendar, enabled: true },
                    { id: SpintrTypes.GroupFunction.Members, enabled: true },
                    { id: SpintrTypes.GroupFunction.Feed, enabled: false },
                ],
                type: 1,
            },
            saveError: [],
            categories: [],
            displayArchiveModal: false,
            displayRemoveModal: false,
            displayUpgradeModal: false,
            isUpgrading: false,
            showUpgradeCustomDialog: false,
            showConfirmDialog: false
        };
    }

    save() {
        this.setState({
            enableFieldValidation: true
        });
        this.setState(
            {
                isLoading: true,
            },
            () => {
                let envelope = {
                    ...this.state.group,
                };

                let req;

                if (this.state.isNew) {
                    req = api.post(`/api/groups`, envelope);
                } else {
                    req = api.put(`/api/groups/${envelope.id}`, envelope);
                }

                req.then((response) => {
                    this.props.history.push({
                        pathname: `/groups/` + response.data.id,
                    });
                }).catch((saveError) => {
                    this.setState({
                        isLoading: false,
                        saveError: saveError.response.data.errorlist,
                    });
                });
            }
        );
    }

    componentDidMount(): void {
        if (this.props.disableGroupCreation) {
            this.props.history.push({
                pathname: `/404`,
            });
        }

        if (!this.state.isNew) {
            api.get(`/api/groups/${this.state.group.id}`).then((response) => {
                this.setState(
                    {
                        group: {
                            ...response.data,
                        },
                        isLoading: false,
                    },
                    () => {
                        api.get(`/api/targets/ubertargets?uberId=${this.state.group.id}`).then((response) => {
                            this.setState({
                                group: {
                                    ...this.state.group,
                                    targets: response.data,
                                    membershipType: response.data && response.data.length > 0 ? 2 : 1,
                                },
                            });
                        });
                    }
                );
            });
        }

        api.get(`/api/groupcategories`).then((response) => {
            let categories = response.data;

            for (let c of categories) {
                if (c.id === -1) {
                    c.name = localize("OvrigaGrupper");
                }
            }

            categories.push({
                id: 0,
                name: localize("SkapaNyGruppkategori"),
            });

            this.setState({
                categories,
            });
        });
    }

    private onDragEnd = (result) => {
        const { source, destination } = result;

        if (!destination) {
            return;
        }

        const updatedTabOrder = [...this.state.group.tabOrder];

        const fromIndex = source.index;
        const toIndex = destination.index;

        const c = updatedTabOrder[fromIndex];

        updatedTabOrder.splice(fromIndex, 1);
        updatedTabOrder.splice(toIndex, 0, c);

        this.setState((prevState) => ({
            group: {
                ...prevState.group,
                tabOrder: updatedTabOrder,
            },
        }));
    };

    closeUpgradeModal = () => {
        this.setState({
            displayUpgradeModal: false,
        });
    };

    confirmSave = () => {
        this.setState({showConfirmDialog: true})
    }

    render() {
        if (this.state.isLoading) {
            return <Loader />;
        }

        const isInAdminView = window.location.href.indexOf("/admin") > -1;

        const tabOrder = this.state.group.tabOrder.map((tab) => ({
            ...tab,
            text: localize(groupFunctions.find((gf) => gf.id === tab.id).text),
        }));

        return (
            <div className={"GroupEditView" + (isInAdminView ? "" : " fullWidthForm")}>
                <Breadcrumbs
                    displayInstance
                    items={[
                        ...(isInAdminView ? [{
                            text: localize("Administration"),
                            key: 2,
                            link: "/admin",
                        }] : []), {
                            text: localize("Grupper"),
                            key: 3,
                            link: isInAdminView ? "/admin/groups" : "/groups",
                        }, {
                            text: this.state.isNew ? localize("SkapaGrupp") : localize("GROUP_EDIT"),
                            key: 4,
                            link: window.location.pathname,
                        },
                    ]}
                />
                {this.state.saveError && this.state.saveError.length > 0 ? (
                    <ErrorMessagebar
                        errorList={this.state.saveError}
                        onDismiss={() => {
                            this.setState({
                                saveError: [],
                            });
                        }}
                    />
                ) : null}
                <ConfirmDialog
                    show={this.state.showConfirmDialog}
                    title={localize("SkapaInnehall")}
                    message={localize("ArDuSakerPaAttDuVillX").replace("{{X}}", localize("PubliceraDetta").toLowerCase())}
                    onDismiss={() => {
                        this.setState({ showConfirmDialog: false });
                    }}
                    onConfirm={() => {
                        this.setState({ showConfirmDialog: false }, () => {
                            this.save()
                        });
                    }}
                />
                <PageHeader
                    title={this.state.isNew ? localize("SkapaGrupp") : localize("GROUP_EDIT")}
                    subText={localize("SkapaGruppText")}
                    displayMandatoryText
                />
                <form>
                <Pivot>
                    <PivotItem headerText={localize("Allmant")}>
                        <FormControl>
                            <TextField
                                label={localize("NamnPaGruppen")}
                                name="name"
                                value={this.state.group.name}
                                onChange={(_event: React.FormEvent, name: string) => {
                                    this.setState((state) => ({ group: { ...state.group, name } }));
                                }}
                                required
                                aria-required
                                validateOnFocusIn
                                validateOnFocusOut
                                validateOnLoad={!!this.state.enableFieldValidation}
                                onGetErrorMessage={validateRequiredTextField}
                            />
                        </FormControl>
                        {!this.state.isNew && this.state.group.isArchived && (
                            <Label
                                color="red"
                                style={{
                                    marginBottom: Style.getSpacing(3),
                                }}
                            >
                                {localize("ArkiveradGruppText")}
                            </Label>
                        )}
                        {this.state.isNew && !this.state.group.disableManualMembership ? (
                            <FormControl>
                                <FormTokenizedObjectInput
                                    items={this.state.group.initialMembers}
                                    label={localize("BjudInMedlemmar")}
                                    onChange={(items) => {
                                        this.setState({
                                            group: {
                                                ...this.state.group,
                                                initialMembers: items.map((i) => {
                                                    return {
                                                        ...i,
                                                        id: i.key,
                                                    };
                                                }),
                                            },
                                        });
                                    }}
                                />
                            </FormControl>
                        ) : null}
                        <FormControl>
                            <TextField
                                label={localize("Beskrivning")}
                                value={this.state.group.description}
                                onChange={(_event: React.FormEvent, description: string) => {
                                    this.setState((state) => ({ group: { ...state.group, description } }));
                                }}
                            />
                        </FormControl>
                        {this.state.isNew && this.props.canCreateSupportGroups && (
                            <FormControl>
                                <Dropdown
                                    label={localize("GROUP_TYPE")}
                                    selectedKey={this.state.group.type}
                                    onChange={(_e, v) => {
                                        this.setState({
                                            group: {
                                                ...this.state.group,
                                                type: v.key,
                                                tabOrder: v.key !== 2
                                                    ? [
                                                        { id: SpintrTypes.GroupFunction.Chat, enabled: true },
                                                        { id: SpintrTypes.GroupFunction.Planning, enabled: true },
                                                        { id: SpintrTypes.GroupFunction.Notes, enabled: true },
                                                        { id: SpintrTypes.GroupFunction.Files, enabled: true },
                                                        { id: SpintrTypes.GroupFunction.Calendar, enabled: true },
                                                        { id: SpintrTypes.GroupFunction.Members, enabled: true },
                                                        { id: SpintrTypes.GroupFunction.Feed, enabled: false },
                                                    ]
                                                    : [
                                                        { id: SpintrTypes.GroupFunction.Feed, enabled: true },
                                                        { id: SpintrTypes.GroupFunction.Support, enabled: true },
                                                        { id: SpintrTypes.GroupFunction.Calendar, enabled: true },
                                                        { id: SpintrTypes.GroupFunction.Planning, enabled: true },
                                                        { id: SpintrTypes.GroupFunction.Notes, enabled: true },
                                                        { id: SpintrTypes.GroupFunction.Files, enabled: true },
                                                        { id: SpintrTypes.GroupFunction.Members, enabled: true },
                                                        { id: SpintrTypes.GroupFunction.Chat, enabled: false },
                                                    ]
                                            },
                                        });
                                    }}
                                    options={[
                                        { key: 1, text: localize("Standard") },
                                        { key: 2, text: localize("CUSTOMER_SUCCESS_GROUP") }
                                    ]}
                                />
                            </FormControl>
                        )}
                        <FormControl>
                            <Dropdown
                                label={localize("Gruppkategori")}
                                selectedKey={this.state.group.categoryId}
                                onChange={(_e, v) => {
                                    this.setState({
                                        group: {
                                            ...this.state.group,
                                            categoryId: v.key,
                                            isArchived: false,
                                        },
                                    });
                                }}
                                options={[
                                    ...this.state.categories.map((c) => {
                                        return {
                                            key: c.id,
                                            text: c.name,
                                        };
                                    }),
                                ]}
                            />
                        </FormControl>
                        {this.state.group.categoryId === 0 ? (
                            <FormControl>
                                <TextField
                                    label={localize("Kategorinamn")}
                                    value={this.state.group.newCategoryName}
                                    onChange={(_event: React.FormEvent, newCategoryName: string) => {
                                        this.setState((state) => ({ group: { ...state.group, newCategoryName } }));
                                    }}
                                />
                            </FormControl>
                        ) : null}
                        <FormControl label={localize("Bild")}>
                            <ContentImageViewerAndSelector
                                renderSizeString={() => {
                                    return (
                                        `${localize(
                                            "OptimalBildStorlek"
                                        )}: 160x160px <br />`
                                    )
                                }}
                                cropImage
                                cropAspect={1}
                                imageUrl={this.state.group.imageUrl}
                                editMode={true}
                                autoHeight={true}
                                imageMaxWidth={250}
                                placeholderString={localize("ValjBild")}
                                onChange={(result) => {
                                    this.setState({
                                        group: {
                                            ...this.state.group,
                                            imageUrl: result.thumbnailUrl,
                                            imageTicket: result.ticket,
                                            ticket: {
                                                ticket: result.ticket,
                                                remove: !result.ticket,
                                            },
                                        },
                                    });
                                }}
                            />
                        </FormControl>
                        {this.state.isNew ? null : (
                            <FormControl label={localize("NyaHandelser")}>
                                <Checkbox
                                    label={localize("VisaInteNyaHandlserForGruppen")}
                                    checked={this.state.group.disableUnreadNotifications}
                                    onChange={(
                                        ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
                                        checked?: boolean
                                    ) => {
                                        this.setState({
                                            group: {
                                                ...this.state.group,
                                                disableUnreadNotifications: checked,
                                            },
                                        });
                                    }}
                                />
                            </FormControl>
                        )}
                    </PivotItem>
                    <PivotItem headerText={localize("Rattigheter")}>
                        <Label className="form-info-label" size="small-1">
                            {localize("GrupptyperBeskrivning")}
                        </Label>
                        <FormControl>
                            <Dropdown
                                label={localize("Tillganglighet")}
                                selectedKey={this.state.group.availability}
                                onChange={(_e, v) => {
                                    this.setState({
                                        group: {
                                            ...this.state.group,
                                            availability: v.key,
                                        },
                                    });
                                }}
                                options={[
                                    {
                                        key: 1,
                                        disabled: !!this.state.group.disableManualMembership,
                                        text: localize("Oppen"),
                                    },
                                    {
                                        key: 2,
                                        text: localize("Stangd"),
                                    },
                                    {
                                        key: 3,
                                        text: localize("Hemlig"),
                                    },
                                ]}
                            />
                        </FormControl>
                        <FormControl>
                            <Label className="form-info-label" size="small-1">
                                {this.state.group.availability === 1
                                    ? localize("OppenGruppBeskrivning")
                                    : this.state.group.availability === 2
                                    ? localize("StangdGruppBeskrivning")
                                    : localize("HemligGruppBeskrivning")}
                            </Label>
                        </FormControl>
                        {this.state.group.availability === 1 ? null : (
                            <FormControl>
                                <Checkbox
                                    label={localize("GruppensMedlemmarFarBjudaInMedlemmarTillGruppen")}
                                    checked={this.state.group.membersCanInvite}
                                    onChange={(
                                        ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
                                        checked?: boolean
                                    ) => {
                                        this.setState({
                                            group: {
                                                ...this.state.group,
                                                membersCanInvite: checked,
                                            },
                                        });
                                    }}
                                />
                            </FormControl>
                        )}
                        <FormControl>
                            <Dropdown
                                label={localize("Medlemskap")}
                                selectedKey={this.state.group.membershipType}
                                onChange={(_e, v) => {
                                    this.setState({
                                        group: {
                                            ...this.state.group,
                                            membershipType: v.key,
                                            targets: v.key === 2
                                                ? this.state.group.targets
                                                : [],
                                        },
                                    });
                                }}
                                options={[
                                    {
                                        key: 1,
                                        text: localize("ManuellHantering"),
                                    },
                                    {
                                        key: 2,
                                        text: localize("Dynamiskt") + " (" + localize("ValjMalgrupp") + ")",
                                    },
                                ]}
                            />
                        </FormControl>
                        {this.state.group.membershipType === 2 ? (
                            <FormControl>
                                <FormTokenizedObjectInput
                                    items={this.state.group.targets}
                                    label={localize("ValjMalgrupp")}
                                    types={[
                                        SpintrTypes.UberType.Department,
                                        SpintrTypes.UberType.Office,
                                        SpintrTypes.UberType.Role,
                                        SpintrTypes.UberType.TargetGroup,
                                    ]}
                                    onChange={(items) => {
                                        this.setState({
                                            group: {
                                                ...this.state.group,
                                                targets: items.map((i) => {
                                                    return {
                                                        ...i,
                                                        id: i.key,
                                                    };
                                                }),
                                            },
                                        });
                                    }}
                                />
                            </FormControl>
                        ) : null}
                        {this.state.group.membershipType === 2 ? (
                            <FormControl>
                                <Checkbox
                                    label={localize("StangAvManuellHantering")}
                                    checked={this.state.group.disableManualMembership}
                                    onChange={(
                                        ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
                                        checked?: boolean
                                    ) => {
                                        this.setState({
                                            group: {
                                                ...this.state.group,
                                                disableManualMembership: checked,
                                                availability:
                                                    checked && this.state.group.availability === 1
                                                        ? 2
                                                        : this.state.group.availability,
                                            },
                                        });
                                    }}
                                />
                            </FormControl>
                        ) : null}
                    </PivotItem>
                    {this.state.group.version === 2 && this.state.group.type !== 2 && (
                        <PivotItem headerText={localize("Funktionalitet")}>
                            <FormControl>
                                <Stack horizontal horizontalAlign="space-between" className="draggable-header">
                                    <Label size="small-2" uppercase>
                                        {localize("AnpassaFunktionalitet")}
                                    </Label>

                                    <Stack
                                        horizontal
                                        tokens={{
                                            childrenGap: Style.getSpacing(6),
                                        }}
                                    >
                                        <Label size="small-2" uppercase>
                                            {localize("Av_off")}/{localize("Pa")}
                                        </Label>
                                        <Label size="small-2" uppercase>
                                            {localize("Ordning")}
                                        </Label>
                                    </Stack>
                                </Stack>
                                <DragDropContext onDragEnd={this.onDragEnd}>
                                    <Droppable droppableId="groupFunctions">
                                        {(provided, snapshot) => (
                                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                                {tabOrder.map((item, index) => {
                                                    return (
                                                        <Draggable
                                                            draggableId={item.id.toString()}
                                                            index={index}
                                                            key={item.id}
                                                        >
                                                            {(provided, _snapshot) => (
                                                                <div
                                                                    className="draggable-item"
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                >
                                                                    <Stack
                                                                        horizontal
                                                                        horizontalAlign="space-between"
                                                                        verticalAlign="center"
                                                                    >
                                                                        <Label size="body-2">{item.text}</Label>

                                                                        <Stack
                                                                            horizontal
                                                                            tokens={{
                                                                                childrenGap: Style.getSpacing(8),
                                                                            }}
                                                                        >
                                                                            <Toggle
                                                                                checked={item.enabled}
                                                                                onChange={(ev, checked) => {
                                                                                    const newTabOrder = [
                                                                                        ...this.state.group.tabOrder,
                                                                                    ];

                                                                                    const tab = newTabOrder.find(
                                                                                        (tab) => tab.id === item.id
                                                                                    );

                                                                                    tab.enabled = checked;

                                                                                    this.setState((prevState) => ({
                                                                                        group: {
                                                                                            ...prevState.group,
                                                                                            tabOrder: newTabOrder,
                                                                                        },
                                                                                    }));
                                                                                }}
                                                                                styles={{
                                                                                    root: {
                                                                                        marginBottom: 0,
                                                                                        marginTop: 2,
                                                                                    },
                                                                                }}
                                                                            />
                                                                            <Visage2Icon icon="menu-1" />
                                                                        </Stack>
                                                                    </Stack>
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    );
                                                })}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </FormControl>
                        </PivotItem>
                    )}
                </Pivot>
                </form>
                <Stack horizontal horizontalAlign="space-between" style={{ marginBottom: "1em" }}>
                    <Stack horizontal tokens={{ childrenGap: 6 }}>
                        {!this.state.isNew && (
                            <DefaultButton
                                text={localize("TaBort")}
                                onClick={() => {
                                    this.setState({
                                        displayRemoveModal: true,
                                    });
                                }}
                            />
                        )}
                        {!this.state.isNew && !this.state.group.isArchived && (
                            <DefaultButton
                                text={localize("Arkivera")}
                                onClick={() => {
                                    this.setState({
                                        displayArchiveModal: true,
                                    });
                                }}
                            />
                        )}
                        {!this.state.isNew && this.state.group.version === 1 ? (
                            <DefaultButton
                                text={localize("GROUP_UPGRADE_APPEARANCE")}
                                onClick={() => {
                                    this.setState({
                                        displayUpgradeModal: true,
                                    });
                                }}
                            />
                        ) : null}
                    </Stack>
                    <Stack horizontal tokens={{ childrenGap: 6 }}>
                        <DefaultButton
                            text={localize("Avbryt")}
                            onClick={() => {
                                this.props.history.goBack();
                            }}
                        />
                        <PrimaryButton
                            disabled={this.state.isLoading}
                            text={localize("Spara")}
                            onClick={() => {
                                this.confirmSave();
                            }}
                        />
                    </Stack>
                </Stack>
                <Modal
                    className="spintr-modal"
                    isOpen={this.state.displayUpgradeModal}
                    onDismiss={this.closeUpgradeModal}
                    containerClassName={"GroupEditView-UpgradeModal"}
                    isBlocking={true}
                >
                    {this.state.isUpgrading ? (
                        <div>
                            <Label size="small-1">{localize("GROUP_UPGRADE_INPROG")}</Label>
                            <Loader />
                        </div>
                    ) : (
                        <div>
                            <ModalHeader onClose={this.closeUpgradeModal}>
                                {localize("GROUP_UPGRADE_HEADING")}
                            </ModalHeader>
                            <Label size="small-1">{localize("GROUP_UPGRADE_TEXT1")}</Label>
                            <div
                                style={{
                                    textAlign: "center",
                                }}
                            >
                                <iframe
                                    allowFullScreen
                                    frameBorder="0"
                                    height="320"
                                    src="https://player.vimeo.com/video/260244899"
                                    width="480"
                                ></iframe>
                            </div>
                            <Label size="small-1">{localize("GROUP_UPGRADE_TEXT2")}</Label>
                            <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 6 }}>
                                <DefaultButton
                                    text={localize("Avbryt")}
                                    onClick={() => {
                                        this.setState({
                                            displayUpgradeModal: false,
                                        });
                                    }}
                                />
                                <PrimaryButton
                                    text={localize("GROUP_UPGRADE_APPEARANCE")}
                                    onClick={() => {
                                        this.setState({
                                            showUpgradeCustomDialog: true,
                                        });
                                    }}
                                />
                            </Stack>
                        </div>
                    )}
                </Modal>
                <CustomDialog
                    show={this.state.displayRemoveModal}
                    message={localize("ArDuSakerPaAttDuVillTaBortDennaGrupp") + "?"}
                    onConfirm={() => {
                        this.setState(
                            {
                                displayRemoveModal: false,
                                isLoading: true,
                            },
                            () => {
                                api.delete("/api/groups/" + this.state.group.id).then(() => {
                                    this.props.history.push({
                                        pathname: `/groups`,
                                    });
                                });
                            }
                        );
                    }}
                    onDismiss={() => {
                        this.setState({
                            displayRemoveModal: false,
                        });
                    }}
                />
                <CustomDialog
                    show={this.state.displayArchiveModal}
                    message={localize("ArDuSakerAttDuVillArkiveraGruppen") + "?"}
                    onConfirm={() => {
                        this.setState(
                            {
                                displayArchiveModal: false,
                                isLoading: true,
                                group: {
                                    ...this.state.group,
                                    isArchived: true,
                                    categoryId: -1,
                                },
                            },
                            () => {
                                api.put("/api/groups/" + this.state.group.id, this.state.group).then(() => {
                                    this.setState({
                                        isLoading: false,
                                    });
                                });
                            }
                        );
                    }}
                    onDismiss={() => {
                        this.setState({
                            displayArchiveModal: false,
                        });
                    }}
                />
                <CustomDialog
                    show={this.state.showUpgradeCustomDialog}
                    title={localize("GROUP_UPGRADE_HEADING")}
                    message={localize("GROUP_UPGRADE_CONFIRM")}
                    onConfirm={() => {
                        this.setState(
                            {
                                isUpgrading: true,
                                showUpgradeCustomDialog: false,
                            },
                            () => {
                                api
                                    .put("/api/groups/" + this.state.group.id + "/upgrade")
                                    .then(() => {
                                        this.props.history.push({
                                            pathname: "/groups/" + this.state.group.id,
                                        });
                                    })
                                    .catch(() => {
                                        this.setState({
                                            isUpgrading: false,
                                            displayUpgradeModal: false,
                                            saveError: [localize("GROUP_UPGRADE_ISE")],
                                        });
                                    });
                            }
                        );
                    }}
                    onDismiss={() => {
                        this.setState({
                            showUpgradeCustomDialog: false,
                        });
                    }}
                />
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    const groupId = parseInt(props.match.params.id, 0);

    return {
        ...props,
        groupId: groupId,
        group: state.groups.group,
        canCreateSupportGroups: state.instance.get("canCreateSupportGroups"),
        disableGroupCreation: state.instance.get("disableGroupCreation"),
        groupShowFiles: state.instance.get("groupShowFiles"),
        groupShowNotes: state.instance.get("groupShowNotes"),
        groupShowPlanning: state.instance.get("groupShowPlanning"),
    };
};

export default connect(mapStateToProps)(GroupEditView);
