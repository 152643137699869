import React, { Component } from "react";
import ReactDOM from "react-dom";
import { connect, MapStateToProps } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { localize } from "src/l10n";
import { IActiveUserProfile } from "src/profile/reducer";
import Color from "src/style/colors/Color";
import { SpintrTypes } from "src/typings";
import { ActionMenu, SpintrUser } from "src/ui";
import CustomDialog from "src/ui/components/Dialogs/CustomDialog";
import { Style } from "src/ui/helpers";
import { circleSmall } from "src/ui/helpers/style";
import { getInitialsFromString } from "src/utils";
import api from "../SpintrApi";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import { getHeadwayCount } from "src/sidebar/utils";
import UnreadIndicator from "src/ui/components/UnreadIndicator/UnreadIndicator";

interface IOwnProps {
    user: IActiveUserProfile;
}

interface IStateProps {
    isAdministrator: boolean;
    isEditor: boolean;
    currentUserDepartments?: any[];
    alternativeInstances?: any[];
    instance: any;
    displayAsOfflineInChat?: boolean;
    currentUser: any;
    enableCrocus: boolean;
    apps?: any;
    betaEnabled?: boolean;
    useColorHeader?: boolean;
    isSpintrAdminAccount?: boolean;
    appMode?: boolean;
}

interface IUserMenuState {
    menuIsOpen: boolean;
    displayReloadDialog: boolean;
    displayChangeInstanceMessage: boolean;
    indicatorDot?: any;
}

interface IProps {
    firstNameOnly?: boolean;
    displaySubText?: boolean;
    bigCoin?: boolean;
    runIntroduction?: any;
}

type Props = IOwnProps & IStateProps & RouteComponentProps & IProps;

class SpintrUserMenu extends Component<Props, IUserMenuState> {
    private actionMenu = React.createRef<typeof ActionMenu>();

    constructor(props: Props) {
        super(props);

        this.state = {
            menuIsOpen: false,
            displayReloadDialog: false,
            displayChangeInstanceMessage: false,
        };
    }

    public componentDidMount = () => {
        if (!this.props.appMode &&
            (this.props.isAdministrator || this.props.isEditor) &&
            window.innerWidth >= SpintrTypes.ViewMode.TabletLandscape) {
            getHeadwayCount().then((count) => {
                this.setState({
                    indicatorDot: count
                })
            });
        }
    }

    isAppEnabled(id) {
        return !!this.props.apps.items.find((a) => a.id === id && a.enabled);
    }

    getCategories() {
        let categories: Spintr.IActionMenuCategory[] = [
            {
                items: [
                    ...(this.props.currentUser.isGroupUser
                        ? []
                        : [
                            {
                                onClick: () => this.goTo(`/profile/${this.props.user.id}`),
                                text: localize("Profil"),
                            },
                        ]),
                    {
                        onClick: () => this.goTo("/profile-settings"),
                        text: localize("Profilinstallningar"),
                    }
                ],
            }
        ];

        if (this.props.currentUserDepartments.length > 0) {
            categories[0].items.push({
                onClick: () => { },
                text: localize("VaxlaAvdelning"),
                subMenuProps: {
                    items: this.props.currentUserDepartments.map((d, i) => {
                        return {
                            key: i,
                            text: d.name,
                            title: d.name,
                            onClick: () => {
                                api.post("/api/users/changedepartment/" + d.id).then(() => {
                                    this.setState({
                                        displayReloadDialog: true,
                                    });
                                });
                            },
                        };
                    }),
                },
            });
        }

        if (this.props.alternativeInstances && this.props.alternativeInstances.length > 0) {
            categories[0].items.push({
                onClick: () => { },
                text: localize("Vaxla") + " " + localize("Intranat").toLowerCase(),
                subMenuProps: {
                    items: this.props.alternativeInstances.map((d, i) => {
                        return {
                            key: i,
                            text: d.name,
                            title: d.name,
                            onClick: () => {
                                this.setState(
                                    {
                                        displayChangeInstanceMessage: true,
                                    },
                                    () => {
                                        api.post("/api/users/changeinstance/" + d.userId).then((response) => {
                                            window.location = response.data.url;
                                        });
                                    }
                                );
                            },
                        };
                    }),
                },
            });
        }

        if (this.props.instance.get("enableMyPages")) {
            categories[0].items.push({
                onClick: () => this.goTo("/my-pages"),
                text: localize("MinaSidor"),
            });
        }

        if (this.props.currentUser.roles.indexOf("supplier") !== -1) {
            categories[0].items = [];
        }

        categories[0].items.push({
            onClick: () => {
                if (navigator.userAgent.indexOf("MSIE") !== -1 || navigator.userAgent.indexOf("Trident") !== -1) {
                    document.execCommand("ClearAuthenticationCache");
                }

                localStorage.clear();

                window.location.assign("/api/authentication/signout");
            },
            text: localize("LoggaUt"),
        });


        if (!this.props.currentUser.isGroupUser &&
            (this.isAppEnabled(SpintrTypes.SpintrApp.Absence) ||
                this.isAppEnabled(SpintrTypes.SpintrApp.Todos) ||
                this.isAppEnabled(SpintrTypes.SpintrApp.Notes) ||
                this.isAppEnabled(SpintrTypes.SpintrApp.Calendars) ||
                this.props.runIntroduction)) {
            let c = {
                items: [],
                title: localize("Appar"),
            };

            if (this.isAppEnabled(SpintrTypes.SpintrApp.Absence) && !this.props.currentUser.isGroupUser) {
                c.items.push({
                    onClick: () => this.goTo("/absence"),
                    text: localize("Franvaro"),
                });
            }

            if (this.isAppEnabled(SpintrTypes.SpintrApp.Todos) && !this.props.currentUser.isGroupUser) {
                c.items.push({
                    onClick: () => this.goTo("/todo"),
                    text: localize("AttGora"),
                });
            }

            if (this.isAppEnabled(SpintrTypes.SpintrApp.Notes) && !this.props.currentUser.isGroupUser) {
                c.items.push({
                    onClick: () => this.goTo("/notes"),
                    text: localize("appNotes"),
                });
            }

            if (this.isAppEnabled(SpintrTypes.SpintrApp.Calendars) && !this.props.currentUser.isGroupUser) {
                c.items.push({
                    onClick: () => this.goTo("/calendar"),
                    text: localize("Kalender"),
                });
            }

            if (this.props.runIntroduction) {
                c.items.push({
                    onClick: () => {
                        this.props.runIntroduction()
                    },
                    text: localize("VisaIntroduktion")
                })
            }

            categories.push(c);
        }

        if (this.props.isAdministrator || this.props.isEditor) {
            let c = {
                items: [{
                    onClick: () => this.goTo("/admin"),
                    text: localize("Administration"),
                }],
                title: localize("Administrator"),
            };

            if (this.props.isAdministrator && this.props.enableCrocus) {
                c.items.push({
                    onClick: () => {
                        this.goTo("/marketplace/library");
                    },
                    text: localize("MARKETPLACE_LIBRARY"),
                });
            }

            categories.push(c);
        }
        if (this.props.isAdministrator || this.props.isEditor) {
            let c = {
                items: [],
                title: localize("Ovrigt"),
            };
            c.items.push({
                onClick: () => {
                    window.open("https://support.spintr.me");
                },
                text: localize("Hjalpcenter"),
            })
            c.items.push({
                onClick: () => {
                    this.goTo("/suggestions");
                },
                text: localize("ForbattringsforslagForm"),
            })
            c.items.push({
                onClick: () => {
                    // @ts-ignore
                    const hw = window.Headway;

                    if (!!hw) {
                        // @ts-ignore
                        hw.show();

                        setTimeout(() => {
                            // @ts-ignore
                            hw.hide();
                        }, 1000);

                        this.setState({
                            indicatorDot: 0
                        });

                        return window.open("https://updates.spintr.me", "_blank");
                    }
                },
                text: (
                    <div className="updates">
                        {localize("Uppdateringar")}
                        <UnreadIndicator count={this.state.indicatorDot} />
                    </div>

                ),
            })
            categories.push(c)
        }

        return categories;
    }

    public render() {
        const userImages = this.props.user.images;

        const imageSrc = userImages.Message;

        const department = this.props.user.department;
        const departmentName = department?.name || "";

        const theme = this.props.instance.get("theme") as Spintr.ISpintrTheme;

        let backgroundColor = Color.fromHex(this.props.instance.get("color")).customLighten().toString("hex");
        let textcolor = Style.getHexFromSpintrColor("dark-grey");
        
        const useColorHeader = theme ? theme.useColorHeader : this.props.instance.get("useColorHeader") as boolean;

        if (useColorHeader) {
            backgroundColor = "#FFFFFF";
        }

        return (
            <div
                className="SpintrUserMenu"
                onClick={(e) => {
                    e.preventDefault();
                //@ts-ignore
                    ReactDOM.findDOMNode(this.actionMenu.current).click();
                }}
            >
                <div className="menuWrap">
                    <SpintrUser
                        imageInitials={getInitialsFromString(this.props.currentUser.name)}
                        name={this.props.currentUser.name}
                        subText={this.props.displaySubText ? departmentName : ""}
                        imageUrl={imageSrc}
                        size={circleSmall}
                        textColor={textcolor}
                        personalName={true}
                    />
                    <UnreadIndicator count={this.state.indicatorDot} />
                    <div className="actions" id="submenu-actionmenu">
                        <div className="icon no-user-select">
                            <ActionMenu
                                ref={this.actionMenu}
                                isOpen={this.state.menuIsOpen}
                                categories={this.getCategories()}
                                icon="ChevronDown"
                                gapSpace={20}
                                renderButton={() => {
                                    return (
                                        <Visage2Icon
                                            hexColor={textcolor}
                                            icon="arrow-right-3"
                                            size="small" />
                                    )
                                }}
                                disableHoverEffect
                            />
                        </div>
                    </div>
                </div>
                <CustomDialog
                    message={localize("LaddarOmForAttSeAndringar")}
                    show={this.state.displayReloadDialog}
                    confirmMessage={localize("RELOAD")}
                    onDismiss={() => {
                        this.setState({
                            displayReloadDialog: false,
                        });
                    }}
                    onConfirm={() => {
                        window.location.reload();
                    }}
                />
                <CustomDialog
                    message={localize("LaddarOmForAttSeAndringar")}
                    show={this.state.displayChangeInstanceMessage}
                    confirmMessage={localize("Ok")}
                    onDismiss={() => { }}
                    onConfirm={() => { }}
                    hideSecondaryBtn={true}
                />
            </div>
        );
    }

    private goTo(url: string): void {
        this.props.history.push(url);
    }
}

const mapStateToProps: MapStateToProps<IStateProps, IOwnProps, Spintr.AppState> = (state) => {
    const roles = state.profile.active.roles;

    return {
        isAdministrator: roles.indexOf("administrators") > -1,
        isEditor: roles.indexOf("editor") > -1,
        currentUserDepartments: state.profile.active.departments,
        alternativeInstances: state.profile.active.alternativeInstances,
        instance: state.instance,
        displayAsOfflineInChat: state.ui.displayAsOfflineInChat,
        currentUser: state.profile.active,
        enableCrocus: state.instance.get("crocusAvailable"),
        apps: state.app,
        betaEnabled: state.instance.get("betaEnabled"),
        useColorHeader: state.instance.get("theme") ? state.instance.get("theme").useColorHeader : state.instance.get("useColorHeader"),
        isSpintrAdminAccount: state.profile.active.isSpintrAdminAccount,
        appMode: state.ui.appMode
    };
};

const SpintrUserMenuWithRouter = withRouter(SpintrUserMenu);

export default connect(mapStateToProps)(SpintrUserMenuWithRouter);