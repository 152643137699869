import React, { Fragment, FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { getTypeName, localize } from "src/l10n";
import { possessiveName } from "src/utils";

interface IProps {
    notification: Spintr.INotification;
}

const MentionNotificationText: FunctionComponent<IProps> = (props) => {
    const notification = props.notification as Spintr.IMentionNotification;
    if (!notification) {
        return;
    }
    
    const typeText = getTypeName(notification.objectType, {
        case: "none"
    });
    
    return (
        <Fragment>
            {localize(notification.userName.length > 0
                ? "DuTaggadesI"
                : "DuTaggadesIEn") + " "}
            {notification.userName.length > 0 && (
                possessiveName(notification.userName) + " "
            )}
            <Link to={notification.url}>
                {typeText}
            </Link>.
        </Fragment>
    );
}

export default MentionNotificationText;
