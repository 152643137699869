import { AxiosResponse } from 'axios';
import { Link } from '@fluentui/react';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { localize } from 'src/l10n';
import { ActionMenu, PageHeader } from 'src/ui';
import SpintrList from 'src/ui/components/SpintrList/SpintrList';
import SuppliersBreadcrumbs from './SuppliersBreadcrumbs';
import api from 'src/spintr/SpintrApi';
import { updateSidebar } from './utils';

interface IProps {
    instance?: any;
    currentUser?: any;
    history?: any;
    dispatch?: any;
    isAdmin?: boolean;
    isEditor?: boolean;
    appName?: string;
}

interface IState {
    isLoading: boolean;
    items: any[];
}

class SuppliersProductCodesView extends Component<IProps, IState> {
    private listRef = React.createRef<SpintrList>();

    state = {
        isLoading: true,
        items: [],
    };

    componentDidMount() {
        updateSidebar(this.props.isAdmin, this.props.isEditor, this.props.appName, this.props.dispatch);
    }

    render() {
        return (
            <div className="SuppliersView">
                <div className="breadcrumbs-wrapper">
                    <SuppliersBreadcrumbs />
                </div>
                <Helmet>
                    <title>{localize("Produktkoder")}</title>
                </Helmet>
                <PageHeader title={localize("Produktkoder")} />
                <SpintrList
                    ref={this.listRef}
                    fetch={(skip, take, columnId, isAscending, searchQuery) => {
                        return new Promise((resolve, reject) => {
                            api
                                .get(`/api/v1/suppliers/tags`, {
                                    params: {
                                        includeDeleted: true,
                                        isAscending,
                                        orderByColumn: columnId,
                                        searchText: searchQuery,
                                        skip,
                                        take,
                                    },
                                })
                                .then((response: AxiosResponse) => {
                                    resolve({
                                        data: response.data.items,
                                        totalCount: response.data.totalCount
                                    });
                                });
                        });
                    }}
                    orderByColumn={"Name"}
                    columns={[
                        {
                            name: localize("Namn"),
                            fieldName: "name",
                            minWidth: 250,
                            onRender: (item) => {
                                return (
                                    <Link className={"linkFGColor"}
                                        style={{
                                            ...(item.isDeleted ? { textDecorationLine: "line-through" } : {}),
                                        }}
                                        onClick={() => {
                                            this.props.history.push({
                                                pathname: `/suppliers`,
                                                search: "?tagId=" + item.id
                                            });
                                        }}
                                    >
                                        {item.name}
                                    </Link>
                                );
                            },
                        },
                        {
                            name: "",
                            key: "actionmenu",
                            minWidth: 40,
                            onRender: (item: any) => {
                                return (
                                    <ActionMenu
                                        categories={[
                                            {
                                                items: [
                                                    {
                                                        text: localize("Redigera"),
                                                        onClick: () => { },
                                                    },
                                                    {
                                                        text: item.isDeleted
                                                            ? localize("Aterstall")
                                                            : localize("TaBort"),
                                                        onClick: () => {
                                                            api
                                                                .post(`/api/v1/todos/toggledeleted/${item.id}`)
                                                                .then((_response) => {
                                                                    this.listRef.current.fetch();
                                                                });
                                                        },
                                                    },
                                                ],
                                            },
                                        ]}
                                    />
                                );
                            },
                        },
                    ]}
                />
            </div>
        );
    }
}

const mapStateToProps = (state, props) => ({
    ...props,
    instance: state.instance,
    currentUser: state.profile.active,
    isAdmin: state.profile.active.isAdmin,
    isEditor: state.profile.active.isEditor,
    appName: state.instance.get("suppliersAppName")
});

// @ts-ignore
export default withRouter(connect(mapStateToProps)(SuppliersProductCodesView));
