import { DefaultButton, Icon } from '@fluentui/react';
import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { ChatView } from "src/chat/components";
import "src/groups/views/GroupChatView.scss";
import "src/groups/views/GroupEditView.scss";
import localize from 'src/l10n/localize';
import { IApplicationState } from "src/spintr/reducer";
import { Label } from 'src/ui/components';
import Visage2Icon from 'src/visage2/Visage2Icon/Visage2Icon';

interface IProps {
    group?: any;
    location: any;
    groupShowFiles?: boolean;
    groupShowNotes?: boolean;
    groupShowPlanning?: boolean;
    history?: any;
}

interface IState {
    parentMessageId?: number;
}

class GroupChatView extends Component<IProps, IState> {
    constructor(props) {
        super(props);

        this.state = {
            parentMessageId: this.getparentMessageId(props)
        }
    }

    getparentMessageId(props) {
        const query = new URLSearchParams(props.location.search);
        const parentMessageId = query.get('parentMessageId');

        return parentMessageId ?
            parseInt(parentMessageId) :
            null;
    }

    componentDidUpdate(prevProps: IProps): void {
        if (this.props.location.search !== prevProps.location.search) {
            this.setState({
                parentMessageId: this.getparentMessageId(this.props)
            });
        }
    }

    renderEmptyGroupViewBox(item: any, index: number) {
        return (
            <div className="Empty-Group" key={index}>
                <Visage2Icon className="Empty-Group-Icon" icon="people" />
                <Label size="body-4" weight="medium" className="Empty-Group-Title">
                    {
                        item.title
                    }
                </Label>
                <Label size="body-3" className="Empty-Group-SubTitle">
                    {
                        item.subTitle
                    }
                </Label>
                <DefaultButton text={item.buttonText} className="Empty-Group-Button" onClick={item.onButtonClick} />
            </div>
        )
    }

    renderEmptyGroupView() {
        let items = [];

        items.push({
            icon: "People",
            title: localize("Medlemmar"),
            subTitle: localize("LaggTillMedlemmar"),
            buttonText: localize("BjudInMedlemmar"),
            onButtonClick: () => {
                this.props.history.push({
                    pathname: "/groups/" + this.props.group.id + "/members"
                });
            }
        });

        if (this.props.groupShowPlanning) {
            items.push({
                icon: "CheckboxComposite",
                title: localize("Planering"),
                subTitle: localize("OversiktIProjektet"),
                buttonText: localize("SkapaBrada"),
                onButtonClick: () => {
                    this.props.history.push({
                        pathname: "/groups/" + this.props.group.id + "/planning"
                    });
                }
            });
        }

        if (this.props.groupShowFiles) {
            items.push({
                icon: "TextDocument",
                title: localize("Filer"),
                subTitle: localize("DelaOchSamarbeta"),
                buttonText: localize("LaddaUppFil"),
                onButtonClick: () => {
                    this.props.history.push({
                        pathname: "/groups/" + this.props.group.id + "/files"
                    });
                }
            });
        }

        return (
            <div className="ChatView-empty-group">
                <Label size="h2">
                    {
                        localize("dummy_group_name")
                    }
                </Label>
                <Label>
                    {
                        localize("GroupEmptyContentStart")
                    }
                </Label>
                {
                    items.map((item, index) => {
                        return this.renderEmptyGroupViewBox(item, index)
                    })
                }
            </div>
        )
    }

    render() {
        return (
            <div className="GroupChatView">
                <ChatView
                    conversationId={this.props.group.conversationId}
                    group={this.props.group}
                    emptyRender={this.renderEmptyGroupView.bind(this)}
                    parentMessageId={this.state.parentMessageId} />
            </div>
        )
    }
}

const mapStateToProps = (state: IApplicationState, props: IProps): IProps => ({
    ...props,
    group: state.groups.group,
    groupShowFiles: state.instance.get("groupShowFiles"),
    groupShowNotes: state.instance.get("groupShowNotes"),
    groupShowPlanning: state.instance.get("groupShowPlanning"),
});

// @ts-ignore
export default withRouter(connect(mapStateToProps)(GroupChatView));
