import React, { Component } from "react";
import { Helmet } from 'react-helmet';
import { connect } from "react-redux";
import { localize } from "src/l10n";
import { IVisageSidebarMenuItem, VisageSidebarMode } from "src/sidebar";
import { expandToActiveItem, setSidebarItems, setSidebarMode } from "src/sidebar/actions";
import { IApplicationState } from "src/spintr/reducer";
import api from "src/spintr/SpintrApi";
import { ActionMenu, Breadcrumbs, ContentWithSubmenu, Label, PageHeader, Submenu } from "src/ui";
import { FormSection } from "src/ui/components/Forms";
import SpintrLoader from "src/ui/components/Loader";
import "./Operatinginfo.scss";
import OperatinginfoList from "./OperatinginfoList";

interface IProps {
    history: any;
    match: any;
    isAdmin: boolean;
    isEditor: boolean;
    currentUserId: number;
    currentUserName: string;
    currentUserDepartmentName: string;
    currentUserImageUrl: string;
    requiredTagCount: number;
    dispatch?: any;
}

interface IState {
    isLoading: boolean;
    hasExtendedRights: boolean;
    ongoing: any;
    planned: any;
    completed: any;
}

class OperatinginfoListView extends Component<IProps, IState> {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            hasExtendedRights: this.props.isAdmin || this.props.isEditor,
            ongoing: [],
            planned: [],
            completed: [],
        };
    }

    componentDidMount() {
        this.fetchItems();

        this.updateSidebar();
    }

    updateSidebar() {
        let children: IVisageSidebarMenuItem[] = [];

        if (this.state.hasExtendedRights) {
            children.push({
                key: "operatinginfo-create",
                name: localize("SkapaNy"),
                route: "/operatinginfo/create",
                routes: ["/operatinginfo/create"],
            });
        }

        let items: IVisageSidebarMenuItem[] = [{
            key: "operatinginfo",
            name: localize("Driftinformation"),
            icon: "Info Square",
            activeIcon: "Info Square",
            route: "/operatinginfo",
            routes: ["/operatinginfo"],
            search: "",
            children,
            isExpanded: this.state.hasExtendedRights,
            isLoading: false,
            moduleKey: "operatinginfo",
        }];

        this.props.dispatch(setSidebarItems(items, "operatinginfo"));
        this.props.dispatch(setSidebarMode(VisageSidebarMode.submenu));
        this.props.dispatch(expandToActiveItem());
    }

    async fetchItems() {
        this.setState({
            isLoading: true,
        });

        await api.get("api/operatinginfo?showDeleted=false").then((response) => {
            this.setState({
                ongoing: response.data[0],
                planned: response.data[1],
                completed: response.data[2],
                isLoading: false,
            });
        });
    }

    reFetch() {
        this.setState(
            {
                ongoing: [],
                planned: [],
                completed: [],
                isLoading: true,
            },
            () => this.fetchItems()
        );
    }

    render() {
        if (this.state.isLoading) {
            return <SpintrLoader />;
        }

        return (
            <div>
                <Helmet>
                    <title>{localize("Driftinformation")}</title>
                </Helmet>
                <Breadcrumbs
                    displayInstance
                    items={[
                        {
                            text: localize("Driftinformation"),
                            key: 3,
                            link: "/operatinginfo",
                        },
                    ]}
                />
                <PageHeader title={localize("Driftinformation")} />
                {this.state.hasExtendedRights ? (
                    <FormSection>
                        <div id="top-right-actionmenu">
                            <ActionMenu
                                categories={[
                                    {
                                        items: [
                                            {
                                                text: localize("SkapaNyDriftuppdatering"),
                                                onClick: () => {
                                                    this.props.history.push("/operatinginfo/create");
                                                },
                                            },
                                            {
                                                text: localize("HanteraSupportkontakt"),
                                                onClick: () => {
                                                    this.props.history.push("/operatinginfo/support");
                                                },
                                            },
                                        ],
                                    },
                                ]}
                            />
                        </div>
                    </FormSection>
                ) : null}

                {this.state.ongoing.items.length === 0 ? null : (
                    <div>
                        <Label as="h2" size="h3">
                            {localize("Pagaende")}
                        </Label>
                        <OperatinginfoList
                            isAdmin={this.state.hasExtendedRights}
                            fetchList={this.reFetch.bind(this)}
                            history={this.props.history}
                            items={this.state.ongoing}
                        ></OperatinginfoList>
                    </div>
                )}
                {this.state.planned.items.length === 0 ? null : (
                    <div>
                        <Label as="h2" size="h3">
                            {localize("Planerade")}
                        </Label>
                        <OperatinginfoList
                            isAdmin={this.state.hasExtendedRights}
                            fetchList={this.reFetch.bind(this)}
                            history={this.props.history}
                            items={this.state.planned}
                        ></OperatinginfoList>
                    </div>
                )}
                <div>
                    <Label as="h2" size="h3">
                        {localize("Avklarade")}
                    </Label>
                    <OperatinginfoList
                        isAdmin={this.state.hasExtendedRights}
                        fetchList={this.reFetch.bind(this)}
                        history={this.props.history}
                        items={this.state.completed}
                    ></OperatinginfoList>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: IApplicationState, props) => {
    return {
        ...props,

        isAdmin: state.profile.active.isAdmin,
        isEditor: state.profile.active.isEditor,
    };
};

export default connect(mapStateToProps)(OperatinginfoListView);
