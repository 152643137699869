import { AxiosResponse } from 'axios';
import { ChoiceGroup, DefaultButton, Link, Modal, PrimaryButton, Stack, TextField } from '@fluentui/react';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { localize } from 'src/l10n';
import { setConfirmPopup } from 'src/popups/actions';
import { ActionMenu, Label, PageHeader } from 'src/ui';
import SpintrList from 'src/ui/components/SpintrList/SpintrList';
import SuppliersBreadcrumbs from './SuppliersBreadcrumbs';
import api from 'src/spintr/SpintrApi';
import { updateSidebar } from './utils';
import { FormControl, FormSection, FormTokenizedObjectInput } from 'src/ui/components/Forms';
import PopupHeader from 'src/ui/components/PopupHeader';
import { Style } from 'src/ui/helpers';
import { SpintrTypes } from 'src/typings';
import { FileSelector } from 'src/spintr/components';
import { uniqueId } from 'src/utils';
import moment from 'moment';

interface IProps {
    instance?: any;
    currentUser?: any;
    history?: any;
    isAdmin?: boolean;
    isEditor?: boolean;
    dispatch?: any;
    appName?: string;
}

interface IState {
    isLoading: boolean;
    items: any[];
    totalCount?: number;
    displayDeleted: boolean;
    displayFileModal: boolean;
    newFile?: any;
    displayFileUploadPopup: boolean;
    isLoadingContent: boolean;
    uploadMethod: string;
    instructions?: string;
}

class SuppliersFilesView extends Component<IProps, IState> {
    private listRef = React.createRef<SpintrList>();
    private draggedItem: any = undefined;

    constructor(props: IProps) {
        super(props);

        this.state = {
            isLoading: true,
            items: [],
            displayDeleted: false,
            displayFileModal: false,
            displayFileUploadPopup: false,
            isLoadingContent: false,
            uploadMethod: "0"
        }
    }

    componentDidMount() {
        updateSidebar(this.props.isAdmin, this.props.isEditor, this.props.appName, this.props.dispatch);
    }

    saveFile() {
        this.setState(
            {
                isLoadingContent: true
            },
            () => {
                api.post(`/api/suppliers/external-files`, this.state.newFile).then(() => {
                    this.setState(
                        {
                            displayFileModal: false,
                            newFile: null,
                        },
                        () => {
                            this.listRef.current.reFetch();
                        }
                    );
                }).catch(() => { });
            }
        );
    }

    renderInstructionsModal() {
        if (!this.state.instructions) {
            return null;
        }

        return (
            <Modal
                className="spintr-modal uppliersFilesViewPopup modalWithPopupHeader"
                isOpen={!!this.state.instructions}
                onDismiss={() => {
                    this.setState({
                        instructions: undefined,
                    });
                }}
            >
                <PopupHeader
                    text={localize("INSTRUCTIONS")}
                    onClose={() => {
                        this.setState({
                            instructions: undefined,
                        });
                    }} />
                <div>
                    <Label className="suppliers-instructions-text">{this.state.instructions}</Label>
                </div>
            </Modal>
        )
    }

    renderFileModal() {
        if (!this.state.newFile) {
            return null;
        }

        return (
            <Modal
                className="spintr-modal uppliersFilesViewPopup modalWithPopupHeader"
                isOpen={this.state.displayFileModal}
                onDismiss={() => {
                    this.setState({
                        displayFileModal: false,
                    });
                }}
            >
                <PopupHeader
                    text={this.state.newFile?.id === 0 ? localize("SkapaNyFil") : localize("RedigeraFil")}
                    onClose={() => {
                        this.setState({
                            displayFileModal: false,
                            displayFileUploadPopup: false,
                            newFile: null,
                        });
                    }} />
                <div>
                    <FormControl>
                        <ChoiceGroup
                            defaultSelectedKey={this.state.uploadMethod}
                            options={[
                                { key: "0", text: localize("LaddaUpp") },
                                { key: "1", text: localize("AngeLank") },
                            ]}
                            onChange={(e, v) => {
                                this.setState({
                                    uploadMethod: v.key,
                                    newFile: {
                                        ...this.state.newFile,
                                        url: "",
                                        instructions: "",
                                        file: null
                                    }
                                });
                            }}
                            required={true}
                            aria-required={true}
                        />
                    </FormControl>
                    {
                        this.state.uploadMethod === "0" && (
                            <div>
                                <FormControl>
                                    {!this.state.newFile?.file && (
                                        <PrimaryButton
                                            onClick={() => {
                                                this.setState({
                                                    displayFileUploadPopup: true,
                                                });
                                            }}
                                        >
                                            {localize("ValjFil")}
                                        </PrimaryButton>
                                    )}
                                    {this.state.newFile?.file && (
                                        <div>
                                            <Label>{localize("SELECTED_FILE") + ": " + this.state.newFile.file.name}</Label>
                                            <FormControl>
                                                <PrimaryButton
                                                    style={{ marginTop: Style.getSpacingStr(3) }}
                                                    onClick={() => {
                                                        this.setState({
                                                            newFile: {
                                                                ...this.state.newFile,
                                                                file: null,
                                                            },
                                                        });
                                                    }}
                                                >
                                                    {localize("TaBortFilen")}
                                                </PrimaryButton>
                                            </FormControl>
                                        </div>
                                    )}
                                    {this.state.displayFileUploadPopup && (
                                        <FileSelector
                                            onClose={() => {
                                                this.setState({
                                                    displayFileUploadPopup: false,
                                                    displayFileModal: false,
                                                });
                                            }}
                                            onSelect={(data) => {
                                                console.log(data);
                                                this.setState({
                                                    displayFileUploadPopup: false,
                                                    newFile: {
                                                        ...this.state.newFile,
                                                        file: data[0],
                                                        targets: [],
                                                    },
                                                });
                                            }}
                                            allowMultipleFiles={false}
                                            sourcesToDisplay={[0]}
                                            fileUploadType={11}
                                        />
                                    )}
                                </FormControl>
                            </div>
                        )
                    }
                    {
                        this.state.uploadMethod === "1" && (
                            <div>
                                <FormSection>
                                    <FormControl>
                                        <TextField
                                            value={this.state.newFile?.url}
                                            className="textField"
                                            label={localize("Lank")}
                                            required
                                            resizable={false}
                                            onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, newValue?: string) => {
                                                this.setState({
                                                    newFile: {
                                                        ...this.state.newFile,
                                                        url: newValue,
                                                    },
                                                });
                                            }}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <TextField
                                            value={this.state.newFile?.displayName}
                                            className="textField"
                                            label={localize("DISPLAY_NAME")}
                                            resizable={false}
                                            onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, newValue?: string) => {
                                                this.setState({
                                                    newFile: {
                                                        ...this.state.newFile,
                                                        displayName: newValue,
                                                    },
                                                });
                                            }}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <TextField
                                            value={this.state.newFile?.instructions}
                                            className="textField"
                                            label={localize("INSTRUCTIONS")}
                                            style={{
                                                minHeight: 100
                                            }}
                                            multiline
                                            resizable={false}
                                            onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, newValue?: string) => {
                                                this.setState({
                                                    newFile: {
                                                        ...this.state.newFile,
                                                        instructions: newValue,
                                                    },
                                                });
                                            }}
                                        />
                                    </FormControl>
                                </FormSection>
                            </div>
                        )
                    }
                    <FormControl>
                        <FormTokenizedObjectInput
                            aria-label={localize("SokOchLaggTill") + " " + localize("SUPPLIER_GROUPS").toLowerCase()}
                            placeholder={localize("SokOchLaggTill") + " " + localize("SUPPLIER_GROUPS").toLowerCase()}
                            items={this.state.newFile.targets.map((tag) => {
                                return {
                                    ...tag,
                                    text: tag.name || tag.text,
                                    key: tag.id || tag.key,
                                };
                            })}
                            label={localize("SynligFor")}
                            types={[
                                SpintrTypes.UberType.SupplierGroup,
                            ]}
                            onChange={(targets) => {
                                this.setState((state) => ({
                                    newFile: {
                                        ...this.state.newFile,
                                        targets: targets.map((t) => {
                                            return {
                                                ...t,
                                                id: t.key,
                                                text: t.name,
                                                image: t.imageUrl,
                                                info: t.subText,
                                            };
                                        }),
                                    },
                                }));
                            }}
                            showAllWhenEmpty
                        />
                    </FormControl>
                    <Stack horizontal={true} horizontalAlign="end" tokens={{ childrenGap: 6 }}>
                        <DefaultButton
                            onClick={() => {
                                this.setState({
                                    displayFileModal: false,
                                    displayFileUploadPopup: false,
                                    newFile: null,
                                });
                            }}
                            text={localize("Avbryt")}
                        />
                        <PrimaryButton
                            onClick={this.saveFile.bind(this)}
                            disabled={this.state.uploadMethod === "0" ? !this.state.newFile?.file : !this.state.newFile?.url}
                            text={localize(this.state.newFile?.file?.id ? "Spara" : "Skapa")}
                        />
                    </Stack>
                </div>
            </Modal>
        )
    }

    move(id: number, indexChange: number) {
        const insertIndex = this.state.items.map(x => x.id).indexOf(id) + indexChange;
        const item = this.state.items.find(x => x.id === id);
        const items = this.state.items.filter(x => x.id !== id);

        items.splice(insertIndex, 0, item);

        this.setState({
            items
        }, () => {
            this.listRef.current.applyNewData({
                items,
                totalCount: this.state.totalCount
            });

            api.post("/api/v1/suppliers/order-files/0", items.map(x => x.id)).then(() => {}).catch(() => {});
        });
    }

    render() {
        return (
            <div className="SuppliersView">
                <div className="breadcrumbs-wrapper">
                    <SuppliersBreadcrumbs />
                </div>
                <Helmet>
                    <title>{localize("SUPPLIER_FILES")}</title>
                </Helmet>
                <PageHeader
                    title={localize("SUPPLIER_FILES")}
                />
                <SpintrList
                    ref={this.listRef}
                    infiniteScroll
                    dragDropEvents={{
                        canDrag: (item: any) => {
                            return this.props.isAdmin || this.props.isEditor;
                        },
                        canDrop: (dropContext, dragContext) => {
                            return this.props.isAdmin || this.props.isEditor;
                        },
                        onDragStart: (item: any) => {
                            this.draggedItem = item;
                        },
                        onDragEnd: (item: any) => {
                            this.draggedItem = undefined;
                        },
                        onDrop: (targetItem?: any, event?: any) => {
                            if (targetItem.id === this.draggedItem.id) {
                                return;
                            }

                            const insertIndex = this.state.items.map(x => x.id).indexOf(targetItem.id)
                            const items = this.state.items.filter(x => x.id !== this.draggedItem.id);

                            items.splice(insertIndex, 0, this.draggedItem);

                            this.setState({
                                items
                            }, () => {
                                this.listRef.current.applyNewData({
                                    items,
                                    totalCount: this.state.totalCount
                                });

                                api.post("/api/v1/suppliers/order-files/0", items.map(x => x.id)).then(() => {}).catch(() => {});
                            });
                        },
                    }}
                    data={{ data: this.state.items, totalCount: this.state.totalCount }}
                    fetch={(skip, take, columnId, isAscending, searchQuery) => {
                        return new Promise((resolve, reject) => {
                            api
                                .get(`/api/v1/suppliers/external-files`, {
                                    params: {
                                        includeDeleted: this.state.displayDeleted,
                                        isAscending,
                                        orderByColumn: columnId,
                                        searchText: searchQuery,
                                        skip,
                                        take,
                                    },
                                })
                                .then((response: AxiosResponse) => {
                                    const newItems = skip === 0 ?
                                        response.data.items :
                                        [...this.state.items, ...response.data.items];

                                    this.setState({
                                        items: newItems,
                                        totalCount: response.data.totalCount
                                    })
                                    resolve({
                                        data: newItems,
                                        totalCount: response.data.totalCount
                                    });
                                });
                        });
                    }}
                    orderByColumn={"index"}
                    columns={[
                        {
                            name: localize("Fil"),
                            fieldName: "name",
                            minWidth: 250,
                            onRender: (item) => {
                                return (
                                    <Link className={"linkFGColor"}
                                        style={{
                                            ...(item.isDeleted ? { textDecorationLine: "line-through" } : {}),
                                        }}
                                        onClick={() => {
                                            if (!!item.file) {
                                                window.open("/api/v1/servefile/" + item.file.id, "_blank");
                                            } else if (!!item.url) {
                                                window.open(item.url, "_blank");
                                            }
                                        }}
                                    >
                                        {item.displayName || item.name}
                                    </Link>
                                );
                            },
                        },
                        {
                            name: localize("INSTRUCTIONS"),
                            fieldName: "instructions",
                            minWidth: 150,
                            onRender: (item) => {
                                if (!item.instructions) {
                                    return null;
                                }

                                return (
                                    <Link className={"linkFGColor"}
                                        style={{
                                            ...(item.isDeleted ? { textDecorationLine: "line-through" } : {}),
                                        }}
                                        onClick={() => {
                                            this.setState({
                                                instructions: item.instructions
                                            })
                                        }}
                                    >
                                        {localize("Visa") + " " + localize("INSTRUCTIONS").toLowerCase()}
                                    </Link>
                                );
                            },
                        },
                        {
                            name: localize("RiktadTill"),
                            fieldName: "targetString",
                            minWidth: 250,
                            onRender: (item) => {
                                const targetString = item.targets.length === 0 ?
                                    localize("Alla") :
                                    item.targets.map((target) => target.name).join(", ");

                                return (
                                    <span title={targetString}>
                                        {targetString}
                                    </span>
                                );
                            },
                        },
                        {
                            name: localize("Skapad"),
                            fieldName: "createDate",
                            minWidth: 250,
                            onRender: (item) => {
                                return (
                                    <span>
                                        {moment(item.createDate).format("LLL")}
                                    </span>
                                );
                            },
                        },
                        {
                            name: "",
                            key: "actionmenu",
                            minWidth: 40,
                            onRender: (item: any) => {
                                if (!(this.props.isAdmin || this.props.isEditor)) {
                                    return null;
                                }

                                const itemindex = this.state.items.map(x => x.id).indexOf(item.id);
                                const isFirst = itemindex === 0;
                                const isLast = itemindex === (this.state.items.length - 1);

                                let items = [];

                                if (!isFirst) {
                                    items.push({
                                        text: localize("FlyttaUpp"),
                                        onClick: () => {
                                            this.move(item.id, -1);
                                        },
                                    });
                                }

                                if (!isLast) {
                                    items.push({
                                        text: localize("FlyttaNer"),
                                        onClick: () => {
                                            this.move(item.id, 1);
                                        },
                                    });
                                }

                                items.push({
                                    text: localize("Redigera"),
                                    onClick: () => {
                                        this.setState({
                                            displayFileModal: true,
                                            newFile: {
                                                ...item,
                                            },
                                            uploadMethod: !!item.file ? "0" : "1"
                                        });
                                    },
                                });

                                items.push({
                                    text: item.isDeleted
                                        ? localize("Aterstall")
                                        : localize("TaBort"),
                                    onClick: () => {
                                        const onConfirm = () => {
                                            if (item.isDeleted) {
                                                api.put("/api/objects/deleted/" + item.id).then(() => {
                                                    this.listRef.current.fetch();
                                                });
                                            } else {
                                                api.delete("/api/objects/" + item.id).then(() => {
                                                    this.listRef.current.fetch();
                                                });
                                            }
                                        }

                                        const title = item.isDeleted ?
                                            localize("Aterstallinnehall") :
                                            localize("RaderaInnehall");

                                        const message = item.isDeleted ?
                                            localize("ArDuSakerPaAttDuVillGoraDetta") :
                                            localize("ArDuSakerPaAttDuVillX").replace("{{X}}", localize("RaderaDetta").toLowerCase());

                                        this.props.dispatch(setConfirmPopup({
                                            isOpen: true,
                                            title,
                                            message,
                                            onConfirm
                                        }));
                                    },
                                });

                                return (
                                    <ActionMenu
                                        categories={[
                                            {
                                                items
                                            },
                                        ]}
                                    />
                                );
                            },
                        },
                    ]}
                    buttons={!this.props.isAdmin && !this.props.isEditor ? null : [
                        {
                            key: "add",
                            text: localize("SkapaNyFil"),
                            onClick: () => {
                                this.setState({
                                    displayFileModal: true,
                                    newFile: {
                                        id: 0,
                                        targets: []
                                    }
                                });
                            },
                            iconProps: { iconName: "Add" },
                            className: "commandBarAddButton",
                        },
                    ]}
                    optionItems={this.props.isAdmin ? {
                        items: [
                            {
                                key: "includeDeleted",
                                text: this.state.displayDeleted ?
                                    localize("DoljBorttagna") :
                                    localize("VisaBorttagna"),
                                onClick: () => {
                                    this.setState({
                                        displayDeleted: !this.state.displayDeleted
                                    }, () => {
                                        this.listRef.current.fetch();
                                    });
                                },
                            }
                        ],
                    } : undefined}
                />
                {this.renderFileModal()}
                {this.renderInstructionsModal()}
            </div>
        );
    }
}

const mapStateToProps = (state, props) => ({
    ...props,
    instance: state.instance,
    currentUser: state.profile.active,
    isAdmin: state.profile.active.isAdmin,
    isEditor: state.profile.active.isEditor,
    appName: state.instance.get("suppliersAppName")
});

// @ts-ignore
export default withRouter(connect(mapStateToProps)(SuppliersFilesView));
