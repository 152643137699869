import axios from 'axios';
import { CommandBar, CommandBarButton, Shimmer, ShimmerElementsGroup, ShimmerElementType } from '@fluentui/react';
import React, { Component, ReactNode } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { RouteChildrenProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { localize } from 'src/l10n';
import { canUserCreateAndEditNewsArticles } from 'src/privileges/utils';
import { IVisageSidebarMenuItem, VisageSidebarMode } from 'src/sidebar';
import { expandToActiveItem, setSidebarItems, setSidebarMode } from 'src/sidebar/actions';
import InformationFeedEntry from 'src/spintr/components/InformationFeed/InformationFeedEntry';
import { IApplicationState } from 'src/spintr/reducer';
import { ActionMenu, Breadcrumbs, ContentWithInfoPanel, Label, Loader, PageHeader, setDisableWrapperBackground, UnstyledButton } from 'src/ui';
import CustomDialog from 'src/ui/components/Dialogs/CustomDialog';
import { debounce } from 'src/utils';
import { INewsQueryParams, loadNewsFeed } from '../api-actions';
import './NewsCatalogView.scss';
import api from 'src/spintr/SpintrApi';
import SpintrSearch from 'src/ui/components/SpintrList/SpintrSearch';
import SpintrBasicInfiniteScroll from 'src/ui/components/SpintrBasicInfiniteScroll';
import { startDeliverTrack } from 'src/utils/spintrStartDeliverfunctions';
import { SpintrTypes } from 'src/typings';
import { getSubMenu } from 'src/sidebar/utils';

interface IPageRouteParams {
    path: string;
    category: string;
}
interface Props extends RouteChildrenProps<IPageRouteParams> {
    canUserCreateAndEditNewsArticles?: boolean;
    dispatch?: any;
    isAdmin?: boolean;
    isEditor?: boolean;
    currentUser?: any;
    instance?: any;
}

interface State {
    news: Spintr.INewsArticle[];
    isLoading: boolean;
    isLoadingTags: boolean;
    pageSize: number;
    activePage: number;
    skip: number;
    totalCount: number;
    params: INewsQueryParams;
    searchExpanded: boolean;
    tags: any;
    newsCategories;
    targets: any;
    newsQueries: any[];
    deleteItem: number;
    showInfoDialog: boolean;
    selectedTag: string;
    selectedNewsCategory: string;
    selectedTarget: string;
    displayAllTags?: boolean;
}

class NewsCatalogView extends Component<Props, State> {
    private _isMounted: boolean = false;

    constructor(props: Props) {
        super(props);

        let tags = null;

        if (props.location.search.indexOf("?tagId=") === 0) {
            const tagId = parseInt(props.location.search.split("=")[1], 0);

            tags = tagId;
        }

        this.state = {
            news: [],
            isLoading: true,
            isLoadingTags: false,
            pageSize: 12,
            activePage: 0,
            skip: 0,
            totalCount: 0,
            params: {
                columnId: "publishDate",
                tags: tags,
                targets: null,
                searchQuery: "",
                currentColumn: null,
                getAdminDeletedOnly: null,
                getAdminDraftsOnly: null,
                getAdminOldOnly: null,
                getAdminPublishedOnly: null,
                getTopNews: null,
                getUserDeletedOnly: null,
                getUserDraftsOnly: null,
                getUserOldOnly: null,
                getUserPublishedOnly: null,
                searchLetter: null,
            },
            searchExpanded: true,
            tags: null,
            newsCategories: null,
            targets: null,
            newsQueries: [],
            deleteItem: undefined,
            showInfoDialog: false,
            selectedTag: undefined,
            selectedTarget: undefined,
            selectedNewsCategory: undefined
        };
    }

    debouncedFetchSearch = debounce(() => this.fetch(), 500);

    searchEvent = (event: React.ChangeEvent, value: string): void => {
        this.setState(
            (state) => ({
                params: { ...state.params, searchQuery: value },
            }),
            () => {
                this.debouncedFetchSearch();
            }
        );
    };

    private fetch = () => {
        const listParams: INewsQueryParams = {
            isAscending: false,
            skip: this.state.skip,
            take: this.state.pageSize,

            ...(this.state.params.currentColumn && { currentColumn: this.state.params.currentColumn }),
            ...(this.state.params.getAdminDeletedOnly && {
                getAdminDeletedOnly: this.state.params.getAdminDeletedOnly,
            }),
            ...(this.state.params.getAdminDraftsOnly && { getAdminDraftsOnly: this.state.params.getAdminDraftsOnly }),
            ...(this.state.params.getAdminOldOnly && { getAdminOldOnly: this.state.params.getAdminOldOnly }),
            ...(this.state.params.getAdminPublishedOnly && {
                getAdminPublishedOnly: this.state.params.getAdminPublishedOnly,
            }),
            ...(this.state.params.getTopNews && { getTopNews: this.state.params.getTopNews }),
            ...(this.state.params.getUserDeletedOnly && { getUserDeletedOnly: this.state.params.getUserDeletedOnly }),
            ...(this.state.params.getUserDraftsOnly && { getUserDraftsOnly: this.state.params.getUserDraftsOnly }),
            ...(this.state.params.getUserOldOnly && { getUserOldOnly: this.state.params.getUserOldOnly }),
            ...(this.state.params.getUserPublishedOnly && {
                getUserPublishedOnly: this.state.params.getUserPublishedOnly,
            }),
            ...(this.state.params.tags && { tags: this.state.params.tags }),
            ...(this.state.params.newsCategory && { newsCategory: this.state.params.newsCategory }),
            ...(this.state.params.searchQuery && { searchQuery: this.state.params.searchQuery }),
            ...(this.state.params.targets && { targets: this.state.params.targets }),
            ...(this.state.params.columnId && { date: this.state.params.columnId }),
            ...(this.state.params.searchLetter && { searchLetter: this.state.params.searchLetter }),
        };

        this.setState({ isLoading: true }, () => {
            loadNewsFeed(listParams).then((response) => {
                this.setState({
                    news: this.state.skip === 0 ? response.data[0] : [...this.state.news, ...response.data[0]],
                    isLoading: false,
                    totalCount: response.data[1],
                });
            });
        });
    };

    private onPageChange = (index: number): void => {
        let skip = index * this.state.pageSize;
        this.setState(
            {
                activePage: index,
                skip,
            },
            () => this.fetch()
        );
    };

    loadMyDrafts = () => {
        this.setState(
            (state) => ({
                params: {
                    ...state.params,
                    currentColumn: false,
                    getAdminDeletedOnly: false,
                    getAdminDraftsOnly: false,
                    getAdminOldOnly: false,
                    getAdminPublishedOnly: false,
                    getTopNews: false,
                    getUserDeletedOnly: false,
                    getUserDraftsOnly: true,
                    getUserOldOnly: false,
                    getUserPublishedOnly: false,
                    isAscending: false,
                    searchLetter: "",
                    searchQuery: "",
                    skip: 0,
                    take: 5,
                },
            }),
            () => {
                this.fetch();
            }
        );
    };

    loadMyPublished = () => {
        this.setState(
            (state) => ({
                params: {
                    ...state.params,
                    currentColumn: false,
                    getAdminDeletedOnly: false,
                    getAdminDraftsOnly: false,
                    getAdminOldOnly: false,
                    getAdminPublishedOnly: false,
                    getTopNews: false,
                    getUserDeletedOnly: false,
                    getUserDraftsOnly: false,
                    getUserOldOnly: false,
                    getUserPublishedOnly: true,
                    isAscending: false,
                    searchLetter: "",
                    searchQuery: "",
                    skip: 0,
                    take: 5,
                },
            }),
            () => {
                this.fetch();
            }
        );
    };

    loadMyOld = () => {
        this.setState(
            (state) => ({
                params: {
                    ...state.params,
                    currentColumn: false,
                    getAdminDeletedOnly: false,
                    getAdminDraftsOnly: false,
                    getAdminOldOnly: false,
                    getAdminPublishedOnly: false,
                    getTopNews: false,
                    getUserDeletedOnly: false,
                    getUserDraftsOnly: false,
                    getUserOldOnly: true,
                    getUserPublishedOnly: false,
                    isAscending: false,
                    searchLetter: "",
                    searchQuery: "",
                    skip: 0,
                    take: 5,
                },
            }),
            () => {
                this.fetch();
            }
        );
    };

    loadMyDeleted = () => {
        this.setState(
            (state) => ({
                params: {
                    ...state.params,
                    currentColumn: false,
                    getAdminDeletedOnly: false,
                    getAdminDraftsOnly: false,
                    getAdminOldOnly: false,
                    getAdminPublishedOnly: false,
                    getTopNews: false,
                    getUserDeletedOnly: true,
                    getUserDraftsOnly: false,
                    getUserOldOnly: false,
                    getUserPublishedOnly: false,
                    isAscending: false,
                    searchLetter: "",
                    searchQuery: "",
                    skip: 0,
                    take: 5,
                },
            }),
            () => {
                this.fetch();
            }
        );
    };

    loadAdminDrafts = () => {
        this.setState(
            (state) => ({
                params: {
                    ...state.params,
                    currentColumn: false,
                    getAdminDeletedOnly: false,
                    getAdminDraftsOnly: true,
                    getAdminOldOnly: false,
                    getAdminPublishedOnly: false,
                    getTopNews: false,
                    getUserDeletedOnly: false,
                    getUserDraftsOnly: false,
                    getUserOldOnly: false,
                    getUserPublishedOnly: false,
                    isAscending: false,
                    searchLetter: "",
                    searchQuery: "",
                    skip: 0,
                    take: 5,
                },
            }),
            () => {
                this.fetch();
            }
        );
    };

    loadAdminPublished = () => {
        this.setState(
            (state) => ({
                params: {
                    ...state.params,
                    currentColumn: false,
                    getAdminDeletedOnly: false,
                    getAdminDraftsOnly: false,
                    getAdminOldOnly: false,
                    getAdminPublishedOnly: true,
                    getTopNews: false,
                    getUserDeletedOnly: false,
                    getUserDraftsOnly: false,
                    getUserOldOnly: false,
                    getUserPublishedOnly: false,
                    isAscending: false,
                    searchLetter: "",
                    searchQuery: "",
                    skip: 0,
                    take: 5,
                },
            }),
            () => {
                this.fetch();
            }
        );
    };

    loadAdminOld = () => {
        this.setState(
            (state) => ({
                params: {
                    ...state.params,
                    currentColumn: false,
                    getAdminDeletedOnly: false,
                    getAdminDraftsOnly: false,
                    getAdminOldOnly: true,
                    getAdminPublishedOnly: false,
                    getTopNews: false,
                    getUserDeletedOnly: false,
                    getUserDraftsOnly: false,
                    getUserOldOnly: false,
                    getUserPublishedOnly: false,
                    isAscending: false,
                    searchLetter: "",
                    searchQuery: "",
                    skip: 0,
                    take: 5,
                },
            }),
            () => {
                this.fetch();
            }
        );
    };

    loadAdminDeleted = () => {
        this.setState(
            (state) => ({
                params: {
                    ...state.params,
                    currentColumn: false,
                    getAdminDeletedOnly: true,
                    getAdminDraftsOnly: false,
                    getAdminOldOnly: false,
                    getAdminPublishedOnly: false,
                    getTopNews: false,
                    getUserDeletedOnly: false,
                    getUserDraftsOnly: false,
                    getUserOldOnly: false,
                    getUserPublishedOnly: false,
                    isAscending: false,
                    searchLetter: "",
                    searchQuery: "",
                    skip: 0,
                    take: 5,
                },
            }),
            () => {
                this.fetch();
            }
        );
    };

    public componentDidUpdate = (prevProps) => {
        if (
            this.props.match.params["category"] !== prevProps.match.params["category"] &&
            this.props.match.params["category"]
        ) {
            this.updateCategory();
        }

        if (this.props.location.search !== prevProps.location.search &&
            this.props.location.search.indexOf("?tagId=") === 0) {
            const tagId = parseInt(this.props.location.search.split("=")[1], 0);

            this.setState({
                params: {
                    ...this.state.params,
                    tags: tagId
                }
            }, () => {
                this.fetch();
            });
        } else if (this.props.location.search !== prevProps.location.search &&
            this.props.location.search.indexOf("?tagId=") === -1 &&
            !!this.state.params.tags) {
            this.setState({
                params: {
                    ...this.state.params,
                    tags: null
                }
            }, () => {
                this.fetch();
            });
        }

        if (this.props.location.search !== prevProps.location.search &&
            this.props.location.search.indexOf("?categoryId=") === 0) {
            const categoryId = parseInt(this.props.location.search.split("=")[1], 0);

            this.setState({
                params: {
                    ...this.state.params,
                    newsCategory: categoryId
                }
            }, () => {
                this.fetch();
            });
        } else if (this.props.location.search !== prevProps.location.search &&
            this.props.location.search.indexOf("?categoryId=") === -1 &&
            !!this.state.params.newsCategory) {
            this.setState({
                params: {
                    ...this.state.params,
                    newsCategory: null
                }
            }, () => {
                this.fetch();
            });
        }
    };

    public updateCategory = () => {
        const { category } = this.props.match.params;
        let subNews = this.props.match.url.split("/")[2];
        if (subNews === "my" && category === "drafts") {
            this.loadMyDrafts();
            return true;
        } else if (subNews === "my" && category === "published") {
            this.loadMyPublished();
            return true;
        } else if (subNews === "my" && category === "old") {
            this.loadMyOld();
            return true;
        } else if (subNews === "my" && category === "deleted") {
            this.loadMyDeleted();
            return true;
        } else if (subNews === "admin" && category === "drafts") {
            this.loadAdminDrafts();
            return true;
        } else if (subNews === "admin" && category === "published") {
            this.loadAdminPublished();
            return true;
        } else if (subNews === "admin" && category === "old") {
            this.loadAdminOld();
            return true;
        } else if (subNews === "admin" && category === "deleted") {
            this.loadAdminDeleted();
            return true;
        }

        return false;
    };

    componentWillUnmount() {
        this._isMounted = false;

        this.props.dispatch(setDisableWrapperBackground(false));
    }

    public componentDidMount = () => {
        this._isMounted = true;
        let alreadyFetched = false;

        startDeliverTrack(SpintrTypes.InsightsType.VisitedNewsArchive);

        // Check path for top news
        let url = window.location.href.split("/");
        if (url[url.length - 1] === "top") {
            this.setState(
                (state) => ({
                    params: {
                        ...state.params,
                        getTopNews: true,
                    },
                }),
                () => {
                    alreadyFetched = this.updateCategory();

                    if (!alreadyFetched) {
                        this.fetch();
                    }
                }
            );
        } else {
            alreadyFetched = this.updateCategory();
            if (!alreadyFetched) {
                this.fetch();
            }
        }

        this.setState({ isLoadingTags: true }, () => {
            axios
                .all([
                    api.get("/api/v1/news/gettags").then((response) => {
                        const tags = response.data.sort((a, b) => {
                            if (a.name < b.name) { return -1; }
                            if (a.name > b.name) { return 1; }
                            return 0;
                        });

                        this.setState({ tags });
                    }),
                    api.get("/api/v1/newstargets?excludeUsers=true&hasNewsArticles=true").then((response) => {
                        this.setState({ targets: response.data });
                    }),
                    api.get("/api/v1/ubercategories?take=9999").then((response) => {
                        this.setState({ newsCategories: response.data.items });
                    }),
                ])
                .then((r) => {
                    this.setState({ isLoadingTags: false });
                });
        });

        this.updateSideBar();

        this.props.dispatch(setDisableWrapperBackground(true));
    }

    updateSideBar() {
        getSubMenu("news", this.props.dispatch, this.props.currentUser, this.props.instance).then((items: IVisageSidebarMenuItem[]) => {
            this.props.dispatch(setSidebarItems(items, "news"));
        }).catch(() => { });
    }

    public saveSearch = () => {
        const { params } = this.state;
        let query = {
            tags: params.tags ? [params.tags] : [],
            // categories: params.categories ? [params.categories] : [], // TODO
            targets: params.targets ? [params.targets] : [],
            text: params.searchQuery ? params.searchQuery : "",
            id: null,
            isRss: false,
        };

        this.setState({ isLoading: true }, () => {
            api.post("/api/v1/newsqueries", query).then((response) => {
                this.fetchSearchSubmenu();
                this.setState({ isLoading: false });
            });
        });
    };

    deleteItem = (id) => {
        this.setState({ isLoading: true }, () => {
            api.delete(`/api/v1/news/${id}`).then((_response) => {
                this.fetch();
            });
        });
    };

    getCommandBarItems() {
        let result = [];

        result.push({
            role: "search",
            key: "search",
            onRender: (a, b) => {
                if (!this.state.searchExpanded) {
                    return (
                        <CommandBarButton
                            iconProps={{ iconName: "Search" }}
                            onClick={() => this.setState({ searchExpanded: true })}
                        />
                    );
                }

                return (
                    <SpintrSearch
                        classes="searchBox"
                        value={this.state.params.searchQuery}
                        onChange={this.searchEvent} />
                )
            },
        });

        result.push({
            key: "actionMenu",
            text: localize("Alternativ"),
            subMenuProps: {
                className: "displayOptions",
                items: [
                    {
                        key: "saveSearch",
                        text: localize("SparaSokning"),
                        onClick: () => this.saveSearch(),
                    }
                ],
            },
        });

        if (this.props.canUserCreateAndEditNewsArticles) {
            result.push({
                role: "menuitem",
                key: "newNews",
                text: localize("Skapa") + " " + localize("Ubertype10_0_4"),
                iconProps: { iconName: "Add" },
                className: "commandBarAddButton",
                onClick: () => {
                    this.props.history.push({
                        pathname: `/news/create`,
                    });
                },
            });
        }

        return result;
    }

    renderActionMenu(actionMenu): JSX.Element {
        return <ActionMenu categories={actionMenu} />;
    }

    fetchSearchSubmenu = async () => {
        return new Promise((resolve, reject) => {
            api.get(`/api/v1/newsqueries`).then((response: any) => {
                response.data.map((item, idx) => {
                    item.key = item.id;
                    item.action = async () => {
                        await this.setState((state) => ({
                            selectedTag: undefined,
                            selectedNewsCategory: undefined,
                            selectedTarget: undefined,
                            params: {
                                ...state.params,
                                tags: undefined,
                                newsCategory: undefined,
                                targets: undefined,
                            },
                        }));
                        await item.tags.map(async (t) => {
                            await this.setState((state) => ({
                                selectedTag: t.title,
                                params: { ...state.params, tags: t.id },
                            }));
                        });
                        await item.category.map(async (t) => {
                            await this.setState((state) => ({
                                selectedNewsCategory: t.name,
                                params: { ...state.params, newsCategory: t.id },
                            }));
                        });
                        await item.targets.map(async (t) => {
                            await this.setState((state) => ({
                                selectedTarget: t.title,
                                params: { ...state.params, targets: t.id },
                            }));
                        });

                        await this.setState((state => ({ params: { ...state.params, searchQuery: item.text } })))

                        this.fetch();
                    };
                });

                resolve(response.data);
            });
        });
    };

    renderInfoPanel() {
        let tagsToDisplay = [];

        if (this.state.tags) {
            for (let tag of this.state.tags) {
                if (this.state.displayAllTags || this.state.tags.indexOf(tag) < 4) {
                    tagsToDisplay.push(tag);
                }
            }
        }

        return (
            <div>
                {
                    !!this.state.newsCategories && this.state.newsCategories.length > 0 && (
                        <div className="customInfoPanelPart visage-box-shadow">
                            <Label
                                size="body-3"
                                weight="medium"
                                uppercase
                                className={"primaryFGColor"}>
                                {localize("Kategorier")}
                            </Label>
                            <div className="customInfoPanelPart-items">
                                {[
                                    {
                                        name: localize("Alla"),
                                    },
                                    ...this.state.newsCategories
                                ].map((item: any, index: number) => {
                                    let active = this.state.params.newsCategory === item.id;

                                    return (
                                        <UnstyledButton
                                            className={"customInfoPanelPart-item" + (active ? " active" : "")}
                                            onClick={() => {
                                                this.setState((state) => ({
                                                    params: { ...state.params, newsCategory: item.id },
                                                    selectedNewsCategory: item.name,
                                                }), () => {
                                                    this.fetch();
                                                });
                                            }}
                                            key={index}>
                                            <Label weight="medium" color="mid-grey" size="body-2">{item.name}</Label>
                                        </UnstyledButton>
                                    )
                                })}
                            </div>
                        </div>
                    )
                }
                {
                    !!this.state.tags && this.state.tags.length > 0 && (
                        <div className="customInfoPanelPart visage-box-shadow">
                            <Label
                                size="body-3"
                                weight="medium"
                                uppercase
                                className={"primaryFGColor"}>
                                {localize("Taggar")}
                            </Label>
                            <div className="customInfoPanelPart-items">
                                {[
                                    {
                                        name: localize("Alla"),
                                    },
                                    ...tagsToDisplay
                                ].map((item: any, index: number) => {
                                    let active = this.state.params.tags === item.id ||
                                        (!this.state.params.tags && item.id === undefined);

                                    return (
                                        <UnstyledButton
                                            className={"customInfoPanelPart-item" + (active ? " active" : "")}
                                            onClick={() => {
                                                this.setState((state) => ({
                                                    params: { ...state.params, tags: item.id },
                                                    selectedTag: item.name,
                                                }), () => {
                                                    this.fetch();
                                                });
                                            }}
                                            key={index}>
                                            <Label weight="medium" color="mid-grey" size="body-2">{item.name}</Label>
                                        </UnstyledButton>
                                    )
                                })}
                                {!!this.state.tags && this.state.tags.length > 4 && (
                                    <UnstyledButton
                                        className={"customInfoPanelPart-item"}
                                        onClick={() => {
                                            this.setState((state) => ({
                                                displayAllTags: !state.displayAllTags
                                            }));
                                        }}>
                                        <Label weight="medium" color="mid-grey" size="body-2">{this.state.displayAllTags ? localize("VisaMindre") : (localize("VisaAlla") + " " + localize("Taggar").toLowerCase())}</Label>
                                    </UnstyledButton>
                                )}
                            </div>
                        </div>
                    )
                }
                {
                    !!this.state.targets && this.state.targets.length > 0 && (
                        <div className="customInfoPanelPart visage-box-shadow">
                            <Label
                                size="body-3"
                                weight="medium"
                                uppercase
                                className={"primaryFGColor"}>
                                {localize("Malgrupp")}
                            </Label>
                            <div className="customInfoPanelPart-items">
                                {[
                                    {
                                        name: localize("Alla"),
                                    },
                                    ...this.state.targets
                                ].map((item: any, index: number) => {
                                    let active = this.state.params.targets === item.id ||
                                        (!this.state.params.targets && item.id === undefined);

                                    return (
                                        <UnstyledButton
                                            className={"customInfoPanelPart-item" + (active ? " active" : "")}
                                            onClick={() => {
                                                this.setState(
                                                    (state) => ({
                                                        params: { ...state.params, targets: item.id },
                                                        selectedTarget: item.name,
                                                    }),
                                                    () => {
                                                        this.fetch();
                                                    }
                                                );
                                            }}
                                            key={index}>
                                            <Label weight="medium" color="mid-grey" size="body-2">{item.name}</Label>
                                        </UnstyledButton>
                                    )
                                })}
                            </div>
                        </div>
                    )
                }
            </div>
        )
    }

    getCustomElements1() {
        return (
            <ShimmerElementsGroup
                shimmerElements={[
                    { type: ShimmerElementType.line, height: 80, width: 80 },
                ]}
            />
        );
    }

    getCustomElements2() {
        return (
            <ShimmerElementsGroup
                flexWrap={true}
                shimmerElements={[
                    { type: ShimmerElementType.line, height: 30, verticalAlign: 'bottom' },
                    { type: ShimmerElementType.gap, height: 0, width: 0 },
                    { type: ShimmerElementType.line, height: 10, verticalAlign: 'bottom' },
                    { type: ShimmerElementType.gap, height: 0, width: 0 },
                    { type: ShimmerElementType.line, height: 10, verticalAlign: 'bottom' },
                ]}
            />
        );
    }

    renderShimmerItem(index: any) {
        return (
            <div className="InformationFeedEntry shimmer" key={index}>
                <div className="top">
                    <Shimmer customElementsGroup={this.getCustomElements1()} />
                </div>
                <div className="bottom">
                    <Shimmer customElementsGroup={this.getCustomElements2()} />
                </div>
            </div>
        )
    }

    render(): ReactNode {
        return (
            <SpintrBasicInfiniteScroll
                className={"NewsCatalogView"}
                hasMore={this.state.news.length !== this.state.totalCount}
                isLoading={this.state.isLoading}
                onEndReached={() => {
                    const activePage = this.state.activePage + 1;
                    const skip = activePage * this.state.pageSize;

                    this.setState({
                        activePage,
                        skip,
                        isLoading: true
                    }, () => {
                        this.fetch();
                    })
                }}>
                <div>
                    <ContentWithInfoPanel
                        visagePageStyle
                        template={(!!this.state.newsCategories && this.state.newsCategories.length > 0) ||
                            (!!this.state.tags && this.state.tags.length > 0) ? 1 : 3}
                        renderInfoPanel={this.renderInfoPanel.bind(this)}
                        customDesign
                    >
                        <div className="ModularPage withCommandBar notSpintrList">
                            <Helmet>
                                <title>{localize("Nyheter")}</title>
                            </Helmet>
                            <div>
                                <>
                                    {this.state.showInfoDialog && (
                                        <CustomDialog
                                            message={localize("ArDuSakerAttDuVillUtforaDennaAtgard")}
                                            show={this.state.showInfoDialog}
                                            onDismiss={() => {
                                                this.setState({ showInfoDialog: false });
                                            }}
                                            onConfirm={() => {
                                                this.setState({ showInfoDialog: false }, () => {
                                                    this.deleteItem(this.state.deleteItem);
                                                });
                                            }}
                                        />
                                    )}
                                    <div className="ModularPageHeader visage-box-shadow">
                                        <Breadcrumbs
                                            displayInstance
                                            items={[
                                                {
                                                    text: localize("Nyheter"),
                                                    key: 0,
                                                    link: "/news",
                                                },
                                            ]}
                                        />
                                        <PageHeader title={localize("Nyheter")} hits={this.state.totalCount} />
                                        <CommandBar
                                            className="CommandBar-GrowSearch"
                                            items={this.getCommandBarItems()}
                                        />
                                    </div>
                                    <div className="feed">
                                        {this.state.news.map((item) => {
                                            return (
                                                <InformationFeedEntry
                                                    key={item.id}
                                                    entry={item}
                                                    onDelete={() => {
                                                        this.setState({ showInfoDialog: true, deleteItem: item.id });
                                                    }}
                                                />
                                            );
                                        })}
                                        {!!this.state.isLoading && Array.from(Array(6).keys()).map((item: any, index: number) => {
                                            return this.renderShimmerItem(index);
                                        })}
                                    </div>
                                </>
                            </div>
                        </div>
                    </ContentWithInfoPanel>
                </div>
            </SpintrBasicInfiniteScroll>
        );
    }
}

const mapStateToProps = (state: IApplicationState, props) => {
    return {
        ...props,
        canUserCreateAndEditNewsArticles: canUserCreateAndEditNewsArticles(
            state.privileges.data,
            state.profile.active,
            state.instance
        ),
        isAdmin: state.profile.active.isAdmin,
        isEditor: state.profile.active.isEditor,
        currentUser: state.profile.active,
        instance: state.instance
    };
};

// @ts-ignore
export default withRouter(connect(mapStateToProps)(NewsCatalogView));
