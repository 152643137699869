import { AxiosResponse } from "axios";
import api from "src/spintr/SpintrApi";

const baseUrl = "/api/v1/favorites";

export const fetchFavorites =
    (userId?: number): Promise<AxiosResponse<Spintr.IUserFavorite[]>> =>
        api.get<Spintr.IUserFavorite[]>(baseUrl, {
            params: {
                userId,
            }
        });
