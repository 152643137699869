import moment from 'moment';
import { Callout } from "@fluentui/react";
import React, { Component } from 'react';
import { localize } from "src/l10n";
import { Label, SpintrUser } from "src/ui";
import { Style } from 'src/ui/helpers';
import { circleLarge } from 'src/ui/helpers/style';

interface IProps {
    imageUrl: string;
    name: string;
    status: number;
    availability: number;
    description: string;
    createdDate: string;
    memberCount: number;
    type: number;
}

interface IState {
    displayDescription: boolean;
}

class GroupHeaderInformation extends Component<IProps, IState> {
    constructor(props) {
        super(props);

        this.state = {
            displayDescription: false
        }
    }

    getSubText() {
        if (this.props.type === 2) {
            return localize("CUSTOMER_SUCCESS_GROUP");
        }

        if (this.props.availability === 1) {
            return localize("OppenGrupp");
        } else if (this.props.availability === 2) {
            return localize("StangdGrupp");
        } else {
            return localize("HemligGrupp");
        }
    }

    onMouseLeave() {
        this.setState({
            displayDescription: false
        });
    }

    onMouseOver() {
        this.setState({
            displayDescription: true
        });
    }

    render() {
        return (
            <div className="GroupHeaderInformation"
                onMouseLeave={this.onMouseLeave.bind(this)}
                onMouseOver={this.onMouseOver.bind(this)}>
                <div className="GroupHeaderInformation-inner">
                    <div className="image">
                        <SpintrUser
                            name={this.props.name}
                            subText={this.getSubText()}
                            imageUrl={this.props.imageUrl}
                            size={circleLarge}
                            hideText={true}
                            personalName={false}
                        />
                    </div>
                    <div className="text">
                        <div className="upper">
                            <Label className="name" size="body-1" weight="medium" color="visageGray">
                                {this.props.name}
                            </Label>
                            <div className="lower">
                                <Label className="message" size="body-2" color="visageGray3">
                                    {this.getSubText()}
                                </Label>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    this.state.displayDescription && (
                        <Callout
                            role="alertdialog"
                            gapSpace={0}
                            setInitialFocus
                            onDismiss={() => {
                                this.setState({
                                    displayDescription: false
                                });
                            }}
                            className="GroupHeaderInformation-Callout"
                            target=".GroupHeaderInformation-inner"
                        >
                            <div className="GroupPopupView">
                                <div className="GroupPopupView-inner">
                                    <Label color="dark-grey">
                                        {
                                            this.props.name
                                        }
                                    </Label>
                                    {
                                        this.props.description ?
                                            <Label size="body-3">
                                                {
                                                    this.props.description
                                                }
                                            </Label> :
                                            null
                                    }
                                    <div style={{
                                        paddingTop: Style.getSpacingStr(2)
                                    }}>
                                        <Label size="body-3" color="dark-grey">
                                            {
                                                localize("Startades") + " " + moment(this.props.createdDate).format("L")
                                            }
                                        </Label>
                                        <Label size="body-3" color="dark-grey">
                                            {
                                                this.props.memberCount +
                                                " " +
                                                (this.props.memberCount === 1 ?
                                                    localize("Medlem").toLowerCase() :
                                                    localize("Medlemmar_small"))
                                            }
                                        </Label>
                                    </div>
                                </div>
                            </div>
                        </Callout>
                    )
                }
            </div>
        )
    }
}

export default GroupHeaderInformation;
