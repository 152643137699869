import React from "react";
import { localize } from "src/l10n";
import { Label } from "src/ui";
import CompactTable from "src/ui/components/CompactTable/CompactTable";
import "./AcademyLeaderboardCategory.scss";

interface IProps {
    name: string;
    columnNames: string[];
    rows: string[][];
}

const AcademyLeaderboardCategory = (props: IProps) => {
    return (
        <div className="AcademyLeaderboardCategory">
            <Label weight="medium" className="category-title">{props.name}</Label>
            {props.rows.length === 0 && (
                <Label color="mid-grey">{localize("NO_DATA_AVAILABLE")}</Label>
            )}
            {props.rows.length > 0 && (
                <CompactTable
                    columNames={props.columnNames}
                    rows={props.rows} />
            )}
        </div>
    )
}

export default AcademyLeaderboardCategory;
