import { applyMiddleware, compose, createStore } from "redux";
import persistState, { AdapterCallback, mergePersistedState, StorageAdapter } from "redux-localstorage"
import filter, { getSubset } from "redux-localstorage-filter";
import adapter from "redux-localstorage/lib/adapters/localStorage";
import promise from "redux-promise-middleware";
import thunk from "redux-thunk";

import createErrorHandlingMiddleware from "./error-handling-middleware";
import rootReducer, { IApplicationState } from "./reducer";

import { createLogger } from "redux-logger";
import Immutable from "immutable";
import isDev from "src/utils/isDev";

const logger = createLogger({
    level: "debug",
    predicate: () => false,
});

const middleware = applyMiddleware(
    createErrorHandlingMiddleware({
        printErrorsToConsole: false
    }),
    logger,
    promise,
    thunk,
);

const merger = (initialState: any, persistentState: any): any => {
    if (!persistentState) {
        return { ...initialState };
    }

    if (!!persistentState.ui) {
        persistentState.ui = {
            ...persistentState.ui,
            isInTeamsApp: !!persistentState.ui.isInTeamsApp ?
                persistentState.ui.isInTeamsApp :
                window.location.href.indexOf("isInTeamsApp=true") > -1,
            appMode: !!persistentState.ui.appMode ?
                persistentState.ui.appMode :
                window.location.href.indexOf("isInTeamsApp=true") > -1,
            public360: persistentState.ui.public360 || {},
            sidebarExpanded: true,
            displayHeaderSearch: false,
            useDocumentWidthMode: false,
            disableWrapperBackground: false,
            useNarrowFormWidthMode: false,
            focusCommentFieldForUberId: undefined,
            reactionPickerConfig: undefined,
            formFooterBarVisible: false,
            visage2FullscreenMode: false,
            visage2FullscreenHeaderVisible: false,
        }
    }

    if (!!persistentState.profile) {
        persistentState.profile = {
            ...persistentState.profile,
            fetchHasBeenInitialized: false
        }
    }

    if (!!persistentState.app) {
        persistentState.app = initialState.app;
    }

    if (!!persistentState.instance) {
        persistentState.instance = initialState.instance;
    }

    if (!!persistentState.menu) {
        persistentState.menu = {
            ...persistentState.menu,
            fetchHasBeenInitialized: false
        };
    }

    if (!!persistentState.privileges) {
        persistentState.privileges = {
            ...persistentState.privileges,
            fetchHasBeenInitialized: false
        }
    }

    if (!!persistentState.rightColumn) {
        persistentState.rightColumn = {
            ...persistentState.rightColumn,
            hasFetchedItems: false
        }
    }

    if (!!persistentState.teaserboxes) {
        persistentState.teaserboxes = {
            ...persistentState.teaserboxes,
            fetchHasBeenInitialized: false
        }
    }

    return {
        ...initialState,
        ...persistentState,
    }
};

const reducer = compose(
    mergePersistedState(merger)
)(rootReducer);

type StorageFilter = (storage: StorageAdapter<unknown>) => StorageAdapter<unknown>;
const excludeSubset = (state: Spintr.AppState, paths: string[]): any => {
    let fn: (pathParts: string[], currentObj: any) => any;

    fn = (pathParts: string[], currentObj: any) => {
        const currentPath = pathParts[0];

        if (currentObj === undefined || currentObj == null) {
            return currentObj;
        }

        // If the current object is an Immutable object, convert it to a plain JS object
        const spreadableObj = typeof currentObj?.toJS === "function"
            ? currentObj.toJS()
            : currentObj;

        // last object, so just delete
        if (pathParts.length === 1) {
            const obj = {
                ...spreadableObj,
            };
            delete obj[currentPath];

            return obj;
        }
        
        // Handle arrays separately
        if (Array.isArray(spreadableObj)) {
            return spreadableObj.map((item) => fn(pathParts, item));
        }

        const remainingPaths = pathParts.slice(1);
        const subset = fn(remainingPaths, spreadableObj[currentPath]);

        return {
            ...spreadableObj,
            [currentPath]: subset,
        };
    };

    return paths.reduce(
        (acc, path) => fn(path.split('.'), acc),
        { ...state },
    );
};

const reverseFilter = (paths: string[]): StorageFilter => (storageAdapter: StorageAdapter<unknown>): StorageAdapter<unknown> => ({
    ...storageAdapter,
    put: (key: string, state: Spintr.AppState, callback: AdapterCallback): void => {
        storageAdapter.put(key, excludeSubset(state, paths), callback);
    }
})

const storage = compose(
    reverseFilter([
        "profile.active.crocusKey",
        "profile.active.email",
        "profile.active.name",
        "profile.active.username",
        "ui.adminNoPadding",
        "ui.formFooterBarTop",
        "teaserboxes.items.blogPost.likers"
    ]),
    filter([
        "ui",
        "profile",
        "menu",
        "privileges",
        "rightColumn",
        "teaserboxes"
    ]),
)(adapter(window.localStorage));

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const enhancer = composeEnhancers(
    middleware,
    persistState(
        storage,
        "spintr",
    ),
)

const configureStore = (preloadedState: IApplicationState) => createStore(
    reducer,
    preloadedState,
    enhancer,
);

export default configureStore;
