import { produce } from "immer";

import {
    FETCH_ACTIVE_USER_FULFILLED,
    FETCH_ACTIVE_USER_PENDING,
    FETCH_ACTIVE_USER_REJECTED,
    UPDATE_ACTIVE_USER,
    ProfileAction,
    FETCH_UNREAD_EMAILS_FULFILLED,
    FETCH_UNREAD_EMAILS_REJECTED,
    CLEAR_UNREAD_EMAILS_PENDING
} from './action-types'

import { setMomentLang } from "src/l10n";

export interface IActiveUserProfile extends Spintr.IActiveUser {
    failed: boolean;
    isAdmin: boolean;
    isEditor: boolean;
    isFileUser: boolean;
    isGroupUser: boolean;
    isLoaded: boolean;
    isLoading: boolean;
    mfaEnabled?:    boolean | undefined;
}

export interface IProfileState {
    active: IActiveUserProfile;
    unreadEmails?: number;
    preventEmailFetch: boolean;
    fetchHasBeenInitialized: boolean;
}

const initialState: IProfileState = {
    active: {
        absence: null,
        alternativeInstances: [],
        calendarId: 0,
        created: new Date(),
        crocusKey: null,
        department: null,
        departments: [],
        email: "",
        failed: false,
        feedId: 0,
        galleryId: 0,
        gender: 0,
        googleConnected: false,
        hasPasswordRecovery: false,
        hasSeenAppFeedback: false,
        id: 0,
        images: null,
        initialUser: false,
        instanceId: 0,
        isAdmin: false,
        isEditor: false,
        isFileUser: false,
        isGroupUser: false,
        isLoaded: false,
        isLoading: false,
        isSpintrAdminAccount: false,
        name: "",
        office365Connected: false,
        onboarding: [],
        organisationroles: null,
        rights: null,
        roles: [],
        settings: null,
        state: 0,
        username: "",
        introductionSequences: [],
        lastSeenOperatingInfo: new Date()
    },
    preventEmailFetch: false,
    fetchHasBeenInitialized: false
};

const profileReducer = (state = initialState, action: ProfileAction) => produce(state, draft => {
    switch (action.type) {
        case FETCH_ACTIVE_USER_PENDING:
            draft.active.failed = false;
            draft.active.isLoaded = false;
            draft.active.isLoading = true;
            draft.fetchHasBeenInitialized = true;
            break;

        case FETCH_ACTIVE_USER_REJECTED:
            draft.active.failed = true;
            draft.active.isLoaded = true;
            draft.active.isLoading = false;
            break;

        case FETCH_ACTIVE_USER_FULFILLED:
            const user = action.payload.data;
            draft.active = {
                ...draft.active,
                ...user,
                failed: false,
                isLoaded: true,
                isLoading: false,
                isAdmin: action.payload.data.roles && action.payload.data.roles.includes('administrators'),
                isEditor: action.payload.data.roles && action.payload.data.roles.includes('editor'),
                isFileUser: action.payload.data.roles && action.payload.data.roles.includes('fileuser'),
                isGroupUser: action.payload.data.roles && action.payload.data.roles.includes('groupuser'),
            };

            const language = parseInt(user.settings.language, 10);
            setMomentLang(isNaN(language) ? 3 : language);
            break;

        case UPDATE_ACTIVE_USER:
            return {
                ...state,
                active: {
                    ...state.active,
                    ...action.meta.user
                }
            }

        case FETCH_UNREAD_EMAILS_FULFILLED:
            draft.unreadEmails = action.payload.data
            break;

        case FETCH_UNREAD_EMAILS_REJECTED:
            draft.preventEmailFetch = true;
            draft.unreadEmails = 0;
            break;

        case CLEAR_UNREAD_EMAILS_PENDING:
            draft.unreadEmails = 0;
            break;
    }
});

export default profileReducer
