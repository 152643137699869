import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Redirect, Route, Switch, useHistory, useLocation } from "react-router";
import { StandardErrorBoundary, setVisage2FullscreenMode } from "src/ui";
import { ProductsListView } from "../ProductsListView";
import Visage2FullScreenHeader from "src/ui/components/Visage2FullScreenView/Visage2FullScreenHeader";
import { renderAdminTitle } from "src/admin/common/Title";
import { localize } from "src/l10n";
import { useDispatch, useSelector } from "react-redux";
import { ProductDetailsView } from "../ProductDetailsView";
import { debounce } from "src/utils";
import { setDisplaySearch } from "src/sidebar";
import classNames from "classnames";
import GlobalBreadcrumbs from "src/admin/views/GlobalBreadcrumbs";
import Visage2FullScreenHeaderSpacer from "src/ui/components/Visage2FullScreenView/Visage2FullScreenHeaderSpacer";
import { ProductsRootViewState } from "./ProductsRootView.types";
import { ProductFormDrawer } from "src/products/components";

function getCreateQueryString(queryString: string): string | undefined {
    const searchParams = new URLSearchParams(queryString);

    return searchParams.get("create");
}

function ProductsRootView(): ReactNode {
    const { pathname, search: queryString } = useLocation();
    const listRefreshRef = useRef<((() => void) | null)>(null);
    
    const dispatch = useDispatch();
    const isAdministrator = useSelector<Spintr.AppState, boolean>(
        (appState) => appState.profile.active.roles?.includes("administrators"),
    );
    
    const history = useHistory();
    const [state, setState] = useState<ProductsRootViewState>({
        showCreationDrawer: !!getCreateQueryString(queryString),
    });

    const closeCreationDrawer = useCallback(() => {
        setState((prevState) => ({
            ...prevState,
            showCreationDrawer: false,
        }));

        if (!listRefreshRef.current) {
            return;
        }

        listRefreshRef.current();
    }, [setState, listRefreshRef]);

    const openCreationDrawer = useCallback(() => setState((prevState) => ({
        ...prevState,
        showCreationDrawer: true,
    })), [setState]);

    const userRoles = useSelector<Spintr.AppState, string[]>(
        (state) => state.profile.active.roles,
    );

    const isAdministrativeView = pathname.startsWith("/admin/");

    const onSearchEvent = useCallback((_: unknown, searchText: string) => {
        const basePath = isAdministrativeView ? "/admin" : "";
        
        const search = !searchText
            ? ""
            : `?search=${encodeURIComponent(searchText)}`;

        history.push(`${basePath}/products${search}`);
    }, [isAdministrativeView, history]);

    const debouncedSearchEvent = useMemo(
        () => debounce(onSearchEvent, 250),
        [onSearchEvent],
    );

    const headerButtons = useMemo(() => isAdministrator ? [{
        key: "add",
        text: localize("ADD_NEW_PRODUCT"),
        onClick: openCreationDrawer,
        iconProps: { iconName: "Add" },
        className: "commandBarAddButton",
    }] : [], [isAdministrator, openCreationDrawer]);

    const breadcrumbs = useMemo<JSX.Element>(() => {
        if (isAdministrativeView) {
            return null;
        }

        const pattern = /\/products\/\d+$/gmi;
        if (pattern.test(pathname)) {
            return null;
        }

        return <GlobalBreadcrumbs />;
    }, [isAdministrativeView, pathname]);

    const headerSpacer = isAdministrativeView ? null : <Visage2FullScreenHeaderSpacer />;

    const adminTitle = isAdministrativeView
        ? renderAdminTitle(localize("PRODUCTS"))
        : null;

    useEffect(() => {
        dispatch(setDisplaySearch(true));
        dispatch(setVisage2FullscreenMode(true));

        return () => {
            dispatch(setDisplaySearch(false));
            dispatch(setVisage2FullscreenMode(false));
        };
    }, []);

    if (isAdministrativeView && !userRoles.includes("administrators")) {
        return <Redirect to="/products" />;
    }

    const prefix = isAdministrativeView ? "/admin" : "";

    return (
        <StandardErrorBoundary>
            <div id="ProductsRootView" className={classNames({
                "general-view": !isAdministrativeView,
                "list-view": pathname === "/products" || pathname === "/admin/products",
            })} role={isAdministrativeView ? undefined : "main"}>
                {headerSpacer}
                {adminTitle}
                <Visage2FullScreenHeader
                    buttons={headerButtons}
                    searchEvent={debouncedSearchEvent}
                />
                {breadcrumbs}
                <div className="products-view">
                    <Switch>
                        <Route path={prefix + "/products/:productId"}>
                            <ProductDetailsView />
                        </Route>
                        <Route path={prefix + "/products"} exact={true}>
                            <ProductsListView listRefreshRef={listRefreshRef} />
                        </Route>
                    </Switch>
                </div>
            </div>

            <ProductFormDrawer
                initialArticleId={getCreateQueryString(queryString)}
                onDismiss={closeCreationDrawer}
                open={state.showCreationDrawer} />
        </StandardErrorBoundary>
    )
}

export default ProductsRootView;
