import React, {
    FunctionComponent,
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState
} from "react";
import { ScrollableAreaContainer } from "src/ui/components";
import Message from "../../Message";
import { ChatTabContext } from "../ChatTabContext";
import { ChatTabDateGroup } from "../ChatTabDateGroup";
import "./ChatTabMessageContainer.scss";
import { groupMessages } from "./utils";

interface IProps {
    messages: Message[];
    lastRead: { [messageId: number]: number[] };
    onRespondToMessage: any;
    isResponding?: any;
    blocked?: boolean;
    blockedBy?: boolean;
}

const topScrollThreshold = 30;

const ChatTabMessageContainer: FunctionComponent<IProps> = (props) => {
    const [initialScrollDone, setInitialScrollDone] = useState(false);
    const [heightData, setHeightData] = useState({
        height: 0,
        scrollPosition: 0,
    });

    const scrollRef = useRef<ScrollableAreaContainer>(null);
    const context = useContext(ChatTabContext);

    const setRead = useCallback(
        () => {
            if (!context) {
                return;
            }

            context.clearUnread();
        },
        [context],
    );

    const onScroll = useCallback(() => {
        if (!scrollRef.current) {
            return;
        }

        const scrollArea = scrollRef.current.getArea();

        setHeightData((_) => ({
            height: scrollArea.getScrollHeight(),
            scrollPosition: scrollArea.getScrollTop()
        }));

        const topPosition = scrollArea.getScrollTop();
        if (topPosition > topScrollThreshold) {
            return;
        }

        context.loadMore();
    }, [scrollRef.current, context]);

    // scroll to bottom once we have the scrollRef
    useEffect(() => {
        if (!scrollRef.current) {
            return;
        }

        scrollRef.current.scrollToBottom();
    }, [scrollRef.current]);

    // scroll down if number of messages changes
    useEffect(() => {
        setRead();

        if (!scrollRef.current) {
            return;
        }

        const scrollArea = scrollRef.current.getArea();

        if (!initialScrollDone) {
            scrollRef.current.scrollToBottom();
            setInitialScrollDone(true);
            setHeightData((_) => ({
                height: scrollArea.getScrollHeight(),
                scrollPosition: scrollArea.getScrollHeight()
            }));
            return;
        }

        if (scrollRef.current.isScrolledToBottomWithHeight(50)) {
            scrollRef.current.scrollToBottom();
            return;
        }

        const diff = scrollArea.getScrollHeight() - heightData.height;
        const nextScroll = heightData.scrollPosition + diff;
        scrollRef.current.scrollToPosition(nextScroll);
    }, [props.messages.length]);

    const groups = groupMessages(props.messages);

    return (
        <div className="ChatTabMessageContainer">
            <ScrollableAreaContainer
                height={!!props.isResponding ? 209 : 256}
                ref={scrollRef}
                onScroll={onScroll}
            >
                <div className="date-groups" onClick={setRead}>
                    {groups.map((dg) => {
                        const key = "DateGroup"
                            + dg.from.toUTCString()
                            + dg.to.toUTCString();

                        return (
                            <div className="date-group" key={key}>
                                <ChatTabDateGroup
                                    dateGroup={dg}
                                    lastRead={props.lastRead}
                                    onRespondToMessage={props.onRespondToMessage}
                                    blocked={props.blocked}
                                    blockedBy={props.blockedBy}
                                />
                            </div>
                        )
                    })}
                </div>
            </ScrollableAreaContainer>
        </div>
    );
}

export default ChatTabMessageContainer;