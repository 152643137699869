import { AxiosResponse } from "axios";
import { convertChangesToXML, diffWords } from "diff";
import moment from "moment";
import { DefaultButton } from "@fluentui/react";
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { localize } from "src/l10n";
import { IApplicationState } from "src/spintr/reducer";
import {
    ActionMenu, Breadcrumbs, ContentWithInfoPanel, ContentWithSubmenu, Label, PageHeader, PageInfoPanel,
    Reach, setShouldReloadFavourites,
    SpintrUser, Submenu
} from "src/ui";
import CustomDialog from "src/ui/components/Dialogs/CustomDialog";
import SpintrLoader from "src/ui/components/Loader";
import TinyFormattedContent from "src/ui/components/Tiny/displayment/TinyFormattedContent";
import { print } from "src/utils/print";
import CenteredPageHeader from "./CenteredPageHeader";
import "./WikiArticleView.scss";
import api from "src/spintr/SpintrApi";
import UnsplashAttribution from "src/ui/components/UnsplashAttribution";

interface IRouteParams {
    article: string;
    wiki: string;
}

interface IProps extends RouteComponentProps<IRouteParams> {
    isAdmin: boolean;
    isEditor: boolean;
    restrictWikis?: boolean;
    currentUserId: number;
    instanceName: string;
    instanceColor: string;
    dispatch: any;
}

interface IState {
    isLoading: boolean;
    article: Spintr.IWikiArticle3;
    activeSection: Spintr.IWikiArticleSection;
    hasExtendedRights: boolean;
    showDeleteArticleDialog: boolean;
    showDeleteSectionDialog: boolean;
    showBackupFor: number;
    showBackupDetailsFor: number;
    showChanges: boolean;
    externalAuthorName?: string;
    externalAuthorUrl?: string;
    externalPhotoUrl?: string;
}

class WikiArticleView extends Component<IProps, IState> {
    private allowChanges: boolean;

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            article: null,
            activeSection: null,
            hasExtendedRights: false,
            showDeleteArticleDialog: false,
            showDeleteSectionDialog: false,
            showBackupFor: 0,
            showBackupDetailsFor: 0,
            showChanges: false,
        };

        this.allowChanges = this.props.isAdmin || this.props.isEditor || !this.props.restrictWikis;
    }

    componentDidMount() {
        this.fetchArticle();
    }

    componentDidUpdate(prevProps, prevState) {
        this.allowChanges = this.props.isAdmin || this.props.isEditor || !this.props.restrictWikis;

        const routeHasUpdated = this.props.match.params.article !== prevProps.match.params.article;
        if (!routeHasUpdated) {
            return;
        }

        this.setState(
            {
                isLoading: true,
            },
            this.fetchArticle
        );
    }

    updateUrlHash() {
        const hash = window.location.hash;

        window.location.hash = "";

        setTimeout(() => {
            window.location.hash = hash;
        }, 0);
    }

    fetchArticle = () => {
        api
            .get("/api/routes", {
                params: { route: `wikis/${this.props.match.params.wiki}/${this.props.match.params.article}` },
            })
            .then((response: AxiosResponse) => {
                var hasExtendedRights =
                    this.props.isAdmin ||
                    this.props.isEditor ||
                    (response.data.wiki as Spintr.IWikiArticle3).wiki.publisherId == this.props.currentUserId ||
                    response.data.wiki.editor.id == this.props.currentUserId;

                if (response.data.wiki.imgUrl?.startsWith("https://images.unsplash.com")) {
                    const imagePath = new URL(response.data.wiki.imgUrl).pathname;

                    api.get("/api/v1/images/metadata", { params: {
                        imagePath
                    }}).then((response) => {
                        this.setState({
                            externalAuthorName: response.data.externalAuthorName,
                            externalAuthorUrl: response.data.externalAuthorUrl,
                            externalPhotoUrl: response.data.externalPhotoUrl
                        });
                    });
                }

                this.setState(
                    {
                        article: response.data.wiki,
                        hasExtendedRights,
                        isLoading: false,
                    },
                    () => {
                        if (window && window.location && window.location.hash && window.location.hash.length > 0) {
                            this.updateUrlHash();
                        }
                    }
                );
            });
    };

    renderDeleteArticleDialog() {
        return (
            <CustomDialog
                show={this.state.showDeleteArticleDialog}
                title={localize("RaderaInnehall")}
                message={
                    this.state.article.deleted
                        ? localize("ArDuSakerAttDuVillAterstallaDennaPost") + "?"
                        : localize("ArDuSakerPaAttDuVillX").replace("{{X}}", localize("RaderaDetta").toLowerCase())
                }
                onConfirm={() => {
                    api
                        .put(`/api/v1/wikis/article/toggledelete/${this.state.article.id}`)
                        .then((response: AxiosResponse) => {
                            this.setState((prevState) => ({
                                article: {
                                    ...prevState.article,
                                    deleted: !prevState.article.deleted,
                                },
                                showDeleteArticleDialog: false,
                            }));
                        });
                }}
                onDismiss={() => this.setState({ showDeleteArticleDialog: false })}
            />
        );
    }

    renderDeleteSectionDialog() {
        return (
            <CustomDialog
                show={this.state.showDeleteSectionDialog}
                message={localize("VillDuVerkligenTaBortSektionen")}
                onConfirm={() => {
                    this.setState({
                        isLoading: true,
                        showDeleteSectionDialog: false,
                    });
                    api
                        .delete("/api/v1/wikis/delete/section", { params: { id: this.state.activeSection.id } })
                        .then(() => {
                            this.setState((prevState) => ({
                                activeSection: null,

                                isLoading: false,
                                article: {
                                    ...prevState.article,
                                    sections: prevState.article.sections.filter(
                                        (s) => s.id !== prevState.activeSection.id
                                    ),
                                },
                            }));
                        })
                        .catch(() => {
                            this.setState({
                                isLoading: false,
                                activeSection: null,
                            });
                        });
                }}
                onDismiss={() => this.setState({ showDeleteSectionDialog: false, activeSection: null })}
            />
        );
    }

    private renderActionMenu() {
        return (
            <div className="action-menu">
                <ActionMenu
                    canReport
                    hasReportIcon
                    objectId={this.state.article.id}
                    categories={[
                        {
                            items: [
                                {
                                    text: localize(
                                        this.state.article.isFavourite ? "TaBortFranFavoriter" : "LaggTillIFavoriter"
                                    ),
                                    onClick: this.state.article.isFavourite
                                        ? () => {
                                            api
                                                  .delete(`/api/v1/favorites/${this.state.article.id}`)
                                                  .then((response: AxiosResponse) => {
                                                      this.setState((prevState) => ({
                                                          article: {
                                                              ...prevState.article,
                                                              isFavourite: false,
                                                          },
                                                      }));
                                                      this.props.dispatch(setShouldReloadFavourites(true));
                                                  });
                                          }
                                        : () => {
                                            api
                                                  .post(`/api/v1/favorites`, {
                                                      id: this.state.article.id,
                                                  })
                                                  .then((response: AxiosResponse) => {
                                                      this.setState((prevState) => ({
                                                          article: {
                                                              ...prevState.article,
                                                              isFavourite: true,
                                                          },
                                                      }));
                                                      this.props.dispatch(setShouldReloadFavourites(true));
                                                  });
                                          },
                                    icon: this.state.article.isFavourite ? "FavoriteStarFill" : "FavoriteStar",
                                },
                                {
                                    text: localize(
                                        this.state.article.isFollowing ? "StangAvNotiser" : "AktiveraNotiser"
                                    ),
                                    onClick: this.state.article.isFollowing
                                        ? () => {
                                            api
                                                  .delete(`/api/v1/follow/${this.state.article.id}`)
                                                  .then((response: AxiosResponse) => {
                                                      this.setState((prevState) => ({
                                                          article: {
                                                              ...prevState.article,
                                                              isFollowing: false,
                                                          },
                                                      }));
                                                  });
                                          }
                                        : () => {
                                            api
                                                  .post(`/api/v1/follow`, { id: this.state.article.id })
                                                  .then((response: AxiosResponse) => {
                                                      this.setState((prevState) => ({
                                                          article: {
                                                              ...prevState.article,
                                                              isFollowing: true,
                                                          },
                                                      }));
                                                  });
                                          },
                                    icon: "Flag",
                                },
                                {
                                    text: localize("Skrivutsidan"),
                                    onClick: () => {
                                        print(this.props.instanceName, this.state.article.name);
                                    },
                                    icon: "Print",
                                },
                                {
                                    text: localize("Andringar"),
                                    onClick: () => {
                                        this.setState((prevState) => ({
                                            showChanges: !prevState.showChanges,
                                        }));
                                    },
                                },
                            ],
                        },
                        ...((this.state.hasExtendedRights ||
                        this.state.article.wiki.allownewarticles ||
                        this.state.article.wiki.allowedit)  && this.allowChanges
                            ? [
                                  {
                                      title: localize("Redaktor"),
                                      items: [
                                          ...((this.state.hasExtendedRights || this.state.article.wiki.allowedit)
                                              ? [
                                                    {
                                                        text: localize("RedigeraArtikel"),
                                                        onClick: () => {
                                                            this.props.history.push({
                                                                pathname: `/wikis/edit-article/${this.state.article.id}`,
                                                            });
                                                        },
                                                    },
                                                ]
                                              : []),
                                          ...(this.state.article.wiki.allowedit
                                              ? [
                                                    {
                                                        text: localize("NySektion"),
                                                        onClick: () => {
                                                            this.props.history.push({
                                                                pathname: `/wikis/create-section/${this.state.article.id}`,
                                                            });
                                                        },
                                                    },
                                                ]
                                              : []),
                                          ...(this.state.hasExtendedRights
                                              ? [
                                                    {
                                                        text: localize(
                                                            this.state.article.deleted ? "Aterstall" : "TaBort"
                                                        ),
                                                        onClick: () => {
                                                            this.setState({ showDeleteArticleDialog: true });
                                                        },
                                                    },
                                                ]
                                              : []),
                                      ],
                                  },
                              ]
                            : []),
                    ]}
                />
            </div>
        );
    }

    renderSection(section) {
        return (
            <div key={section.id} className="section-wrapper">
                <div className="section">
                    <div id={`section-${section.id}`} className="section-anchor" />
                    <div className="action-menu">
                        {(this.state.hasExtendedRights ||
                                this.state.article.wiki.allowedit ||
                                section.editor.id === this.props.currentUserId) && this.allowChanges && (
                                <ActionMenu
                                    categories={[
                                        {
                                            items: [
                                                ...(this.state.hasExtendedRights ||
                                                this.state.article.wiki.allowedit ||
                                                section.editor.id === this.props.currentUserId
                                                    ? [
                                                        {
                                                            text: localize("Redigera"),
                                                            onClick: () => {
                                                                this.props.history.push({
                                                                    pathname: `/wikis/edit-section/${section.id}`,
                                                                });
                                                            },
                                                        },
                                                    ]
                                                    : []),
                                                ...(this.state.hasExtendedRights ||
                                                section.editor.id === this.props.currentUserId
                                                    ? [
                                                        {
                                                            text: localize("TaBortDennaSektion"),
                                                            onClick: () => {
                                                                // Dialog här med..
                                                                this.setState({
                                                                    showDeleteSectionDialog: true,
                                                                    activeSection: section,
                                                                });
                                                            },
                                                        },
                                                    ]
                                                    : []),
                                            ],
                                        },
                                    ]}
                                />
                            )}
                    </div>
                    <Label as="h2" className="section-name" size="h3" weight="medium" color="dark-grey">
                        {section.title}
                    </Label>
                    <Label size="body-4">
                        <TinyFormattedContent key={section.id} content={section.content} />
                    </Label>
                    {/* <Link to={`/wikis/create-section/${this.state.article.id}/${section.position + 1}`}>
                    <FooterCreateButton iconSize={1.6} iconColor="mid-grey" />
                </Link> */}
                </div>
            </div>
        );
    }

    private renderSectionChanges(section) {
        return (
            <div key={section.id}>
                <DefaultButton
                    text={section.title}
                    className="backup-button primaryFGColor"
                    onClick={() => {
                        this.setState((prevState) => ({
                            showBackupFor: section.id === prevState.showBackupFor ? 0 : section.id,
                        }));
                    }}
                />
                {this.state.showBackupFor == section.id && (
                    <div className="backup">
                        <ul>
                            {section.backups.map((backup) => {
                                const date = moment(backup.changedate).format("D MMMM YYYY");
                                var old = document.createElement("div");
                                old.innerHTML = backup.content;
                                var current = document.createElement("div");
                                current.innerHTML = backup.newcontent;

                                var diffText = diffWords(old.textContent, current.textContent);
                                var diffHTML = convertChangesToXML(diffText);

                                return (
                                    <li key={backup.id}>
                                        <DefaultButton
                                            text={date}
                                            className="backup-button primaryFGColor"
                                            onClick={() => {
                                                this.setState((prevState) => ({
                                                    showBackupDetailsFor:
                                                        backup.id === prevState.showBackupDetailsFor ? 0 : backup.id,
                                                }));
                                            }}
                                        />
                                        {this.state.showBackupDetailsFor == backup.id && (
                                            <div className="primaryBorderLeft">
                                                <Label className="section-name" as="h2">
                                                    {backup.title}
                                                </Label>

                                                <div
                                                    className="backup-content"
                                                    dangerouslySetInnerHTML={{
                                                        __html: diffHTML,
                                                    }}
                                                ></div>
                                            </div>
                                        )}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                )}
            </div>
        );
    }

    private renderSubmenu = () => (
        <>
            <div className="wikiInformation">
                {this.state.article && (
                    <CenteredPageHeader
                        title={this.state.article.wiki.title}
                        description={this.state.article.wiki.description}
                        imageUrl={this.state.article.wiki.imageUrl}
                        icon={this.state.article.wiki.icon}
                        displayIcon
                        isArticleView
                    />
                )}
            </div>
            <Submenu search={{ enabled: true }} nameProp="title" fetch={this.submenuFetch} autoExpand={false} />
        </>
    );

    private submenuFetch = (params) => {
        return new Promise((resolve, reject) => {
            api
                .get("/api/wikis/articles/" + this.state.article.wiki.id, {
                    params: {
                        searchText: params.searchText,
                    },
                })
                .then((response: AxiosResponse) => {
                    let articles = [];

                    if (!!params.searchText) {
                        articles = response.data[0]?.sections
                            .filter(section => section.header.toLowerCase().includes(params.searchText))
                            .map((section) => {
                            return { title: section.header, url: `/${response.data[0].url}#section-${section.id}`,}
                        }) ?? []
                    }
                    else {
                        articles = response.data.map((article) => ({
                            ...article,
                            url: `/${article.url}`,
                            isExpanded: params.searchText || this.props.location.pathname.slice(1) === article.url,
                            nodes: article.sections.map((section) => ({
                                title: section.header,
                                url: `/${article.url}#section-${section.id}`,
                            })),
                        }));
                    }

                    resolve(articles);
                });
        });
    };

    private renderInfoPanel = () => {
        return (
            <>
                <PageInfoPanel
                    customItems={[
                        {
                            title: localize("Innehallsansvarig"),
                            render: () => (
                                <SpintrUser
                                    id={this.state.article.editor.id}
                                    name={this.state.article.editor.name}
                                    subText={this.state.article.editor.info}
                                    imageUrl={this.state.article.editor.imageUrl}
                                    personalName={true}
                                />
                            ),
                        },
                        [
                            {
                                title: localize("Skapad"),
                                render: () => (
                                    <Label as="span" size="body-2">
                                        {moment(this.state.article.created).format("YYYY-MM-DD")}
                                        <span>&nbsp;</span>
                                    </Label>
                                ),
                            },
                            {
                                title: localize("SenastAndrad"),
                                render: () => (
                                    <Label as="span" size="body-2">
                                        {moment(this.state.article.lastchange).format("YYYY-MM-DD")}
                                        <span>&nbsp;</span>
                                    </Label>
                                ),
                            },
                        ],
                        {
                            title: localize("Rackvidd"),
                            render: () => (
                                <Reach uberId={this.state.article.id} />
                            ),
                        },
                        ...(this.state.article.links.length > 0
                            ? [
                                  {
                                      title: localize("Lankar"),
                                      render: () => (
                                          <>
                                              {this.state.article.links.map((link, idx) => (
                                                  <a className="link" href={link.url} target="_blank" key={idx}>
                                                      <Label as="span" size="body-2">
                                                          {link.title || link.url}
                                                      </Label>
                                                  </a>
                                              ))}
                                          </>
                                      ),
                                  },
                              ]
                            : []),
                    ]}
                />
            </>
        );
    };

    public render() {
        if (this.state.isLoading) {
            return <SpintrLoader />;
        }

        return (
            <>
                <Helmet>
                    <title>{this.state.article.name}</title>
                </Helmet>
                <Breadcrumbs
                    displayInstance
                    items={[
                        {
                            key: "",
                            text: localize("Wikis"),
                            link: "/wikis",
                        },
                        ...(this.state.article
                            ? [
                                  {
                                      key: this.state.article.wiki.id,
                                      text: this.state.article.wiki.title,
                                      link: `/goto/${this.state.article.wiki.id}`,
                                  },
                                  {
                                      key: this.state.article.id,
                                      text: this.state.article.name,
                                      link: `/${this.state.article.url.aliasurl}`,
                                  },
                              ]
                            : []),
                    ]}
                />
                <ContentWithSubmenu renderSubmenu={this.renderSubmenu}>
                    <div className="wiki-article-view">
                        {this.state.article && (
                            <>
                                {this.renderDeleteArticleDialog()}
                                {this.renderDeleteSectionDialog()}
                                {this.state.article.imgUrl && (
                                    <div style={{ marginBottom: 16 }}>
                                        <div
                                            style={{
                                                maxHeight: 170,
                                                overflow: "hidden",
                                                display: "flex",
                                                alignItems: "center",
                                                flexDirection: "row",
                                                marginBottom: 16,
                                            }}
                                        >
                                            <img
                                                src={this.state.article.imgUrl}
                                                style={{ width: "100%" }}
                                                className="image"
                                                alt=""
                                            />
                                        </div>
                                        {this.state.externalAuthorName && (
                                            <UnsplashAttribution
                                                authorName={this.state.externalAuthorName}
                                                authorUrl={this.state.externalAuthorUrl}
                                            />
                                        )}
                                    </div>
                                )}
                                <div style={{ position: "relative" }}>
                                    {this.renderActionMenu()}
                                    <PageHeader title={this.state.article.name} subText={this.state.article.preamble ? this.state.article.preamble : ""} />
                                    {!this.state.showChanges ? (
                                        <ContentWithInfoPanel template={1} renderInfoPanel={this.renderInfoPanel}>
                                            <div className="print-container">
                                                {this.state.article.sections.map((section) =>
                                                    this.renderSection(section)
                                                )}
                                            </div>
                                        </ContentWithInfoPanel>
                                    ) : (
                                        this.state.article.sections
                                            .filter((section) => section.backups.length > 0)
                                            .map((section) => this.renderSectionChanges(section))
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                </ContentWithSubmenu>
            </>
        );
    }
}

const mapStateToProps = (state: IApplicationState, props) => {
    return {
        ...props,

        isAdmin: state.profile.active.isAdmin,
        isEditor: state.profile.active.isEditor,
        restrictWikis: state.instance.get("restrictWikis"),
        currentUserId: state.profile.active.id,
        instanceName: state.instance.get("name"),
        instanceColor: state.instance.get("color"),
    };
};

export default connect(mapStateToProps)(WikiArticleView);
