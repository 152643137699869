import React, { Component } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { connect } from "react-redux";
import { fetchPlanning, saveCategoryFulfilled, saveItemFulfilled } from 'src/groups/planning-actions';
import "src/groups/views/planning/GroupPlanningView.scss";
import { localize } from "src/l10n";
import api from "src/spintr/SpintrApi";
import { ActionMenu, Label, Loader, UnstyledButton } from "src/ui";
import CustomDialog from "src/ui/components/Dialogs/CustomDialog";
import PlanningCard from "./PlanningCard";
import PlanningCardEditor from "./PlanningCardEditor";
import PlanningCategoryEditor from "./PlanningCategoryEditor";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";

interface IProps {
    dispatch: any;
    category: any;
    categoryId: any;
    index: any;
    groupId: number;
}

class Category extends Component<IProps> {
    state = {
        editingTitle: false,
        title: this.props.category.title,
        showCardEditor: false,
        isCreateingCard: false,
        isSavingCategoryTitle: false,
        displayDeleteCategoryDialog: false
    };

    toggleshowCardEditor = () =>
        this.setState({ showCardEditor: !this.state.showCardEditor });

    addCard = async (cardText) => {
        if (!cardText || cardText.length === 0) {
            return;
        }

        this.setState({
            isCreateingCard: true
        }, () => {
            const item = {
                id: 0,
                projectId: this.props.groupId,
                categoryId: this.props.categoryId,
                title: cardText
            };

            api.post(`/api/groupplanning/${this.props.groupId}/item/0`, item).then((response) => {
                this.props.dispatch(saveItemFulfilled(this.props.groupId, response.data));

                this.setState({
                    isCreateingCard: false,
                    showCardEditor: false
                });
            });
        });
    };

    toggleEditingTitle = () =>
        this.setState({
            editingTitle: !this.state.editingTitle
        });

    handleChangeTitle = e => this.setState({ title: e.target.value });

    saveCategoryTitle = async () => {
        this.setState({
            isSavingCategoryTitle: true
        }, () => {
            const c = {
                ...this.props.category,
                title: this.state.title
            };

            api.post(`/api/groupplanning/${this.props.groupId}/category/${this.props.categoryId}`, c).then((response) => {
                this.props.dispatch(saveCategoryFulfilled(this.props.groupId, response.data));

                this.setState({
                    isSavingCategoryTitle: false,
                    editingTitle: false
                });
            });
        });
    };

    deleteCategory = async () => {
        const { categoryId, category, dispatch } = this.props;

        dispatch({
            type: "DELETE_LIST",
            payload: { categoryId, cards: category.cards }
        });
    };

    renderDeleteCategoryDialog() {
        return (
            <CustomDialog
                show={this.state.displayDeleteCategoryDialog}
                message={localize("VillDuVerkligenTaBortX").replace("{{X}}", this.props.category.title)}
                onConfirm={() => {
                    this.setState({
                        displayDeleteCategoryDialog: false
                    }, () => {
                        api.delete(`/api/groupplanning/${this.props.groupId}/category/${this.props.category.id}`).then(() => {
                            this.props.dispatch(fetchPlanning(api, this.props.groupId));
                        });
                    });
                }}
                onDismiss={() => this.setState({ displayDeleteCategoryDialog: false })}
            />
        );
    }

    render() {
        return (
            <Draggable draggableId={this.props.category.id.toString()} index={this.props.index}>
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className="Planning-Category visage-box-shadow"
                        tabIndex={-1}
                    >
                        {
                            this.state.editingTitle ?
                                (this.state.isSavingCategoryTitle ?
                                    <Loader /> :
                                    <PlanningCategoryEditor
                                        groupId={this.props.groupId}
                                        category={this.props.category}
                                        title={this.state.title}
                                        handleChangeTitle={this.handleChangeTitle}
                                        saveCategory={this.saveCategoryTitle}
                                        onClickOutside={this.saveCategoryTitle}
                                        deleteCategory={this.deleteCategory}
                                    />) :
                                <div className="Planning-CategoryTitle">
                                    <UnstyledButton className="Planning-CategoryTitleButton" onClick={this.toggleEditingTitle}>
                                        <Label as="div" size="small-1">
                                            {
                                                this.props.category.title
                                            }

                                            <div className="Planning-CategoryActionMenu">
                                                <ActionMenu
                                                    categories={[{
                                                        items: [
                                                            this.props.category.isFollowing ? {
                                                                text: localize("AvfoljKolumn"),
                                                                onClick: () => {
                                                                    api.delete(`/api/follow/${this.props.category.id}`).then(() => {
                                                                        const updatedCategory = {
                                                                            ...this.props.category,
                                                                            isFollowing: false
                                                                        };

                                                                        this.props.dispatch(saveCategoryFulfilled(this.props.groupId, updatedCategory));
                                                                    });
                                                                },
                                                            } : {
                                                                text: localize("FoljKolumn"),
                                                                onClick: () => {
                                                                    api.post(`/api/follow`, {
                                                                        id: this.props.category.id
                                                                    }).then(() => {
                                                                        const updatedCategory = {
                                                                            ...this.props.category,
                                                                            isFollowing: true
                                                                        };

                                                                        this.props.dispatch(saveCategoryFulfilled(this.props.groupId, updatedCategory));
                                                                    });
                                                                },
                                                            },
                                                            {
                                                                text: localize("TaBort"),
                                                                onClick: () => {
                                                                    this.setState({
                                                                        displayDeleteCategoryDialog: true
                                                                    });
                                                                }
                                                            }
                                                        ]
                                                    }]}
                                                />
                                            </div>
                                        </Label>
                                    </UnstyledButton>

                                </div>
                        }
                        <Droppable droppableId={this.props.category.id.toString()}>
                            {(provided, _snapshot) => (
                                <div ref={provided.innerRef}>
                                    {
                                        this.props.category.items &&
                                        this.props.category.items.map((item, index) => (
                                            <PlanningCard
                                                card={item}
                                                key={item.id}
                                                groupId={this.props.groupId}
                                                index={index}
                                                categoryId={this.props.category.id}
                                            />
                                        ))
                                    }
                                    {
                                        this.props.category.items.length === 0 ?
                                            <div className="empty-text-wrapper">
                                                <Label as="p" size="body-2" color="dark-grey">
                                                    {
                                                        localize("IngaKortHittades")
                                                    }
                                                </Label>
                                            </div> :
                                            null
                                    }
                                    {
                                        provided.placeholder
                                    }
                                </div>
                            )}
                        </Droppable>
                        {
                            this.state.showCardEditor ?
                                (this.state.isCreateingCard ?
                                    <Loader /> :
                                    <PlanningCardEditor
                                        groupId={this.props.groupId}
                                        onSave={this.addCard}
                                        onCancel={this.toggleshowCardEditor}
                                    />) :
                                <div className="Planning-ToggleAddCard primaryFGColor">
                                    <UnstyledButton onClick={this.toggleshowCardEditor}>
                                        <Label as="div" size="body-2">
                                            <Visage2Icon icon="add" />
                                            {
                                                localize("LaggTillNyttKort")
                                            }
                                        </Label>
                                    </UnstyledButton>
                                </div>
                        }
                        {
                            this.renderDeleteCategoryDialog()
                        }
                    </div>
                )}
            </Draggable>

        );
    }
}

export default connect()(Category);
