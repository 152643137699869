import { Shimmer, ShimmerElementsGroup, ShimmerElementType } from "@fluentui/react";
import { AxiosResponse } from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { RouteChildrenProps, withRouter } from "react-router";
import { findByFaName } from "src/admin/views/AdminTeaserboxItems";
import { localize } from "src/l10n";
import { MarketplaceWidget } from "src/marketplace";
import api from "src/spintr/SpintrApi";
import { SpintrTypes } from "src/typings";
import { Label, SpintrUser, UnstyledButton } from "src/ui";
import TranslateButton from "src/ui/components/TranslateButton";
import { decodeHtmlEntities, getInitialsFromString, mixpanelTrack } from "src/utils";
import { startDeliverTrack } from "src/utils/spintrStartDeliverfunctions";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import "./TeaserBox.scss";
import InformationFeedEntry from "src/spintr/components/InformationFeed/InformationFeedEntry";

export interface IProps extends RouteChildrenProps {
    item?: any;
    id?: number;
    allTeaserBoxes: any[];
    onClick?: any;
    showBorder?: boolean;
    isSelected?: boolean;
    isSelectable?: boolean;
    onFetchError?: () => void;
}

interface State {
    isLoading: boolean;
    error?: boolean;
    item?: any;
    translatedTexts?: object;
}

class TeaserBox extends Component<IProps, State> {
    constructor(props) {
        super(props);

        let item = undefined;

        if (props.id) {
            item = props.allTeaserBoxes.find((tb: any) => tb.id === props.id);
        }

        this.state = {
            isLoading: false,
            item,
            translatedTexts: {
                [SpintrTypes.TranslateType.Title]: props.item?.blogPost?.headline,
                [SpintrTypes.TranslateType.Content]: props.item?.blogPost?.text,
            }
        };
    }

    componentDidMount(): void {
        if (!!this.props.id && !this.state.item) {
            this.fetch();
        }
    }

    fetch = () => {
        this.setState({
            isLoading: true
        }, () => {
            api.get<Spintr.ITeaserBoxItem>(`/api/v1/teaserbox/${this.props.id}`).then((response: AxiosResponse) => {
                this.setState({
                    item: response.data,
                    isLoading: false,
                    translatedTexts: {
                        [SpintrTypes.TranslateType.Title]: response.data?.blogPost?.headline,
                        [SpintrTypes.TranslateType.Content]: response.data?.blogPost?.text,
                    },
                });
            }).catch(() => {
                this.props.onFetchError?.();
            });
        });
    };

    getItem() {
        return !!this.props.item ?
            this.props.item :
            this.state.item;
    }

    getShimmerElements() {
        return (
            <ShimmerElementsGroup
                flexWrap
                width="100%"
                shimmerElements={[
                    { type: ShimmerElementType.line, width: '50px', height: 10, verticalAlign: "center" },
                ]}
            />
        );
    }

    protected translate = (text: string, key: string) => {
        this.setState((prevState) => ({
            translatedTexts: {
                ...prevState.translatedTexts,
                [key]: text,
            },
        }));
    }

    renderBlog(item: any) {
        let classes = [
            "blog-teaser-box-content",
            "start-page-flexible-padding"
        ];

        if (!!item.blogPost.image) {
            classes.push("hasImage");
        }

        const transformedItem = {
            ...item.blogPost,
            blog: item.blog.name,
            date: item.blogPost.publishDate,
            isBlogPost: true,
            title: item.blogPost.headline,
            //content: item.blogPost.text,
            imageUrl: item.blogPost.image
        };
        
        return (
            <div className={classes.join(" ")}>
                <InformationFeedEntry
                    key={item.id}
                    entry={transformedItem} />
            </div>
        )
    }

    renderDataWidget(item: any) {
        return (
            <div className="teaser-box DataWidget">
                <MarketplaceWidget id={item.dataWidgetId || item.widgetConfigurationId} />
            </div>
        )
    }

    renderShimmer() {
        return (
            <div className="teaser-box" style={{
                width: "100%",
                height: 125,
                flexGrow: 1,
                textAlign: "center",
                backgroundColor: "white",
                alignContent: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>
                <Shimmer
                    style={{ width: "30%" }}
                    className="ShimmerLoader"
                    customElementsGroup={this.getShimmerElements()}
                />
            </div>
        )
    }

    renderIframe(item: any) {
        return (
            <iframe
                src={item.url}
                style={{ width: "100%" }}
                scrolling={item.showScroll ? "yes" : "no"}
                height={item.height || 145}
                title={localize("VisarUppInnehallFran").replace("{0}", item.url)}
                frameBorder="0"
            />
        )
    }

    renderSJWidget(item: any) {
        return (
            <div className="teaser-box-inner">
                <img className="teaser-box-image" src={"/images/sj.jpg"} />
            </div>
        )
    }

    renderTeaserBox(item: any) {
        let iconPath = "";
        let hasIconBackgroundColor = false;

        if (item.type === SpintrTypes.TeaserBoxType.HeadingAndIcon && !!item.icon) {
            const foundIcon = findByFaName(item.icon);

            if (!!foundIcon) {
                iconPath = foundIcon.visage2Icon;
                hasIconBackgroundColor = !!item.iconBackgroundColor && item.iconBackgroundColor !== item.backgroundColor;
            }
        }

        return (
            <div
                className="teaser-box-inner"
                style={{
                    backgroundColor: item.backgroundColor,
                    backgroundImage: `url("${item.backgroundImageUrl}")`,
                    backgroundPosition: "center"
                }}>
                {!!iconPath && (
                    <div
                        className={"teaser-box-icon-wrapper" + (hasIconBackgroundColor ? " has-icon-bg" : "")}
                        style={{
                            backgroundColor: item.iconBackgroundColor,
                        }}>
                        <Visage2Icon
                            className="fa-icon"
                            icon={iconPath}
                            hexColor={item.iconColor ? item.iconColor : "black"} />
                    </div>
                )}
                {!!item.image && (
                    <img className="teaser-box-image" src={item.image} />
                )}
                {!!item.text && item.type !== SpintrTypes.TeaserBoxType.ImageOnly && (
                    <Label
                        className="main-text"
                        size="body-1"
                        weight="medium"
                        style={{
                            color: item.textColor
                        }}>
                        {item.text}
                    </Label>
                )}
                {!!item.subText && (
                    <Label
                        className="sub-text"
                        size="body-2"
                        style={{
                            color: item.textColor
                        }}>
                        {item.subText}
                    </Label>
                )}
            </div>
        )
    }

    renderContent(item: any) {
        if (this.state.isLoading || !item) {
            return this.renderShimmer();
        }

        if (!!item.blogPost) {
            return this.renderBlog(item);
        }

        if (item.type === SpintrTypes.TeaserBoxType.DataWidget && (item.dataWidgetId || item.widgetConfigurationId)) {
            return this.renderDataWidget(item);
        }

        if (item.type === SpintrTypes.TeaserBoxType.Iframe) {
            return this.renderIframe(item);
        }

        if (item.type === SpintrTypes.TeaserBoxType.SjWidget) {
            return this.renderSJWidget(item);
        }

        return this.renderTeaserBox(item);
    }

    trackClick() {
        if (window.location.pathname === "/") {
            mixpanelTrack("Clicked teaser box");
            startDeliverTrack(SpintrTypes.InsightsType.ClickedTeaserbox);
        }
    }

    getUrl(item: any) {
        let url = "";

        if (!item) {
            return url;
        }

        if (item.type === SpintrTypes.TeaserBoxType.DataWidget) {
            return item.url;
        }

        if (item.blogPost) {
            url = "/goto/" + item.blogPost.id;
        } else if (item.targetUberId) {
            url = "/goto/" + item.targetUberId;
        } else if (item.url) {
            url = item.url;
        }

        if (!!url && url.indexOf(window.location.origin) === 0) {
            url = url.substring(window.location.origin.length);
        }

        return url;
    }

    onClick(e: any) {
        if (this.props.onClick) {
            return this.props.onClick();
        }

        if (this.props.isSelectable) {
            return;
        }

        this.trackClick();

        const item = this.getItem();
        let url = this.getUrl(item);

        if (!url) {
            return;
        }

        const isApiLink = url.indexOf("/api/") === 0;
        const isRelativeLink = url.indexOf("/") === 0;
        const openInCurrentTab = !isApiLink && isRelativeLink;

        if (openInCurrentTab) {
            const queryStringIndex = url.indexOf("?");
            let search = "";

            if (queryStringIndex > -1) {
                search = url.substring(queryStringIndex);
                url = url.substring(0, queryStringIndex);
            }

            const hashIndex = url.indexOf("#");
            let hash = "";

            if (hashIndex > -1) {
                hash = url.substring(hashIndex);
                url = url.substring(0, hashIndex);
            }

            this.props.history.push({ pathname: url, search, hash });
        } else {
            window.open(url, "_blank");
        }
    }

    isClickable = (item: any) => {
        if (!item) {
            return false;
        }

        const url = this.getUrl(item);

        return !!url ||
            this.props.isSelectable ||
            item.type == SpintrTypes.TeaserBoxType.SjWidget;
    }

    render() {
        const item = this.getItem();

        if (!item) {
            return null;
        }

        let classes = ["teaser-box"];

        if (item.type !== SpintrTypes.TeaserBoxType.Blog) {
            classes.push("visage-box-shadow");
        }

        if (!!this.props.showBorder) {
            classes.push("with-border");
        }

        if (!!this.props.isSelected) {
            classes.push("is-selected");
        }

        if (!!this.props.isSelectable) {
            classes.push("is-selectable");
        }

        if (!!item && (item.isDeleted || item.isDraft)) {
            return null;
        }

        return (
            <UnstyledButton
                disabled={!this.isClickable(item)}
                className={classes.join(" ")}
                onClick={this.onClick.bind(this)}>
                {this.renderContent(item)}
            </UnstyledButton>
        )
    }
}

const mapStateToProps = (state: Spintr.AppState, props) => ({
    ...props,
    allTeaserBoxes: state.teaserboxes.items
});

// @ts-ignore
export default withRouter(connect(mapStateToProps)(TeaserBox));
