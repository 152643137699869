import { DefaultButton, Modal, PrimaryButton, Stack, TextField } from "@fluentui/react";
import { AxiosError, AxiosResponse } from "axios";
import React, { FunctionComponent, useCallback, useMemo, useState } from "react";
import { localize } from "src/l10n";
import api from "src/spintr/SpintrApi";
import { Label, Loader } from "src/ui";
import { FormControl, MandatoryText } from "src/ui/components/Forms";
import ErrorMessagebar from "src/ui/components/Messagebars/ErrorMessagebar";
import PopupHeader from "src/ui/components/PopupHeader";
import { validateRequiredTextField } from "src/utils";

interface IProps {
    isOpen: boolean;

    onClosing: (saved?: boolean) => void;
}

interface ITicketModel {
    subject: string;
    body: string;
}

interface ILocalization {
    body: string;
    cancel: string;
    close: string;
    header: string;
    link: string;
    save: string;
    saved: string;
    subject: string;
}

const initialModel: ITicketModel = {
    subject: "",
    body: "",
};

type CreateTickerHandler =
    (model: ITicketModel) => Promise<AxiosResponse<Spintr.IZendeskTicket>>;
const createSupportTicket: CreateTickerHandler =
    (model) => api.post<Spintr.IZendeskTicket>(
        "/api/v1/zendesk/tickets",
        model,
    );

const renderForm = (
    dismissErrors: () => void,
    errors: string[],
    isDirty: boolean,
    localization: ILocalization,
    model: ITicketModel,
    onClosing: () => void,
    onSaving: () => void,
    onTextChange: (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
) => (
    <div className="form-wrapper">
            <MandatoryText />
            {errors.length === 0 ? null : (
                <ErrorMessagebar
                    errorList={errors}
                    onDismiss={dismissErrors}
                />
            )}
            <form>
                <FormControl>
                    <TextField
                        label={localization.subject}
                        name="subject"
                        placeholder={localization.subject}
                        value={model.subject}
                        onChange={onTextChange}
                        required={true}
                        aria-required={true}
                        validateOnFocusIn={true}
                        validateOnFocusOut={true}
                        validateOnLoad={isDirty}
                        onGetErrorMessage={validateRequiredTextField}
                    />
                </FormControl>
                <FormControl>
                    <TextField
                        label={localization.body}
                        name="body"
                        placeholder={localization.body}
                        value={model.body}
                        onChange={onTextChange}
                        required={true}
                        multiline={true}
                        aria-required={true}
                        validateOnFocusIn={true}
                        validateOnFocusOut={true}
                        validateOnLoad={isDirty}
                        onGetErrorMessage={validateRequiredTextField}
                    />
                </FormControl>
                <Stack
                    horizontal={true}
                    horizontalAlign="end"
                    tokens={{ childrenGap: 6 }}
                >
                    <DefaultButton onClick={onClosing} text={localization.cancel} />
                    <PrimaryButton onClick={onSaving} text={localization.save} />
                </Stack>
            </form>
        </div>
);

const renderTicket = (
    localization: ILocalization,
    ticket: Spintr.IZendeskTicket,
    onClosing: () => void,
) => (
    <div className="ticket-information">
        <Label
            as="div"
            className="success-text"
        >
            {localization.saved}
        </Label>
        <Stack
            horizontal={true}
            horizontalAlign="end"
            tokens={{ childrenGap: 6 }}
        >
            <DefaultButton
                as="a"
                href={`https://support.spintr.me/hc/sv/requests/${ticket.id}`}
                rel="noopener noreferrer"
                target="blank"
            >
                {localization.link}
            </DefaultButton>
            <PrimaryButton onClick={onClosing} text={localization.close} />
        </Stack>
    </div>
)

const TicketFormModal: FunctionComponent<IProps> = ({ isOpen, onClosing }) => {
    const [errors, setErrors] = useState<string[]>([]);
    const [isLoading, setLoading] = useState(false);
    const [isDirty, setDirty] = useState(false);
    const [model, setModel] = useState(initialModel);
    const [ticket, setTicket] = useState<Spintr.IZendeskTicket | undefined>();

    const localization = useMemo<ILocalization>(
        () => ({
            body: localize("Beskrivning"),
            cancel: localize("Avbryt"),
            close: localize("Stang"),
            header: localize("CREATE_SUPPORT_TICKET"),
            link: localize("SHOW_IN_SUPPORT_SYSTEM"),
            save: localize("Spara"),
            saved: localize("SUPPORT_TICKET_SAVED"),
            subject: localize("Amne"),
        }),
        [],
    );

    const dismissErrors = useCallback(() => setErrors([]), [setErrors]);

    const onTextChange = useCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const { name, value } = event.currentTarget;
            
            if (name !== "subject" && name !== "body") {
                return;
            }

            setModel((current) => ({
                ...current,
                [name]: value,
            }));
            setDirty(true);
        },
        [setModel],
    );

    const handleApiError = useCallback(
        (error: AxiosError) => {
            if (error?.response?.status === 500) {
                setErrors([
                    localize("TeknisktFel"),
                ]);
            }
        },
        [],
    );

    const onSaving = useCallback(
        async () => {
            if (isLoading) {
                return;
            }

            setDirty(true);
            setLoading(true);

            try {
                const response = await createSupportTicket(model)

                setTicket(response.data);
            } catch (err) {
                const reason = err as AxiosError;
                if (reason && reason.isAxiosError) {
                    handleApiError(reason);
                }
            }

            setLoading(false);
        },
        [isLoading, handleApiError, model],
    );

    const onCloseSaved = useCallback(
        () => onClosing(true),
        [onClosing],
    );

    const onDismiss = useCallback(
        () => onClosing(false),
        [onClosing],
    );

    return (
        <Modal
            allowTouchBodyScroll={true}
            className="spintr-modal modalWithPopupHeader"
            containerClassName="TicketFormModal"
            isBlocking={true}
            isOpen={isOpen}
            onDismiss={onDismiss}
        >
            <PopupHeader
                text={localization.header}
                onClose={ticket ? onCloseSaved : onDismiss}
            />
            {isLoading ? <Loader /> : (
                ticket
                    ? renderTicket(
                        localization,
                        ticket,
                        onCloseSaved,
                    )
                    : renderForm(
                        dismissErrors,
                        errors,
                        isDirty,
                        localization,
                        model,
                        onDismiss,
                        onSaving,
                        onTextChange
                    )
            )}
        </Modal>
    )
};

export default TicketFormModal;