import { Map } from "immutable";

type SpintrThemeKey = keyof Spintr.ISpintrTheme;

export function getSpintrTheme(instance: Map<string, any>): Spintr.ISpintrTheme {
    const theme = instance.get("theme") as Spintr.ISpintrTheme;
    if (theme) {
        return {
            ...theme,
            ...[...Object.keys(theme) as SpintrThemeKey[]]
                .filter((key) => key.toLowerCase().indexOf("color") > -1)
                .filter((key) => typeof theme[key] === "string")
                .reduce((obj, key) => {
                    obj[key] = "#" + theme[key];
                    return obj;
                }, {}),
        };
    }

    return {
        id: -1,
        name: "",
        description: "",
        deleted: false,
        created: new Date(),
        lastChanged: new Date(),
        backgroundPosition:      instance.get("backgroundPosition", 0) as number,
        backgroundFixed:         instance.get("backgroundFixed", false) as boolean,
        setBackgroundImageWhite: instance.get("setBackgroundImageWhite", false) as boolean,
        fontUrl:                 instance.get("fontUrl", "") as string,
        bodyFontUrl:             instance.get("bodyFontUrl", "") as string,
        bodyFontName:            instance.get("bodyFontName", "") as string,
        menuFontUrl:             instance.get("menuFontUrl", "") as string,
        menuFontName:            instance.get("menuFontName", "") as string,
        primaryColor:            instance.get("primaryColor", "") as string,
        accentColor:             instance.get("accentColor", "") as string,
        backgroundColor:         instance.get("backgroundColor", "") as string,
        headerColor:             instance.get("headerColor", "") as string,
        logoUrl:                 instance.get("logoUrl", "") as string,
        useColorHeader:          instance.get("useColorHeader", false) as boolean,
        headerHeight:            instance.get("headerHeight", 60) as number,
        useUppercaseMainMenu:    instance.get("useUppercaseMainMenu", false) as boolean,
        backgroundUrl:           instance.get("backgroundUrl", "") as string,
        useSecondaryColorHeader: instance.get("useSecondaryColorHeader", false) as boolean,
        searchFieldBackgroundColor:    instance.get("searchFieldBackgroundColor", "") as string,
        headerSecondaryColor:    instance.get("headerSecondaryColor", "") as string,
        backgroundType:          instance.get("backgroundType", 0) as number,
        gradientType:            instance.get("gradientType", 0) as number,
    };
}