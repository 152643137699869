import { AxiosResponse } from "axios";
import {
    ComboBox,
    IComboBox,
    IComboBoxOption,
    Modal,
    PrimaryButton,
    SearchBox,
    Stack,
    TagItemSuggestion,
    TextField
} from '@fluentui/react/lib';
import React, { Component, RefObject } from "react";
import { connect } from 'react-redux';
import { Action } from "redux";
import { localize } from "src/l10n";
import { MarketplaceWidget, WidgetSelectionPopup } from 'src/marketplace';
import { setConfirmPopup } from "src/popups/actions";
import FilePicker from 'src/spintr/components/FilePicker';
import { IApplicationState } from 'src/spintr/reducer';
import { SpintrTypes } from 'src/typings';
import { ContentImageViewerAndSelector, DivWithHoverActions, Icon, Label, Loader } from "src/ui";
import CoverImageButton from 'src/ui/components/Buttons/CoverImageButton/CoverImageButton';
import CustomDialog from "src/ui/components/Dialogs/CustomDialog";
import TinyEditorComponent from "src/ui/components/Tiny/TinyEditorComponent";
import { Style } from "src/ui/helpers";
import { uniqueId, validateRequiredTextField } from "src/utils";
import stripHtml from "src/utils/stripHtml";
import { FormControl, FormTokenizedObjectInput } from '../../Forms';
import "./EditableContentSections.scss";
import api from "src/spintr/SpintrApi";
import classNames from "classnames";
import TeaserBoxSelectionPopup from "../../TeaserBoxSelectionPopup";
import { TeaserBox } from "src/teaser-box";
import ExpandableField from "../../ExpandableField";
import "./links.scss"
import SpintrSearch from "../../SpintrList/SpintrSearch";
import { Icon as FabricIcon } from "@fluentui/react";
import UserList from "../../UserList";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import { ProductsColumn } from "./ProductsColumn";
import { UberContentColumn, UberContentRow } from "../UberContent.types";

interface IProps {
    contentRow: UberContentRow;
    enableSharePointListSearch: boolean;
    enableCrocus?: boolean;
    enableDirekten?: boolean;
    enableMediaflow?: boolean;
    enableUnsplash?: boolean;
    enableTaxiFiles?: boolean;
    enabledColumnTypes?: SpintrTypes.UberContentSectionType[];
    instance?: any;
    ref?: any;
    isSmallViewMode: boolean;
    handleProps?: any;
    dispatch: (action: Action) => void;
    apps?: any;
    enableExtendedPageBlockTypes?: boolean;
}

interface IState {
    contentRow: any,
    showFormSelectorDialog: boolean,
    showCalendarSelectorDialog: boolean,
    isLoadingForms: boolean,
    isLoadingCalendars,
    columnTmpIdToAddFormTo?: any,
    columnTmpIdToAddWidgetTo?: any,
    columnTmpIdToAddFileTo?: any, // could possible merge these three?
    columnTmpIdToAddTeaserBoxTo?: any // four
    columnTmpIdToAddCalendarTo?: any // five
    forms?: any,
    calendars?: any,
    selectedForm?: any
    selectedCalendar?: any
    showFilePicker: boolean;
    showWidgetSelector: boolean;
    showTeaserBoxSelector: boolean;
    enableFieldValidation?: boolean;
    isKeyboardNavigation?: boolean;
    expandableTitle?: string;
    searchText: string;
}

class EditableContentSectionsRow extends Component<IProps, IState> {
    protected observer: MutationObserver;
    protected typeSelector: RefObject<HTMLDivElement>;
    protected titleRefs;

    private contentTypes = [{
        typeId: SpintrTypes.UberContentSectionType.Text,
        render: (cc) => {
            if (cc.state === SpintrTypes.UberContentSectionState.IsDisplayed) {
                return (
                    <div onDoubleClick={() => {
                        if (window.getSelection) {
                            // prevent weird selection issue in chrome when double clicking on text block
                            window.getSelection().empty();
                        }

                        this.setState({
                            contentRow: {
                                ...this.state.contentRow,
                                uberContentColumns: this.state.contentRow.uberContentColumns.map(contentColumn => {
                                    return {
                                        ...contentColumn,
                                        state: contentColumn.tmpId === cc.tmpId ?
                                            SpintrTypes.UberContentSectionState.IsEditing :
                                            contentColumn.state
                                    }
                                })
                            }
                        }, this.postUpdatedRowToParent);
                    }}>
                        <div style={{
                            minHeight: "1em",
                            wordBreak: "break-word"
                        }}>
                            <div className="TinyFormattedContent" dangerouslySetInnerHTML={{ __html: cc.content }} />
                        </div>
                    </div>
                )
            } else if (cc.state === SpintrTypes.UberContentSectionState.IsEditing) {
                return (
                    <div>
                        <TinyEditorComponent
                            id={cc.tmpId}
                            content={cc.content}
                            enableMediaflow={this.props.enableMediaflow}
                            enableUnsplash={this.props.enableUnsplash}
                            displaySaveButton={true}
                            saveOnClickOutside={true}
                            onSave={(content) => {
                                this.setState({
                                    enableFieldValidation: true
                                });
                                this.setState({
                                    enableFieldValidation: true
                                });
                                this.setState({
                                    contentRow: {
                                        ...this.state.contentRow,
                                        uberContentColumns: this.state.contentRow.uberContentColumns.map(contentColumn => {
                                            if (contentColumn.tmpId === cc.tmpId) {
                                                return {
                                                    ...contentColumn,
                                                    state: SpintrTypes.UberContentSectionState.IsDisplayed,
                                                    content
                                                }
                                            } else {
                                                return contentColumn;
                                            }
                                        })
                                    }
                                }, this.postUpdatedRowToParent);
                            }}
                            onChange={(content) => {
                                this.setState({
                                    contentRow: {
                                        ...this.state.contentRow,
                                        uberContentColumns: this.state.contentRow.uberContentColumns.map(contentColumn => {
                                            if (contentColumn.tmpId === cc.tmpId) {
                                                return {
                                                    ...contentColumn,
                                                    content
                                                }
                                            } else {
                                                return contentColumn;
                                            }
                                        })
                                    }
                                }, this.postUpdatedRowToParent);
                            }} />
                    </div>
                )
            } else if (cc.state === SpintrTypes.UberContentSectionState.IsSelectingType ||
                cc.state === SpintrTypes.UberContentSectionState.IsDisplayingPlusSign) {
                return (
                    <div>
                        {
                            this.renderColumnPlusSign(cc)
                        }
                        {
                            cc.state === SpintrTypes.UberContentSectionState.IsSelectingType ?
                                this.renderColumnTypeSelector(cc) :
                                null
                        }
                    </div>
                )
            }
        }
    }, {
        typeId: SpintrTypes.UberContentSectionType.Preamble,
        render: (cc) => {
            if (cc.state === SpintrTypes.UberContentSectionState.IsSelectingType ||
                cc.state === SpintrTypes.UberContentSectionState.IsDisplayingPlusSign) {
                return (
                    <div>
                        {
                            this.renderColumnPlusSign(cc)
                        }
                        {
                            cc.state === SpintrTypes.UberContentSectionState.IsSelectingType ?
                                this.renderColumnTypeSelector(cc) :
                                null
                        }
                    </div>
                )
            }

            return (
                <div className="EditPreamble" style={{
                    backgroundColor: Style.getHexFromSpintrColor("visageMidBlueBackground"),
                    borderRadius: "12px",
                    padding: Style.getSpacingStr(3)
                }}>
                    <form>
                        <FormControl>
                            <TextField
                                label={localize("Ingress")}
                                multiline
                                value={cc.content}
                                onChange={(_event, content) => {
                                    this.setState({
                                        contentRow: {
                                            ...this.state.contentRow,
                                            uberContentColumns: this.state.contentRow.uberContentColumns.map(contentColumn => {
                                                if (contentColumn.tmpId === cc.tmpId) {
                                                    return {
                                                        ...contentColumn,
                                                        content
                                                    }
                                                } else {
                                                    return contentColumn;
                                                }
                                            })
                                        }
                                    }, this.postUpdatedRowToParent);
                            }} />
                        </FormControl>
                    </form>
                </div>
            )
        }
    },
    {
        typeId: SpintrTypes.UberContentSectionType.Image,
        render: (cc) => {
            if (cc.state === SpintrTypes.UberContentSectionState.IsEditing ||
                cc.state === SpintrTypes.UberContentSectionState.IsDisplayed) {

                let imageUrl;

                if (cc.content[0] === "{")
                {
                    imageUrl = JSON.parse(cc.content).content;
                } else {
                    imageUrl = cc.content;
                }

                return (
                    <ContentImageViewerAndSelector
                        customRequestUrl={"/api/uploads/redactor/image"}
                        imageUrl={imageUrl}
                        autoHeight={true}
                        placeholderString={localize("ValjBild")}
                        editMode={true}
                        displayFileSelector={!imageUrl && cc.isNew}
                        enableMediaflow={this.props.enableMediaflow}
                        enableUnsplash={this.props.enableUnsplash}

                        renderSizeString={() => {
                            let columnCount = 1;
                            if(this.state.contentRow.uberContentColumns){
                                columnCount = this.state.contentRow.uberContentColumns.length;
                            }
                            
                            let width;
        
                            if (columnCount === 1) {
                                width = 930;
                            } else if (columnCount === 2) {
                                width = 460;
                            } else {
                                width = 300;
                            }
    
                            return localize("OptimalBildBredd") + ": " + width + "px"
                        }}

                        onChange={result => {
                            let newContentRow = { ...this.state.contentRow };

                            for (let contentColumn of newContentRow.uberContentColumns) {
                                if (contentColumn.tmpId === cc.tmpId) {
                                    if (result.externalMediaflowId) {
                                        contentColumn.content = JSON.stringify({
                                            content: result.thumbnailUrl,
                                            externalMediaflowId: result.externalMediaflowId,
                                        });
                                    } else if (result.externalAuthorName) {
                                        contentColumn.content = JSON.stringify({
                                            content: result.thumbnailUrl,
                                            externalAuthorName: result.externalAuthorName,
                                            externalAuthorUrl: result.externalAuthorUrl,
                                            externalPhotoUrl: result.externalPhotoUrl,
                                        });
                                    } else {
                                        contentColumn.content = result.thumbnailUrl;
                                    }

                                    contentColumn.state = result.thumbnailUrl || result.ticket ? 0 : 1;

                                    if (result.ticket) {
                                        contentColumn.ticket = {
                                            remove: false,
                                            ticket: result.ticket
                                        };
                                    }
                                }
                            }

                            this.setState({
                                contentRow: newContentRow
                            }, this.postUpdatedRowToParent);
                        }}
                    />
                );
            } else if (cc.state === SpintrTypes.UberContentSectionState.IsSelectingType ||
                cc.state === SpintrTypes.UberContentSectionState.IsDisplayingPlusSign) {
                return (
                    <div>
                        {
                            this.renderColumnPlusSign(cc)
                        }
                        {
                            cc.state === SpintrTypes.UberContentSectionState.IsSelectingType ?
                                this.renderColumnTypeSelector(cc) :
                                null
                        }
                    </div>
                )
            }
        }
    }, {
        typeId: SpintrTypes.UberContentSectionType.IFrame,
        render: (cc) => {
            if (cc.state === SpintrTypes.UberContentSectionState.IsDisplayed) {
                return (
                    <div className="contentSectionItem">
                        <CoverImageButton
                            icon={"box-1"}
                            placeholder={localize("SELECTED_IFRAME")}
                            subPlaceholder={cc.content.split('src="')[1].split('"')[0]} />
                    </div>
                )
            } else if (cc.state === SpintrTypes.UberContentSectionState.IsEditing) {
                const saveIframe = () => {
                    this.setState({
                        contentRow: {
                            ...this.state.contentRow,
                            uberContentColumns: this.state.contentRow.uberContentColumns.map(contentColumn => {
                                if (contentColumn.tmpId === cc.tmpId) {
                                    return {
                                        ...contentColumn,
                                        state: SpintrTypes.UberContentSectionState.IsDisplayed,
                                        content: `<iframe width="100%" ${cc.iframeHeight ? `height=${cc.iframeHeight}` : ``
                                            } frameborder="0" src="${cc.iframeUrl}"></iframe>`,
                                    }
                                } else {
                                    return contentColumn;
                                }
                            })
                        }
                    }, this.postUpdatedRowToParent);
                }

                return (
                    <div className="EditableContentSectionsRow-IframeForm" style={{
                        backgroundColor: Style.getHexFromSpintrColor("visageMidBlueBackground"),
                        minHeight: 150,
                        borderRadius: "12px",
                        padding: Style.getSpacingStr(3)
                    }}>
                        <form>
                            <FormControl>
                                <TextField
                                    className="textField"
                                    label={localize("Url")}
                                    autoFocus
                                    value={cc.iframeUrl}
                                    onChange={this.handleIframeUrlChange}
                                    name={cc.tmpId}
                                    required
                                    aria-required
                                    validateOnFocusIn
                                    validateOnFocusOut
                                    validateOnLoad={!!this.state.enableFieldValidation}
                                    onGetErrorMessage={validateRequiredTextField}
                                />
                            </FormControl>
                            <FormControl>
                                <TextField
                                    className="textField"
                                    label={localize("Hojd")}
                                    value={typeof (cc.iframeHeight) !== undefined ? cc.iframeHeight : 150}
                                    name={cc.tmpId}
                                    onChange={this.handleIframeHeightChange}
                                />
                            </FormControl>
                        </form>
                        <Stack horizontal={true} horizontalAlign="end">
                            <PrimaryButton
                                disabled={!cc.iframeUrl || cc.iframeUrl.length === 0}
                                onClick={() => {
                                    this.setState({
                                        enableFieldValidation: true
                                    });
                                    saveIframe();
                                }}
                                text={localize("Spara")}
                            />
                        </Stack>
                    </div>
                );
            } else if (cc.state === SpintrTypes.UberContentSectionState.IsSelectingType ||
                cc.state === SpintrTypes.UberContentSectionState.IsDisplayingPlusSign) {
                return (
                    <div>
                        {
                            this.renderColumnPlusSign(cc)
                        }
                        {
                            cc.state === SpintrTypes.UberContentSectionState.IsSelectingType ?
                                this.renderColumnTypeSelector(cc) :
                                null
                        }
                    </div>
                )
            }
        }
    }, {
        typeId: SpintrTypes.UberContentSectionType.Graph,
        render: (cc) => {
            return null;
        }
    }, {
        typeId: SpintrTypes.UberContentSectionType.Form,
        render: (cc) => {
            if (cc.state === SpintrTypes.UberContentSectionState.IsDisplayed) {
                const inlineFormPattern = /(?:class="redactorInlineFolder)\s([a-z]*-[0-9]*)">([A-Za-zÀ-ÖØ-öø-ÿ\d\s]*)/igm;

                let match, formId, formName;

                while ((match = inlineFormPattern.exec(cc.content))) {
                    formId = parseInt(match[1].match(/(\d+)/)[0]);

                    if (isNaN(formId)) {
                        continue;
                    }

                    formName = match[2];
                };

                return (
                    <div className="contentSectionItem">
                        <CoverImageButton
                            icon={"row-vertical"}
                            placeholder={localize("SELECTED_FORM") + ":"}
                            subPlaceholder={formName} />
                    </div>
                )
            } else if (cc.state === SpintrTypes.UberContentSectionState.IsEditing) {
                return (
                    <div className="contentSectionItem">
                        <CoverImageButton
                            icon={"row-vertical"}
                            placeholder={localize("SELECT_FORM")}
                            onClick={() => {
                                this.setState({
                                    showFormSelectorDialog: true,
                                    isLoadingForms: true,
                                    columnTmpIdToAddFormTo: cc.tmpId
                                }, () => {
                                    api.get(`/api/v1/survey/list`, {
                                        params: {
                                            take: 9999,
                                            orderByColumn: "name"
                                        }
                                    }).then((response: AxiosResponse) => {
                                        let forms = response.data.filter(f => !f.isDeleted).sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

                                        this.setState({
                                            isLoadingForms: false,
                                            forms,
                                            selectedForm: forms[0]
                                        });
                                    });
                                });
                            }} />
                    </div>
                );
            } else if (cc.state === SpintrTypes.UberContentSectionState.IsSelectingType ||
                cc.state === SpintrTypes.UberContentSectionState.IsDisplayingPlusSign) {
                return (
                    <div>
                        {
                            this.renderColumnPlusSign(cc)
                        }
                        {
                            cc.state === SpintrTypes.UberContentSectionState.IsSelectingType ?
                                this.renderColumnTypeSelector(cc) :
                                null
                        }
                    </div>
                )
            }
        }
    }, {
        typeId: SpintrTypes.UberContentSectionType.File,
        render: (cc) => {
            if (cc.state === SpintrTypes.UberContentSectionState.IsEditing && cc.content) {
                // if editing name
                const inlineFolderPattern = /(?:\<(?:span|font|p)[^\<]*?\>\[@Folder:([0-9]+)@\]\<\/(?:span|font|p)\>(.+?)\<(?:span|font|p)[^\<]*?\>\[\/@Folder@\]\<\/(?:span|font|p)\>)/gim;
                const inlineSharePointListSearchPattern = /<div><div><span class="redactorInlineFolder sharePoint">\[@SharePointListSearch:([0-9]+)@\]<\/span><\/div>(.+?)<div><span class="redactorInlineFolder">(.+?)<\/span><\/div><\/div>/gim;

                const folderMatches = inlineFolderPattern.exec(cc.content);
                const sharePointListSearchMatches = inlineSharePointListSearchPattern.exec(cc.content);

                const name =
                    cc.name ||
                    (folderMatches
                        ? stripHtml(folderMatches[2])
                        : sharePointListSearchMatches
                            ? stripHtml(sharePointListSearchMatches[2])
                            : "");

                const id = folderMatches
                    ? folderMatches[1]
                    : sharePointListSearchMatches
                        ? sharePointListSearchMatches[1]
                        : 0;

                return (
                    <div
                        className="EditableContentSectionsRow-IframeForm"
                        style={{
                            backgroundColor: Style.getHexFromSpintrColor("visageMidBlueBackground"),
                            padding: Style.getSpacingStr(3),
                        }}
                    >
                        <form>
                            <FormControl>
                                <TextField
                                    className="textField"
                                    label={localize("Namn")}
                                    autoFocus
                                    value={name}
                                    onChange={this.handleAttachedFileNameChange}
                                    name={cc.tmpId}
                                    required
                                    aria-required
                                    validateOnFocusIn
                                    validateOnFocusOut
                                    validateOnLoad={!!this.state.enableFieldValidation}
                                    onGetErrorMessage={validateRequiredTextField}
                                    onKeyPress={(event) => {
                                        if (event.key === "Enter") {
                                            event.stopPropagation(); // Prevents page from reloading
                                            event.preventDefault();
                                            this.saveAttachedFileName(cc, name, id, folderMatches, sharePointListSearchMatches);
                                        }}}
                                />
                            </FormControl>
                        </form>
                        <Stack horizontal={true} horizontalAlign="end">
                            <PrimaryButton
                                disabled={!name || name.length === 0}
                                onClick={() => {
                                    this.setState({
                                        enableFieldValidation: true
                                    });
                                    this.setState({
                                        enableFieldValidation: true
                                    });
                                    this.saveAttachedFileName(cc, name, id, folderMatches, sharePointListSearchMatches);
                                }}
                                text={localize("Spara")}
                            />
                        </Stack>
                    </div>
                );
            } else if (cc.state === SpintrTypes.UberContentSectionState.IsSelectingType ||
                cc.state === SpintrTypes.UberContentSectionState.IsDisplayingPlusSign) {
                return (
                    <div>
                        {
                            this.renderColumnPlusSign(cc)
                        }
                        {
                            cc.state === SpintrTypes.UberContentSectionState.IsSelectingType ?
                                this.renderColumnTypeSelector(cc) :
                                null
                        }
                    </div>
                )
            }

            const inlineFilePattern = /<div><div><span class="redactorSingleFile(?: external)?">(.+?)<\/span><\/div>(.+?)<div><span class="redactorSingleFile(?: external)?">(.+?)<\/span><\/div><\/div>/gim;
            const inlineFolderPattern = /<div><div><span class="redactorInlineFolder(?: external)?">(.+?)<\/span><\/div>(.+?)<div><span class="redactorInlineFolder(?: external)?">(.+?)<\/span><\/div><\/div>/gim;
            const inlineSharePointSearch = /<div><div><span class="redactorInlineFolder sharePoint">(.+?)<\/span><\/div>(.+?)<div><span class="redactorInlineFolder">(.+?)<\/span><\/div><\/div>/gim;

            const fileMatches = inlineFilePattern.exec(cc.content);
            const folderMatches = inlineFolderPattern.exec(cc.content);
            const sharePointSearchMatches = inlineSharePointSearch.exec(cc.content);

            let name: string;

            if (fileMatches?.length >= 3) {
                name = fileMatches[2];
            } else if (folderMatches?.length >= 3) {
                name = folderMatches[2];
            } else if (sharePointSearchMatches?.length >= 3) {
                name = sharePointSearchMatches[2];
            }

            let selectedTypeLanguage: string;
            let icon: string;

            if (fileMatches) {
                selectedTypeLanguage = "SELECTED_FILE";
                icon = "folder";
            } else if (folderMatches) {
                selectedTypeLanguage = "SELECTED_FOLDER";
                icon = "folder";
            } else if (sharePointSearchMatches) {
                selectedTypeLanguage = "SELECTED_SEARCH";
                icon = "search-normal-1";
            } else {
                icon = "folder";
            }

            const placeholder =
                cc.state === SpintrTypes.UberContentSectionState.IsDisplayed
                    ? localize(selectedTypeLanguage) + ":"
                    : localize("BifogaFiler");

            return (
                <>
                    <div
                        className="contentSectionItem"
                    >
                        <CoverImageButton
                            icon={icon}
                            placeholder={placeholder}
                            subPlaceholder={name}
                            onClick={() => {
                                this.setState({
                                    showFilePicker: true,
                                    columnTmpIdToAddFileTo: cc.tmpId,
                                });
                            }}
                        />
                    </div>
                </>
            );
        },
    }, {
        typeId: SpintrTypes.UberContentSectionType.DataWidget,
        render: (column: any) => {
            if (column.type !== SpintrTypes.UberContentSectionType.DataWidget) {
                return null;
            }

            if (column.state === SpintrTypes.UberContentSectionState.IsEditing) {
                const onSelectClick = () => this.setState({
                    columnTmpIdToAddWidgetTo: column.tmpId,
                    showWidgetSelector: true,
                });

                return (
                    <div
                        className="contentSectionItem"
                    >
                        <CoverImageButton
                            icon={"chart"}
                            placeholder={localize("Valj")}
                            onClick={onSelectClick}
                        />
                    </div>
                );
            }

            if (
                column.state === SpintrTypes.UberContentSectionState.IsSelectingType ||
                column.state === SpintrTypes.UberContentSectionState.IsDisplayingPlusSign
            ) {
                return (
                    <div>
                        {
                            this.renderColumnPlusSign(column)
                        }
                        {
                            column.state === SpintrTypes.UberContentSectionState.IsSelectingType ?
                                this.renderColumnTypeSelector(column) :
                                null
                        }
                    </div>
                )
            }

            return (
                <div style={{
                    margin: "0 auto",
                }}>
                    <MarketplaceWidget
                        id={column.content}
                        autoWidth
                        displayBorder
                    />
                </div>
            );
        },
    },
    {
        typeId: SpintrTypes.UberContentSectionType.TeaserBox,
        render: (column: any) => {
            if (column.type !== SpintrTypes.UberContentSectionType.TeaserBox) {
                return null;
            }

            if (column.state === SpintrTypes.UberContentSectionState.IsEditing) {
                const onSelectClick = () => this.setState({
                    columnTmpIdToAddTeaserBoxTo: column.tmpId,
                    showTeaserBoxSelector: true,
                });

                return (
                    <div
                        className="contentSectionItem"
                    >
                        <CoverImageButton
                            icon={"box-1"}
                            placeholder={localize("Valj")}
                            onClick={onSelectClick}
                        />
                    </div>
                );
            }

            if (
                column.state === SpintrTypes.UberContentSectionState.IsSelectingType ||
                column.state === SpintrTypes.UberContentSectionState.IsDisplayingPlusSign
            ) {
                return (
                    <div>
                        {
                            this.renderColumnPlusSign(column)
                        }
                        {
                            column.state === SpintrTypes.UberContentSectionState.IsSelectingType ?
                                this.renderColumnTypeSelector(column) :
                                null
                        }
                    </div>
                )
            }

            return (
                <div className="teaserbox-textpage" style={{
                    margin: "0 auto",
                }}>
                    <TeaserBox
                        id={column.content}
                        showBorder
                    />
                </div>
            );
        },
    },
    {
        typeId: SpintrTypes.UberContentSectionType.Calendar,
        render: (cc) => {
            if (cc.state === SpintrTypes.UberContentSectionState.IsDisplayed) {
                const inlineCalendarPattern = /(?:class="redactorInlineFolder)\s([a-z]*-[0-9]*)">([A-Za-zÀ-ÖØ-öø-ÿ\d\s]*)/igm;

                let match, calendarId, calendarName;

                while ((match = inlineCalendarPattern.exec(cc.content))) {
                    calendarId = parseInt(match[1].match(/(\d+)/)[0]);

                    if (isNaN(calendarId)) {
                        continue;
                    }

                    calendarName = match[2];
                };

                return (
                    <div className="contentSectionItem">
                        <CoverImageButton
                            icon={"calendar"}
                            placeholder={localize("SELECTED_CALENDAR") + ":"}
                            subPlaceholder={calendarName} />
                    </div>
                )
            } else if (cc.state === SpintrTypes.UberContentSectionState.IsEditing) {
                return (
                    <div className="contentSectionItem">
                        <CoverImageButton
                            icon={"calendar"}
                            placeholder={localize("ValjKalender")}
                            onClick={() => {
                                this.setState({
                                    showCalendarSelectorDialog: true,
                                    isLoadingCalendars: true,
                                    columnTmpIdToAddCalendarTo: cc.tmpId
                                }, () => {
                                    api.get(`/api/v1/calendar/currentuser`, {
                                        params: {
                                            take: 9999,
                                            orderByColumn: "name"
                                        }
                                    }).then((response: AxiosResponse) => {
                                        let calendars = response.data.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

                                        this.setState({
                                            isLoadingCalendars: false,
                                            calendars,
                                            selectedCalendar: calendars[0]
                                        });
                                    });
                                });
                            }} />
                    </div>
                );
            } else if (cc.state === SpintrTypes.UberContentSectionState.IsSelectingType ||
                cc.state === SpintrTypes.UberContentSectionState.IsDisplayingPlusSign) {
                return (
                    <div>
                        {
                            this.renderColumnPlusSign(cc)
                        }
                        {
                            cc.state === SpintrTypes.UberContentSectionState.IsSelectingType ?
                                this.renderColumnTypeSelector(cc) :
                                null
                        }
                    </div>
                )
            }
        }
    },
    {
        typeId: SpintrTypes.UberContentSectionType.ExpandableField,
        render: (cc) => {
            let contentObject = { text: "", title: "" };

            if (cc.content) {
                try {
                    contentObject = JSON.parse(cc.content)
                } catch {
                    contentObject = { text: "", title: "" };
                }
            }

            this.titleRefs[cc.tmpId] = React.createRef();

            if (cc.state === SpintrTypes.UberContentSectionState.IsDisplayed) {
                return (
                    <div onDoubleClick={() => {
                        if (window.getSelection) {
                            // prevent weird selection issue in chrome when double clicking on text block
                            window.getSelection().empty();
                        }

                        this.setState({
                            contentRow: {
                                ...this.state.contentRow,
                                uberContentColumns: this.state.contentRow.uberContentColumns.map(contentColumn => {
                                    return {
                                        ...contentColumn,
                                        state: contentColumn.tmpId === cc.tmpId ?
                                            SpintrTypes.UberContentSectionState.IsEditing :
                                            contentColumn.state
                                    }
                                })
                            }
                        }, this.postUpdatedRowToParent);
                    }}>
                        <div style={{
                            minHeight: "1em",
                            wordBreak: "break-word"
                        }}>
                            <ExpandableField title={contentObject.title} text={contentObject.text} />
                        </div>
                    </div>
                )
            } else if (cc.state === SpintrTypes.UberContentSectionState.IsEditing) {
                let title;
                const content = this.state.contentRow.uberContentColumns.find(c => c.tmpId == cc.tmpId).content;
                if (content) {
                    title = JSON.parse(content)?.title
                }

                return (
                    <div className="EditableContentSectionsRow-IframeForm" style={{
                        backgroundColor: Style.getHexFromSpintrColor("visageMidBlueBackground"),
                        padding: Style.getSpacingStr(3),
                        borderRadius: "12px"
                    }}>
                        <FormControl>
                            <TextField
                                label={localize("Rubrik")}
                                componentRef={(input) => this.titleRefs[cc.tmpId] = input}
                                defaultValue={title}
                                autoFocus
                                required
                                aria-required
                                validateOnFocusIn
                                validateOnFocusOut
                                validateOnLoad={!!this.state.enableFieldValidation}
                                onGetErrorMessage={validateRequiredTextField} 
                            />
                        </FormControl>
                        <Label color="mid-grey" weight="medium" size="body-2" style={{ marginBottom: "8px" }}>{localize("Innehall")}</Label>
                        <TinyEditorComponent
                            required
                            id={cc.tmpId}
                            content={contentObject.text}
                            displaySaveButton={true}
                            saveOnClickOutside={false}
                            onSave={(content) => {
                                this.setState({
                                    enableFieldValidation: true,
                                    contentRow: {
                                        ...this.state.contentRow,
                                        uberContentColumns: this.state.contentRow.uberContentColumns.map(contentColumn => {
                                            if (contentColumn.tmpId === cc.tmpId) {
                                                const expandableTitle = this.titleRefs[cc.tmpId]._textElement.current.value;

                                                return {
                                                    ...contentColumn,
                                                    state: SpintrTypes.UberContentSectionState.IsDisplayed,
                                                    content: JSON.stringify({
                                                        text: content,
                                                        title: expandableTitle,
                                                    }),
                                                }
                                            } else {
                                                return contentColumn;
                                            }
                                        })
                                    }
                                }, () => {
                                    this.postUpdatedRowToParent();
                                }
                                );
                            }}
                        />
                    </div>
                )
            } else if (cc.state === SpintrTypes.UberContentSectionState.IsSelectingType ||
                cc.state === SpintrTypes.UberContentSectionState.IsDisplayingPlusSign) {
                return (
                    <div>
                        {
                            this.renderColumnPlusSign(cc)
                        }
                        {
                            cc.state === SpintrTypes.UberContentSectionState.IsSelectingType ?
                                this.renderColumnTypeSelector(cc) :
                                null
                        }
                    </div>
                )
            }
        }
    },
    {
        typeId: SpintrTypes.UberContentSectionType.Link,
        render: (cc) => {
            let contentObject = { url: "", title: "" };

            if (cc.content) {
                try {
                    contentObject = JSON.parse(cc.content)
                } catch {
                    contentObject = { url: "", title: "" };
                }
            }

            if (cc.state === SpintrTypes.UberContentSectionState.IsDisplayed) {
                return (
                    <div style={{
                        backgroundColor: Style.getHexFromSpintrColor("visageMidBlueBackground"),
                        height: 52,
                        borderRadius: "12px"
                    }}>
                        <a href={contentObject.url} style={{ color: "#22234A" }}>
                            <div className="fs-body-2 InTextPageLinkButton">
                                {contentObject.title}
                                <Visage2Icon style={{ marginLeft: 8 }} color="dark-grey" icon="arrow-right-1" />
                            </div>
                        </a>
                    </div>
                )
            } else if (cc.state === SpintrTypes.UberContentSectionState.IsEditing) {

                const saveLink = (contentObject) => {
                    this.setState({
                        contentRow: {
                            ...this.state.contentRow,
                            uberContentColumns: this.state.contentRow.uberContentColumns.map(contentColumn => {
                                if (contentColumn.tmpId === cc.tmpId) {
                                    return {
                                        ...contentColumn,
                                        state: SpintrTypes.UberContentSectionState.IsDisplayed,
                                        content: JSON.stringify({ url: contentObject.url, title: contentObject.title })
                                    }
                                } else {
                                    return contentColumn;
                                }
                            })
                        }
                    }, this.postUpdatedRowToParent);
                }

                return (
                    <div className="EditableContentSectionsRow-IframeForm" style={{
                        backgroundColor: Style.getHexFromSpintrColor("visageMidBlueBackground"),
                        minHeight: 150,
                        padding: Style.getSpacingStr(3),
                        borderRadius: "12px"
                    }}>
                        <form>
                            <FormControl>
                                <TextField
                                    className="textField"
                                    label={localize("Titel")}
                                    value={contentObject.title ?? ""}
                                    name={cc.tmpId}
                                    onChange={(ev, val) => this.handleLinkTitleChange(ev, val, cc)}
                                    autoFocus
                                    required
                                    aria-required
                                    validateOnFocusIn
                                    validateOnFocusOut
                                    validateOnLoad={!!this.state.enableFieldValidation}
                                    onGetErrorMessage={validateRequiredTextField}
                                />
                            </FormControl>
                            <FormControl>
                                <TextField
                                    className="textField"
                                    type="url"
                                    label={localize("Url")}
                                    value={contentObject.url}
                                    onChange={(ev, val) => this.handleLinkUrlChange(ev, val, cc)}
                                    name={cc.tmpId}
                                    required
                                    aria-required
                                    validateOnFocusOut
                                    onGetErrorMessage={validateRequiredTextField}
                                />
                            </FormControl>
                        </form>
                        <Stack horizontal={true} horizontalAlign="end">
                            <PrimaryButton
                                disabled={(!contentObject.url || contentObject.url.length === 0) || (!contentObject.title || contentObject.title.length === 0)}
                                onClick={() => {
                                    this.setState({
                                        enableFieldValidation: true
                                    });
                                    saveLink(contentObject);
                                }}
                                text={localize("Spara")}
                            />
                        </Stack>
                    </div>
                );
            } else if (cc.state === SpintrTypes.UberContentSectionState.IsSelectingType ||
                cc.state === SpintrTypes.UberContentSectionState.IsDisplayingPlusSign) {
                return (
                    <div>
                        {
                            this.renderColumnPlusSign(cc)
                        }
                        {
                            cc.state === SpintrTypes.UberContentSectionState.IsSelectingType ?
                                this.renderColumnTypeSelector(cc) :
                                null
                        }
                    </div>
                )
            }
        }
    }, {
        typeId: SpintrTypes.UberContentSectionType.UserList,
        render: (cc) => { 

            let contentObject = { title: "", items: [] };
            
            if(cc.content) {
                try {
                    contentObject = JSON.parse(cc.content)
                } catch {
                    contentObject = { title: "", items: [] };
                }
            }

            if (cc.state === SpintrTypes.UberContentSectionState.IsDisplayed) {
                return (
                    <UserList data={cc.content}/>
                )
            } else if (cc.state === SpintrTypes.UberContentSectionState.IsEditing) {

                return (
                    <div className="EditableContentSectionsRow-IframeForm"
                        style={{
                            backgroundColor: Style.getHexFromSpintrColor("visageMidBlueBackground"),
                            borderRadius: "12px",
                            padding: Style.getSpacingStr(3),
                        }}>
                    <FormControl>
                        <TextField
                            className="textField"
                            label={localize("Titel")}
                            value={contentObject.title ?? ""}
                            name={cc.tmpId}
                            required
                            aria-required
                            validateOnFocusIn
                            validateOnFocusOut
                            validateOnLoad={!!this.state.enableFieldValidation}
                            onGetErrorMessage={validateRequiredTextField}
                            onChange={(ev, val) => {
                                this.setState({
                                    enableFieldValidation: true,
                                    contentRow: {
                                        ...this.state.contentRow,
                                        uberContentColumns: this.state.contentRow.uberContentColumns.map(
                                            contentColumn => {
                                                if(contentColumn.tmpId === cc.tmpId) {
                                                    return {
                                                        ...contentColumn,
                                                        content: JSON.stringify({
                                                            title: val,
                                                            items: contentObject.items
                                                        })
                                                    }
                                                } else {
                                                    return contentColumn;
                                                }
                                            }
                                        )
                                    }
                                }, () => {
                                    this.postUpdatedRowToParent();
                                });
                            }}
                            autoFocus
                        />
                    </FormControl>
                    <FormControl>
                        <FormTokenizedObjectInput
                            items={contentObject.items}
                            types={[
                                SpintrTypes.UberType.User,
                            ]}
                            label={localize("Sok")}
                            required
                            onChange={(items) => {
                                this.setState({
                                    enableFieldValidation: true,
                                    contentRow: {
                                        ...this.state.contentRow,
                                        uberContentColumns: this.state.contentRow.uberContentColumns.map(contentColumn => {
                                            if (contentColumn.tmpId === cc.tmpId) {
                                                return {
                                                    ...contentColumn,
                                                    content: JSON.stringify({
                                                        title: contentObject.title,
                                                        items: items
                                                    })
                                                }
                                            } else {
                                                return contentColumn;
                                            }
                                        })
                                    }
                                }, () => {
                                    this.postUpdatedRowToParent();
                                });
                            }}
                        />
                        </FormControl>
                        <Stack horizontal={true} horizontalAlign="end">
                        <PrimaryButton
                            onClick={() => {
                                this.setState({
                                    enableFieldValidation: true,
                                    contentRow: {
                                        ...this.state.contentRow,
                                        uberContentColumns: this.state.contentRow.uberContentColumns.map(contentColumn => {
                                            if (contentColumn.tmpId === cc.tmpId) {
                                                return {
                                                    ...contentColumn,
                                                    state: SpintrTypes.UberContentSectionState.IsDisplayed,
                                                }
                                            } else {
                                                return contentColumn;
                                            }
                                        })
                                    }
                                }, () => {
                                    this.postUpdatedRowToParent();
                                });
                            }}>
                            {localize("Spara")}
                        </PrimaryButton>
                        </Stack>
                    </div>
                )
            } else if (cc.state === SpintrTypes.UberContentSectionState.IsSelectingType ||
                cc.state === SpintrTypes.UberContentSectionState.IsDisplayingPlusSign) {
                return (
                    <div>
                        {
                            this.renderColumnPlusSign(cc)
                        }
                        {
                            cc.state === SpintrTypes.UberContentSectionState.IsSelectingType ?
                                this.renderColumnTypeSelector(cc) :
                                null
                        }
                    </div>
                );
            }
        }
    }, {
        typeId: SpintrTypes.UberContentSectionType.Direkten,
        render: (cc) => {
            if (cc.state === SpintrTypes.UberContentSectionState.IsDisplayed) {
                return (
                    <div className="contentSectionItem">
                        <CoverImageButton
                            icon={"shop"}
                            placeholder={localize("SELECTED_CATEGORY")}
                            subPlaceholder={cc.content}
                        />
                    </div>
                )
            } else if (cc.state == SpintrTypes.UberContentSectionState.IsEditing) {
                const saveDirekten = () => {
                    this.setState({
                        contentRow: {
                            ...this.state.contentRow,
                            uberContentColumns: this.state.contentRow.uberContentColumns.map(contentColumn => {
                                if (contentColumn.tmpId === cc.tmpId) {
                                    return {
                                        ...contentColumn,
                                        state: SpintrTypes.UberContentSectionState.IsDisplayed,
                                        content: cc.category
                                    }
                                } else {
                                    return contentColumn;
                                }
                            })
                        }
                    }, this.postUpdatedRowToParent);
                };
                return (
                    <div className="contentSectionItem" style={{
                        padding: Style.getSpacingStr(3)
                    }}>
                        <FormControl>
                            <TextField
                                className="textField"
                                label={localize("Kategori")}
                                autoFocus
                                defaultValue={cc.content}
                                value={cc.category}
                                onChange={this.handleDirektenCategoryChange}
                                name={cc.tmpId}
                                required
                                aria-required
                                validateOnFocusIn
                                validateOnFocusOut
                                validateOnLoad={!!this.state.enableFieldValidation}
                                onGetErrorMessage={validateRequiredTextField}
                            />
                        </FormControl>
                        <Stack horizontal={true} horizontalAlign="end">
                            <PrimaryButton
                                disabled={!cc.category || cc.category.length === 0}
                                onClick={() => {
                                    this.setState({
                                        enableFieldValidation: true
                                    });
                                    saveDirekten();
                                }}
                                text={localize("Spara")}
                            />
                        </Stack>
                    </div>
                )
            } else if (cc.state === SpintrTypes.UberContentSectionState.IsSelectingType ||
                cc.state === SpintrTypes.UberContentSectionState.IsDisplayingPlusSign) {
                return (
                    <div>
                        {
                            this.renderColumnPlusSign(cc)
                        }
                        {
                            cc.state === SpintrTypes.UberContentSectionState.IsSelectingType ?
                                this.renderColumnTypeSelector(cc) :
                                null
                        }
                    </div>
                );
            }
        }
    }, {
        typeId: SpintrTypes.UberContentSectionType.Products,
        render: (column: UberContentColumn) => (
            <ProductsColumn
                column={column}
                enabeFieldValidation={this.state.enableFieldValidation}
                onColumnChanged={this.onColumnChanged} />
        ),
    }, {
        typeId: SpintrTypes.UberContentSectionType.TaxiFiles,
        render: () => (
            <>{localize("TAXI_FILES")}</>
        ),
    }];

    private onColumnChanged = (tmpId: string, callback: (column: UberContentColumn) => UberContentColumn): void => {
        this.setState({
            enableFieldValidation: true,
            contentRow: {
                ...this.state.contentRow,
                uberContentColumns: this.state.contentRow.uberContentColumns.map(
                    (contentColumn: UberContentColumn) => contentColumn.tmpId === tmpId
                        ? callback(contentColumn)
                        : contentColumn
                ),
            },
        }, () => this.postUpdatedRowToParent());
    };

    constructor(props) {
        super(props);

        this.state = {
            contentRow: props.contentRow,
            isLoadingForms: false,
            showFilePicker: false,
            showFormSelectorDialog: false,
            showWidgetSelector: false,
            searchText: "",
            showTeaserBoxSelector: false,
            showCalendarSelectorDialog: false,
            isLoadingCalendars: false
        };

        if (!this.props.enableCrocus) {
            this.contentTypes = this.contentTypes.filter(
                (type) => type.typeId != SpintrTypes.UberContentSectionType.DataWidget,
            );
        }

        this.typeSelector = React.createRef();
        this.titleRefs = {};

        this.onDismissWidgetSelection = this.onDismissWidgetSelection.bind(this);
        this.onWidgetSelected = this.onWidgetSelected.bind(this);

        this.onDismissTeaserBoxSelection = this.onDismissTeaserBoxSelection.bind(this);
        this.onTeaserBoxSelected = this.onTeaserBoxSelected.bind(this);
    }

    public componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside.bind(this));
        this.addObserver();
    }

    public componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside.bind(this));
        this.observer.disconnect();
    }

    private addObserver() {
        // put this into redux instead if needed in other places?
        const body = window.document.querySelector("body");
        this.observer = new MutationObserver(() => {
            const isKeyboardNavigation = body.classList.contains("ms-Fabric--isFocusVisible");

            if (this.state.isKeyboardNavigation !== isKeyboardNavigation) {
                this.setState({
                    isKeyboardNavigation
                });
            }
        });

        this.observer.observe(body, {
            attributes: true,
            attributeFilter: ["class"]
        });
    }

    public render() {
        return (
            <div>
                {
                    this.renderContentRow()
                }
                {
                    this.renderFormSelectorDialog()
                }
                {
                    this.renderCalendarSelectorDialog()
                }
                {
                    this.renderFilePicker()
                }
                {this.state.showWidgetSelector && (
                    <WidgetSelectionPopup
                        isShowing={true}
                        onDismiss={this.onDismissWidgetSelection}
                        onSelected={this.onWidgetSelected}
                    />
                )}
                {this.state.showTeaserBoxSelector && (
                    <TeaserBoxSelectionPopup
                        isShowing={true}
                        onDismiss={this.onDismissTeaserBoxSelection}
                        onSelected={this.onTeaserBoxSelected}
                        hideDataWidgets={true}
                    />
                )}
            </div>
        )
    }

    private onWidgetSelected(widget: Spintr.IMarketplaceLibraryWidget) {
        this.setState({
            contentRow: {
                ...this.state.contentRow,
                uberContentColumns: this.state.contentRow.uberContentColumns.map(
                    (contentColumn: any) => contentColumn.tmpId !== this.state.columnTmpIdToAddWidgetTo
                        ? contentColumn
                        : {
                            ...contentColumn,
                            content: widget.id,
                            state: SpintrTypes.UberContentSectionState.IsDisplayed,
                        })
            },
            showWidgetSelector: false,
        }, this.postUpdatedRowToParent);
    }

    private onTeaserBoxSelected(teaserbox) {
        this.setState({
            contentRow: {
                ...this.state.contentRow,
                uberContentColumns: this.state.contentRow.uberContentColumns.map(
                    (contentColumn: any) => contentColumn.tmpId !== this.state.columnTmpIdToAddTeaserBoxTo
                        ? contentColumn
                        : {
                            ...contentColumn,
                            content: teaserbox.id.toString(),
                            state: SpintrTypes.UberContentSectionState.IsDisplayed,
                        })
            },
            showTeaserBoxSelector: false,
        }, this.postUpdatedRowToParent);
    }

    private onDismissWidgetSelection() {
        this.setState({
            showWidgetSelector: false,
        });
    }

    private onDismissTeaserBoxSelection() {
        this.setState({
            showTeaserBoxSelector: false,
        });
    }

    private handleClickOutside(event) {
        if (this &&
            this.state &&
            this.props &&
            this.typeSelector &&
            this.typeSelector.current &&
            !this.typeSelector.current.contains(event.target)) {
            this.setState({
                contentRow: {
                    ...this.state.contentRow,
                    uberContentColumns: this.state.contentRow.uberContentColumns.map(contentColumn => {
                        if (contentColumn.state === SpintrTypes.UberContentSectionState.IsSelectingType) {
                            return {
                                ...contentColumn,
                                state: SpintrTypes.UberContentSectionState.IsDisplayingPlusSign
                            }
                        } else {
                            return contentColumn;
                        }
                    })
                }
            });
        }
    }

    private handleIframeUrlChange = (ev, val) => {
        const targetId = parseInt(ev.target.name, 10);
        this.setState({
            contentRow: {
                ...this.state.contentRow,
                uberContentColumns: this.state.contentRow.uberContentColumns.map(contentColumn => {
                    if (contentColumn.tmpId === ev.target.name) {
                        return {
                            ...contentColumn,
                            iframeUrl: val
                        }
                    } else {
                        return contentColumn;
                    }
                })
            }
        }, this.postUpdatedRowToParent);
    }

    private handleIframeHeightChange = (ev, val) => {
        this.setState({
            contentRow: {
                ...this.state.contentRow,
                uberContentColumns: this.state.contentRow.uberContentColumns.map(contentColumn => {
                    if (contentColumn.tmpId === ev.target.name) {
                        return {
                            ...contentColumn,
                            iframeHeight: val
                        }
                    } else {
                        return contentColumn;
                    }
                })
            }
        }, this.postUpdatedRowToParent);
    }

    private handleLinkUrlChange = (ev, val, column) => {
        let contentObject = { url: "", title: "" };

        if (column.content) {
            try {
                contentObject = JSON.parse(column.content)
            } catch {
                contentObject = { url: val, ...contentObject };
            }
        }

        contentObject.url = val;

        this.setState({
            contentRow: {
                ...this.state.contentRow,
                uberContentColumns: this.state.contentRow.uberContentColumns.map(contentColumn => {
                    if (contentColumn.tmpId === ev.target.name) {
                        return {
                            ...contentColumn,
                            content: JSON.stringify(contentObject)
                        }
                    } else {
                        return contentColumn;
                    }
                })
            }
        }, this.postUpdatedRowToParent);
    }

    private handleLinkTitleChange = (ev, val, column) => {
        let contentObject = { url: "", title: "" };

        if (column.content) {
            try {
                contentObject = JSON.parse(column.content)
            } catch {
                contentObject = { ...contentObject, title: val };
            }
        }

        contentObject.title = val;

        this.setState({
            contentRow: {
                ...this.state.contentRow,
                uberContentColumns: this.state.contentRow.uberContentColumns.map(contentColumn => {
                    if (contentColumn.tmpId === ev.target.name) {
                        return {
                            ...contentColumn,
                            content: JSON.stringify(contentObject)
                        }
                    } else {
                        return contentColumn;
                    }
                })
            }
        }, this.postUpdatedRowToParent);
    }

    private handleDirektenCategoryChange = (ev, val) => {
        this.setState({
            contentRow: {
                ...this.state.contentRow,
                uberContentColumns: this.state.contentRow.uberContentColumns.map(contentColumn => {
                    if (contentColumn.tmpId === ev.target.name) {
                        return {
                            ...contentColumn,
                            category: val
                        }
                    } else {
                        return contentColumn;
                    }
                })
            }
        }, this.postUpdatedRowToParent);
    }

    private saveAttachedFileName = (cc, name, id, folderMatches, sharePointListSearchMatches) => {
        let newContent;
        if (folderMatches) {
            newContent =
                '<div><div><span class="redactorInlineFolder">[@Folder:' +
                id +
                "@]</span></div>" +
                name +
                '<div><span class="redactorInlineFolder">[/@Folder@]</span></div></div><br />';
        } else if (sharePointListSearchMatches) {
            newContent =
                '<div><div><span class="redactorInlineFolder sharePoint">[@SharePointListSearch:' +
                id +
                "@]</span></div>" +
                name +
                '<div><span class="redactorInlineFolder">[/@SharePointListSearch@]</span></div></div><br />';
        }

        this.setState(
            {
                contentRow: {
                    ...this.state.contentRow,
                    uberContentColumns: this.state.contentRow.uberContentColumns.map((contentColumn) => {
                        if (contentColumn.tmpId === cc.tmpId) {
                            return {
                                ...contentColumn,
                                state: SpintrTypes.UberContentSectionState.IsDisplayed,
                                content: newContent,
                            };
                        } else {
                            return contentColumn;
                        }
                    }),
                },
            },
            this.postUpdatedRowToParent
        );
    };

    private handleAttachedFileNameChange = (ev, val) => {
        this.setState(
            {
                contentRow: {
                    ...this.state.contentRow,
                    uberContentColumns: this.state.contentRow.uberContentColumns.map((contentColumn) => {
                        if (contentColumn.tmpId === ev.target.name) {
                            return {
                                ...contentColumn,
                                name: val,
                            };
                        } else {
                            return contentColumn;
                        }
                    }),
                },
            },
            this.postUpdatedRowToParent
        );
    };

    private renderTypeSearchBox = () => {
        return (
            <SpintrSearch
                classes="type-search searchBox"
                placeholder={localize("SokBlandBlock")}
                value={this.state.searchText}
                onChange={(event: React.ChangeEvent, searchText: string) => {
                    this.setState({ searchText });
                }} />
        )

        return <SearchBox
            role="search"
            className="type-search"
            placeholder={localize("SokBlandBlock")}
            value={this.state.searchText}
            onChange={(event: React.ChangeEvent, searchText: string) => {
                this.setState({ searchText });
            }}
        />
    }

    onEnter = (e, fn) => {
        if (e.keyCode === 27) {
            e.preventDefault();
            fn();
        }

        if (e.keyCode === 13) {
            e.preventDefault();
            fn();
        }
    };

    private renderColumnType(index, text, icon, onClick) {
        return (
            <div key={index} className="columnType" tabIndex={0} onKeyUp={(e) => this.onEnter(e, onClick)}
                style={{
                    display: "inline-block",
                    width: "100%",
                }}>
                <div style={{
                    textAlign: "center"
                }}>
                    <a onClick={onClick} key={text}>
                        <div>
                            <div className="columnTypeInner" style={{
                                display: "inline-block",
                                // padding: Style.getSpacingStr(4)
                            }}>
                                <div style={{
                                    textAlign: "center"
                                }}>
                                    <div style={{
                                        display: "inline-block"
                                    }}>
                                        <Visage2Icon icon={icon} />
                                    </div>
                                </div>
                                <Label as="span" size="body-3" color="dark-grey">{text}</Label>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        )
    }

    private selectType(cc, typeId, state?: SpintrTypes.UberContentSectionState) {
        this.setState({
            contentRow: {
                ...this.state.contentRow,
                uberContentColumns: this.state.contentRow.uberContentColumns.map(contentColumn => {
                    if (contentColumn.tmpId === cc.tmpId) {
                        return {
                            ...contentColumn,
                            type: typeId,
                            state: state ?? SpintrTypes.UberContentSectionState.IsEditing,
                            isNew: true
                        }
                    } else {
                        return contentColumn;
                    }
                })
            }
        }, this.postUpdatedRowToParent);
    }

    isAppEnabled(id) {
        return !!this.props.apps.items.find((a) => a.id === id && a.enabled);
    }

    private renderColumnTypeSelector(cc) {
        let types = [{
            text: localize("Text"),
            icon: "textalign-justifyleft",
            type: SpintrTypes.UberContentSectionType.Text,
            onClick: () => {
                this.selectType(cc, SpintrTypes.UberContentSectionType.Text);
            },
            enabled: true
        }, {
            enabled: this.props.enableExtendedPageBlockTypes,
            icon: "text",
            type: SpintrTypes.UberContentSectionType.Preamble,
            onClick: () => {
                this.selectType(cc, SpintrTypes.UberContentSectionType.Preamble, SpintrTypes.UberContentSectionState.IsDisplayed);
            },
            text: localize("Ingress"),
        }, {
            text: localize("Bild"),
            icon: "image",
            type: SpintrTypes.UberContentSectionType.Image,
            onClick: () => {
                this.selectType(cc, SpintrTypes.UberContentSectionType.Image);
            },
            enabled: true
        }, {
            text: "iFrame",
            icon: "frame-2",
            type: SpintrTypes.UberContentSectionType.IFrame,
            onClick: () => {
                this.selectType(cc, SpintrTypes.UberContentSectionType.IFrame);
            },
            enabled: true
        }, {
            text: localize("Formular"),
            icon: "note-1",
            type: SpintrTypes.UberContentSectionType.Form,
            onClick: () => {
                this.selectType(cc, SpintrTypes.UberContentSectionType.Form);
            },
            enabled: this.isAppEnabled(SpintrTypes.SpintrApp.Forms)
        }, {
            text: localize("Filer"),
            icon: "folder",
            type: SpintrTypes.UberContentSectionType.File,
            onClick: () => {
                this.selectType(cc, SpintrTypes.UberContentSectionType.File);
            },
            enabled: this.isAppEnabled(SpintrTypes.SpintrApp.Files)
        }, {
            enabled: this.props.enableCrocus,
            icon: "chart",
            type: SpintrTypes.UberContentSectionType.DataWidget,
            onClick: () => {
                this.selectType(cc, SpintrTypes.UberContentSectionType.DataWidget);
            },
            text: localize("TEASER_BOX_DATA"),
        }, {
            enabled: this.props.enableExtendedPageBlockTypes,
            icon: "box-1",
            type: SpintrTypes.UberContentSectionType.TeaserBox,
            onClick: () => {
                this.selectType(cc, SpintrTypes.UberContentSectionType.TeaserBox);
            },
            text: localize("Textpuff"),
        }, {
            enabled: this.props.enableExtendedPageBlockTypes,
            icon: "calendar",
            type: SpintrTypes.UberContentSectionType.Calendar,
            onClick: () => {
                this.selectType(cc, SpintrTypes.UberContentSectionType.Calendar);
            },
            text: localize("Kalender"),
        },
        {
            enabled: this.props.enableExtendedPageBlockTypes,
            icon: "arrow-down-1",
            type: SpintrTypes.UberContentSectionType.ExpandableField,
            onClick: () => {
                this.selectType(cc, SpintrTypes.UberContentSectionType.ExpandableField);
            },
            text: localize("ExpanderbartFalt"),
        },
        {
            enabled: this.props.enableExtendedPageBlockTypes,
            icon: "card",
            type: SpintrTypes.UberContentSectionType.Link,
            onClick: () => {
                this.selectType(cc, SpintrTypes.UberContentSectionType.Link);
            },
            text: localize("LankBlock"),
        }, {
            enabled: this.props.enableDirekten,
            icon: "home-1",
            type: SpintrTypes.UberContentSectionType.Direkten,
            onClick: () => {
                this.selectType(cc, SpintrTypes.UberContentSectionType.Direkten);
            },
            text: "Direkten",
        }, {
            enabled: this.props.enableExtendedPageBlockTypes,
            icon: "people",
            type: SpintrTypes.UberContentSectionType.UserList,
            onClick: () => {
                this.selectType(cc, SpintrTypes.UberContentSectionType.UserList);
            },
            text: localize("Medarbetare"),
        }, {
            enabled: this.isAppEnabled(SpintrTypes.SpintrApp.Products),
            icon: "shopping-cart",
            type: SpintrTypes.UberContentSectionType.Products,
            onClick: () => {
                this.selectType(cc, SpintrTypes.UberContentSectionType.Products);
            },
            text: localize("PRODUCTS")
        }, {
            enabled: this.props.enableTaxiFiles,
            icon: "car",
            type: SpintrTypes.UberContentSectionType.TaxiFiles,
            onClick: () => {
                this.selectType(cc, SpintrTypes.UberContentSectionType.TaxiFiles, SpintrTypes.UberContentSectionState.IsDisplayed);
            },
            text: localize("TAXI_FILES"),
        }];

        types = types.filter((t) =>
            t.enabled && this.props.enabledColumnTypes?.length > 0
                ? this.props.enabledColumnTypes.includes(t.type)
                : t.enabled
        );

        if (this.props.isSmallViewMode) {
            return (
                <Modal
                    isOpen={true}
                    className="columnTypeSelectorModal"
                    onDismiss={() => { }}
                    isModeless={true}
                    forceFocusInsideTrap={true}
                    styles={{ main: { minHeight: 0 } }}
                >
                    <div className="columnTypeSelector box-shadow-and-border" ref={this.typeSelector}>
                        {/* {types.length > 3 && this.renderTypeSearchBox()} */}
                        <div
                            className={classNames("typeSelectorContainer", {
                                ["smallViewMode"]: this.props.isSmallViewMode,
                            })}
                        >
                            <div
                                className={classNames("columnContainer", { ["smallViewMode"]: this.props.isSmallViewMode })}
                                style={{
                                    columnGap: Style.getSpacingStr(2),
                                }}
                            >
                                {types
                                    .filter((t) => t.text.toLowerCase().includes(this.state.searchText.toLowerCase()))
                                    .map((o) => {
                                        return this.renderColumnType(types.indexOf(o), o.text, o.icon, o.onClick);
                                    })}
                            </div>
                        </div>
                    </div>
                </Modal>
            );
        } else {
            return (
                <div
                    ref={this.typeSelector}
                    className={classNames("box-shadow-and-border columnTypeSelector", {
                        ["smallViewMode"]: this.props.isSmallViewMode,
                    })}
                >
                    {/* {types.length > 2 && this.renderTypeSearchBox()} */}

                    <div
                        className={classNames("columnContainer", { ["smallViewMode"]: this.props.isSmallViewMode })}
                        style={{

                            columnGap: Style.getSpacingStr(2),
                        }}
                    >
                        {types
                            .filter((t) => t.text.toLowerCase().includes(this.state.searchText.toLowerCase()))
                            .map((o) => {
                                return this.renderColumnType(types.indexOf(o), o.text, o.icon, o.onClick);
                            })}
                    </div>
                </div>
            );
        }
    }

    private postUpdatedRowToParent() {
        this.props.contentRow.onChange(this.state.contentRow);
    }

    private renderColumnPlusSign(cc) {

        return (
            <a onClick={() => {
                this.setState({
                    contentRow: {
                        ...this.state.contentRow,
                        uberContentColumns: this.state.contentRow.uberContentColumns.map(uberContentColumn => {
                            if (cc.tmpId === uberContentColumn.tmpId) {
                                return {
                                    ...uberContentColumn,
                                    state: SpintrTypes.UberContentSectionState.IsSelectingType
                                }
                            } else {
                                return uberContentColumn;
                            }
                        })
                    }
                }, this.postUpdatedRowToParent);
            }}>
                <div style={{
                    backgroundColor: Style.getHexFromSpintrColor("visageMidBlueBackground"),
                    height: "150px",
                    textAlign: "center",
                    width: "100%",
                    display: "inline-block",
                    borderRadius: "12px",
                    cursor: "pointer"
                }}>
                    <div style={{
                        display: "inline-block",
                        position: "relative",
                        transform: "translateY(-50%)",
                        msTransform: "translateY(-50%)",
                        top: "50%"
                    }}>
                        <Visage2Icon icon="add-circle" color="visageMidBlue" />
                    </div>
                </div>
            </a>
        )
    }

    moveColumn(tmpId: string, newIndex: number) {
        const columns = [...this.state.contentRow.uberContentColumns];
        const column = columns.find(x => x.tmpId === tmpId);

        if (!column) {
            return;
        }

        columns.splice(columns.indexOf(column), 1);
        columns.splice(newIndex, 0, column);

        for (let i = 0; i < columns.length; i++) {
            columns[i].index = i;
        }

        this.setState({
            contentRow: {
                ...this.state.contentRow,
                uberContentColumns: columns
            }
        }, this.postUpdatedRowToParent);
    }

    private renderContentColumn(cc, columnCount) {
        let actions = [[], []];

        actions[0].push({
            icon: "menu",
            title: localize("Flytta"),
            parent: (ele) => (<div className={ele.className} {...this.props.handleProps}>{ele.children}</div>),
        })

        if (columnCount < 3) {
            actions[1].push({
                icon: "add",
                title: localize("ADD_COLUMN"),
                onClick: () => {
                    this.setState({
                        contentRow: {
                            ...this.state.contentRow,
                            uberContentColumns: [
                                ...this.state.contentRow.uberContentColumns,
                                {
                                    id: 0,
                                    tmpId: uniqueId(),
                                    index: columnCount,
                                    type: SpintrTypes.UberContentSectionType.Text,
                                    state: SpintrTypes.UberContentSectionState.IsDisplayingPlusSign,
                                    content: '',
                                }
                            ]
                        }
                    }, this.postUpdatedRowToParent);
                }
            })
        }

        if (columnCount > 1) {
            const index = this.state.contentRow.uberContentColumns.indexOf(cc);
            const isFirst = index === 0;
            const isLast =  index === this.state.contentRow.uberContentColumns.length - 1;

            if (!isFirst) {
                actions[1].push({
                    icon: "arrow-left",
                    title: localize("MOVE_LEFT"),
                    onClick: () => {
                        this.moveColumn(cc.tmpId, index - 1);
                    }
                });
            }

            if (!isLast) {
                actions[1].push({
                    icon: "arrow-right-1",
                    title: localize("MOVE_RIGHT"),
                    onClick: () => {
                        this.moveColumn(cc.tmpId, index + 1);
                    }
                });
            }
        }

        if (
            cc.state === SpintrTypes.UberContentSectionState.IsDisplayed &&
            (cc.type === SpintrTypes.UberContentSectionType.Text ||
                cc.type === SpintrTypes.UberContentSectionType.ExpandableField ||
                cc.type === SpintrTypes.UberContentSectionType.Direkten ||
                cc.type === SpintrTypes.UberContentSectionType.UserList ||
                cc.type === SpintrTypes.UberContentSectionType.Products)
        ) {
            actions[1].push({
                icon: "edit",
                title: localize("Redigera"),
                onClick: () => {
                    this.setState({
                        contentRow: {
                            ...this.state.contentRow,
                            uberContentColumns: this.state.contentRow.uberContentColumns.map(contentColumn => {
                                return {
                                    ...contentColumn,
                                    state: contentColumn.tmpId === cc.tmpId ?
                                        SpintrTypes.UberContentSectionState.IsEditing :
                                        contentColumn.state,
                                }
                            })
                        }
                    }, this.postUpdatedRowToParent);
                }
            });
        }

        if (cc.state === SpintrTypes.UberContentSectionState.IsDisplayed &&
            cc.type === SpintrTypes.UberContentSectionType.Form) {

            const inlineFormPattern = /(?:class="redactorInlineFolder)\s([a-z]*-[0-9]*)">([A-Za-zÀ-ÖØ-öø-ÿ\d\s]*)/igm;

            let match, formId;

            while ((match = inlineFormPattern.exec(cc.content))) {
                formId = parseInt(match[1].match(/(\d+)/)[0]);

                if (isNaN(formId)) {
                    continue;
                }
            };

            actions[1].push({
                icon: "edit",
                title: localize("CHANGE_FORM"),
                onClick: () => {
                    this.setState({
                        showFormSelectorDialog: true,
                        isLoadingForms: true,
                        columnTmpIdToAddFormTo: cc.tmpId
                    }, () => {
                        api.get(`/api/v1/survey/list`, {
                            params: {
                                take: 9999
                            }
                        }).then((response: AxiosResponse) => {
                            let forms = response.data.filter(f => !f.isDeleted);

                            this.setState({
                                isLoadingForms: false,
                                forms,
                                selectedForm: forms.find(f => f.id === formId) || response.data[0]
                            })
                        });
                    });
                }
            });
        }

        if (cc.state === SpintrTypes.UberContentSectionState.IsDisplayed &&
            cc.type === SpintrTypes.UberContentSectionType.IFrame) {
            actions[1].push({
                icon: "edit",
                title: localize("CHANGE_IFRAME"),
                onClick: () => {
                    this.setState({
                        contentRow: {
                            ...this.state.contentRow,
                            uberContentColumns: this.state.contentRow.uberContentColumns.map(contentColumn => {
                                if (contentColumn.tmpId === cc.tmpId) {
                                    return {
                                        ...contentColumn,
                                        state: SpintrTypes.UberContentSectionState.IsEditing
                                    }
                                } else {
                                    return contentColumn;
                                }
                            })
                        }
                    }, this.postUpdatedRowToParent);
                }
            });
        }

        if (cc.state === SpintrTypes.UberContentSectionState.IsDisplayed &&
            cc.type === SpintrTypes.UberContentSectionType.Link) {
            actions[1].push({
                icon: "edit",
                title: localize("RedigeraLank"),
                onClick: () => {
                    this.setState({
                        contentRow: {
                            ...this.state.contentRow,
                            uberContentColumns: this.state.contentRow.uberContentColumns.map(contentColumn => {
                                if (contentColumn.tmpId === cc.tmpId) {
                                    return {
                                        ...contentColumn,
                                        state: SpintrTypes.UberContentSectionState.IsEditing
                                    }
                                } else {
                                    return contentColumn;
                                }
                            })
                        }
                    }, this.postUpdatedRowToParent);
                }
            });
        }

        if (cc.state === SpintrTypes.UberContentSectionState.IsDisplayed &&
            cc.type === SpintrTypes.UberContentSectionType.Calendar) {

            const inlineCalendarPattern = /(?:class="redactorInlineFolder)\s([a-z]*-[0-9]*)">([A-Za-zÀ-ÖØ-öø-ÿ\d\s]*)/igm;

            let match, calendarId, calendarName;

            while ((match = inlineCalendarPattern.exec(cc.content))) {
                calendarId = parseInt(match[1].match(/(\d+)/)[0]);

                if (isNaN(calendarId)) {
                    continue;
                }

                calendarName = match[2];
            };

            actions[1].push({
                icon: "edit",
                title: localize("CHANGE_CALENDAR"),
                onClick: () => {
                    this.setState({
                        showCalendarSelectorDialog: true,
                        isLoadingCalendars: true,
                        columnTmpIdToAddCalendarTo: cc.tmpId
                    }, () => {
                        api.get(`/api/v1/calendar/currentuser`, {
                            params: {
                                take: 9999
                            }
                        }).then((response: AxiosResponse) => {
                            let calendars = response.data //.filter(f => !f.isDeleted);

                            this.setState({
                                isLoadingCalendars: false,
                                calendars,
                                selectedCalendar: calendars.find(f => f.id === calendarId) || response.data[0]
                            })
                        });
                    });
                }
            });
        }

        if (cc.state === SpintrTypes.UberContentSectionState.IsDisplayed &&
            cc.type === SpintrTypes.UberContentSectionType.File) {
            actions[1].push({
                icon: "edit",
                title: localize("Redigera"),
                onClick: () => {
                    this.setState({
                        showFilePicker: true,
                        columnTmpIdToAddFileTo: cc.tmpId,
                    });
                }
            });

            if (cc.content) {
                const inlineFolderPattern = /(?:\<(?:span|font|p)[^\<]*?\>\[@Folder:([0-9]+)@\]\<\/(?:span|font|p)\>(.+?)\<(?:span|font|p)[^\<]*?\>\[\/@Folder@\]\<\/(?:span|font|p)\>)/gim;
                const inlineSharePointListSearchPattern = /<div><div><span class="redactorInlineFolder sharePoint">(.+?)<\/span><\/div>(.+?)<div><span class="redactorInlineFolder">(.+?)<\/span><\/div><\/div>/gim;

                if (inlineFolderPattern.exec(cc.content) || inlineSharePointListSearchPattern.exec(cc.content)) {
                    actions[1].push({
                        icon: "edit",
                        title: localize("AndraNamn"),
                        onClick: () => {
                            this.setState(
                                {
                                    contentRow: {
                                        ...this.state.contentRow,
                                        uberContentColumns: this.state.contentRow.uberContentColumns.map(
                                            (contentColumn) => {
                                                if (contentColumn.tmpId === cc.tmpId) {
                                                    return {
                                                        ...contentColumn,
                                                        state: SpintrTypes.UberContentSectionState.IsEditing,
                                                    };
                                                } else {
                                                    return contentColumn;
                                                }
                                            }
                                        ),
                                    },
                                },
                                this.postUpdatedRowToParent
                            );
                        },
                    });
                }
            }
        }

        if (cc.state === SpintrTypes.UberContentSectionState.IsDisplayingPlusSign) {
            actions[1].push({
                icon: "trash",
                title: localize("TaBortSektionen"),
                onClick: () => {
                    let columnTmpId = cc.tmpId;
                    let rowTmpId = this.props.contentRow.tmpId;
                    let isMultipleColumns = this.props.contentRow.uberContentColumns.length > 1;

                    if (isMultipleColumns) {
                        this.setState({
                            contentRow: {
                                ...this.state.contentRow,
                                uberContentColumns: this.state.contentRow.uberContentColumns.filter(contentColumn => {
                                    return contentColumn.tmpId !== columnTmpId
                                })
                            }
                        }, this.postUpdatedRowToParent);
                    } else {
                        this.props.contentRow.removeRow(rowTmpId);
                    }
                }
            });
        }

        if (cc.state !== SpintrTypes.UberContentSectionState.IsDisplayingPlusSign) {
            actions[1].push({
                icon: "trash",
                title: localize("TaBort"),
                onClick: () => {
                    this.props.dispatch(setConfirmPopup({
                        isOpen: true,
                        message: localize("ArDuSakerPaAttDuVillTaBortDettaInnehall"),
                        onConfirm: () => {
                            this.setState({
                                contentRow: {
                                    ...this.state.contentRow,
                                    uberContentColumns: this.state.contentRow.uberContentColumns.map(contentColumn => {
                                        if (contentColumn.tmpId === cc.tmpId) {
                                            return {
                                                ...contentColumn,
                                                state: SpintrTypes.UberContentSectionState.IsDisplayingPlusSign,
                                                content: ""
                                            }
                                        } else {
                                            return contentColumn;
                                        }
                                    })
                                }
                            }, this.postUpdatedRowToParent);
                        }
                    }));
                }
            });
        }

        const displayBorder = true;

        return (
            <div className="content-column" key={cc.tmpId}>
                <DivWithHoverActions disableHoverEffects={false}
                    actions={actions}
                    displayBorder={displayBorder}
                    displayActionsVertically={!this.props.isSmallViewMode && !this.state.isKeyboardNavigation}>
                    {
                        this.contentTypes.find(c => c.typeId == cc.type).render(cc)
                    }
                </DivWithHoverActions>
            </div>
        )
    }

    private renderContentRow() {
        return (
            <div className={[
                "content-row",
                "column-count-" + this.state.contentRow.uberContentColumns.length
            ].join(" ")}>
                {
                    this.state.contentRow.uberContentColumns.map(cc => {
                        return this.renderContentColumn(cc, this.state.contentRow.uberContentColumns.length);
                    })
                }
            </div>
        )
    }

    private handleDismissDialog = () => {
        this.setState({
            showFormSelectorDialog: false
        });
    }

    private handleConfirmDialog = () => {
        this.setState({
            contentRow: {
                ...this.state.contentRow,
                uberContentColumns: this.state.contentRow.uberContentColumns.map(contentColumn => {
                    if (contentColumn.tmpId === this.state.columnTmpIdToAddFormTo) {
                        return {
                            ...contentColumn,
                            content: '<div><span class="redactorInlineFolder form-' +
                                this.state.selectedForm.id +
                                '">' +
                                this.state.selectedForm.name +
                                '</span></div>',
                            state: SpintrTypes.UberContentSectionState.IsDisplayed
                        }
                    } else {
                        return contentColumn;
                    }
                })
            },
            showFormSelectorDialog: false
        }, this.postUpdatedRowToParent);
    }

    private handleConfirmCalendarDialog = () => {
        this.setState({
            contentRow: {
                ...this.state.contentRow,
                uberContentColumns: this.state.contentRow.uberContentColumns.map(contentColumn => {
                    if (contentColumn.tmpId === this.state.columnTmpIdToAddCalendarTo) {
                        return {
                            ...contentColumn,
                            content: '<div><span class="redactorInlineFolder calendar-' +
                                this.state.selectedCalendar.id +
                                '">' +
                                this.state.selectedCalendar.name +
                                '</span></div>',
                            state: SpintrTypes.UberContentSectionState.IsDisplayed
                        }
                    } else {
                        return contentColumn;
                    }
                })
            },
            showCalendarSelectorDialog: false
        }, this.postUpdatedRowToParent);
    }

    private toggleFilePicker = () => {
        this.setState((prevState) => ({
            showFilePicker: !prevState.showFilePicker
        }));
    }

    private onChangeForm = (event: React.FormEvent<IComboBox>,
        option?: IComboBoxOption,
        index?: number,
        value?: string) => {
        this.setState({
            selectedForm: this.state.forms.find(f => f.id === option.key)
        });
    }

    private renderFormSelectorDialog() {
        let comboBoxBasicOptions: IComboBoxOption[] = [];

        if (!!this.state.forms) {
            this.state.forms.map((f) => {
                comboBoxBasicOptions.push({
                    key: f.id,
                    text: f.name
                });
            });
        }

        return (
            <CustomDialog
                title={localize("SELECT_FORM")}
                show={this.state.showFormSelectorDialog}
                onDismiss={this.handleDismissDialog}
                onConfirm={this.handleConfirmDialog}
                children={(
                    <div>
                        {
                            this.state.isLoadingForms ?
                                <Loader /> :
                                <div>
                                    <ComboBox
                                        selectedKey={this.state.selectedForm ? this.state.selectedForm.id : null}
                                        onChange={this.onChangeForm}
                                        options={comboBoxBasicOptions}
                                    />
                                </div>
                        }
                    </div>
                )}
            />
        )
    }

    private renderCalendarSelectorDialog() {
        let comboBoxBasicOptions: IComboBoxOption[] = [];

        if (!!this.state.calendars) {
            this.state.calendars.map((f) => {
                comboBoxBasicOptions.push({
                    key: f.id,
                    text: f.name
                });
            });
        }

        return (
            <CustomDialog
                title={localize("ValjKalender")}
                show={this.state.showCalendarSelectorDialog}
                onDismiss={() => { this.setState({ showCalendarSelectorDialog: false }) }}
                onConfirm={this.handleConfirmCalendarDialog}
                children={(
                    <div>
                        {
                            this.state.isLoadingCalendars ?
                                <Loader /> :
                                <div>
                                    <ComboBox
                                        selectedKey={this.state.selectedCalendar ? this.state.selectedCalendar.id : null}
                                        onChange={(_ev, option) => { this.setState({ selectedCalendar: this.state.calendars.find(f => f.id === option.key) }) }}
                                        options={comboBoxBasicOptions}
                                    />
                                </div>
                        }
                    </div>
                )}
            />
        )
    }

    private onSelectFilePicker = (data, source) => {
        let content: string;

        if (source === SpintrTypes.FolderRootSource.SharePointSearch) {
            content =
                '<div><div><span class="redactorInlineFolder sharePoint">[@SharePointListSearch:' +
                data.id +
                "@]</span></div>" +
                data.name +
                '<div><span class="redactorInlineFolder">[/@SharePointListSearch@]</span></div></div><br />';
        } else if ((source === SpintrTypes.FolderRootSource.Office365Public || source === SpintrTypes.FolderRootSource.Office365Private) && !data[0].isDirectory) {
            content =
                '<div><div><span class="redactorSingleFile external">[@ExternalFile:' +
                data[0].id +
                ":" +
                source +
                "@]</span></div>" +
                data[0].name +
                '<div><span class="redactorSingleFile external">[/@ExternalFile@]</span></div></div><br />';
        } else if ((source === SpintrTypes.FolderRootSource.Office365Public || source === SpintrTypes.FolderRootSource.Office365Private) && data[0].isDirectory) {
            content =
                '<div><div><span class="redactorInlineFolder external">[@ExternalFolder:' +
                data[0].id +
                ":" +
                source +
                "@]</span></div>" +
                data[0].name +
                '<div><span class="redactorInlineFolder external">[/@ExternalFolder@]</span></div></div><br />';
        } else if (source === SpintrTypes.FolderRootSource.GoogleDrive) {
            const typeStr = data[0].isDirectory ? "Folder" : "File";

            content =
                `<div><div><span class="redactorSingleFile external">[@External${typeStr}:` +
                data[0].id +
                ":" +
                source +
                "@]</span></div>" +
                data[0].name +
                `<div><span class="redactorSingleFile external">[/@External${typeStr}@]</span></div></div><br />`;
        } else if (data[0].isDirectory) {
            content =
                '<div><div><span class="redactorInlineFolder">[@Folder:' +
                data[0].id +
                "@]</span></div>" +
                data[0].name +
                '<div><span class="redactorInlineFolder">[/@Folder@]</span></div></div><br />';
        } else {
            content =
                '<div><div><span class="redactorSingleFile">[@File:' +
                data[0].id +
                "@]</span></div>" +
                data[0].name +
                '<div><span class="redactorSingleFile">[/@File@]</span></div></div><br />';
        }

        this.setState(
            {
                contentRow: {
                    ...this.state.contentRow,
                    uberContentColumns: this.state.contentRow.uberContentColumns.map((contentColumn) => {
                        if (contentColumn.tmpId === this.state.columnTmpIdToAddFileTo) {
                            return {
                                ...contentColumn,
                                content,
                                state: SpintrTypes.UberContentSectionState.IsDisplayed,
                            };
                        } else {
                            return contentColumn;
                        }
                    }),
                },
                showFormSelectorDialog: false,
            },
            this.postUpdatedRowToParent
        );
    }

    private renderFilePicker() {
        if (!this.state.showFilePicker) {
            return null;
        }

        return (
            <FilePicker
                onClose={this.toggleFilePicker}
                onSelect={this.onSelectFilePicker}
                showFiles
                selectFolders
                sourcesToDisplay={[
                    SpintrTypes.FolderRootSource.SpintrPublic,
                    SpintrTypes.FolderRootSource.Office365Public,
                    ...(this.props.enableSharePointListSearch ? [SpintrTypes.FolderRootSource.SharePointSearch] : []),
                    SpintrTypes.FolderRootSource.GoogleDrive,
                ]}
                source={SpintrTypes.FolderRootSource.SpintrPublic}
            />
        );
    }
}

const mapStateToProps = (state: IApplicationState, props) => {
    return {
        ...props,
        enableCrocus: state.instance.get("crocusAvailable"),
        enableExtendedPageBlockTypes: state.instance.get("enableExtendedPageBlockTypes"),
        enableMediaflow: state.instance.get("enableMediaflow"),
        enableUnsplash: state.instance.get("enableUnsplash"),
        enableSharePointListSearch: state.profile.active.office365Connected && state.instance.get("enableSharePointListSearch"),
        enableDirekten: state.instance.get("enableDirekten"),
        enableTaxiFiles: state.instance.get("enableTaxiFiles"),
        instance: state.instance,
        isSmallViewMode: state.ui.isSmallViewMode,
        apps: state.app
    };
};

export default connect(mapStateToProps)(EditableContentSectionsRow)
