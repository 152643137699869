import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Action } from "redux";
import { localize } from "src/l10n";
import { IActiveUserProfile } from "src/profile/reducer";
import api from "src/spintr/SpintrApi";
import { PageHeader } from "src/ui";
import SpintrList from "src/ui/components/SpintrList/SpintrList";
import SuppliersBreadcrumbs from "./SuppliersBreadcrumbs";
import { updateSidebar } from "./utils";

interface IProps {
    instance?: any;
    currentUser: IActiveUserProfile;
    isAdmin?: boolean;
    isEditor?: boolean;
    dispatch: (action: Action) => void;
    appName: string;
}

interface IState {
    includeDeleted: boolean;
    roles: any[];
    suppliers: any[];
    activeRole?: number;
    activeSupplier?: number;
    isAscending: boolean;
    orderByColumn: string;
    searchText: string;
}

class SuppliersContactPersonsView extends Component<IProps, IState> {
    private listRef = React.createRef<SpintrList>();

    constructor(props: IProps) {
        super(props);

        this.state = {
            includeDeleted: false,
            isAscending: false,
            orderByColumn: "",
            roles: [],
            searchText: "",
            suppliers: [],
        };
    }

    public componentDidMount() {
        updateSidebar(this.props.isAdmin, this.props.isEditor, this.props.appName, this.props.dispatch);
        this.fetchRoles();
        this.fetchSuppliers();
    }

    public render() {
        return (
            <div className="SuppliersContactPersonsView">
                <div className="breadcrumbs-wrapper">
                    <SuppliersBreadcrumbs />
                </div>
                <Helmet>
                    <title>{localize("Kontaktpersoner")}</title>
                </Helmet>
                <PageHeader title={localize("Kontaktpersoner")} />
                <SpintrList
                    ref={this.listRef}
                    fetch={this.fetchList}
                    orderByColumn="FullName"
                    columns={[
                        { name: localize("Namn"), fieldName: "fullName" },
                        { name: localize("Leverantor"), fieldName: "supplierName" },
                        { name: localize("Roll"), fieldName: "role", minWidth: 200 },
                    ]}
                    optionItems={
                        this.props.isAdmin
                            ? {
                                  items: [
                                      {
                                          key: "includeDeleted",
                                          text: localize("VisaBorttagna"),
                                          onClick: () => {
                                              this.setState(
                                                  (prevState) => ({
                                                      includeDeleted: !prevState.includeDeleted,
                                                  }),
                                                  () => {
                                                      this.listRef.current.fetch();
                                                  }
                                              );
                                          },
                                          ...(this.state.includeDeleted
                                              ? {
                                                    iconProps: {
                                                        iconName: "CheckMark",
                                                    },
                                                }
                                              : {}),
                                      },
                                      {
                                        key: "export",
                                        text: localize("ExporteraLista"),
                                        onClick: async () => {
                                            api.get("/api/v1/suppliers/contactpersons", {
                                                params: {
                                                    includeDeleted: this.state.includeDeleted,
                                                    skip: 0,
                                                    take: 9999,
                                                    supplierId: this.state.activeSupplier,
                                                    roleId: this.state.activeRole,
                                                },
                                            }).then((response: any) => {
                                                const rows = [
                                                    [localize("Foretag"), localize("Namn"), localize("Roll"), localize("EMAIL_ADDRESS"), localize("PHONE_NUMBER")],
                                                    ...(response.data.items || []).map((item: any) => {
                                                        const role = (item.roles || []).map(x => x.name).join(", ");

                                                        return [
                                                            item.supplierName,
                                                            item.fullName,
                                                            role,
                                                            item.email,
                                                            item.phoneNumber,
                                                        ];
                                                    })
                                                ];

                                                api.post("/api/files/generate-excel-file", rows).then((response) => {
                                                    const link = document.createElement("a");
                                                    link.setAttribute("href", response.data);
                                                    link.setAttribute("target", "_blank");
                                                    link.style.visibility = "hidden";
                                                    document.body.appendChild(link);
                                                    link.click();
                                                    document.body.removeChild(link);
                                                }).catch(() => { });
                                            }).catch(() => { });
                                        },
                                      },
                                  ],
                              }
                            : undefined
                    }
                    buttons={[
                        {
                            key: "supplier",
                            text: `${localize("Leverantor")}${
                                this.state.activeSupplier
                                    ? ` - ${
                                          this.state.suppliers.find(
                                              (supplier) => supplier.id === this.state.activeSupplier
                                          ).name
                                      }`
                                    : ``
                            }`,
                            subMenuProps: {
                                items: [
                                    ...this.state.suppliers.map((supplier) => ({
                                        text: supplier.name,
                                        key: supplier.id,
                                        onClick: () => {
                                            this.setState(
                                                {
                                                    activeSupplier:
                                                        supplier.id === this.state.activeSupplier
                                                            ? undefined
                                                            : supplier.id,
                                                },
                                                this.listRef.current.fetch
                                            );
                                        },
                                        ...(supplier.id === this.state.activeSupplier
                                            ? {
                                                  iconProps: {
                                                      iconName: "CheckMark",
                                                  },
                                              }
                                            : {}),
                                    })),
                                    {
                                        key: "all",
                                        text: localize("VisaAlla"),
                                        onClick: () => {
                                            this.setState(
                                                {
                                                    activeSupplier: undefined,
                                                },
                                                this.listRef.current.fetch
                                            );
                                        },
                                        ...(this.state.activeSupplier === undefined
                                            ? {
                                                  iconProps: {
                                                      iconName: "CheckMark",
                                                  },
                                              }
                                            : {}),
                                    },
                                ],
                            },
                        },
                        {
                            key: "role",
                            text: `${localize("Roll")}${
                                this.state.activeRole
                                    ? ` - ${this.state.roles.find((role) => role.id === this.state.activeRole).name}`
                                    : ``
                            }`,
                            subMenuProps: {
                                items: [
                                    ...this.state.roles.map((role) => ({
                                        text: role.name,
                                        key: role.id,
                                        onClick: () => {
                                            this.setState(
                                                {
                                                    activeRole: role.id === this.state.activeRole ? undefined : role.id,
                                                },
                                                this.listRef.current.fetch
                                            );
                                        },
                                        ...(role.id === this.state.activeRole
                                            ? {
                                                  iconProps: {
                                                      iconName: "CheckMark",
                                                  },
                                              }
                                            : {}),
                                    })),
                                    {
                                        key: "all",
                                        text: localize("VisaAlla"),
                                        onClick: () => {
                                            this.setState(
                                                {
                                                    activeRole: undefined,
                                                },
                                                this.listRef.current.fetch
                                            );
                                        },
                                        ...(this.state.activeRole === undefined
                                            ? {
                                                  iconProps: {
                                                      iconName: "CheckMark",
                                                  },
                                              }
                                            : {}),
                                    },
                                ],
                            },
                        },
                    ]}
                />
            </div>
        );
    }

    private fetchRoles = async () => {
        const response = await api.get("/api/suppliers/roles");

        this.setState({
            roles: response.data,
        });
    };

    private fetchSuppliers = async () => {
        const response = await api.get("/api/suppliers", {
            params: { skip: 0, take: 999, orderByColumn: "Name", isAscending: true },
        });

        this.setState({
            suppliers: response.data.items,
        });
    };

    private fetchList = (skip, take, columnId, isAscending, searchQuery) => {
        return new Promise(async (resolve, reject) => {
            this.setState({
                isAscending,
                orderByColumn: columnId,
                searchText: searchQuery,
            });

            const response = await api.get("/api/v1/suppliers/contactpersons", {
                params: {
                    includeDeleted: this.state.includeDeleted,
                    isAscending,
                    orderByColumn: columnId,
                    searchText: searchQuery,
                    skip,
                    take,
                    supplierId: this.state.activeSupplier,
                    roleId: this.state.activeRole,
                },
            });

            const items = response.data.items.map((item) => ({
                ...item,
                role: item.roles.map((role: any) => {
                    return role.name
                }).join(", "),
            }));

            resolve({
                data: items,
                totalCount: response.data.totalCount,
            });
        });
    };
}

const mapStateToProps = (state: Spintr.AppState, props) => ({
    ...props,
    instance: state.instance,
    currentUser: state.profile.active,
    isAdmin: state.profile.active.isAdmin,
    isEditor: state.profile.active.isEditor,
    appName: state.instance.get("suppliersAppName"),
});

export default withRouter(connect(mapStateToProps)(SuppliersContactPersonsView));
