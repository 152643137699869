import { AxiosResponse } from "axios";
import { Action } from "redux";

import {
    AddToFavoruteAction,
    RemoveFavoriteAction,

    StartFollowingObjectAction,
    StopFollowingObjectAction,
} from "src/spintr/actions";
import api from "src/spintr/SpintrApi";
import { SocialFeedActionTypes } from "./action-types";
import {
    fetchCombinedFeed,
    fetchGeneralFeed,
    fetchGroupFeed,
    fetchProfileFeed
} from "./api";
import { FeedRequest, SocialFeedType } from "./types";

interface IFetchSocialFeedBaseAction {
    meta: FeedRequest;
}

export interface IFetchSocialFeedAction extends Action, IFetchSocialFeedBaseAction {
    payload: Promise<AxiosResponse<Spintr.ISocialPostBase[]>>;
    type: SocialFeedActionTypes.FetchSocialFeed;
}

export interface IFetchSocialFeedPendingAction extends Action, IFetchSocialFeedBaseAction {
    type: SocialFeedActionTypes.FetchSocialFeedPending;
}

export interface IFetchSocialFeedRejectedAction extends Action, IFetchSocialFeedBaseAction {
    type: SocialFeedActionTypes.FetchSocialFeedRejected;
}

export interface IFetchSocialFeedFulfilledAction extends
    Spintr.IAsyncAction<Spintr.ISocialPostBase[], FeedRequest>,
    IFetchSocialFeedBaseAction {
    type: SocialFeedActionTypes.FetchSocialFeedFulfilled;
}

export const getFeedRequest = (req: FeedRequest) => {
    switch (req.feed) {
        case SocialFeedType.Combined:
            return fetchCombinedFeed(req.params);
        case SocialFeedType.General:
            return fetchGeneralFeed(req.params);
        case SocialFeedType.Group:
            return fetchGroupFeed(req.params);
        case SocialFeedType.Profile:
            return fetchProfileFeed(req.params);
    }

    return null;
};

export interface IClearFeedAction extends Action, IFetchSocialFeedBaseAction {
    type: SocialFeedActionTypes.ClearFeed;
}
export const clearFeed = (feedType: SocialFeedType) => ({
    meta: {
        feedType
    },
    type: SocialFeedActionTypes.ClearFeed
});

export type SocialFeedFetcher = (req: FeedRequest) => IFetchSocialFeedAction;
export const fetchSocialFeed: SocialFeedFetcher = (req: FeedRequest) => ({
    meta: req,
    payload: getFeedRequest(req),
    type: SocialFeedActionTypes.FetchSocialFeed
});


export interface ILocalPostCreatedAction extends Action {
    post: Spintr.ISocialPostBase;
    type: SocialFeedActionTypes.LocalPostCreated;
    enableProjectPostsOnStartpage?: boolean;
}
export type LocallyCreatedPostAdder = (post: Spintr.ISocialPostBase) => ILocalPostCreatedAction;
export const addLocallyCreatedPost: LocallyCreatedPostAdder = (post) => ({
    post,
    type: SocialFeedActionTypes.LocalPostCreated,
});


export interface IRemotePostCreatedAction extends Action {
    post: Spintr.ISocialPostBase;
    type: SocialFeedActionTypes.RemotePostCreated;
    enableProjectPostsOnStartpage?: boolean;
}
export type RemotePostCreatedHandler = (post: Spintr.ISocialPostBase, enableProjectPostsOnStartpage?: boolean) => IRemotePostCreatedAction;
export const addRemotePost: RemotePostCreatedHandler = (post, enableProjectPostsOnStartpage?: boolean) => ({
    post,
    type: SocialFeedActionTypes.RemotePostCreated,
    enableProjectPostsOnStartpage
});

export interface IRemotePostUpdatedAction extends Action {
    post: Spintr.ISocialPostBase;
    type: SocialFeedActionTypes.RemotePostUpdated;
}
export type RemotelyUpdatedPostHandler = (post: Spintr.ISocialPostBase) => IRemotePostUpdatedAction;
export const updateRemotePost: RemotelyUpdatedPostHandler = (post) => ({
    post,
    type: SocialFeedActionTypes.RemotePostUpdated,
});

export interface IRemotePostDeletedAction extends Action {
    id: number;
    type: SocialFeedActionTypes.RemotePostDeleted;
    uberType: Spintr.UberType;
}
export type RemotelyDeletedPostHandler = (id: number, uberType: Spintr.UberType) => IRemotePostDeletedAction;
export const deleteRemotePost: RemotelyDeletedPostHandler = (id, uberType) => ({
    id,
    type: SocialFeedActionTypes.RemotePostDeleted,
    uberType,
});

export interface IRemotePostPinAction extends Action {
    id: number;
    type: SocialFeedActionTypes.RemotePostPinned;
    value: boolean;
}
export type RemotelyPinPostHandler = (id: number, value: boolean) => IRemotePostPinAction;
export const pinRemotePost: RemotelyPinPostHandler = (id, value) => ({
    id,
    type: SocialFeedActionTypes.RemotePostPinned,
    value
})

export interface ISetFiltersAction extends Action {
    data: any,
    type: SocialFeedActionTypes.SetFilters
}
export type SetFiltersHandler = (data: any) => ISetFiltersAction;
export const setFilters: SetFiltersHandler = (data: any) => ({
    data,
    type: SocialFeedActionTypes.SetFilters,
});

export interface IVerifyQuestionAction extends Action {
    meta: any;
    payload: Promise<AxiosResponse<string>>;
    type: SocialFeedActionTypes.VerifyQuestion;
}

export interface IVerifyQuestionFulfilledAction extends Spintr.IAsyncAction<any> {
    meta: any;
    type: SocialFeedActionTypes.VerifyQuestionFulfilled;
}

export interface IVerifyQuestionPendingAction extends Action {
    meta: any;
    type: SocialFeedActionTypes.VerifyQuestionPending;
}

export interface IVerifyQuestionRejectedAction extends Spintr.IAsyncActionError {
    meta: any;
    type: SocialFeedActionTypes.VerifyQuestionRejected;
}

export type VerifyQuestionAction =
    IVerifyQuestionAction
    | IVerifyQuestionFulfilledAction
    | IVerifyQuestionPendingAction
    | IVerifyQuestionRejectedAction;

export type VerifyQuestionHandler = (id: number, value: boolean) => IVerifyQuestionAction;
export const verifyQuestion: VerifyQuestionHandler =
    (id, value) => ({
        meta: {
            id,
            value
        },
        payload: api.post<never>(
            "/api/v1/objects/verify-question", { id, value },
        ),
        type: SocialFeedActionTypes.VerifyQuestion,
    });

export interface IRemoteVerifyQuestionAction extends Action {
    meta: any;
    type: SocialFeedActionTypes.RemoteVerifyQuestion;
    value: boolean;
}
export type RemotelyVerifyQuestionHandler = (id: number, value: boolean) => IRemoteVerifyQuestionAction;
export const remoteVerifyQuestion: RemotelyVerifyQuestionHandler = (id, value) => ({
    meta: {
        id,
        value
    },
    type: SocialFeedActionTypes.RemoteVerifyQuestion,
    value
});

export interface IRemotePollVoteAction extends Action {
    item: Spintr.IPollVoteMessage;
    type: SocialFeedActionTypes.RemotePollVote
}

export type RemotePollVoteHandler = (item: Spintr.IPollVoteMessage) => IRemotePollVoteAction;

export const remotePollVote: RemotePollVoteHandler = (item) => ({
    item,
    type: SocialFeedActionTypes.RemotePollVote,
});

export type SocialFeedAction =
    IFetchSocialFeedAction
    | IFetchSocialFeedPendingAction
    | IFetchSocialFeedRejectedAction
    | IFetchSocialFeedFulfilledAction

    | ILocalPostCreatedAction

    | IRemotePostCreatedAction
    | IRemotePostDeletedAction
    | IRemotePostPinAction
    | IRemotePostUpdatedAction
    | IRemoteVerifyQuestionAction

    | AddToFavoruteAction
    | RemoveFavoriteAction

    | StartFollowingObjectAction
    | StopFollowingObjectAction
    | IClearFeedAction

    | ISetFiltersAction
    | IVerifyQuestionPendingAction
    | IRemotePollVoteAction;
