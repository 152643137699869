import React from "react";
import AcademyCourseListItem from "src/academy/courses/AcademyCourseListItem/AcademyCourseListItem";
import { IAcademyCourse } from "src/academy/types";
import { Label } from "src/ui";
import "./AcademyCourseList.scss";

interface IProps {
    courses: IAcademyCourse[];
    title?: string;
    big?: boolean;
}

const AcademyCourseList = (props: IProps) => {
    return (
        <div className="AcademyCourseList">
            {props.title && (
                <Label size="h5" weight="medium">{props.title}</Label>
            )}
            <div className="courses">
                {props.courses.map((x: IAcademyCourse) => {
                    return (
                        <AcademyCourseListItem key={x.id} course={x} big={props.big} />
                    )
                })}
            </div>
        </div>
    )
}

export default AcademyCourseList;
