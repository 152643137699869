import moment from "moment";

/**
 * Compares two dates to determine if they refer to the same date
 * 
 * @param a A date
 * @param b A date
 * 
 * @returns true if dates refer to the same date, otherwise false
 */
export function isSameDate(a: Date, b: Date): boolean {
    return (
        a.getFullYear() === b.getFullYear()
        &&
        a.getMonth() === b.getMonth()
        &&
        a.getDate() === b.getDate()
    );
}

export function fromNow(date: Date | string, max24h?: boolean): string {
    if (typeof date === "string") {
        date = new Date(date);
    }

    const timeLeft = moment(date).fromNow();
    const diffWeeks = moment().diff(date, "weeks");
    
    if (max24h) {
        const diffHours = moment().diff(date, "hours");
        if (diffHours > 23) {
            return moment(date).format("LL");
        }
    }

    if (diffWeeks <= 2) {
        return timeLeft;
    } else {
        return moment(date).format("LL");
    }
}