import { DirectionalHint, IIconProps, Image, Shimmer, TooltipDelay, TooltipHost } from '@fluentui/react';
import React, { ChangeEvent, Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { localize } from 'src/l10n';
import { VisageSidebarMode } from 'src/sidebar';
import { setSidebarItemExpanded, setSidebarItems, setSidebarMode } from 'src/sidebar/actions';
import { IVisageSidebarMenuItem } from 'src/sidebar/reducer';
import { IApplicationState } from 'src/spintr/reducer';
import { Label, UnstyledButton } from 'src/ui';
import SpintrSearch from 'src/ui/components/SpintrList/SpintrSearch';
import UnreadIndicator from "src/ui/components/UnreadIndicator/UnreadIndicator";
import { Style } from 'src/ui/helpers';
import Visage2Icon from 'src/visage2/Visage2Icon/Visage2Icon';
import { isSidebarItemActive, mixpanelTrack } from 'src/utils';
import './VisageSidebar.scss';
import { getSubMenu } from 'src/sidebar/utils';
import SpintrLoader from 'src/ui/components/Loader';
import VisageSidebarLines from './VisageSidebarLines';
import StartViewRefetchButton from './StartViewRefetchButton';

// interface IPageRouteParams {
//     history: any;
//     location: any;
// }

interface IProps {
    item: IVisageSidebarMenuItem;
    history?: any;
    location?: any;
    sidebarMode?: VisageSidebarMode;
    instance?: any;
    dispatch?: any;
    unreadCountGroupConversations1?: number;
    unreadCountGroupConversations2?: number;
    hasFetchedFetchTypes?: any;
    sidebarItems?: IVisageSidebarMenuItem[];
    forceActive?: boolean;
    currentUser: any;
}

interface IState {
    searchValue: string;
}

class VisageSidebarItem extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            searchValue: ""
        }
    }

    render() {
        return this.renderItem(this.props.item);
    }

    moduleKeysWithSubmenu = ["blogtop",
        "admin",
        "files",
        "gallerytop",
        "news",
        "departmenttop",
        "kontordir",
        "departmentparent",
        "qa",
        "suppliers",
        "people",
        "textpage_",
        "bookings"];

    getGroupUnreadCount() {
        if (!!this.props.hasFetchedFetchTypes &&
            this.props.hasFetchedFetchTypes.indexOf(3) > -1) {
            return this.props.unreadCountGroupConversations2 || 0;
        }

        return !!this.props.unreadCountGroupConversations2 ?
            this.props.unreadCountGroupConversations2 :
            this.props.unreadCountGroupConversations1;
    }

    renderItem(item: IVisageSidebarMenuItem, isChild?: boolean) {
        const filterIcon: IIconProps = { iconName: 'Search' };

        let wrapperClassNames = ["VisageSidebar-menuItem-wrapper"]

        let classNames = ["VisageSidebar-menuItem"];

        if (item.small) {
            classNames.push("VisageSidebar-menuItem-small")
        }

        let active = (!isChild && !!this.props.forceActive) || isSidebarItemActive(item);

        if (active) {
            classNames.push("VisageSidebar-menuItem-active")
        }

        if (isChild) {
            wrapperClassNames.push("VisageSidebar-menuItem-isChild")
        }

        if (item.isExpanded) {
            wrapperClassNames.push("VisageSidebar-menuItem-wrapper-expanded")
        }

        let children = !!item.children ?
            item.children :
            [];

        if (!!this.state.searchValue) {
            let allSearchableItems = [];

            const addItems = (items: IVisageSidebarMenuItem[]) => {
                for (let item of items) {
                    if (!!item.route) {
                        allSearchableItems.push(item);
                    }

                    if (!!item.children) {
                        addItems(item.children);
                    }
                }
            }

            addItems(children);

            const searchResult = allSearchableItems.filter((i: IVisageSidebarMenuItem) => i.name.toLowerCase().indexOf(this.state.searchValue.toLowerCase()) > -1);

            children = searchResult;
        }

        const showChevron = (!!item.children && item.children.length > 0) || item.lazyLoadChildren;

        return (
            <div className={wrapperClassNames.join(" ")} key={item.key}>
                <UnstyledButton
                    id={item.dummyClassName}
                    className={classNames.join(" ")}
                    title={item.name}
                    onClick={() => {
                        if (this.props.location.pathname === "/") {
                            mixpanelTrack("Clicked item in menu on start page", {
                                name: item.name,
                            });
                        }

                        if (item.onClick && item.onClick(item)) {
                            return;
                        }

                        const isExternal = !!item.route && item.route.indexOf("http") === 0;

                        if (isExternal) {
                            if (item.key === "updates") {
                                // @ts-ignore
                                const hw = window.Headway;

                                if (!!hw) {
                                    // @ts-ignore
                                    hw.show();

                                    setTimeout(() => {
                                        // @ts-ignore
                                        hw.hide();
                                    }, 1000);
                                }
                            }

                            return window.open(item.route, "_blank");
                        }

                        if (item.route) {
                            this.props.history.push({
                                pathname: item.route,
                                search: !!item.search ? item.search : undefined
                            });
                        }
                        
                        if (item.children) {
                            this.props.dispatch(setSidebarItemExpanded(item.key, !item.isExpanded));
                        }
                    }}>
                    <div className={"VisageSidebar-menuItem-titleRow icon-animation animation-pulse" + (!!item.menuClassName ? " " + item.menuClassName : "")}>
                        {
                            !isChild && (
                                (!item.imageUrl || item.moduleKey.substring(0, 8) !== 'AppPage_')
                                    ? (
                                        <Visage2Icon
                                            icon={item.icon || "document"}
                                            type={!!active ? "bold" : "outline"}
                                            hexColor={!!active ? this.props.instance.get("color") : Style.getHexFromSpintrColor("dark-grey")}
                                            size={"medium"}
                                        />
                                    )
                                    : (
                                        <Image
                                            className="VisageSidebar-menuItem-image"
                                            height={20}
                                            src={item.imageUrl}
                                            width={20}
                                        />
                                    )
                            )
                        }
                        <Label
                            as="span"
                            className={"general-row-break" + (!!active ? " primaryFGColor" : "")}
                            color={"dark-grey"}
                            size={"body-2"}
                            style={{
                                color: (item.color && (!!active || item?.moduleKey?.startsWith("AppPage_"))) ? ('#' + item.color) : undefined
                            }}
                            weight={!!active ? "medium" : "normal"}
                        >
                            {item.name}
                        </Label>
                        {item.key === "admin-Dashboard" && (
                            <Label size='small-1' weight='semi-bold' style={{ top: -3, marginLeft: 4, letterSpacing: 1 }}>BETA</Label>
                        )}
                        {
                            !!item.dummyClassName && (
                                <span className={item.dummyClassName} />
                            )
                        }
                    </div>
                    {item.moduleKey === "project" && (
                        <div className="UnreadIndicatorWrapper">
                            <UnreadIndicator count={this.getGroupUnreadCount()} />
                        </div>
                    )}
                    {showChevron && !item.isLoading && (
                        <div
                            role="button"
                            tabIndex={0}
                            className="VisageSidebar-menuItem-chevron"
                            title={localize(item.isExpanded ? "Minimera" : "VisaAlternativ")}
                            onKeyDown={(e) => {
                                if (e.which === 13) {
                                    e.preventDefault();
                                    e.stopPropagation();

                                    this.props.dispatch(setSidebarItemExpanded(item.key, !item.isExpanded));
                                }
                            }} onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();

                                const doLazyLoad = item.lazyLoadChildren &&
                                    !item.isLoading &&
                                    (!item.children || item.children.length === 0)

                                this.props.dispatch(setSidebarItemExpanded(item.key, !item.isExpanded, doLazyLoad));

                                if (doLazyLoad) {
                                    getSubMenu(item.moduleKey, this.props.dispatch, this.props.currentUser, this.props.instance).then((items: IVisageSidebarMenuItem[]) => {
                                        this.props.dispatch(setSidebarItems(items, item.moduleKey));
                                    }).catch(() => { })
                                }
                            }}>
                            <Visage2Icon icon={item.isExpanded ? "arrow-up-2" : "arrow-down-1"} size="small" />
                        </div>
                    )}
                    {item.isLoading && (
                        <SpintrLoader loaderSize="small" />
                    )}
                    {item.key === "home" && active && (
                        <StartViewRefetchButton />
                    )}
                </UnstyledButton>
                {/* {
                    item.isLoading && (
                        <div className="VisageSidebar-menuItem-shimmer">
                            <Shimmer />
                            <Shimmer />
                            <Shimmer />
                        </div>
                    )
                } */}
                {item.isExpanded && !!item.children && item.enableChildrenSearch && (
                    <SpintrSearch
                        classes="searchBox sidebarSearch"
                        value={this.state.searchValue}
                        onClear={() => {
                            this.setState({
                                searchValue: ""
                            });
                        }}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            if (!event) {
                                return;
                            }

                            const { value } = event.target;

                            this.setState({
                                searchValue: value
                            });
                        }} />
                )}
                {item.isExpanded && !!item.children && item.children.length > 0 && (
                    <div className="VisageSidebar-menuItem-children">
                        <VisageSidebarLines items={item.children} />
                        {
                            children.map((child: IVisageSidebarMenuItem, index: number) => {
                                return this.renderItem(child, true);
                            })
                        }
                    </div>
                )}
            </div>
        )
    }
}

const mapStateToProps = (state: IApplicationState) => ({
    sidebarMode: state.sidebar.mode,
    drawerId: state.sidebar.drawerId,
    instance: state.instance,
    unreadCountGroupConversations1: state.chat.conversations.unreadCountGroupConversations,
    unreadCountGroupConversations2: (state.chat.conversations.items || []).filter((c: any) => c.type === 3 && c.unread > 0).length,
    hasFetchedFetchTypes: state.chat.conversations.hasFetchedFetchTypes,
    sidebarItems: state.sidebar.items,
});

export default withRouter(
    // @ts-ignore
    connect(mapStateToProps)(VisageSidebarItem)
);
