import { AxiosError, AxiosResponse } from "axios";
import { Action } from "redux";

import SearchActionTypes from "./action-types";
import {
    executeMainSearch,
    ISearchParams,
} from "./api";

export interface IClearSearchResultsAction extends Action {
    type: SearchActionTypes.ClearCurrentResults;
}

export type ClearResultsAction = () => IClearSearchResultsAction;
export const clearSearchResults: ClearResultsAction = () => ({
    type: SearchActionTypes.ClearCurrentResults,
});

export interface IExecuteSearchAction extends Action {
    payload: Promise<AxiosResponse<Spintr.ISearchResult[]>>;
    meta?: ISearchParams,
    type: SearchActionTypes.ExecuteSearch;
}

export interface IExecuteSearchFulfilledAction extends Spintr.IResolvedPromiseAction<
    AxiosResponse<Spintr.ISearchResult[]>, ISearchParams | undefined
> {
    type: SearchActionTypes.ExecuteSearchFulfilled;
}

export interface IExecuteSearchPendingAction extends Action {
    type: SearchActionTypes.ExecuteSearchPending;
}

export interface IExecuteSearchRejectedAction extends Spintr.IAsyncActionError<
    AxiosError<any>, ISearchParams | undefined
> {
    type: SearchActionTypes.ExecuteSearchRejected;
}

export type SearchAction =
      IClearSearchResultsAction
    | IExecuteSearchAction
    | IExecuteSearchFulfilledAction
    | IExecuteSearchPendingAction
    | IExecuteSearchRejectedAction;


export type ExecuteSearchThunkPromise = 
    Spintr.ThunkPromise<
        AxiosResponse<Spintr.ISearchResult[]> | AxiosError<any>, 
        IExecuteSearchFulfilledAction | IExecuteSearchRejectedAction
    >;
export type ExecuteSearchAction =
    (params: ISearchParams, cancelToken?) => IExecuteSearchAction | ExecuteSearchThunkPromise;
export const executeSearchAction: ExecuteSearchAction =
    (params: ISearchParams, cancelToken?) => ({
        meta: params, cancelToken,
        payload: executeMainSearch(params, cancelToken),
        type: SearchActionTypes.ExecuteSearch,
    });
