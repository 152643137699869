import { TooltipHost } from "@fluentui/react";
import classNames from "classnames";
import React, { FunctionComponent, useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { addReaction, queryMessages, removeReaction } from "src/chat/redux";
import localize from "src/l10n/localize";
import { setConfirmPopup } from "src/popups/actions";
import api from "src/spintr/SpintrApi";
import { DivWithHoverActions, Label, Loader, SpintrUser, UnstyledButton } from "src/ui";
import { ChatTabContext } from "../ChatTabContext";
import { ChatTabMessage } from "../ChatTabMessage";
import { IUserGroup } from "../ChatTabMessageContainer/types";
import "./ChatTabUserGroup.scss";
import "src/chat/components/ChatMessage/ChatMessage.scss";
import { IQueryMessagesParams, MessageFetchType } from "src/chat/message-types";

interface IProps {
    userGroup: IUserGroup;
    lastRead?: { [messageId: number]: number[] };
    onRespondToMessage: any;
    blocked?: boolean;
    blockedBy?: boolean;
}

const ChatTabUserGroup: FunctionComponent<IProps> = (props) => {
    const context = useContext(ChatTabContext);
    const isSelf = context.currentUser.id === props.userGroup.user.id;
    const dispatch = useDispatch();
    const lastIndex = props.userGroup.messages.length - 1;
    const [isLoading, setIsLoading] = useState(false);

    const getReactions = (message: any) => {
        let reactions = [];

        for (let r of (message.reactions || [])) {
            let alreadyAddedReactionId = reactions.find(rtd => rtd.reactionId === r.reactionId);

            if (!!alreadyAddedReactionId) {
                alreadyAddedReactionId.users.push(r.user);
            } else {
                reactions.push({
                    reactionId: r.reactionId,
                    users: [
                        r.user
                    ]
                })
            }
        }

        return reactions;
    }

    const hexToUnicode = (input) => {
        const code = input - 0x10000;
        const high = (code >> 10) + 0xD800;
        const low = (code & 0x3FF) + 0xDC00;
        const output = String.fromCharCode(high, low);

        return output;
    };

    return (
        <div className={classNames(
            "ChatTabUserGroup", isSelf ? "self" : "",
        )}>
            {!isSelf && (
                <div className="user-column">
                    <SpintrUser
                        hideText={true}
                        personalName={true}
                        imageUrl={props.userGroup.user.imageUrl}
                        name={props.userGroup.user.name}
                        size={30}
                    />
                </div>
            )}
            <div className="messages">
                {props.userGroup.messages.map((m, i) => {
                    const key = m.id;
                    const lastRead = (props.lastRead[m.id] || [])
                        .filter((id) => id !== context.currentUser.id);
                    const reactions = getReactions(m);

                    return (
                        <div className="message" key={key}>
                            <ChatTabMessage
                                onRespondToMessage={props.onRespondToMessage}
                                isFirst={i === 0}
                                isLast={i === lastIndex}
                                isSelf={isSelf}
                                message={m}
                                blocked={props.blocked}
                                blockedBy={props.blockedBy}
                            />
                            {

                                !!reactions &&
                                reactions.length > 0 && (
                                    <div className="ChatMessage-reactions">
                                        <div className="ChatMessage-reactions-inner">
                                            {
                                                reactions.map((item, index) => {
                                                    let tooltipText = "";

                                                    for (const u of item.users) {
                                                        tooltipText += u.name + ", ";
                                                    }

                                                    tooltipText = tooltipText.substring(0, tooltipText.length - 2);

                                                    return (
                                                        <TooltipHost
                                                            key={index}
                                                            content={tooltipText}
                                                        >
                                                            <UnstyledButton
                                                                onClick={() => {
                                                                    const reactionExist = m.reactions.find(r =>
                                                                        r.reactionId === item.reactionId &&
                                                                        r.user.id === context.currentUser.id);

                                                                    if (!!reactionExist) {
                                                                        dispatch(removeReaction(m.id,
                                                                            item.reactionId,
                                                                            context.currentUser.id));
                                                                    }

                                                                    const otherReactions = m.reactions.filter(r =>
                                                                        r.user.id === context.currentUser.id);

                                                                    if (!!otherReactions && otherReactions.length > 0) {
                                                                        for (let r of otherReactions) {
                                                                            dispatch(removeReaction(m.id,
                                                                                r.reactionId,
                                                                                context.currentUser.id));
                                                                        }
                                                                    }
                                                                }}
                                                            >
                                                                <div className="ChatMessage-reaction no-user-select">
                                                                    <div className="ChatMessage-reaction-content">
                                                                        <Label size="small-1">
                                                                            {
                                                                                hexToUnicode("0x" + item.reactionId.toString(16))
                                                                            }
                                                                        </Label>
                                                                    </div>
                                                                    <div className="ChatMessage-reaction-count">
                                                                        <Label
                                                                            as="p"
                                                                            color="light-blue"
                                                                            size="small-1"
                                                                        >
                                                                            {
                                                                                item.users.length
                                                                            }
                                                                        </Label>
                                                                    </div>
                                                                </div>
                                                            </UnstyledButton>
                                                        </TooltipHost>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default ChatTabUserGroup;
