export enum MessageActionTypes {
    QueryMessages = "QUERY_MESSAGES",
    QueryMessagesPending = "QUERY_MESSAGES_PENDING",
    QueryMessagesRejected = "QUERY_MESSAGES_REJECTED",
    QueryMessagesFulFilled = "QUERY_MESSAGES_FULFILLED",

    AddReaction = "ADD_REACTION",
    AddReactionPending = "ADD_REACTION_PENDING",
    AddReactionFulfilled = "ADD_REACTION_FULFILLED",

    RemoveReaction = "REMOVE_REACTION",
    RemoveReactionPending = "REMOVE_REACTION_PENDING",
    RemoveReactionFulfilled = "REMOVE_REACTION_FULFILLED",

    AddMessage = "ADD_MESSAGE",
    AddMessagePending = "ADD_MESSAGE_PENDING",
    AddMessageFulfilled = "ADD_MESSAGE_FULFILLED",

    RemoveMessageFulfilled = "REMOVE_MESSAGE_FULFILLED",

    PinMessageFulfilled = "PIN_MESSAGE_FULFILLED",

    UnpinMessageFulfilled = "UNPIN_MESSAGE_FULFILLED",

    SaveMessage = "MESSAGES_SAVE_MESSAGE",
    SaveMessageFulfilled = "MESSAGES_SAVE_MESSAGE_FULFILLED",
    SaveMessagePending = "MESSAGES_SAVE_MESSAGE_PENDING",
    SaveMessageRejected = "MESSAGES_SAVE_MESSAGE_REJECTED",
}
