import { ProgressIndicator } from "@fluentui/react";
import React, { Component, createRef, ReactNode, RefObject } from "react";
import { connect } from "react-redux";
import { localize } from "src/l10n";
import { updateRemotePost } from "src/social-feed";
import { SpintrTypes } from "src/typings";
import { ImageCore, Label, LocalizedString } from "src/ui";
import { decodeHtmlEntities, formatInteractiveText, uniqueId } from "src/utils";
import videojs from "video.js";
import "./SocialImage.scss";
import api from "src/spintr/SpintrApi";
import TranslateButton from "src/ui/components/TranslateButton";
import VideoViewCount from "src/ui/components/VideoViewCount";
import postMediaPlayCountUpdate from "src/utils/postMediaPlayCountUpdate";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";

interface ISocialImageProps {
    post: Spintr.ISocialImage;
    parentPost?: Spintr.ISocialPostBase;
    dispatch: any;
}

interface ISocialImageState {
    componentId: string;
    postText: string;
}

class SocialImage extends Component<ISocialImageProps, ISocialImageState> {
    protected videoRef: RefObject<HTMLVideoElement>;

    constructor(props : ISocialImageProps) {
        super(props);

        this.videoRef = createRef();

        this.state = {
            componentId: uniqueId("SocialImage"),
            postText: this.props.post.Text
        };
    }

    public componentDidMount(): void {
        setTimeout(() => {
            try {
                if (!this.videoRef.current) {
                    return;
                }

                videojs(this.videoRef.current, {});
            } catch (err) {
                console.log(err);
            }
        }, 500);

        // If this is a videopost in progress, we set an interval and update the post via redux
        if (this.videoIsUploading(this.props.post)) {
            const i = setInterval(() => {
                api.get(`/api/v1/status/${this.props.post.Id}`).then((response) => {
                    this.props.dispatch(updateRemotePost(response.data));
                    if (!this.videoIsUploading(response.data)) {
                        clearInterval(i);
                    }
                });
            }, 10000);
        }
    }

    videoIsUploading = (post) => {
        const isImage : boolean = (
            post.photos !== undefined &&
            post.photos.length > 0 &&
            !post.photos[0].videoThumbnailUrl
        );

        return !isImage && post.FileStatus >= 0 && post.FileStatus <= 2;
    }

    protected translate = (text: string): void => {
        this.setState({ postText: text })
    }

    public render() : ReactNode {

        const { post } = this.props

        const isImage : boolean = (
            post.photos !== undefined &&
            post.photos.length > 0 &&
            !post.photos[0].videoThumbnailUrl
        );
            
        const imageUrl : string = isImage
            ? post.photos[0].photoUrl
            : post.VideoThumbnail;


        return(
            <div className="SocialImage">
                <div className="image-stage">
                    <a 
                        className="image-link">
                        {isImage &&
                            <div className="maximize-icon">
                                <Visage2Icon icon="maximize-3" color="white" />
                            </div>}
                        {isImage
                            ? this.renderImage()
                            : this.renderVideo()}
                    </a>
                </div>
                {!isImage && <div className="videoViewCount">
                    <VideoViewCount id={post.Id} url={post.VideoUrl} />
                </div>}
            </div>   
        );
    }

    public renderImage() :ReactNode {
        return (
            <ImageCore
                imageId={this.props.post.photos[0].id}
                src={this.props.post.photos[0].photoUrl}
                alt={this.props.post.photos[0].description}
                post={this.props.post}
                parentPost={this.props.parentPost}
                samePostOnly={true}
                openPhotoBoxOnClick={true} />
        )
    }

    public renderVideo() : ReactNode {
        const { post } = this.props;

        const usesPhotos = !!post.photos;
        
        const thumbnail = usesPhotos
            ? post.photos[0].videoThumbnailUrl
            : post.VideoThumbnail;

        if (post.FileStatus !== 3) {
            return (
                <>
                    {post.FileStatus === SpintrTypes.FileStatus.Error ? (
                        <div className="status">
                            <Label as="span">{localize("FilmenKundeIntePubliceras")}</Label>
                        </div>
                    ) : (
                        <div className="ProgressIndicator">
                            <ProgressIndicator
                                label={localize("BearbetningPagar")}
                                description={post.MediaUploadProgress > 0 
                                    ? `${post.MediaUploadProgress}% ${localize("Bearbetad").toLowerCase()}` 
                                    : `${localize("BearbetningForbereds")}`}
                                percentComplete={post.MediaUploadProgress > 0 && post.MediaUploadProgress / 100}
                            />
                        </div>
                    )}
                </>
            );
        }


        return (<>
                <div className="azure-video-item ignore-custom-font">
                    <div className="video">
                        <video
                            onPlay={(_event) => postMediaPlayCountUpdate(post.Id, post.VideoUrl)}
                            className="video-js vjs-default-skin vjs-big-play-centered"
                            controls={true}
                            data-setup="{}"
                            height="auto"
                            id={this.state.componentId}
                            poster={thumbnail}
                            preload="metadata"
                            ref={this.videoRef}
                            style={{ width: "100%" }}
                            width="auto"
                        >
                            <source
                                src={post.VideoUrl}
                                type="video/mp4" />
                            <source
                                src={post.VideoUrl}
                                type="video/ogg" />
                            <Label
                                as="p"
                                className="amp-no-js"
                                color="dark-grey"
                                size="body-2">
                                <LocalizedString text="HTML5_REQUIRED" />
                            </Label>
                        </video>
                    </div>
                </div>
            </>
        );
    }
}

export default connect()(SocialImage);