import React, { Component, ReactNode } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { IVisageSidebarMenuItem, VisageSidebarDrawerId, VisageSidebarMode } from "src/sidebar";
import { setSidebarDrawerId, setSidebarMode } from "src/sidebar/actions";
import { IApplicationState } from "src/spintr/reducer";

interface IProps {
    sidebarMode?: VisageSidebarMode;
    dispatch?: any;
    history?: any;
    sidebarItems?: IVisageSidebarMenuItem[];
    drawerId?: VisageSidebarDrawerId;
}

class VisageSidebarModeChanger extends Component<IProps> {
    unlisten: any = null;

    constructor(props: IProps) {
        super(props);
    }

    componentDidMount() {
        this.unlisten = this.props.history.listen(this.onNavigation.bind(this));
        document.body.classList.add("VisageSidebarMode" + this.props.sidebarMode);
    }

    componentWillUnmount() {
        if (!!this.unlisten) {
            this.unlisten();
        }
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<{}>, snapshot?: any): void {
        if (this.props.sidebarMode !== prevProps.sidebarMode) {
            document.body.classList.remove("VisageSidebarMode" + prevProps.sidebarMode);
            document.body.classList.add("VisageSidebarMode" + this.props.sidebarMode);
        }
    }

    onNavigation(location : any) {
        if (this.props.sidebarMode === VisageSidebarMode.drawer &&
            this.props.drawerId == VisageSidebarDrawerId.groups &&
            location.pathname.indexOf("/groups") !== 0) {
            this.props.dispatch(setSidebarMode(VisageSidebarMode.menu));
            this.props.dispatch(setSidebarDrawerId(VisageSidebarDrawerId.none));
        }

        if (this.props.drawerId === VisageSidebarDrawerId.groups &&
            location.pathname.indexOf("/groups") !== 0) {
                this.props.dispatch(setSidebarDrawerId(VisageSidebarDrawerId.none))
        }

        if (this.props.sidebarMode === VisageSidebarMode.drawer &&
            this.props.drawerId == VisageSidebarDrawerId.messages &&
            location.pathname.indexOf("/messages") !== 0) {
            this.props.dispatch(setSidebarMode(VisageSidebarMode.menu));
            this.props.dispatch(setSidebarDrawerId(VisageSidebarDrawerId.none));
        }

        if (this.props.sidebarMode === VisageSidebarMode.submenu &&
            !!this.props.sidebarItems &&
            this.props.sidebarItems.length > 0) {
            let routes : string[] = [];

            const addRoutesFromCollection = (items: IVisageSidebarMenuItem[]) => {
                for (let item of items) {
                    if (!!item.routes) {
                        routes = [
                            ...routes,
                            ...item.routes
                        ]
                    }

                    if (!!item.children) {
                        addRoutesFromCollection(item.children);
                    }
                }
            }

            routes = routes.filter((route : string) => !!route);

            addRoutesFromCollection(this.props.sidebarItems);

            let shouldDeactivateSubmenu : boolean = true;

            for (let route of routes) {
                if (location.pathname.indexOf(route) === 0) {
                    shouldDeactivateSubmenu = false;
                }
            }

            if (shouldDeactivateSubmenu) {
                this.props.dispatch(setSidebarMode(VisageSidebarMode.menu));
            }
        }
    }

    public render(): ReactNode {
        return null;
    }
}

const mapStateToProps = (state: IApplicationState, props) => {
    return {
        ...props,
        sidebarMode: state.sidebar.mode,
        sidebarItems: state.sidebar.items,
        drawerId: state.sidebar.drawerId
    };
};

export default withRouter(connect(mapStateToProps)(VisageSidebarModeChanger));
