import classNames from "classnames";
import moment from "moment";
import React, { FunctionComponent, useCallback } from "react";
import { Conversation } from "src/chat/conversation-types";
import { localize } from "src/l10n";
import { ActionMenu, Label, SpintrUser, UnstyledButton } from "src/ui";
import { circleLarge, circleMedium, circleSmall, circleXLarge } from "src/ui/helpers/style";
import { decapitalizeFirstLetter, decodeHtmlEntities, fromNow, getTimeOrDateFromDate, stripInteractiveText } from "src/utils";
import "./ConversationItem.scss";
import api from "src/spintr/SpintrApi";
import { queryConversations } from "src/chat/redux";
import { SpintrTypes } from "src/typings";
import { useDispatch } from "react-redux";

interface IProps {
    conversation: Conversation;
    currentUserId: number;
    location?: any;
    onClick?: (conversation: Conversation) => void;
    isGroupItem?: boolean;
}

const ConversationItem: FunctionComponent<IProps> = (props) => {

    const dispatch = useDispatch()
    const getText = () => {
        if (!props.conversation.lastMessage) {
            return null;
        }

        let message = props.conversation.lastMessage.text;

        if (props.conversation.lastMessage.isSystem) {
            let prefix = props.conversation.lastMessage.user.id === props.currentUserId ?
                localize("Du") :
                props.conversation.lastMessage.user.name;

            message = decapitalizeFirstLetter(localize(message));

            return prefix + " " + message;
        }

        if (props.conversation.lastMessage.user.id === props.currentUserId) {
            message = localize("Du") + ": " + message;
        }

        return decodeHtmlEntities(
            stripInteractiveText(
                message
            )
        );
    }

    const getActionMenuItems = () => {

        let items = [{
            text: props.conversation.isPinned ? localize("Slapp") : localize("Fast"),
            onClick: () => {
                api.post("/api/groupmembers/pin/" + props.conversation.group.id)
                    .then((response) => {
                        dispatch(queryConversations({
                            fetchType: SpintrTypes.ConversationsFetchType.Groups,
                            skip: 0,
                            take: 20
                        }))
                    })
            }
        }];
        return items
    }

    const onClick = useCallback(
        () => {
            if (props.onClick) {
                props.onClick(props.conversation);
            }
        },
        [props.onClick, props.conversation],
    );

    const { lastMessage } = props.conversation;
    const message = (lastMessage && lastMessage.text)
        ? lastMessage.text
        : "";

    let dateText = "";
    if (lastMessage) {
        const date = typeof (lastMessage.date) === "string"
            ? new Date(lastMessage.date)
            : lastMessage.date;

        dateText = moment(date).format("HH:mm");
    }

    let imageUrl = "";

    if (props.conversation.imageUrl && props.conversation.imageUrl.length > 0) {
        imageUrl = props.conversation.imageUrl[0];
    }

    const isActive = props.location.pathname.indexOf("/" + (!!props.conversation.group ? props.conversation.group.id : props.conversation.id)) > -1;

    return (
        <UnstyledButton
            className={classNames(
                "ConversationItem",
                props.conversation.unread > 0 ? "unread" : "",
                isActive ? "active lightPrimaryBGColor" : "",
                props.isGroupItem ? "group" : ""
            )}
            onClick={onClick}
        >
            <div className="image">
                <SpintrUser
                    name={props.conversation.title}
                    personalName={!props.isGroupItem}
                    imageUrl={imageUrl}
                    hideText={true}
                    size={props.isGroupItem ? circleLarge : circleSmall}
                />
            </div>
            <div className="text">
                <div className="upper">
                    <Label className="name" size="body-2" weight="medium" color="visageGray" title={props.conversation.title}>
                        {props.conversation.title}
                    </Label>
                </div>
                {
                    (!!message || !!dateText ) && (
                        props.isGroupItem ? (
                            <div className="lower">
                                <Label className={classNames("message", {"group-view": props.isGroupItem})} size="body-3" color="mid-grey" title={localize("SenastAktivitet") + " " + fromNow(props.conversation.lastMessage.date)}>
                                    {localize("SenastAktivitet")} {" "} { fromNow(props.conversation.lastMessage.date)}
                                </Label>
                            </div>
                        ) : (
                            <div className="lower">
                                <Label className="message" size="body-3" color="mid-grey">
                                    {getText()}
                                </Label>
                                {!!dateText && (
                                    <Label className="date" size="body-3" weight="normal" color="mid-grey">
                                        {getTimeOrDateFromDate(
                                            props.conversation.lastMessage.date
                                        )}
                                    </Label>
                                )}
                            </div>
                        )
                    )
                }
            </div>
            {props.conversation.unread > 0 && (
                <div className="unreadDot">&nbsp;&nbsp;&nbsp;</div>
            )}
            {props.isGroupItem &&
                <div className="action-menu">
                    <ActionMenu
                        categories={[
                            {
                                items: getActionMenuItems()
                            },
                        ]}
                    />
                </div>

            }
        </UnstyledButton>
    );
};

export default ConversationItem;