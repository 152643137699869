import { FunctionComponent, ReactElement, useEffect } from "react";
import { connect, MapDispatchToProps, MapStateToProps } from "react-redux";
import { queryConversations, QueryConversationsHandler } from "src/chat/redux/conversation-actions";
import { fetchNotifications } from "src/notifications/actions";
import { INotificationsQueryParams } from "src/notifications/types";
import { SpintrTypes } from "src/typings";

interface IDispatchProps {
    fetchNotifications: (params: INotificationsQueryParams) => void;
    queryConversations: QueryConversationsHandler;
}

type Props = IDispatchProps;

const ResponsiveDataLoader: FunctionComponent<Props> =
    (props: Props): ReactElement => {
        useEffect(
            (() => {
                let loaded = false;

                return () => {
                    if (loaded) {
                        return;
                    }

                    if (!props.fetchNotifications || !props.queryConversations) {
                        return;
                    }

                    loaded = true;

                    props.fetchNotifications({
                        latestOnly: false,
                        take: 20,
                    });

                    props.queryConversations({
                        fetchType: SpintrTypes.ConversationsFetchType.Groups,
                        take: 20,
                    });

                    props.queryConversations({
                        fetchType: SpintrTypes.ConversationsFetchType.Users,
                        take: 20,
                    });
                };
            })(),
            [props.queryConversations, props.fetchNotifications],
        );
        return null;
    };

const mapStateToProps: MapStateToProps<{}, {}, Spintr.AppState> = () => ({});

const mapDispatchToProps: MapDispatchToProps<IDispatchProps, {}> = {
    fetchNotifications,
    queryConversations,
};

const ConnectedResponsiveDataLoader = connect(
    mapStateToProps, mapDispatchToProps,
)(ResponsiveDataLoader);

export default ConnectedResponsiveDataLoader;