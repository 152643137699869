import React, { Component } from 'react';
import { connect } from "react-redux";
import { IApplicationState } from "src/spintr/reducer";
import "src/groups/views/GroupConversationItem/GroupConversationItem.scss";
import { ActionMenu, Label, SpintrUser, UnstyledButton } from "src/ui";
import { circleLarge, circleSmall } from "src/ui/helpers/style";
import { localize } from "src/l10n";
import { setConfirmPopup } from "src/popups/actions";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import api from "src/spintr/SpintrApi";

interface IProps {
    dispatch?: any,
    item: any;
    location?: any;
    history?: any;
    updateItem?: any;
    refreshList?: any;
    displayMembersForGroup?: any;
    allowAdministratorsAccessToAllGroups?: boolean;
}

class GroupConversationItem extends Component<IProps> {
    getActionMenuItems() {
        const item = this.props.item;

        let items = [{
            text: localize("ListaMedlemmar"),
            onClick: () => {
                if (this.props.displayMembersForGroup) {
                    this.props.displayMembersForGroup(item);
                }
            }
        }];

        if (this.props.allowAdministratorsAccessToAllGroups &&
            !item.isMember) {
            items.push({
                text: localize("JOIN_AS_ADMIN"),
                onClick: () => {
                    api.post("/api/groups/" + item.id + "/join?joinAsAdmin=true").then(() => {
                        if (this.props.refreshList) {
                            this.props.refreshList();
                        }

                        if (this.props.history) {
                            this.props.history.push({
                                pathname: "/groups/" + item.id,
                            });
                        }
                    }).catch(() => { });
                }
            });
        }

        if (item.isMember) {
            items.push({
                text: localize("LamnaGruppen"),
                onClick: () => {
                    const fn = () => {
                        api.delete("/api/groupmembers/" + item.id).then(() => {
                            if (this.props.refreshList) {
                                this.props.refreshList();
                            }
                        }).catch((saveError) => {
                            if (saveError &&
                                saveError.response &&
                                saveError.response.data &&
                                saveError.response.data.errorlist &&
                                saveError.response.data.errorlist.length > 0) {
                                let s = "";

                                for (let e of saveError.response.data.errorlist) {
                                    s += localize(e);
                                }

                                this.props.dispatch(setConfirmPopup({
                                    isOpen: true,
                                    message: s,
                                    onConfirm: () => { }
                                }));
                            } else {
                                this.props.dispatch(setConfirmPopup({
                                    isOpen: true,
                                    message: localize("TeknisktFel"),
                                    onConfirm: () => { }
                                }));
                            }
                        });
                    }

                    this.props.dispatch(setConfirmPopup({
                        isOpen: true,
                        title: localize("LamnaGruppen"),
                        message: localize("ArDuSakerPaAttDuVillGoraDetta"),
                        onConfirm: fn
                    }));
                }
            });
        }

        if (!item.isMember &&
            item.isOpen) {
            items.push({
                text: localize("GaMed"),
                onClick: () => {
                    api.post("/api/groups/" + item.id + "/join").then(() => {
                        if (this.props.refreshList) {
                            this.props.refreshList();
                        }
                    }).catch(() => { });
                }
            });
        }

        if (!item.isMember &&
            item.status === 4) {
            if (item.isRequestingMembership) {
                items.push({
                    text: localize("AnsokanHarSkickats"),
                    onClick: () => { }
                });
            } else {
                items.push({
                    text: localize("AnsokOmMedlemskap"),
                    onClick: () => {
                        api.post("/api/groupmembers/request/" + item.id).then(() => {
                            if (this.props.updateItem) {
                                this.props.updateItem({
                                    ...item,
                                    isRequestingMembership: true
                                });
                            }
                        }).catch(() => { });
                    }
                });
            }
        }

        return items;
    }

    render() {
        let text = this.props.item.status === 1 ?
            localize("Borttagen") :
            this.props.item.status === 2 ?
                localize("Hemlig") :
                this.props.item.status === 3 ?
                    localize("Oppen") :
                    localize("Stangd");

        text += (", " + this.props.item.memberCount + " " + (this.props.item.memberCount === 1 ? localize("Medlem").toLowerCase() : localize("Medlemmar_small")));

        const isActive = this.props.location.pathname.indexOf("/" + this.props.item.id) > -1;

        return (
            <div className={classNames(
                "ConversationItem",
                "GroupConversationItem",
                isActive ? "active" : ""
            )}>
                <div className="image">
                    <SpintrUser
                        name={this.props.item.name}
                        imageUrl={this.props.item.imageUrl}
                        hideText={true}
                        personalName={false}
                        size={circleLarge}
                    />
                </div>
                <div className="text">
                    <div className="upper">
                        <Label className="name" size="body-2" weight="medium" color="visageGray">
                            {this.props.item.name}
                        </Label>
                    </div>
                    <div className="lower">
                        <Label className="message" size="body-3" color="mid-grey">
                            {text}
                        </Label>
                    </div>
                </div>
                <div className="action-menu">
                    <ActionMenu
                        categories={[
                            {
                                items: this.getActionMenuItems()
                            },
                        ]}
                    />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: IApplicationState, props) => {
    return {
        ...props,
        allowAdministratorsAccessToAllGroups: state.profile.active.isSpintrAdminAccount ||
            (state.instance.get("allowAdministratorsAccessToAllGroups") && state.profile.active.isAdmin)
    };
};

export default withRouter(connect(mapStateToProps)(GroupConversationItem));