import React, { ReactElement } from "react";
import { DrawerBodyProps } from "./DrawerBody.types";

function DrawerBody(props: DrawerBodyProps): ReactElement {
    return (
        <div className="DrawerBody">
            {props.children}
        </div>
    );
}

export default DrawerBody;
