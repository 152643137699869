import { AxiosResponse } from 'axios';
import { DefaultButton, Link, Modal, PrimaryButton, Stack, TextField } from '@fluentui/react';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { localize } from 'src/l10n';
import { setConfirmPopup } from 'src/popups/actions';
import { ActionMenu, Label, Loader, PageHeader } from 'src/ui';
import SpintrList from 'src/ui/components/SpintrList/SpintrList';
import SuppliersBreadcrumbs from './SuppliersBreadcrumbs';
import api from 'src/spintr/SpintrApi';
import { updateSidebar } from './utils';
import PopupHeader from 'src/ui/components/PopupHeader';
import FormControl from 'src/ui/components/Forms/FormControl';
import validateRequiredTextField from 'src/utils/validateRequiredTextField';
import moment from 'moment';
import { isAnythingDirty } from 'src/utils';
import { FormSection, FormTokenizedObjectInput } from 'src/ui/components/Forms';
import { SpintrTypes } from 'src/typings';
import { Style } from 'src/ui/helpers';
import FileSelector from 'src/spintr/components/FileSelector';

interface IProps {
    instance?: any;
    currentUser?: any;
    history?: any;
    isAdmin?: boolean;
    isEditor?: boolean;
    dispatch?: any;
    appName?: string;
}

interface IState {
    isLoading: boolean;
    items: any[];
    displayDeleted: boolean;
    displayMessageModal: boolean;
    isEditingMessage: boolean;
    displayedMessage: any;
    isLoadingMessage: boolean;
    displayFileUploadPopup: boolean;
}

const defaultMessage = {
    id: 0,
    subject: "",
    text: "",
    createDate: new Date(),
    targets: [],
    targetRoles: [],
    file: null
};

class SuppliersMessagesView extends Component<IProps, IState> {
    private listRef = React.createRef<SpintrList>();

    state = {
        isLoading: true,
        items: [],
        displayDeleted: false,
        displayMessageModal: false,
        isEditingMessage: false,
        displayedMessage: defaultMessage,
        isLoadingMessage: false,
        displayFileUploadPopup: false
    };

    componentDidMount() {
        updateSidebar(this.props.isAdmin, this.props.isEditor, this.props.appName, this.props.dispatch);
    }

    private onMessageFieldChange(event: React.ChangeEvent<HTMLInputElement>): void {
        if (!event?.target) {
            return;
        }

        const { name, value } = event.target;
        if (["subject", "text"].indexOf(name) === -1) {
            return;
        }

        this.setState((state) => ({
            ...state,
            displayedMessage: {
                ...state.displayedMessage || {
                    subject: "",
                    text: "",
                },
                [name]: value,
            }
        }));
    }

    openMessageDismissWarning() {
        if (isAnythingDirty()) {
            this.props.dispatch(
                setConfirmPopup({
                    isOpen: true,
                    message: localize("UnsavedChangesWarning"),
                    onConfirm: this.onDismissMessage,
                })
            );
        } else {
            this.onDismissMessage();
        }
    }

    private onDismissMessage(): void {
        this.setState((state) => ({
            ...state,
            displayMessageModal: false,
            displayedMessage: undefined,
            isEditingMessage: undefined,
        }));
    }

    saveMessage() {
        this.setState({
            isLoadingMessage: true
        }, () => {
            api.post("/api/v1/suppliers/external-messages", this.state.displayedMessage).then(() => {
                this.setState({
                    isLoadingMessage: false,
                    displayMessageModal: false
                }, () => {
                    this.listRef.current.reFetch();
                });
            }).catch(() => { })
        });
    }

    renderPopup() {
        return (
            <Modal
                className="spintr-modal modalWithPopupHeader supplier-message-modal"
                isOpen={this.state.displayMessageModal && !!this.state.displayedMessage}
                onDismiss={this.state.isEditingMessage
                    ? this.openMessageDismissWarning.bind(this)
                    : this.onDismissMessage.bind(this)
                }
            >
                <PopupHeader
                    text={
                        this.state.isEditingMessage
                            ? (!!this.state.displayedMessage.id ? (localize("Redigera") + " " + localize("Ubertype37_0_4")) : localize("chat_createmsg"))
                            : localize("Meddelande")
                    }
                    onClose={this.state.isEditingMessage
                        ? this.openMessageDismissWarning.bind(this)
                        : this.onDismissMessage.bind(this)}
                />
                {this.state.isLoadingMessage && <Loader />}
                {!this.state.isLoadingMessage && (
                    <div>
                        {this.state.isEditingMessage
                            ? (
                                <FormControl>
                                    <TextField
                                        label={localize("Amne")}
                                        required
                                        aria-required
                                        validateOnFocusOut
                                        validateOnLoad={false}
                                        onGetErrorMessage={validateRequiredTextField}
                                        onChange={this.onMessageFieldChange.bind(this)}
                                        value={this.state.displayedMessage?.subject || ""}
                                        name="subject"
                                    />
                                </FormControl>
                            )
                            : (
                                <Label
                                    as="div"
                                    className="title"
                                    size="h4"
                                    weight="medium"
                                >
                                    {this.state.displayedMessage?.subject}
                                </Label>
                            )}
                        {!this.state.isEditingMessage && (
                        <div className="messageInfo">
                            <Label
                                as="div"
                                className="date"
                                size="body-2"
                                color="visageGray3"
                            >
                                {localize("Skapad") + ": " + moment(this.state.displayedMessage?.createDate).format("LLL")}
                            </Label>
                            {!this.state.isEditingMessage && !!this.state.displayedMessage?.file && (
                                <Label
                                    size="body-2"
                                    color="visageGray3">
                                    {localize("BifogadFil") + ": "}
                                    <Link
                                        className={"linkFGColor"}
                                        onClick={() => {
                                            window.open("/api/v1/servefile/" + this.state.displayedMessage?.file.id, "_blank");
                                        }}
                                    >
                                        {this.state.displayedMessage?.file.name}
                                    </Link>
                                </Label>
                            )}
                        </div>
                        )}
                        {this.state.isEditingMessage
                            ? (
                                <FormControl>
                                    <TextField
                                        label={localize("Text")}
                                        required
                                        aria-required
                                        validateOnFocusOut
                                        validateOnLoad={false}
                                        onGetErrorMessage={validateRequiredTextField}
                                        multiline={true}
                                        style={{
                                            minHeight: 100
                                        }}
                                        name="text"
                                        onChange={this.onMessageFieldChange.bind(this)}
                                        value={this.state.displayedMessage?.text || ""}
                                    />
                                </FormControl>
                            )
                            : (
                                <Label
                                    as="div"
                                    className="text"
                                    size="body-1"
                                >
                                    {this.state.displayedMessage?.text}
                                </Label>
                            )
                        }
                        {this.state.isEditingMessage && (
                            <FormControl>
                                {!this.state.displayedMessage?.file && (
                                    <PrimaryButton
                                        onClick={() => {
                                            this.setState({
                                                displayFileUploadPopup: true,
                                            });
                                        }}
                                    >
                                        {localize("LaddaUppFil")}
                                    </PrimaryButton>
                                )}
                                {this.state.displayedMessage?.file && (
                                    <div>
                                        <Label>{localize("SELECTED_FILE") + ": " + this.state.displayedMessage.file.name}</Label>
                                        <FormControl>
                                            <PrimaryButton
                                                style={{ marginTop: Style.getSpacingStr(3) }}
                                                onClick={() => {
                                                    this.setState({
                                                        displayedMessage: {
                                                            ...this.state.displayedMessage,
                                                            file: null,
                                                        },
                                                    });
                                                }}
                                            >
                                                {localize("TaBortFilen")}
                                            </PrimaryButton>
                                        </FormControl>
                                    </div>
                                )}
                                {this.state.displayFileUploadPopup && (
                                    <FileSelector
                                        onClose={() => {
                                            this.setState({
                                                displayFileUploadPopup: false,
                                            });
                                        }}
                                        onSelect={(data) => {
                                            this.setState({
                                                displayFileUploadPopup: false,
                                                displayedMessage: {
                                                    ...this.state.displayedMessage,
                                                    file: data[0],
                                                    targets: [],
                                                },
                                            });
                                        }}
                                        allowMultipleFiles={false}
                                        //handleUploadSeparately
                                        sourcesToDisplay={[0]}
                                        fileUploadType={11}
                                    />
                                )}
                            </FormControl>
                        )}
                        {this.state.isEditingMessage && (
                            <FormSection title={localize("SynligFor")}>
                                <FormControl>
                                    <FormTokenizedObjectInput
                                        aria-label={localize("SokOchLaggTill") + " " + localize("SUPPLIER_GROUPS").toLowerCase()}
                                        placeholder={localize("SokOchLaggTill") + " " + localize("SUPPLIER_GROUPS").toLowerCase()}
                                        items={this.state.displayedMessage.targets.map((tag) => {
                                            return {
                                                ...tag,
                                                text: tag.name || tag.text,
                                                key: tag.id || tag.key,
                                            };
                                        })}
                                        label={localize("SUPPLIER_GROUPS")}
                                        types={[
                                            SpintrTypes.UberType.SupplierGroup,
                                        ]}
                                        onChange={(targets) => {
                                            this.setState((state) => ({
                                                displayedMessage: {
                                                    ...this.state.displayedMessage,
                                                    targets: targets.map((t) => {
                                                        return {
                                                            ...t,
                                                            //@ts-ignore
                                                            id: t.id ? t.id : t.key,
                                                            //@ts-ignore
                                                            key: t.key ? t.key : t.id,
                                                            text: t.name,
                                                            image: t.imageUrl,
                                                            info: t.subText,
                                                        };
                                                    }),
                                                },
                                            }));
                                        }}
                                        showAllWhenEmpty
                                    />
                                </FormControl>
                                <FormControl>
                                    <FormTokenizedObjectInput
                                        aria-label={localize("SokOchLaggTill") + " " + localize("Roller").toLowerCase()}
                                        placeholder={localize("SokOchLaggTill") + " " + localize("Roller").toLowerCase()}
                                        items={this.state.displayedMessage.targetRoles.map((tag) => {
                                            return {
                                                ...tag,
                                                text: tag.name || tag.text,
                                                key: tag.id || tag.key,
                                            };
                                        })}
                                        label={localize("Roller")}
                                        searchSupplierRoles
                                        types={[
                                            SpintrTypes.UberType.SupplierGroup,
                                        ]}
                                        onChange={(targets) => {
                                            this.setState((state) => ({
                                                displayedMessage: {
                                                    ...this.state.displayedMessage,
                                                    targetRoles: targets.map((t) => {
                                                        return {
                                                            ...t,
                                                            //@ts-ignore
                                                            id: t.id ? t.id : t.key,
                                                            //@ts-ignore
                                                            key: t.key ? t.key : t.id,
                                                            text: t.name,
                                                            image: t.imageUrl,
                                                            info: t.subText,
                                                        };
                                                    }),
                                                },
                                            }));
                                        }}
                                        showAllWhenEmpty
                                    />
                                </FormControl>
                            </FormSection>
                        )}
                        {this.state.isEditingMessage && (
                            <Stack
                                horizontal={true}
                                horizontalAlign={"end"}
                            >
                                <Stack horizontal={true} horizontalAlign="end" tokens={{ childrenGap: 6 }}>
                                    <DefaultButton onClick={this.openMessageDismissWarning.bind(this)} text={localize("Avbryt")} />
                                    <PrimaryButton
                                    disabled={!this.state.displayedMessage ||
                                        !this.state.displayedMessage.subject ||
                                        !this.state.displayedMessage.text}
                                    onClick={this.saveMessage.bind(this)}
                                    text={!!this.state.displayedMessage.id ? localize("Spara") : localize("Skicka")} />
                                </Stack>
                            </Stack>
                        )}
                    </div>
                )}
            </Modal>
        )
    }

    render() {
        return (
            <div className="SuppliersView">
                <div className="breadcrumbs-wrapper">
                    <SuppliersBreadcrumbs />
                </div>
                <Helmet>
                    <title>{localize("Meddelanden")}</title>
                </Helmet>
                <PageHeader
                    title={localize("Meddelanden")}
                />
                <SpintrList
                    ref={this.listRef}
                    fetch={(skip, take, columnId, isAscending, searchQuery) => {
                        return new Promise((resolve, reject) => {
                            api
                                .get(`/api/v1/suppliers/external-messages`, {
                                    params: {
                                        includeDeleted: this.state.displayDeleted,
                                        isAscending,
                                        orderByColumn: columnId,
                                        searchText: searchQuery,
                                        skip,
                                        take,
                                    },
                                })
                                .then((response: AxiosResponse) => {
                                    resolve({
                                        data: response.data.items,
                                        totalCount: response.data.totalCount
                                    });
                                });
                        });
                    }}
                    isDescending
                    orderByColumn={"createDate"}
                    columns={[
                        {
                            name: localize("Titel"),
                            fieldName: "subject",
                            minWidth: 250,
                            onRender: (item) => {
                                return (
                                    <Link className={"linkFGColor"}
                                        style={{
                                            ...(item.isDeleted ? { textDecorationLine: "line-through" } : {}),
                                        }}
                                        onClick={() => {
                                            this.setState({
                                                displayMessageModal: true,
                                                isEditingMessage: false,
                                                displayedMessage: item
                                            });
                                        }}
                                    >
                                        {item.subject}
                                    </Link>
                                );
                            },
                        },
                        {
                            name: localize("RiktadTill"),
                            fieldName: "targetString",
                            minWidth: 150,
                            onRender: (item) => {
                                const allTargets = [
                                    ...(item.targets || []),
                                    ...(item.targetRoles || [])
                                ];

                                const targetString = allTargets.length === 0 ?
                                    localize("Alla") :
                                    allTargets.map((target) => target.name).join(", ");

                                return (
                                    <span title={targetString}>
                                        {targetString}
                                    </span>
                                );
                            },
                        },
                        {
                            name: localize("BifogadFil"),
                            fieldName: "file",
                            minWidth: 150,
                            onRender: (item) => {
                                if (!item.file) {
                                    return null;
                                }

                                return (
                                    <Link
                                        className={"linkFGColor"}
                                        onClick={() => {
                                            window.open("/api/v1/servefile/" + item.file.id, "_blank");
                                        }}
                                    >
                                        {item.file.name}
                                    </Link>
                                )
                            },
                        },
                        {
                            name: localize("Skapad"),
                            fieldName: "createDate",
                            minWidth: 200,
                            onRender: (item) => {
                                return (
                                    <span>
                                        {moment(item.createDate).format("LLL")}
                                    </span>
                                );
                            },
                        },
                        {
                            name: "",
                            key: "actionmenu",
                            minWidth: 40,
                            onRender: (item: any) => {
                                if (!(this.props.isAdmin || this.props.isEditor)) {
                                    return null;
                                }

                                return (
                                    <ActionMenu
                                        categories={[
                                            {
                                                items: [
                                                    {
                                                        text: localize("Redigera"),
                                                        onClick: () => {
                                                            this.setState({
                                                                displayMessageModal: true,
                                                                isEditingMessage: true,
                                                                displayedMessage: item
                                                            });
                                                        },
                                                    },
                                                    {
                                                        text: item.isDeleted
                                                            ? localize("Aterstall")
                                                            : localize("TaBort"),
                                                        onClick: () => {
                                                            const onConfirm = () => {
                                                                if (item.isDeleted) {
                                                                    api.put("/api/objects/deleted/" + item.id).then(() => {
                                                                        this.listRef.current.fetch();
                                                                    });
                                                                } else {
                                                                    api.delete("/api/objects/" + item.id).then(() => {
                                                                        this.listRef.current.fetch();
                                                                    });
                                                                }
                                                            }

                                                            const title = item.isDeleted ?
                                                                localize("Aterstallinnehall") :
                                                                localize("RaderaInnehall");

                                                            const message = item.isDeleted ?
                                                                localize("ArDuSakerPaAttDuVillGoraDetta") :
                                                                localize("ArDuSakerPaAttDuVillX").replace("{{X}}", localize("RaderaDetta").toLowerCase());

                                                            this.props.dispatch(setConfirmPopup({
                                                                isOpen: true,
                                                                title,
                                                                message,
                                                                onConfirm
                                                            }));
                                                        },
                                                    },
                                                ],
                                            },
                                        ]}
                                    />
                                );
                            },
                        },
                    ]}
                    buttons={!this.props.isAdmin && !this.props.isEditor ? null : [
                        {
                            key: "add",
                            text: localize("chat_createmsg"),
                            onClick: () => {
                                this.setState({
                                    displayMessageModal: true,
                                    isEditingMessage: true,
                                    displayedMessage: defaultMessage
                                });
                            },
                            iconProps: { iconName: "Add" },
                            className: "commandBarAddButton",
                        },
                    ]}
                    optionItems={this.props.isAdmin ? {
                        items: [
                            {
                                key: "includeDeleted",
                                text: this.state.displayDeleted ?
                                    localize("DoljBorttagna") :
                                    localize("VisaBorttagna"),
                                onClick: () => {
                                    this.setState({
                                        displayDeleted: !this.state.displayDeleted
                                    }, () => {
                                        this.listRef.current.fetch();
                                    });
                                },
                            }
                        ],
                    } : undefined}
                />
                {this.renderPopup()}
            </div>
        );
    }
}

const mapStateToProps = (state, props) => ({
    ...props,
    instance: state.instance,
    currentUser: state.profile.active,
    isAdmin: state.profile.active.isAdmin,
    isEditor: state.profile.active.isEditor,
    appName: state.instance.get("suppliersAppName")
});

// @ts-ignore
export default withRouter(connect(mapStateToProps)(SuppliersMessagesView));
