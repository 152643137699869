import React, { Component, ReactNode } from 'react';
import Intercom from 'react-intercom';
import { connect } from 'react-redux';
import { IApplicationState } from 'src/spintr/reducer';
import api from "src/spintr/SpintrApi";
import "./SpintrSupportChatContainer.scss";

interface IProps {
    currentUser?: any;
    instance?: any;
    sidebarIsExpanded?: boolean;
    hasFormFooterBar?: boolean;
    conversations?: any;
    isSmallViewMode?: boolean;
    appMode: boolean;
}

interface IState {
    privilege?: any;
}

class SpintrSupportChatContainer extends Component<IProps, IState> {
    constructor(props) {
        super(props);

        this.state = {}
    }

    componentDidMount() {
        api.get("/api/users/supportchatprivilege").then(response => {
            this.setState({
                privilege: response.data
            }, () => {
                if (!this.state.privilege ||
                    !this.state.privilege.isAllowed ||
                    !this.state.privilege.id) {
                    return;
                }

                this.injectStyling();
            });
        });
    }

    injectStyling() {
        var stylesheet = document.createElement('link');
        var stylesheet2 = document.createElement('link');

        stylesheet.rel = 'stylesheet';
        stylesheet.href = '/Style/intercom.css';

        stylesheet2.rel = 'stylesheet';
        stylesheet2.href = '/Style/intercom.css';

        var timeout = setTimeout(function () { clearInterval(interval) }, 30000);

        var interval = setInterval(function() {
            var iframe = document.querySelector('.intercom-launcher-frame');
            var iframe2 = document.querySelector('.intercom-launcher-discovery-frame');
            var container = document.querySelector('#intercom-container')

            if (iframe) {
                //@ts-ignore
                iframe.style.cssText = 'height:60px !important;width:60px !important;';
                //@ts-ignore
                iframe.contentDocument.head.appendChild(stylesheet);

                if (iframe2) {
                    //@ts-ignore
                    iframe2.style.cssText = 'height:60px !important;width:60px !important;';
                    //@ts-ignore
                    iframe2.contentDocument.head.appendChild(stylesheet2);
                }

                //@ts-ignore
                setTimeout(function() { container.style.cssText = 'display: block;'; }, 0)
                clearInterval(interval);
                clearTimeout(timeout);
            }
        }, 100);
    }

    getSettings() {
        const id = this.props.instance.get("instance").get ?
            this.props.instance.get("instance").get("id") :
            this.props.instance.get("instance").id;

        const started = this.props.instance.get("instance").get ?
            this.props.instance.get("instance").get("started") :
            this.props.instance.get("instance").started;

        const maxUsers = this.props.instance.get("instance").get ?
            this.props.instance.get("instance").get("maxUsers") :
            this.props.instance.get("instance").maxUsers;

        const users = this.props.instance.get("instance").get ?
            this.props.instance.get("instance").get("users") :
            this.props.instance.get("instance").users;

        const settings = {
            email: this.props.currentUser.email,
            name: this.props.currentUser.name,
            user_id: this.props.currentUser.id + ":" + this.props.currentUser.email,
            created_at: Math.ceil(new Date(this.props.currentUser.created).getTime() / 1000),
            app_id: this.state.privilege.id,
            InitialUser: !!this.props.currentUser.initialUser,
            company: {
                id: id.toString() + ":" + this.props.instance.get("domain"),
                name: this.props.instance.get("name"),
                created_at: Math.ceil((new Date(started)).getTime() / 1000),
                plan: this.props.instance.get("office365Enabled") ?
                    "Spintr365" :
                    (maxUsers > 10 || users > 10 ?
                        "SpintrCore" :
                        "Trial"),
                UserCount: users,
                CustomDesign: (this.props.instance.get("color") !== 'E10D7B' || this.props.instance.get("logo").substr(0, 1) !== '/')
            },
        }

        return settings;
    }

    public render() : ReactNode {
        if (!this.state.privilege ||
            !this.state.privilege.isAllowed ||
            !this.state.privilege.id ||
            this.props.hasFormFooterBar ||
            this.props.isSmallViewMode ||
            (this.props.conversations && this.props.conversations.length > 0) ||
            this.props.appMode) {
            return null;
        }

        const settings = this.getSettings();

        return (
            <Intercom appID={settings.app_id} { ...settings } />
        );
    }
}

const mapStateToProps = (state: IApplicationState, props) => {
    return {
        ...props,
        currentUser: state.profile.active,
        instance: state.instance,
        sidebarIsExpanded: state.ui.sidebarExpanded,
        hasFormFooterBar: state.ui.formFooterBarVisible,
        conversations: state.chat.conversations.tabIds
            .map((tab) => state.chat.conversations.items.find(
                (c) => c.id === tab.id
            ))
            .filter((c) => !!c),
        // isSmallViewMode: state.ui.viewMode <= SpintrTypes.ViewMode.PhoneLandscape,
        appMode: state.ui.appMode
    };
};

export default connect(mapStateToProps)(SpintrSupportChatContainer);