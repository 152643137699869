import { generateUuid } from "src/utils";
import RecordFactory from "src/utils/RecordFactory";

const defaultValues: Spintr.IChatMessage = {
    attachments: [],
    conversationId: -1,
    date: new Date(),
    id: -1,
    isPinned: false,
    isStarred: false,
    isSystem: false,
    object: null,
    parentId: null,
    reactions: [],
    referenceId: null,
    replies: [],
    replyCount: 0,
    savingFailed: false,
    text: "",
    user: null,
    parent: null
};

let nextTempId = -2;
class Message extends RecordFactory(defaultValues) implements Spintr.IChatMessage {
    public static getTempId(): number {
        return nextTempId--;
    }

    public static createLocal(conversationId: number, text: string, user: Spintr.IUser, parent?: any): Message {
        return new Message({
            conversationId,
            date: new Date(),
            id: Message.getTempId(),
            referenceId: generateUuid(),
            text,
            user,
            parent,
            parentId: !!parent ? parent.id : undefined
        });
    }

    constructor(data: Partial<Spintr.IChatMessage>) {
        super({
            ...data,

            date: typeof data.date === "string"
                ? new Date(data.date)
                : data.date,
        });
    }

    public merge(...collections: Array<Partial<Spintr.IChatMessage>>): this {
        return super.merge(
            ...collections.map((val) => {
                if (typeof val.date !== "string") {
                    return val;
                }

                return {
                    ...val,

                    date: new Date(val.date),
                };
            })
        );
    }
}

export default Message;