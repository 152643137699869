import { PrimaryButton, Separator } from "@fluentui/react";
import classNames from "classnames";
import moment from "moment";
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { RouteChildrenProps, withRouter } from "react-router";
import { SocialBlock } from "src/interactions/components";
import { localize } from "src/l10n";
import { canUserCreateAndEditNewsArticles } from "src/privileges/utils";
import api from "src/spintr/SpintrApi";
import { SpintrTypes } from "src/typings";
import {
    Breadcrumbs,
    ContentImageViewerAndSelector, ContentWithInfoPanel, Label, Loader, PageHeader, PageInfoPanel, SpintrUser, setUseDocumentWidthMode
} from "src/ui";
import CategoryButton from "src/ui/components/Buttons/CategoryButton/CategoryButton";
import PageActionButton from "src/ui/components/Buttons/PageActionButton/PageActionButton";
import CustomDialog from "src/ui/components/Dialogs/CustomDialog";
import TimeoutInformation from "src/ui/components/TimeoutInformation";
import TinyFormattedContent from "src/ui/components/Tiny/displayment/TinyFormattedContent";
import TranslateButton from "src/ui/components/TranslateButton";
import { Style } from "src/ui/helpers";
import { print } from "src/utils/print";
import "./NewsArticleView.scss";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import { removeImportantArticle } from "src/spintr/components/InformationFeed/actions";

interface IPageRouteParams {
    slug: string;
}

interface Props extends RouteChildrenProps<IPageRouteParams> {
    isAdmin: boolean;
    isEditor: boolean;
    canUserCreateAndEditNewsArticles?: boolean;
    instanceName: string;
    appMode: boolean;
    smallViewMode: boolean;
    allInteractions: any;
    article?: Spintr.INewsArticle // for preview mode
    isPreview?: boolean;
    dispatch?: any;
    goBackToFormCallback?: any;
    enableExternalFiles?: boolean;
    activeUserId: number;
}

interface State {
    article: Spintr.INewsArticle;
    isLoading: boolean;
    showInfoDialog: boolean;
    showShareModal: boolean;
    loadTimedOut: boolean;
    translatedTexts?: object;
}

class NewsArticleView extends Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            article: props.article ? props.article : {
                menuUrl: "",
            } as Spintr.INewsArticle,
            isLoading: this.props.isPreview ? false : true,
            showInfoDialog: false,
            showShareModal: false,
            loadTimedOut: false,
            translatedTexts: {
                [SpintrTypes.TranslateType.Title]: props.article ? props.article.name : "",
                [SpintrTypes.TranslateType.Content]: props.article ? props.article.content : "",
                [SpintrTypes.TranslateType.Preamble]: props.article ? props.article.preamble : "",
            },
        };

    }

    protected translate = (text: string, key: string) => {
        //@ts-ignore
        this.setState((prevState) => ({
            translatedTexts: {
                ...prevState.translatedTexts,
                [key]: text,
            },
        }));
    }

    public componentDidMount = () => {
        if (this.props.isPreview) return null;

        const { slug } = this.props.match.params;

        if (slug) {
            this.getByRoute(`nyheter/${slug}`);
        }

        this.props.dispatch(setUseDocumentWidthMode(true));
    };

    componentWillUnmount = () => {
        this.props.dispatch(setUseDocumentWidthMode(false));
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (this.props.isPreview) return null;

        if (prevProps.match.params.slug !== this.props.match.params.slug) {
            this.getByRoute(`nyheter/${this.props.match.params.slug}`);
        }
    };

    private getByRoute = (route) => {
        const fetchTimeoutMS = 20000;
        let params = { route: route };

        this.setState({ isLoading: true, loadTimedOut: false }, () => {
            api
                .get("/api/v1/routes", { params, timeout: fetchTimeoutMS })
                .then((response) => {
                    this.handleResponse(response.data as Spintr.INewsArticle);
                })
                .catch((error) => {
                    if (error.code === "ECONNABORTED") {
                        this.setState({ loadTimedOut: true, isLoading: false })

                        return error
                    }

                    if (error?.response?.status === 404) {
                        this.props.history.push("/404");
                    }

                    let newRoute = route.split("/");
                    newRoute[0] = "news";
                    newRoute = newRoute.join("/");
                    params = { route: newRoute };
                    api
                        .get("/api/v1/routes", { params, timeout: fetchTimeoutMS })
                        .then((response) => {
                            this.handleResponse(response.data as Spintr.INewsArticle);
                        })
                        .catch((error) => {
                            if (error.code === "ECONNABORTED") {
                                this.setState({ loadTimedOut: true, isLoading: false })
                                return
                            }

                            if ([400, 404].indexOf(error?.response?.status) !== -1) {
                                this.props.history.push("/404");
                            }
                        });
                });
        });
    };

    handleResponse(data) {
        this.setState({
            translatedTexts: {
                [SpintrTypes.TranslateType.Title]: data.name,
                [SpintrTypes.TranslateType.Content]: data.content,
                [SpintrTypes.TranslateType.Preamble]: data.preamble,
            },
            article: {
                ...data,
                publisher: { name: data.publisherName, id: data.publisherId, imageUrl: data.publisherImage, info: data.publisherDepartment },
            },
            isLoading: false,
        });

        this.props.dispatch(removeImportantArticle(data.id));
    }

    deleteItem = (id) => {
        this.setState({ isLoading: true }, () => {
            api.delete(`/api/v1/news/${id}`).then((_response) => {
                this.props.history.push({
                    pathname: "/news",
                });
            });
        });
    };

    onEditClick() {
        this.props.history.push({
            pathname: "/news/" + this.props.match.params.slug + "/edit",
        });
    }

    onDeleteClick() {
        this.setState({
            showInfoDialog: true,
        });
    }

    private onPrintClick = () => {
        print(this.props.instanceName, this.state.translatedTexts[SpintrTypes.TranslateType.Title]);
    };

    listCategories = () => {
        const categories = this.state.article?.uberCategories?.filter(a => !a.deleted)

        if (categories?.length > 0) {
            return (<div className="categories">
                {categories.map(a => {
                    return <CategoryButton key={a.id} id={a.id} label={a.name} />
                })}
            </div>)
        } else {
            return null
        }
    }

    render() {
        const { article, isLoading, showInfoDialog, loadTimedOut } = this.state;
        const publisher = article.publisher

        if (isLoading) {
            return <Loader />;
        }

        if (loadTimedOut) {
            return <TimeoutInformation reloadFn={this.componentDidMount} />
        }

        return (
            <div className="NewsArticleView">
                {this.props.goBackToFormCallback && (
                    <div className="info-bar with-bottom-spacing">
                        <Label size="body-2">{localize("PREVIEW_INFO")}</Label>
                        <PrimaryButton
                            onClick={this.props.goBackToFormCallback}
                        >
                            {localize("GaTillbakaTillRedigering")}
                        </PrimaryButton>
                    </div>
                )}
                <Helmet>
                    <title>{this.state.translatedTexts[SpintrTypes.TranslateType.Title]}</title>
                </Helmet>
                {!this.props.isPreview && !this.props.appMode &&
                    <Breadcrumbs
                        displayInstance
                        // TODO: Add complete path?
                        items={[
                            {
                                key: "news",
                                text: localize("Nyheter"),
                                link: `/news`,
                            },
                            {
                                key: article.id,
                                text: this.state.translatedTexts[SpintrTypes.TranslateType.Title],
                                link: "",
                            },
                        ]}
                    />
                }
                <div className="contentMiddle">
                    {showInfoDialog && (
                        <CustomDialog
                            message={localize("ArDuSakerPaAttDuVillX").replace(
                                "{{X}}",
                                localize("RaderaDetta").toLowerCase()
                            )}
                            title={localize("RaderaInnehall")}
                            show={showInfoDialog}
                            onDismiss={() => {
                                this.setState({ showInfoDialog: false });
                            }}
                            onConfirm={() => {
                                this.setState({ showInfoDialog: false }, () => {
                                    this.deleteItem(article.id);
                                });
                            }}
                        />
                    )}
                    <div className="contentMiddle2 headerWrapper">
                        {/* <Label as="h1" size="h1">{article.name}</Label> */}
                        <TranslateButton authorLanguage={article.language === "" ? article.authorLanguage : article.language} 
                            uberId={article.id} 
                            texts={{
                                [SpintrTypes.TranslateType.Title]: this.state.translatedTexts[SpintrTypes.TranslateType.Title],
                                [SpintrTypes.TranslateType.Preamble]: this.state.translatedTexts[SpintrTypes.TranslateType.Preamble],
                                [SpintrTypes.TranslateType.Content]: this.state.translatedTexts[SpintrTypes.TranslateType.Content],
                            }}
                            onTranslateFn={this.translate}
                        />
                        <PageHeader
                            title={this.state.translatedTexts[SpintrTypes.TranslateType.Title]}
                            size="big"
                            standardActionMenuProps={{
                                canAddToFavourites: true,
                                canFollow: true,
                                canShare: true,
                                canDelete: this.props.canUserCreateAndEditNewsArticles,
                                canEdit: this.props.canUserCreateAndEditNewsArticles,
                                canReport: this.props.activeUserId !== article.publisherId,
                                objectId: article.id,
                                onEditClick: this.onEditClick.bind(this),
                                onDeleteClick: this.onDeleteClick.bind(this),
                                isFollowing: article.isFollowing,
                                isFavourite: article.isFavourite,
                                onPrintClick: this.onPrintClick,
                            }}
                            lang={article.language}
                        />

                    </div>

                    <ContentWithInfoPanel
                        template={2}
                        renderInfoPanel={() => {
                            return (
                                <PageInfoPanel
                                    panelClassName={classNames("contentMiddle2", "bottomPageInfoPanel", { "smallViewMode": this.props.smallViewMode || this.props.appMode })}
                                    uberId={article.id}
                                    targets={article.targets}
                                    publishers={[article.publisher]}
                                    displayTags={true}
                                    displayLastEdited={true}
                                    displayReach={true}
                                    displayPublishers={article.showPublisher}
                                    isWide={true}
                                    tags={article.tags}
                                    displayFiles={(article.attachedFiles || []).length > 0}
                                    externalFiles={(article.attachedExternalFiles || [])}
                                    displayExternalFiles={
                                        article.attachedExternalFiles?.length > 0 &&
                                        this.props.enableExternalFiles
                                    }
                                    files={(article.attachedFiles || [])}
                                />
                            );
                        }}
                    >
                        <div className="print-container">
                            <div className="contentMiddle2">
                                <div className="categoriesAndTime">
                                    {this.listCategories()}
                                    <div className="time">
                                        <Visage2Icon icon="calendar" color="mid-grey" />
                                        <Label color="mid-grey" size="body-2">
                                            {moment(article.publishStartDate).format("LL")}
                                        </Label>
                                    </div>
                                </div>
                            </div>

                            <div className="contentMiddle2">
                                <span className="newsPreamble">
                                    <Label className="preambleLabel" size="body-5" as="p" weight="medium" color="dark-grey">
                                        <span lang={article.language} dangerouslySetInnerHTML={{ __html: this.state.translatedTexts[SpintrTypes.TranslateType.Preamble]}} />
                                    </Label>
                                </span>
                            </div>

                            {article.image && (
                                <div
                                    style={{
                                        marginBottom: Style.getSpacingStr(3),
                                    }}
                                >
                                    <ContentImageViewerAndSelector
                                        imageUrl={article.image}
                                        editMode={false}
                                        cropAspect={SpintrTypes.ImageCropAspect.Wide}
                                        autoHeight
                                        blur
                                    />
                                </div>
                            )}

                            <div className="contentMiddle2">
                                <div className="PublisherAndActions">
                                    <SpintrUser
                                        id={publisher.id}
                                        name={publisher.name}
                                        subText={publisher.info}
                                        imageUrl={publisher.imageUrl ? publisher.imageUrl : publisher.image}
                                        state={publisher.state}
                                        tabIndex={0}
                                        personalName
                                    />
                                    {!this.props.smallViewMode &&
                                        <SocialBlock
                                            onlyDisplayInteractionsBar
                                            uberId={article.id}
                                            displayShareButton
                                            onCommentIconClick={() => {
                                                setTimeout(() => {
                                                    document.querySelector('.CommentsContainer')?.scrollIntoView();

                                                }, 50);
                                            }} />
                                    }
                                </div>
                            </div>

                            <Separator className="content-separator" />

                            <div className="contentMiddle2">
                                <div className="newsArticleContent">
                                    <Label size="body-1" className="contentLabel" color="dark-grey">
                                        <TinyFormattedContent content={this.state.translatedTexts[SpintrTypes.TranslateType.Content]} language={article.language} />
                                    </Label>
                                </div>
                            </div>
                            <Separator className="content-separator" />
                        </div>
                    </ContentWithInfoPanel>

                    <Separator className="content-separator" />

                    <div className="contentMiddle2">
                        <Label className="newsSocialBlock">
                            {article.allowComments &&
                                <SocialBlock
                                    onlyDisplayCommentsContainer={!this.props.smallViewMode}
                                    uberId={article.id}
                                    commentsContainerId="comments" />
                            }
                        </Label>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: Spintr.AppState, props) => {
    return {
        ...props,

        activeUserName: state.profile.active.name,
        activeUserId: state.profile.active.id,
        isAdmin: state.profile.active.isAdmin,
        isEditor: state.profile.active.isEditor,
        canUserCreateAndEditNewsArticles: canUserCreateAndEditNewsArticles(
            state.privileges.data,
            state.profile.active,
            state.instance
        ),
        instanceName: state.instance.get("name"),
        appMode: state.ui.appMode,
        smallViewMode: state.ui.isSmallViewMode,
        allInteractions: state.interactions,
        enableExternalFiles: state.profile.active.office365Connected && state.instance.get("office365Enabled"),
    };
};

export default withRouter(connect(mapStateToProps)(NewsArticleView));
