import React from "react";
import "./Visage2FullScreenView.scss";
import { IApplicationState } from "src/spintr/reducer";
import { connect } from "react-redux";

interface IProps {
    visage2FullscreenHeaderVisible?: boolean;
}

const Visage2FullScreenHeaderSpacer = (props: IProps) => {
    if (!props.visage2FullscreenHeaderVisible) {
        return null;
    }

    return (
        <div className="Visage2FullScreenHeaderSpacer" />
    )
}

const mapStateToProps = (state: IApplicationState, props) => ({
    ...props,
    visage2FullscreenHeaderVisible: state.ui.visage2FullscreenHeaderVisible
});

export default connect(mapStateToProps)(Visage2FullScreenHeaderSpacer);
