import { produce } from "immer";
import { Reducer } from "redux";

import { ConversationActionTypes } from "./conversation-action-types";
import * as ConversationReducer from "./conversation-reducer";
import { MessageActionTypes } from "./message-action-types";
import * as MessageReducer from "./message-reducer";
import { ChatAction, IChatState } from "./types";

const initialState: IChatState = {
    conversations: {
        hasMoreFetchTypes: {},
        hasFetchedFetchTypes: [],
        isLoading: false,
        items: [],
        tabIds: [],
        unreadCountPersonConversations: 0,
        unreadCountGroupConversations: 0
    },
    messages: [],
};

const chatReducer: Reducer<IChatState, ChatAction> =
    (state = initialState, action) => produce(state, (draft) => {
        switch (action.type) {
            case ConversationActionTypes.QueryConversationsPending:
                ConversationReducer.queryConversationsPending(draft, action);
                break;

            case ConversationActionTypes.QueryConversationsRejected:
                ConversationReducer.queryConversationsRejected(draft, action);
                break;

            case ConversationActionTypes.QueryConversationsFulfilled:
                ConversationReducer.queryConversationsFulfilled(draft, action);
                break;

            case ConversationActionTypes.GetConversationFulfilled:
                ConversationReducer.getConversationFulfilled(draft, action);
                break;

            case ConversationActionTypes.SetTabState:
                ConversationReducer.setTabState(draft, action);
                break;

            case ConversationActionTypes.CreateConversationPeding:
                ConversationReducer.createConversationPeding(draft, action);
                break;

            case ConversationActionTypes.CreateConversationRejected:
                ConversationReducer.createConversationRejected(draft, action);
                break;

            case ConversationActionTypes.CreateConversationFulfilled:
                ConversationReducer.createConversationFulfilled(draft, action);
                break;

            case ConversationActionTypes.AddRemoteConversation:
                ConversationReducer.addRemoteConversation(draft, action);
                break;

            case ConversationActionTypes.MarkAsReadPending:
                ConversationReducer.markAsReadPending(draft, action);
                break;

            case ConversationActionTypes.MarkAsReadRejected:
                ConversationReducer.markAsReadRejected(draft, action);
                break;

            case ConversationActionTypes.LastReadReceived:
                ConversationReducer.lastReadReceived(draft, action);
                break;

            case ConversationActionTypes.UpdateGroupActivityPending:
                ConversationReducer.updateGroupActivity(draft, action);
                break;

            case ConversationActionTypes.SetUnreadCountPersonConversations:
                ConversationReducer.setUnreadCountPersonConversations(draft, action);
                break;

            case ConversationActionTypes.SetUnreadCountGroupConversations:
                ConversationReducer.setUnreadCountGroupConversations(draft, action);
                break;

            case MessageActionTypes.AddMessageFulfilled:
                ConversationReducer.addMessageFulfilled(draft, action);
                MessageReducer.addMessageFulfilled(draft, action);
                break;

            case MessageActionTypes.QueryMessagesFulFilled:
                MessageReducer.queryMessagesFulFilled(draft, action);
                break;

            case MessageActionTypes.RemoveMessageFulfilled:
                MessageReducer.removeMessageFulfilled(draft, action);
                break;

            case MessageActionTypes.PinMessageFulfilled:
                MessageReducer.pinMessageFulfilled(draft, action);
                break;

            case MessageActionTypes.UnpinMessageFulfilled:
                MessageReducer.unpinMessageFulfilled(draft, action);
                break;

            case MessageActionTypes.RemoveReaction:
            case MessageActionTypes.RemoveReactionPending:
            case MessageActionTypes.RemoveReactionFulfilled:
                MessageReducer.removeReaction(draft, action);
                break;

            case MessageActionTypes.AddReaction:
            case MessageActionTypes.AddReactionPending:
            case MessageActionTypes.AddReactionFulfilled:
                MessageReducer.addReaction(draft, action);
                break;

            case MessageActionTypes.SaveMessagePending:
                MessageReducer.saveMessagePending(draft, action);
                break;

            case MessageActionTypes.SaveMessageFulfilled:
                ConversationReducer.saveMessageFulfilled(draft, action);
                MessageReducer.saveMessageFulfilled(draft, action);
                break;

            case MessageActionTypes.SaveMessageRejected:
                MessageReducer.saveMessageRejected(draft, action);
                break;
        }
    });

export default chatReducer;
