import api from "src/spintr/SpintrApi";
import { SpintrTypes } from "src/typings";

const baseUrl = "/api/v1/uploads";

export interface IChatUploadResponse {
    id: number;
    recipientId: number;
    senderId: number;
    senderName: string;
    text: string;
    date: string;
    fileId: number;
    fileSize: number;
    fileType: SpintrTypes.FileType;
    fileUrl: string;
}

export type ProgressEventHandler = (progressEvent: ProgressEvent) => void;

/**
 * Uploads a file to a chat conversation
 * 
 * @param conversationId the ID of the conversation
 * @param file the File object to upload
 * @param refId (optional) a locally generated reference id
 * @param onProgress (optional) a function that will be called on upload progress
 */
export async function uploadChatFile(
    conversationId: number,
    file: File,
    refId?: string,
    onProgress?: ProgressEventHandler,
): Promise<IChatUploadResponse> {
    const formData = new FormData();
    formData.append("type", "4");
    formData.append("conversationId", conversationId.toString());
    formData.append("file", file, file.name);
    
    if (refId && refId.length) {
        formData.append("refId", refId);
    }

    try {
        const { data } = await api.post<IChatUploadResponse>(
            baseUrl,
            formData,
            {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "multipart/form-data"
                },
                onUploadProgress: onProgress,
            }
        );

        return data;
    } catch (_) {
        return null;
    }
}
