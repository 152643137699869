import { takeRight } from "lodash";
import React, { Component, ReactNode } from "react";

import { localize } from "src/l10n";
import { Label } from "src/ui";
import Comment from "./Comment";
import CommentCreator from "./CommentCreator";

import "./CommentsContainer.scss";

interface ICommentsContainerProps {
    comments: Spintr.IComment[];
    uberId: number;
    uberIsComment?: boolean;
    identity?: any;
    sendToMixpanel: boolean;
    id?: string;
    displayCommentForm?: boolean;
    targetCreatorId?: number;
    targetIsQuestion?: boolean;
    onChildReply?: any;
}

interface IState {
    isDisplayingAllComments: boolean;
    autoFocusInput: boolean;
}

class CommentsContainer extends Component<ICommentsContainerProps, IState> {
    private displayLimit: number = 2;

    constructor(props: ICommentsContainerProps) {
        super(props);

        this.state = {
            isDisplayingAllComments: false,
            autoFocusInput: false
        }
    }

    componentDidUpdate(prevProps: ICommentsContainerProps) {
        if (this.props.displayCommentForm !== prevProps.displayCommentForm) {
            this.setState({
                autoFocusInput: true
            });
        }
    }

    public render(): ReactNode {
        let comments = (this.props.comments || []);
        let commentsToDisplay = [];

        if (this.state.isDisplayingAllComments) {
            commentsToDisplay = comments;
        } else {
            const answer = this.props.targetIsQuestion ?
                comments.find(x => x.isAnswer) :
                null;

            if (!!answer) {
                commentsToDisplay.push(answer);
            } else {
                commentsToDisplay = takeRight(comments, this.displayLimit);
            }
        }

        return (
            <div className="CommentsContainer" id={this.props.id}>
                {
                    comments.length > commentsToDisplay.length && (
                        <div
                            className="CommentsContainer-load-more"
                            onClick={() => {
                                this.setState({
                                    isDisplayingAllComments: true
                                });
                            }}
                            role="button">
                            <Label size="small-1" color="light-blue" className="fw-medium">
                                {
                                    (this.props.uberIsComment ? localize("SHOW_ALL_ANSWERS") : localize("SHOW_ALL_COMMENTS")) +
                                    " (" +
                                    (comments.length) +
                                    ")"
                                }
                            </Label>
                        </div>
                    )
                }
                <div className="comments-wrapper">
                    {commentsToDisplay.map(comment => {
                        const key = `CommentsContainer${comment.id}`;

                        return (
                            <div className="comment-wrapper" key={key}>
                                <Comment
                                    comment={comment}
                                    isChildComment={this.props.uberIsComment}
                                    identity={this.props.identity}
                                    sendToMixpanel={this.props.sendToMixpanel}
                                    targetCreatorId={this.props.targetCreatorId}
                                    targetIsQuestion={this.props.targetIsQuestion}
                                    onChildReply={() => {
                                        this.props.onChildReply();
                                    }}
                                />
                            </div>
                        );
                    })}
                    {this.props.displayCommentForm && (
                        <div className="comment-creator">
                            <CommentCreator
                                autofocus={true}
                                uberId={this.props.uberId}
                                identity={this.props.identity}
                                placeholder={this.props.uberIsComment ?
                                    (localize("Svara") + "...") :
                                    null}
                                sendToMixpanel={this.props.sendToMixpanel}
                            />
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default CommentsContainer;