import { AxiosResponse } from "axios";
import React, { Component, createRef, RefObject } from 'react';
import ReactDOM from 'react-dom';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { ChatComposer, ChatDateGroup } from "src/chat/components";
import { FillHeight, Scrollable } from "src/ui/components";
import Conversation from "../../Conversation";
import { Message } from '../../message-types';
import {
    BoundQueryMessagesHandler,
    getConversation,
    GetConversationHandler,
    markAsRead,
    MarkAsReadHandler,
    queryMessages,
    QueryMessagesHandler
} from "../../redux";
import "./ChatView.scss";

interface IDispatchProps {
    getConversation: GetConversationHandler;
    queryMessages: QueryMessagesHandler | BoundQueryMessagesHandler;
    markAsRead: MarkAsReadHandler
}

interface IOwnProps {
    conversationId: number;
    group?: any,
    parentMessageId?: number;
    emptyRender?: any;
    bottomOffset?: number;
}

interface IStateProps {
    conversation: Conversation;
    currentUser: Spintr.IActiveUser;
    messages: Message[];
}

interface IState {
    hasMore: boolean;
    isLoading: boolean;
    isEmpty?: boolean;
}

type Props = IDispatchProps & IOwnProps & IStateProps;

class ChatView extends Component<Props, IState> {
    protected composerRef: RefObject<any>;
    protected chatViewRef: HTMLElement;
    private _isMounted: boolean = false;

    constructor(props: Props) {
        super(props);

        this.state = {
            hasMore: true,
            isLoading: true,
        }

        this.composerRef = createRef();
    }

    parentMessageId() {
        const messageIsFetched = !!this.props.messages &&
            this.props.messages.find(m => m.id === this.props.parentMessageId);

        if (messageIsFetched) {
            const chatView = ReactDOM.findDOMNode(this.chatViewRef);

            if (!chatView) {
                return;
            }

            //@ts-ignore
            const messages = chatView.getElementsByClassName("ChatMessage-" + this.props.parentMessageId);

            if (!messages ||
                messages.length === 0) {
                return;
            }

            const message = messages[0];

            if (!message) {
                return;
            }

            setTimeout(() => {
                message.scrollIntoView();
            });
        } else {
            this.fetchMessages(() => {
                this.parentMessageId();
            });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        this.fetchConversation();

        if (this.props.parentMessageId &&
            this.props.parentMessageId > 0) {
            return this.parentMessageId();
        }

        this.fetchMessages(null, true);

        this.composerRef.current.giveFocus();
    }

    componentDidUpdate(prevProps) {
        if (this.props.messages.length === (prevProps.messages.length + 1)) {
            // On new message

            const id = this.props.messages[this.props.messages.length - 1].id;

            const chatView = ReactDOM.findDOMNode(this.chatViewRef);

            // @ts-ignore
            const message = chatView.querySelector(".ChatMessage-" + id);

            if (message) {
                this.scrollToBottomIfAtBottom(message.offsetHeight + 50); // 50 to account for margin + some error
            }
        }

        if (!!this.props.parentMessageId &&
            this.props.parentMessageId !== prevProps.parentMessageId) {
            this.parentMessageId();
        }

        if (this.props.conversation &&
            this.props.conversation.unread > 0) {
            let messageId = null;

            if (this.props.messages && this.props.messages.length > 0) {
                // grab highest messageId
                messageId = this.props.messages.reduce(
                    (id, msg) => msg.id > id ? msg.id : id,
                    0,
                );
            }

            this.props.markAsRead(
                this.props.conversation.id,
                this.props.conversation.unread,
            );
        }

        if (this.props.messages.length > 0 && this.state.isEmpty) {
            this.setState({
                isEmpty: false
            });
        }
    }

    fetchConversation() {
        this.props.getConversation(this.props.conversationId);
    }

    scrollToBottomIfAtBottom(diffSpan?: number) {
        if (!this.refs.scrollable ||
            // @ts-ignore
            !this.refs.scrollable.scrollToBottomIfAtBottom) {
            return;
        }

        // @ts-ignore
        this.refs.scrollable.scrollToBottomIfAtBottom(diffSpan);
    }

    scrollToBottom() {
        if (!this.refs.scrollable ||
            // @ts-ignore
            !this.refs.scrollable.scrollToBottom) {
            return;
        }

        // @ts-ignore
        this.refs.scrollable.scrollToBottom();
    }

    fetchMessages(doAfter?, isFirstFetch?: boolean) {
        const fn = async () => {
            let maxId;

            if (!!this.props.messages && this.props.messages.length > 0) {
                const sortedMessages = this.props.messages.sort((a, b) => {
                    return (a.date as Date).getTime() - (b.date as Date).getTime();
                });

                maxId = sortedMessages[0].id;
            } else {
                maxId = 0;
            }

            const take = 30;
            const result = await (this.props.queryMessages as BoundQueryMessagesHandler)({
                conversationId: this.props.conversationId,
                fetchType: 2,
                maxId,
                take,
            });

            if (!this._isMounted) {
                return;
            }

            const response = result.value as AxiosResponse<Spintr.IChatMessage[]>;

            if (!response) {
                this.setState({
                    hasMore: false,
                    isLoading: false,
                });

                return;
            }

            this.setState({
                hasMore: response.data && response.data.length === take,
                isEmpty: maxId === 0 &&
                    (!response.data || response.data.length === 0),
                isLoading: false,
            }, () => {
                if (isFirstFetch) {
                    this.scrollToBottom();
                }

                if (doAfter) {
                    doAfter();
                }
            });
        }

        if (this.state.isLoading) {
            fn();
        } else {
            this.setState({
                isLoading: true
            }, fn);
        }
    }

    fetchMore() {
        if (!this.state.hasMore ||
            this.state.isLoading) {
            return;
        }

        this.fetchMessages();
    }

    renderComposer() {
        return (
            <div className="site-max-width-with-padding ChatComposer-wrapper">
                <div className="chat-width">
                    <ChatComposer
                        ref={this.composerRef}
                        currentUser={this.props.currentUser}
                        conversationId={this.props.conversationId}
                        group={this.props.group} />
                </div>
            </div>
        )
    }

    getDateGroupsFromMessages(messages) {
        // TODO: Make this prettier?

        if (!messages) {
            return [];
        }

        let jsDates = {};

        for (let m of messages) {
            if (!!jsDates[m.id]) {
                continue;
            }

            jsDates[m.id] = new Date(m.date);
        }

        let sortedMessages;

        sortedMessages = messages.sort((a, b) => {
            return jsDates[a.id] - jsDates[b.id];
        });

        let dateGroups = [];

        const threshold = 1000 * 60 * 60; //1h

        for (let m of sortedMessages) {
            let dateGroup;
            let d = jsDates[m.id];

            for (let dg of dateGroups) {
                if ((dg.from.getTime() - threshold) < d.getTime() &&
                    (dg.to.getTime() + threshold) > d.getTime()) {
                    dateGroup = dg;
                }
            }

            if (!dateGroup) {
                dateGroups.push({
                    from: d,
                    to: d,
                    messages: [
                        m
                    ]
                });
            } else {
                if (dateGroup.from > d) {
                    dateGroup.from = d;
                }

                if (dateGroup.to < d) {
                    dateGroup.to = d;
                }

                dateGroup.messages.push(m);
            }
        }

        dateGroups = dateGroups.sort((a, b) => {
            return a.from - b.from;
        });

        return dateGroups;
    }

    renderDateGroups() {
        const dateGroups = this.getDateGroupsFromMessages(this.props.messages);

        return (
            <div>
                {
                    dateGroups.map((dateGroup, index) => {
                        const key = dateGroups.indexOf(dateGroup) === dateGroups.length - 1 ?
                            dateGroup.from.getTime() :
                            dateGroup.to.getTime();

                        return (
                            <ChatDateGroup
                                dateGroup={dateGroup}
                                key={key}
                                group={this.props.group} />
                        )
                    })
                }
            </div>
        )
    }

    render() {
        return (
            <div ref={(el) => { this.chatViewRef = el; }} className="ChatView">
                <FillHeight renderFooter={this.renderComposer.bind(this)} bottomOffset={this.props.bottomOffset}>
                    <Scrollable
                        ref={"scrollable"}
                        displayLoader={this.state.isLoading}
                        onEndReached={this.fetchMore.bind(this)}
                        isInvertedChatView={true}>
                        <div className="site-max-width-with-padding">
                            <div className="chat-width">
                                {
                                    this.state.isEmpty && this.props.emptyRender ?
                                        this.props.emptyRender() :
                                        this.renderDateGroups()
                                }
                            </div>
                        </div>
                    </Scrollable>
                </FillHeight>
            </div>
        )
    }
}

const mapStateToProps: MapStateToProps<IStateProps, IOwnProps, Spintr.AppState> =
    (state, props): IStateProps => ({
        conversation: state.chat.conversations.items.find(c => c.id === props.conversationId),
        currentUser: state.profile.active,
        messages: state.chat.messages.filter(m => m.conversationId === props.conversationId && !m.isSystem),
    });

const mapDispatchToProps: MapDispatchToProps<IDispatchProps, IOwnProps> = {
    getConversation,
    queryMessages,
    markAsRead
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatView);
