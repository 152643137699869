import { PrimaryButton } from "@fluentui/react";
import { AxiosResponse } from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { IAcademyLesson } from "src/academy/types";
import { localize } from "src/l10n";
import api from "src/spintr/SpintrApi";
import { Label, PageHeader } from "src/ui";
import SpintrLoader from "src/ui/components/Loader";
import UberContentView from "src/ui/components/UberContent/UberContentView";
import "./AcademyLessonView.scss";
import AcademyImage from "../AcademyImage/AcademyImage";

interface IProps {
    onDone: () => void;
    id: number;
    isLastStep: boolean;
}

interface IState {
    isLoading: boolean;
    item?: IAcademyLesson;
}

const AcademyLessonView = (props: IProps) => {
    const [state, setState] = useState<IState>({
        isLoading: true
    });

    useEffect(() => {
        fetch();
    }, []);

    const fetch = useCallback(() => {
        api.get("/api/v1/academy/lessons/" + props.id).then((response: AxiosResponse) => {
            setState((s: IState) => {
                return {
                    ...s,
                    isLoading: false,
                    item: response.data
                }
            });
        }).catch(() => { });
    }, []);

    const onDone = useCallback(() => {
        if (state.item.userProgress.isCompleted) {
            return props.onDone();
        }

        setState((s: IState) => {
            return {
                ...s,
                isLoading: true
            }
        });

        api.post("/api/v1/academy/lessons/" + state.item.id + "/read").then(props.onDone).catch(() => { });
    }, [state.item]);

    return (
        <div className="AcademyLessonView">
            {
                state.item && (
                    <div>
                        {state.item.imageUrl && (
                            <AcademyImage imageUrl={state.item.imageUrl} />
                        )}
                        <Label color="grey">{state.item.chapterName}</Label>
                        <PageHeader title={state.item.name} />
                        <div className="lesson-content">
                            <UberContentView uberContent={state.item.uberContent} />
                        </div>
                    </div>
                )
            }
            <div className="lesson-footer">
                {state.isLoading && (
                    <SpintrLoader />
                )}
                {!state.isLoading && !(props.isLastStep && state.item.userProgress.isCompleted) && (
                    <PrimaryButton
                        text={localize(state.item.userProgress.isCompleted ? "CONTINUE" : "COMPLETE_LESSON")}
                        onClick={onDone} />
                )}
            </div>
        </div>
    )
}

export default AcademyLessonView;
