import { Component, ReactNode } from 'react';
import { connect } from "react-redux";
import { fromEvent, Observer, Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { SpintrTypes } from "src/typings";
import { setViewMode } from "src/ui/actions";
import { NoOp } from 'src/utils';

interface IProps {
    setViewMode(viewMode: Spintr.ViewMode): void;
}

interface IState {
    width: number;
    viewMode: Spintr.ViewMode;
}

class SpintrWidthWatcher extends Component<IProps, IState> {
    protected static readonly debounceThreshold = 250;

    protected static getViewMode(width: number): Spintr.ViewMode {
        const vm = SpintrTypes.ViewMode;

        if (width >= vm.FullSize) {
            return vm.FullSize;
        }

        if (width >= vm.MediumLaptop) {
            return vm.MediumLaptop;
        }

        if (width >= vm.SmallestLaptop) {
            return vm.SmallestLaptop;
        }

        if (width >= vm.SmallestLaptop) {
            return vm.SmallestLaptop;
        }

        if (width >= vm.TabletLandscape) {
            return vm.TabletLandscape;
        }

        if (width >= vm.PhoneLandscape) {
            return vm.PhoneLandscape;
        }

        if (width < vm.TabletPortrait) {
            return vm.PhonePortrait;
        }

        return vm.TabletPortrait;
    }

    protected readonly widthObserver: Observer<Event>;
    protected widthSubscription: Subscription;

    constructor(props: IProps) {
        super(props);

        this.onResize = this.onResize.bind(this);

        this.widthObserver = {
            complete: NoOp,
            error: NoOp,
            next: this.onResize,
        };

        this.state = this.getUpdatedState();
    }

    public componentDidMount(): void {
        this.widthSubscription = fromEvent(window, "resize")
            .pipe(debounceTime(SpintrWidthWatcher.debounceThreshold))
            .subscribe(this.widthObserver);

        this.onResize();
    }

    public componentWillUnmount() {
        if (!this.widthSubscription) {
            return;
        }
        
        this.widthSubscription.unsubscribe();
    }

    public render(): ReactNode {
       return null;
    }

    protected getUpdatedState(): IState {
        const height = window.innerHeight;
        const width = window.innerWidth;

        if (document.documentElement && document.documentElement.style) {
            document.documentElement.style.setProperty(
                "--vh", 
                (height * 0.01) + "px",
            );
            document.documentElement.style.setProperty(
                "--vw", 
                (width * 0.01) + "px",
            );
        }

        const viewMode = SpintrWidthWatcher.getViewMode(width);

        const updatedState: IState = {
            viewMode,
            width,
        };

        return updatedState;
    }

    protected onResize(): void {
        const updatedState = this.getUpdatedState();

        this.setState(updatedState, () => {
            this.props.setViewMode(updatedState.viewMode);
        });
    }
}

export default connect<{}, IProps, {}, Spintr.AppState>(
    () => ({}),
    { setViewMode }
)(SpintrWidthWatcher);
