import classnames from "classnames";
import { Callout, Icon } from "@fluentui/react";
import React, { Component, ReactNode } from "react";
import { connect, MapDispatchToProps, MapStateToProps } from "react-redux";
import { UnstyledButton } from "src/ui";
import { uniqueId } from "src/utils";
import Conversation from "../../Conversation";
import { setTabState, SetTabStateHandler } from "../../redux";
import { ChatTab } from "../ChatTab";
import { HiddenChatTab } from "../HiddenChatTab";
import "./ChatTabContainer.scss";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";

interface IDispatchProps {
    setTabState: SetTabStateHandler;
}

interface IStateProps {
    conversations: Conversation[];
    sidebarExpanded: boolean;
    hasFormFooterBar: boolean;
}

type Props = IDispatchProps & IStateProps;

interface IState {
    switcherVisible: boolean;
}

const TabsToShow = 3;

class ChatTabContainer extends Component<Props, IState> {
    constructor(props: Props) {
        super(props);

        this.state = {
            switcherVisible: false
        };

        this.onSwitcherClick = this.onSwitcherClick.bind(this);
        this.onSwitcherDismiss = this.onSwitcherDismiss.bind(this);
    }

    public render(): ReactNode {
        const { conversations, sidebarExpanded, hasFormFooterBar } = this.props;

        const tabsToRender = conversations.length > TabsToShow
            ? conversations.slice(conversations.length - TabsToShow)
            : conversations;
        const tabsInMenu = conversations.length > TabsToShow
            ? conversations.slice(0, conversations.length - TabsToShow)
            : [];

        return (
            <div id="ChatTabContainer" className={classnames("", {
                    ["with-sidebar"] : sidebarExpanded,
                    ["with-formfooterbar"] : hasFormFooterBar})}>
                        
                <div className="wrapper">
                    {tabsInMenu.length > 0 && (
                        <>
                            <UnstyledButton
                                className="tab-switcher"
                                onClick={this.onSwitcherClick}
                            >
                                <Visage2Icon icon="message" />
                            </UnstyledButton>
                            {this.state.switcherVisible && (
                                <Callout
                                    onDismiss={this.onSwitcherDismiss}
                                    target="#ChatTabContainer .tab-switcher"
                                    setInitialFocus={true}
                                >
                                    <ul className="HiddenTabList">
                                        {tabsInMenu.map((tab) => (
                                            <li
                                                className="hidden-tab"
                                                key={tab.id}
                                            >
                                                <HiddenChatTab
                                                    conversation={tab}
                                                />
                                            </li>
                                        ))}
                                    </ul>
                                </Callout>
                            )}
                        </>
                    )}
                    {tabsToRender.map(this.renderTab)}
                </div>
            </div>
        )
    }

    protected onSwitcherClick() {
        this.setState({
            switcherVisible: true
        });
    }

    protected onSwitcherDismiss() {
        this.setState({
            switcherVisible: false
        });
    }

    protected renderTab(conversation: Conversation): ReactNode {
        const id = conversation.id === 0
            ? uniqueId("ct.")
            : conversation.id.toString();

        return (
            <div className="tab-wrapper" key={"ChatTab." + id}>
                <ChatTab
                    conversation={conversation}
                />
            </div>
        );
    }
}

const mapStateToProps: MapStateToProps<IStateProps, {}, Spintr.AppState> =
    (state) => ({
        conversations: state.chat.conversations.tabIds
            .map((tab) => state.chat.conversations.items.find(
                (c) => c.id === tab.id
            ))
            .filter((c) => !!c),

        sidebarExpanded: state.ui.sidebarExpanded,
        hasFormFooterBar: state.ui.formFooterBarVisible
    })

const mapDispatchToProps: MapDispatchToProps<IDispatchProps, {}> = {
    setTabState,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatTabContainer);