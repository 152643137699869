import React, { Fragment, FunctionComponent } from 'react';
import { Link } from "react-router-dom";
import { getTypeName, localize } from "src/l10n";
import { getUserPart } from '../utils';

interface IProps {
    notification: Spintr.INotification;
}

const ProfilePageNotification: FunctionComponent<IProps> = (props)  => {
    const notification = props.notification as Spintr.IProfilePostNotification;
    if (!notification) {
        return;
    }

    return (
        <Fragment>
            {
                  getUserPart(notification)
                + " "
                + localize("HarPostat")
                + " "
            }
            <Link to={notification.url}>
                {getTypeName(notification.objectType, { case: "indefinite "})}
            </Link>
            {
                  " "
                + localize("PaDinProfilSida")
            }
        </Fragment>
    );
};

export default ProfilePageNotification;
