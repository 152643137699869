import React, { FunctionComponent, ReactElement } from 'react';
import { SocialFeedContainer, SocialFeedType } from "src/social-feed";
import { InlineComposer } from "../Composer";
import "./SpintrSocialFeed.scss";

interface IProps {
    feedId?: number;
    feedType: SocialFeedType;
    galleryId?: number;
    userId?: number;
    groupId?: number;
    identity?: any;
    isUnit?: boolean;
    isStartPage?:boolean;
    hideComposer?: boolean;
}

const SpintrSocialFeed: FunctionComponent<IProps> =
    (props: IProps): ReactElement => (
        <div role="region" className="SpintrSocialFeed">
            {!props.hideComposer && (
                <InlineComposer
                    feedId={props.feedId}
                    galleryId={props.galleryId}
                    identity={props.identity}
                    isUnit={props.isUnit}
                    isStartPage={props.isStartPage}
                />
            )}
            <div className="social-post-feed">
                <SocialFeedContainer 
                    feedId={props.feedId}
                    groupId={props.groupId}
                    feedType={props.feedType}
                    identity={props.identity}
                />
            </div>
        </div>
    );

export default SpintrSocialFeed
