import React from "react";
import { Link } from "react-router-dom";
import AcademyCourseCategories from "src/academy/course/AcademyCourseCategories/AcademyCourseCategories";
import AcademyImage from "src/academy/course/AcademyImage/AcademyImage";
import AcademyCourseMetaDataRow from "src/academy/course/AcademyCourseMetaDataRow/AcademyCourseMetaDataRow";
import AcademyCourseProgress from "src/academy/course/AcademyCourseProgress/AcademyCourseProgress";
import { IAcademyCourse } from "src/academy/types";
import { Label } from "src/ui";
import "./AcademyCourseListItem.scss";

interface IProps {
    course: IAcademyCourse;
    big?: boolean;
}

const AcademyCourseListItem = (props: IProps) => {
    return (
        <Link to={"/academy/" + props.course.id} className={["AcademyCourseListItem", ...(props.big ? ["big"] : [])].join(" ")}>
            <AcademyImage imageUrl={props.course.imageUrl} />
            <div className="AcademyCourseListItem-info">
                <Label size="h3" className="course-name" weight="semi-bold" color="dark-grey">{props.course.name}</Label>
                {props.course.category && (
                    <AcademyCourseCategories categories={[props.course.category]} />
                )}
                {props.course.description && (
                    <Label className="course-description" size="body-1" color="dark-grey">{props.course.description}</Label>
                )}
                <AcademyCourseMetaDataRow
                    lessonCount={props.course.statistics.lessonCount}
                    userCount={props.course.statistics.usersTotal}
                    language={props.course.language} />
                {props.course.userProgress.isInitialized && (
                    <AcademyCourseProgress userProgress={props.course.userProgress} />
                )}
            </div>
        </Link>
    )
}

export default AcademyCourseListItem;
