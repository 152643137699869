import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ChatMessageContent } from "src/chat";
import "src/groups/views/GroupPopupView.scss";
import { localize } from "src/l10n";
import api from "src/spintr/SpintrApi";
import { Loader } from "src/ui";
import { Label, Scrollable, UnstyledButton } from "src/ui/components";
import { Style } from "src/ui/helpers";

interface IProps {
    conversationId: number;
    groupId: number;
    history: any;
}

interface IState {
    isLoading: boolean;
    pins: any[];
}

class GroupPinsPopupView extends Component<IProps, IState> {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            pins: []
        }
    }

    componentDidMount() {
        api.get("/api/v1/conversations/" + this.props.conversationId + "/pins").then((response) => {
            this.setState({
                isLoading: false,
                pins: response.data.reverse()
            });
        }).catch(() => {});
    }

    getTypeName (message) {
        if (message.object &&
            message.object.type) {
            switch (message.object.type) {
                case 7:
                    return localize('Fil');
                case 11:
                    return localize('Kalender');
                case 56:
                    return localize('Anteckning');
                case 61:
                    return localize('Planering');
                case 64:
                    return localize('Kort');
            }
        }

        return localize('Meddelande');
    };

    renderPin (p, i) {
        return (
            <div className="GroupPopupView-pin" key={p.id}>
                <UnstyledButton onClick={() => {
                    this.props.history.push({
                        pathname: "/groups/" + this.props.groupId,
                        search: "?parentMessageId=" + p.id
                    });
                }}>
                    <div className="GroupPopupView-pin-header">
                        <Label size="small-2" color="dark-grey">
                            {
                                this.getTypeName(p).toUpperCase()
                            }
                        </Label>
                    </div>
                    <div className="GroupPopupView-pin-text">
                        <ChatMessageContent
                            message={p}
                        />
                    </div>
                    <div className="GroupPopupView-pin-info">
                        <Label size="body-3" color="dark-grey">
                            {
                                localize("Av_By") + " " + p.user.name + " @ " + moment(p.date).format("L")
                            }
                        </Label>
                    </div>
                </UnstyledButton>
            </div>
        )
    }

    render() {
        return (
            <div className="GroupPopupView">
                <Scrollable>
                    <div className="GroupPopupView-inner">
                        {
                            this.state.isLoading ?
                                <Loader /> :
                                <div>
                                    {
                                        this.state.pins.map((p, index) => {
                                            return this.renderPin(p, index);
                                        })
                                    }
                                </div>
                        }
                        {
                            !this.state.isLoading &&
                            this.state.pins.length === 0 ?
                                <Label role="heading" ariaLevel={1} as="div" size="small-1" style={{
                                    margin: Style.getSpacing(3),
                                    textAlign: "center"
                                }}>
                                    {
                                        localize("IngaPoster")
                                    }
                                </Label> :
                                null
                        }
                    </div>
                </Scrollable>
            </div>
        )
    }
}

const mapStateToProps = (state, props) => ({
    ...props
});

export default withRouter(connect(mapStateToProps)(GroupPinsPopupView));
