import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { localize } from "src/l10n";
import { Icon, Label, UnstyledButton } from "src/ui";
import ImageCore from "src/ui/components/ImageCore";
import { formatInteractiveText } from "src/utils";
import "../ChatMessage/ChatMessage.scss";
import { ExternalFile } from '../ExternalFile';
import Visage2Icon from 'src/visage2/Visage2Icon/Visage2Icon';

interface IProps {
    message: any,
    history?: any;
}

class ChatMessageContent extends Component<IProps> {
    constructor(props) {
        super(props);
    }

    shouldComponentUpdate() {
        return false;
    }

    renderFile(name, userName, onOpen) {
        return (
            <div className="ChatMessage-object">
                <div className="Chatmessage-object-top">
                    <div className="Chatmessage-object-top-left">
                        <Visage2Icon icon="folder" color="dark-grey" />
                    </div>
                    <div className="Chatmessage-object-top-right">
                        <Label color="dark-grey" size="body-3">
                            {
                                name ? name : localize("NamnSaknas")
                            }
                        </Label>
                        {
                            !!userName ?
                                <Label color="dark-grey" size="body-3">
                                {
                                    localize("ADDED_BY") + " " + userName
                                }
                                </Label> :
                                null
                        }
                    </div>
                </div>
                <div className="Chatmessage-object-bottom">
                    <UnstyledButton onClick={onOpen}>
                        <Label color="light-blue" size="small-1" title={localize("Oppna")}>
                            {
                                localize("Oppna")
                            }
                        </Label>
                    </UnstyledButton>
                </div>
            </div>
        )
    }

    renderImage(path) {
        return (
            <div className="ChatMessage-image attached">
                <ImageCore
                    alt=""
                    openPhotoBoxOnClick={true}
                    src={
                        path
                    } />
            </div>
        )
    }

    renderAttachments(message) {
        return (
            <div>
                {
                    message.attachments.map((a, index) => {
                        let isImage = a.typeId === 4 || a.typeId === 9;

                        return (
                            <div key={index}>
                                {
                                    isImage ?
                                        this.renderImage(a.path ? a.path : a.thumbnailUrl) :
                                        this.renderFile(a.name, message.user.name, () => {
                                            window.location.href = `/api/v1/servefile/${a.id}`;
                                        })
                                }
                            </div>
                        )
                    })
                }
                {
                    message.object &&
                    !message.object.externalId ?
                        this.renderFile(message.object.name, message.object.userName, () => {
                            this.props.history.push({
                                pathname: `/goto/${message.object.id}`,
                            });
                        }) :
                        null
                }
                {
                    message.object &&
                    message.object.externalId ?
                        <ExternalFile
                            externalId={message.object.externalId}
                            source={message.object.source} /> :
                        null
                }
            </div>
        )
    }

    renderText(message) {
        let text = message.text;

        const systemMessageTypes = [
            "GROUP_EVENT_CREATED_F",
            "GROUP_SYSTEM_FILE_UPLOAD_F",
            "CHAT_MIGRATION_HAPPENED",
            "GROUP_EVENT_EDITED_F",
            "GROUP_NOTE_CREATED_F",
            "GROUP_NOTE_EDITED_F",
            "GROUP_PLANNING_ITEM_CREATED_F",
            "GROUP_PLANNING_ITEM_UPDATED_F",
            "GROUP_PLANNING_ITEM_COMMENTED_F",
            "GROUP_PLANNING_TODO_CREATED_F",
            "GROUP_SHARED_POST_F",
            "GROUP_PAGE_CREATED"
        ];

        if (systemMessageTypes.indexOf(text) > -1) {
            text = localize(text);

            if (message.object) {
                text.replace("{0}", message.object.name)
            }
        }

        return (
            <Label
                as="p"
                className="interactive-text"
            >
                {formatInteractiveText(text)}
            </Label>
        )
    }

    render() {
        return (
            <div className="ChatMessage-content">
                <div className="ChatMessage-text" tabIndex={0}>
                    {
                        this.renderText(this.props.message)
                    }
                </div>
                {
                    this.renderAttachments(this.props.message)
                }
            </div>
        )
    }
}

export default withRouter(ChatMessageContent);
