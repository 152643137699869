import Message from "../Message";
import * as MessageActions from "./message-actions";
import { ChatReducerHandler } from "./types";

export const queryMessagesFulFilled: ChatReducerHandler<MessageActions.IQueryMessagesFulFilledAction> =
    (draft, action) => {
        if (action.meta.threadId) {
            draft.messages = draft.messages.map(m => {
                if (m.id !== action.meta.threadId) {
                    return m;
                }

                action.payload.data.reverse();

                return m.merge({
                    replies: [
                        ...(!!m.replies ? m.replies : []),
                        ...action.payload.data.map(
                            (msg) => new Message(msg),
                        )
                    ]
                });
            });

            return;
        }

        draft.messages = [
            ...draft.messages,
            ...action.payload.data
                .filter(m => draft.messages.every(msg => msg.id !== m.id))
                .map(m => new Message(m))
        ];
    };

export const addMessageFulfilled: ChatReducerHandler<MessageActions.IMessageReceivedAction> =
    (draft, action) => {
        if (action.meta.message.parentId > 0) {
            draft.messages = draft.messages.map(m => {
                if (m.id !== action.meta.message.parentId) {
                    return m;
                }

                if (action.meta.message.referenceId) {
                    // Don't add duplicates
                    if (m.replies?.some((r) => r.referenceId === action.meta.message.referenceId)) {
                        return m;
                    }
                }

                return m.merge({
                    replies: [
                        ...(!!m.replies ? m.replies : []),
                        new Message(action.meta.message)
                    ],
                    replyCount: m.replyCount + 1
                });
            });
            return;
        }

        if (action.meta.message.referenceId) {
            // Don't add duplicates
            if (draft.messages.some((m) => m.referenceId === action.meta.message.referenceId)) {
                return;
            }
        }

        draft.messages = [
            ...draft.messages,
            new Message(action.meta.message),
        ];
    };

export const removeMessageFulfilled: ChatReducerHandler<MessageActions.IMessageDeletedAction> =
    (draft, action) => {
        draft.messages = draft.messages
            .filter(
                (m) => m.id !== action.meta.messageId
            )
            .map(m => {
                if (!m.replies) {
                    return m;
                }

                const newReplies: Spintr.IChatMessage[] = [];
                let replyCountChange = 0;

                m.replies.forEach(r => {
                    if (r.id !== action.meta.messageId) {
                        newReplies.push(r);
                    } else {
                        replyCountChange--;
                    }
                });

                return m.merge({
                    replies: newReplies,
                    replyCount: m.replyCount ?
                        (m.replyCount + replyCountChange) :
                        m.replyCount
                });
            });
    };

export const pinMessageFulfilled: ChatReducerHandler<MessageActions.IMessagePinnedAction> =
    (draft, action) => {
        draft.messages = draft.messages.map(m => {
            if (m.id !== action.meta.messageId) {
                return m;
            }

            return m.merge({
                isPinned: true,
            });
        });
    };

export const unpinMessageFulfilled: ChatReducerHandler<MessageActions.IMessageUnpinnedAction> =
    (draft, action) => {
        draft.messages = draft.messages.map(m => {
            if (m.id !== action.meta.messageId) {
                return m;
            }

            return m.merge({
                isPinned: false,
            });
        });
    };

export const removeReaction: ChatReducerHandler<MessageActions.RemoveReactionAction> =
    (draft, action) => {
        draft.messages = draft.messages.map(m => {
            let reply;

            if (m.id !== action.meta.messageId) {
                reply = m.replies.find(r => r.id === action.meta.messageId);

                if (!reply) {
                    return m;
                }
            }

            const existingReaction = (!!reply ? reply.reactions : m.reactions).find(r =>
                r.reactionId === action.meta.reactionId &&
                r.user.id === action.meta.userId);

            if (!existingReaction) {
                return m;
            }

            return m.merge({
                reactions: [
                    ...m.reactions.slice(0, m.reactions.indexOf(existingReaction)),
                    ...m.reactions.slice(m.reactions.indexOf(existingReaction) + 1)
                ],
                replies: !reply ?
                    m.replies :
                    m.replies.map((r: any) => {
                        if (r.reactions.indexOf(existingReaction) === -1) {
                            return r;
                        }

                        return r.merge({
                            reactions: [
                                ...m.reactions.slice(0, r.reactions.indexOf(existingReaction)),
                                ...m.reactions.slice(r.reactions.indexOf(existingReaction) + 1)
                            ]
                        })
                    })
            });
        });
    };

export const addReaction: ChatReducerHandler<MessageActions.AddReactionAction> =
    (draft, action) => {
        draft.messages = draft.messages.map(m => {
            let reply;

            if (m.id !== action.meta.messageId) {
                reply = m.replies.find(r => r.id === action.meta.messageId);

                if (!reply) {
                    return m;
                }
            }

            const existingReaction = (!!reply ? reply.reactions : m.reactions).find(r =>
                r.reactionId === action.meta.reactionId &&
                r.user.id === action.meta.userId);

            if (!!existingReaction) {
                return m;
            }

            return m.merge({
                reactions: !!reply ?
                    m.reactions :
                    [
                        ...m.reactions,
                        {
                            reactionId: action.meta.reactionId,
                            user: {
                                id: action.meta.userId,
                                name: action.meta.userName
                            }
                        }
                    ],
                replies: !reply ?
                    m.replies :
                    m.replies.map((r) => {
                        if (r.id !== action.meta.messageId) {
                            return r;
                        }

                        r.reactions.push({
                            reactionId: action.meta.reactionId,
                            user: {
                                id: action.meta.userId,
                                name: action.meta.userName
                            }
                        })

                        return r;
                    })
            });
        });
    };

export const saveMessagePending: ChatReducerHandler<MessageActions.ISaveMessagePendingAction> =
    (draft, action) => {
        const message = action.meta.message;

        if (message.parentId <= 0) {
            draft.messages = [
                ...draft.messages,
                message,
            ];
            return;
        }

        draft.messages = draft.messages.map(m => {
            if (m.id !== message.parentId) {
                return m;
            }

            return m.merge({
                replies: [
                    ...(!!m.replies ? m.replies : []),
                    message,
                ],
                replyCount: m.replyCount + 1,
            });
        });
    };

export const saveMessageFulfilled: ChatReducerHandler<MessageActions.ISaveMessageFulfilledAction> =
    (draft, action) => {
        draft.messages = draft.messages.map((m) => {
            if (m.id !== action.meta.message.id) {
                return m;
            }

            if (!!action.payload.data.user &&
                !action.payload.data.user.imageUrl &&
                !!m.user &&
                !!m.user.imageUrl) {
                action.payload.data.user.imageUrl = m.user.imageUrl;
            }

            return m.merge(action.payload.data);
        });
    };

export const saveMessageRejected: ChatReducerHandler<MessageActions.ISaveMessageRejectedAction> =
    (draft, action) => {
        draft.messages = draft.messages.map((m) => {
            if (m.id !== action.meta.message.id) {
                return m;
            }

            return m.merge({
                savingFailed: true,
            });
        });
    };
