import React, { Component, ReactNode } from "react";
import SocialBlock from "src/interactions/components/SocialBlock";
import { SocialFeedType } from "src/social-feed";
import { SocialPost } from "../SocialPost";
import "./SocialPostContainer.scss";
import { SpintrTypes } from "src/typings";
import { InteractiveTextFormatOptions } from "src/utils";

interface ISocialPostContainerProps {
    identity?: any;
    isShare?: boolean;
    post: Spintr.ISocialPostBase;
    feedType?: SocialFeedType;
    onDoneEditing?: any;
    interactiveTextOptions?:    InteractiveTextFormatOptions | undefined;
}

class SocialPostContainer extends Component<ISocialPostContainerProps> {
    public render(): ReactNode {
        return (
            <div className="SocialPostContainer visage-box-shadow start-page-flexible-padding">
                <div className="post-wrapper">
                    <SocialPost
                        post={this.props.post}
                        onDoneEditing={this.props.onDoneEditing}
                        interactiveTextOptions={this.props.interactiveTextOptions}
                    />
                </div>
                {!this.props.isShare && !this.props.post.hidden && (
                    <>
                        <SocialBlock
                            onCommentIconClick={() => { }}
                            uberId={this.props.post.Id}
                            comments={this.props.post.comments}
                            likers={this.props.post.likers}
                            identity={this.props.identity}
                            sendToMixpanel={this.props.feedType === SocialFeedType.Combined}
                            enableReactions={true}
                            displayBigButtons={true}
                            isFeedPost={true}
                            targetCreatorId={this.props.post.UserId}
                            targetIsQuestion={this.props.post.StatusType === SpintrTypes.StatusType.Question}
                        />
                    </>
                )}
            </div>
        );
    }
}

export default SocialPostContainer;