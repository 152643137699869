import { SpintrTypes } from "src/typings";

import Conversation from "./Conversation";

// for create
export interface IConversationModel {

}

export interface IConversationExternalFileModel {

}

export interface IGetConversationsParams {
    skip?: number;
    take?: number;
    orderBy?: string;
    asc?: boolean;
    filter?: string;
    fetchType: SpintrTypes.ConversationsFetchType;
}

export { Conversation };
