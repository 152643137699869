import React from "react";
import { SpintrUser } from "src/ui";
import { circleLarge } from "src/ui/helpers/style";

interface IProps {
    onClosing?: () => void;
    setFeedId: any;
    onPostUpdate: any;
    imageUrl: string;
    userName: string;
    feedId: number;
    post: Spintr.ISocialPostBase;
    hideTargetSelector: boolean;
}

const Visage2ComposerHeader = (props: IProps) => {
    return (
        <div className="Visage2ComposerHeader">
            <SpintrUser
                name={props.userName}
                imageUrl={props.imageUrl}
                size={circleLarge}
                personalName={true}
            />
        </div>
    )
}

export default Visage2ComposerHeader;