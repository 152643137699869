import { ContentBlock, ContentState } from "draft-js";

type IndexList = Array<{ start: number; end: number }>;

export const draftToHtml = (content: ContentState, useCrlf: boolean = false): string => {
    const blockArr = content.getBlocksAsArray();
    return blockArr.map((block: ContentBlock, index): string => {
        let text = block.getText();
        if (!text) {
            return useCrlf ? "\r\n" : "<br />";
        }

        const entityRanges: IndexList = []; 
        block.findEntityRanges(
            meta => !!meta.getEntity(),
            (start, end) => entityRanges.push({ start, end })
        );

        text = entityRanges
            .sort((a,b) => a.start > b.start ? -1 : 1)
            .reduce(
                (acc: string, range) => {
                    const entityKey = block.getEntityAt(range.start);
                    const entity = content.getEntity(entityKey);
                    const { mention, product } = entity.getData();

                    let link: string | undefined = mention?.link;
                    if (!mention && product) {
                        link = "/products/" + product.id;
                    }

                    if (!link) {
                        return acc;
                    }

                    return [
                        acc.substring(0, range.start),
                        `[link@${link}@link]`,
                        acc.substring(range.start, (range.end - range.start)),
                        "[/link]",
                        acc.substring(range.end)
                    ].join("") + "\r\n";
                },
                text
            );

        if ((index + 1) === blockArr.length) {
            return text;
        }

        return text + (useCrlf ? "\r\n" : "<br />");
    }).join("");
};