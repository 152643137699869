import { Context, createContext } from "react";
import { NoOp } from "src/utils";

export interface IChatTabContext {
    currentUser: Spintr.IUser;
    lastRead: { [messageId: number]: number[] };

    clearUnread(): void;
    loadMore(): void;
}

const ChatTabContext: Context<IChatTabContext> = createContext({
    currentUser: null,
    lastRead: {},

    clearUnread: NoOp,
    loadMore: NoOp,
});

export default ChatTabContext;