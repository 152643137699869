import classnames from "classnames";
import React, { Component, ReactNode } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { SocialBlock } from "src/interactions/components";
import { localize } from "src/l10n";
import ThemeContext from "src/style/ThemeContext";
import { ActionMenu, Label, SpintrUser, UserHovercard } from "src/ui";
import ImageCore from "src/ui/components/ImageCore";
import TranslateButton from "src/ui/components/TranslateButton";
import { circleLarge, circleMedium, circleSmall } from "src/ui/helpers/style";
import { decodeHtmlEntities, formatInteractiveText, getShortFromNow } from "src/utils";
import { markCommentAsAnswer, removeComment } from "../actions";
import "./Comment.scss";
import CommentForm from "./CommentForm";
import api from "src/spintr/SpintrApi";
import { Icon, TooltipHost } from "@fluentui/react";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import moment from "moment";
import UpdatingDate from "./UpdatingDate";

interface IProps {
    comment: Spintr.IComment;
    isChildComment?: boolean;
    identity?: any;
    dispatch?: any;
    user?: any;
    isAdmin?: boolean;
    isEditor?: boolean;
    sendToMixpanel: boolean;
    enableTranslator: boolean;
    targetCreatorId?: number;
    targetIsQuestion?: boolean;
    onChildReply?: any;
}

interface IState {
    commentDate?: Date;
    isEditing: boolean;
    commentText: string;
}

class Comment extends Component<IProps, IState> {
    public static contextType = ThemeContext;

    constructor(props: IProps) {
        super(props);

        this.onDeleteClick = this.onDeleteClick.bind(this);

        this.state = {
            commentDate: this.props.comment.date
                ? new Date(this.props.comment.date)
                : undefined,
            isEditing: false,
            commentText: this.props.comment.text
        };
    }

    public componentDidUpdate(prevProps: Readonly<IProps>): void {
        if (prevProps.comment?.text !== this.props.comment?.text) {
            this.setState({
                commentText: this.props.comment.text
            });
        }

        if (prevProps.comment?.date !== this.props.comment?.date) {
            this.setState({
                commentDate: this.props.comment.date
                    ? new Date(this.props.comment.date)
                    : undefined
            });
        }
    }

    protected translate = (commentText: string): void => {
        this.setState({ commentText })
    }

    renderActionMenu = () => {
        let items = [];

        if (this.props.comment.author.id === this.props.user.id || this.props.isAdmin) {
            items.push({
                onClick: this.onEditClick.bind(this),
                text: localize("Redigera"),
            });

            items.push({
                onClick: this.onDeleteClick.bind(this),
                text: localize("TaBort"),
            });
        }

        if (this.props.targetIsQuestion && (this.props.targetCreatorId === this.props.user.id || this.props.isAdmin || this.props.isEditor)) {
            items.push({
                onClick: this.onMarkAsAnswerClick.bind(this),
                text: localize(this.props.comment.isAnswer ? "UNMARK_AS_ANSWER" : "MARK_AS_ANSWER"),
            });
        }

        if (!items) {
            return null;
        }

        return (
            <ActionMenu
                categories={[{
                    items: items,
                    title: "",
                }]}
                canReport={this.props.comment.author.id !== this.props.user.id}
                objectId={this.props.comment.id}
            />
        );
    }

    public render(): ReactNode {
        if (this.state.isEditing) {
            return (
                <div className="Edit-Comment">
                    <CommentForm
                        uberId={this.props.comment.objectId}
                        currentUser={this.props.user}
                        comment={this.props.comment}
                        displayButtons={true}
                        autofocus={true}
                        onClose={() => {
                            this.setState({
                                isEditing: false
                            });
                        }}
                        sendToMixpanel={this.props.sendToMixpanel} />
                </div>
            )
        }

        let className = "Comment";

        if (this.props.isChildComment) {
            className += " Comment-child-comment"
        }

        let commentInnerlassName = "comment-inner";

        if (this.props.comment.author.id === this.props.user.id) {
            commentInnerlassName += " primaryBorderColor"
        }

        const hasText = !!this.props.comment.text &&
            this.props.comment.text.length > 0;

        let contentImageWrapperClassName = "comment-image-wrapper";

        if (hasText) {
            contentImageWrapperClassName += " comment-has-text"
        }

        return (
            <div className={className}>
                <div className={commentInnerlassName}>
                    <div className="comment-first-row">
                        <div className="commentor-image">
                            <SpintrUser
                                id={this.props.comment.author.id}
                                name={this.props.comment.author.name}
                                imageUrl={this.props.comment.author.imageUrl}
                                size={circleSmall}
                                hideText={true}
                                personalName={true}
                                state={this.props.comment.author.state}
                            />
                        </div>
                        <div className="comment-content">
                            <div className="comment-content-first-row">
                                <div className={classnames("comment-content-inner", {
                                    translationEnabled: this.props.enableTranslator
                                })}>
                                {this.props.comment.isAnswer && this.props.targetIsQuestion && (
                                    <div className="visage2-feed-icon">
                                        <TooltipHost
                                            content={localize("Svar")}
                                            className="marketplace-tooltip"
                                            calloutProps={{ beakWidth: 8 }}>
                                            <div className="visage2-icon-wrapper" style={{
                                                backgroundColor: "#F1F2FC"
                                            }}>
                                                <Visage2Icon title={localize("Svar")} icon="tick-circle" size="small" type="bold" hexColor="#787CDD" />
                                                <Label size="body-2">{localize("Svar")}</Label>
                                            </div>
                                        </TooltipHost>
                                    </div>
                                )}
                                    <div className="commentor">
                                        <UserHovercard userId={this.props.comment.author.id}>
                                            <Link to={"/profile/" + this.props.comment.author.id}>
                                                <Label color="dark-grey" size="body-2" weight="medium" className="commentor-name" as="div">
                                                    {this.props.comment.author.name}
                                                </Label>
                                            </Link>
                                        </UserHovercard>
                                        <Label size="body-2" color="dark-grey">
                                            <UpdatingDate date={this.state.commentDate} short />
                                        </Label>
                                    </div>
                                    {
                                        this.state.commentText && (
                                            <>
                                                <Label as="p" color="visageGray" size="body-2" className="comment-text underline-wrapper general-row-break interactive-text">
                                                    {formatInteractiveText(decodeHtmlEntities(this.state.commentText))}
                                                </Label>
                                                <TranslateButton authorLanguage={this.props.comment.author.language} uberId={this.props.comment.id} text={this.props.comment.text} onTranslateFn={this.translate} />
                                            </>
                                        )
                                    }
                                    {
                                        !!this.props.comment.imageUrl ?
                                            <div className={contentImageWrapperClassName}>
                                                <ImageCore
                                                    alt=""
                                                    height={118}
                                                    openPhotoBoxOnClick={true}
                                                    src={this.props.comment.imageUrl}
                                                />
                                            </div> :
                                            null
                                    }
                                </div>
                                {
                                    this.renderActionMenu()
                                }
                            </div>
                            <SocialBlock
                                uberId={this.props.comment.id}
                                likers={this.props.comment.likers}
                                comments={this.props.comment.comments || []}
                                uberIsComment={this.props.isChildComment}
                                //date={new Date(this.props.comment.date)}
                                useSmallerInteractionsStyle={true}
                                onlyDisplayInteractionsBar={true}
                                onCommentIconClick={() => {}}
                                identity={this.props.identity}
                                sendToMixpanel={this.props.sendToMixpanel}
                                enableReactions
                                onChildReply={!this.props.isChildComment ? undefined : () => {
                                    this.props.onChildReply();
                                }}
                            />
                        </div>
                    </div>
                    {!this.props.isChildComment && (
                        <div className="child-comments">
                            <SocialBlock
                                uberId={this.props.comment.id}
                                likers={this.props.comment.likers}
                                comments={this.props.comment.comments || []}
                                uberIsComment={true}
                                useSmallerInteractionsStyle={true}
                                onlyDisplayCommentsContainer={true}
                                identity={this.props.identity}
                                sendToMixpanel={this.props.sendToMixpanel}
                                enableReactions={true}
                            />
                        </div>
                    )}
                </div>
            </div>
        );
    }

    protected renderRegularText(text: string): ReactNode {
        return (
            <span
                dangerouslySetInnerHTML={{
                    __html: text
                }}
            />
        )
    }

    protected onEditClick(): void {
        this.setState({
            isEditing: true
        });
    }

    protected onDeleteClick(): void {
        this.props.dispatch(removeComment(this.props.comment.id));
    }

    protected onMarkAsAnswerClick(): void {
        const envelope = {
            id: this.props.comment.id,
            value: !this.props.comment.isAnswer
        };

        this.props.dispatch(markCommentAsAnswer(envelope.id, envelope.value));

        api.post('/api/comments/mark-as-answer', envelope).then(() => {}).catch(() => {});
    }
}

const mapStateToProps = (state, props) => ({
    ...props,
    user: state.profile.active,
    isAdmin: state.profile.active.isAdmin,
    isEditor: state.profile.active.isEditor,
    enableTranslator: state.instance.get("enableTranslator")
});

export default connect(mapStateToProps)(Comment);