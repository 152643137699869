import { Stack } from "@fluentui/react";
import React, { PureComponent } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Label } from "src/ui";
import { Style } from "src/ui/helpers";
import "./WikiStructure.scss";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";

interface IProps {
    onChangePositions: (result: any[]) => void;
    positions: any[];
}

interface IState {
    positions: any[];
}

class WikiStructure extends PureComponent<IProps, IState> {
    constructor(props) {
        super(props);

        this.state = {
            positions: this.props.positions,
        };
    }

    private onDragEnd = (result) => {
        const { source, destination } = result;

        if (!destination) {
            return;
        }

        const updatedPositions = [...this.state.positions];

        const fromIndex = source.index;
        const toIndex = destination.index;

        const c = updatedPositions[fromIndex];

        updatedPositions.splice(fromIndex, 1);
        updatedPositions.splice(toIndex, 0, c);

        this.setState((prevState) => ({
            positions: updatedPositions,
        }));

        this.props.onChangePositions(updatedPositions);
    };

    render() {
        return (
            <div className="wiki-structure">
                <DragDropContext onDragEnd={this.onDragEnd}>
                    <Droppable droppableId="wikiSection">
                        {(provided, snapshot) => (
                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                {this.state.positions.map((item, index) => {
                                    return (
                                        <Draggable draggableId={item.id.toString()} index={index} key={item.id}>
                                            {(provided, _snapshot) => (
                                                <div
                                                    className="draggable-item"
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                >
                                                    <Stack
                                                        horizontal
                                                        horizontalAlign="space-between"
                                                        verticalAlign="center"
                                                    >
                                                        <Label size="body-2">{item.title}</Label>

                                                        <Stack
                                                            horizontal
                                                            tokens={{
                                                                childrenGap: Style.getSpacing(8),
                                                            }}
                                                        >
                                                            <Visage2Icon icon="menu-1" />
                                                        </Stack>
                                                    </Stack>
                                                </div>
                                            )}
                                        </Draggable>
                                    );
                                })}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
        );
    }
}

export default WikiStructure;
