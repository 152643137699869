import { AxiosError, AxiosResponse, CancelToken } from "axios";
import { Action } from "redux";
import api from "src/spintr/SpintrApi";

import {
    createConversation as createConversationApi
} from "../api";
import { Conversation, IGetConversationsParams } from "../conversation-types";
import { ConversationActionTypes } from "./conversation-action-types";

const baseUrl = "/api/v1/conversations";

export interface IQueryConversationsAction extends Action {
    meta: IGetConversationsParams;
    payload: Promise<AxiosResponse<Spintr.IConversation[]>>;
    type: ConversationActionTypes.QueryConversations;
}

export interface IQueryConversationsFulfilledAction extends Action {
    meta: IGetConversationsParams;
    payload: AxiosResponse<Spintr.IConversation[]>;
    type: ConversationActionTypes.QueryConversationsFulfilled;
}

export interface IQueryConversationsPendingAction extends Action {
    meta: IGetConversationsParams;
    payload: Promise<AxiosResponse<Spintr.IConversation[]>>;
    type: ConversationActionTypes.QueryConversationsPending;
}

export interface IQueryConversationsRejectedAction extends Action {
    meta: IGetConversationsParams;
    payload: AxiosError;
    type: ConversationActionTypes.QueryConversationsRejected;
}

export type QueryConversationsAction =
      IQueryConversationsAction
    | IQueryConversationsFulfilledAction
    | IQueryConversationsPendingAction
    | IQueryConversationsRejectedAction;

export type QueryConversationsHandler =
    (params: IGetConversationsParams, cancelToken?: CancelToken) => IQueryConversationsAction;
export const queryConversations: QueryConversationsHandler = (params, cancelToken) => ({
    meta: params,
    payload: api.get(baseUrl, {
        params,
        cancelToken,
    }),
    type: ConversationActionTypes.QueryConversations,
});

export interface IGetConversationAction extends Action {
    payload: Promise<AxiosResponse<Spintr.IConversation>>;
    type: ConversationActionTypes.GetConversation;
}

export interface IGetConversationFulfilledAction extends Action {
    payload: AxiosResponse<Spintr.IConversation>;
    type: ConversationActionTypes.GetConversationFulfilled;
}

export interface IGetConversationPendingAction extends Action {
    payload: Promise<AxiosResponse<Spintr.IConversation>>;
    type: ConversationActionTypes.GetConversationPending;
}

export interface IGetConversationRejectedAction extends Action {
    payload: AxiosError;
    type: ConversationActionTypes.GetConversationRejected;
}

export type GetConversationAction = 
      IGetConversationAction
    | IGetConversationFulfilledAction
    | IGetConversationPendingAction
    | IGetConversationRejectedAction;

export type GetConversationHandler = (id: number) => IGetConversationAction;
export const getConversation: GetConversationHandler = (id : number) => ({
    payload: api.get(baseUrl + "/" + id),
    type: ConversationActionTypes.GetConversation,
});

export interface ISetTabStateAction extends Action {
    id: number;
    minimized: boolean;
    open: boolean;
    type: ConversationActionTypes.SetTabState;
}

export type SetTabStateHandler =
    (id: number, open?: boolean, minimized?: boolean) => ISetTabStateAction;
export const setTabState: SetTabStateHandler = (id, open = true, minimized = true) => ({
    id, minimized, open, type: ConversationActionTypes.SetTabState,
});


export interface ICreateConversationAction extends Action {
    meta: Conversation;
    payload: Promise<AxiosResponse<Spintr.IConversation>>;
    type: ConversationActionTypes.CreateConversation;
}

export interface ICreateConversationFulfilledAction extends Action {
    meta: Conversation;
    payload: AxiosResponse<Spintr.IConversation>;
    type: ConversationActionTypes.CreateConversationFulfilled;
}

export interface ICreateConversationPendingAction extends Action {
    meta: Conversation;
    payload: Promise<AxiosResponse<Spintr.IConversation>>;
    type: ConversationActionTypes.CreateConversationPeding;
}

export interface ICreateConversationRejectedAction extends Action {
    meta: Conversation;
    payload: AxiosError<Spintr.IConversation>;
    type: ConversationActionTypes.CreateConversationRejected;
}

export type BoundCreateConversationHandler =
    (conversation: Conversation) => Promise<AxiosResponse<Spintr.IConversation> | AxiosError<never>>;

export type CreateConversationHandler =
    (conversation: Conversation) => ICreateConversationAction;
export const createConversation: CreateConversationHandler =
    (conversation) => ({
        meta: conversation,
        payload: createConversationApi(
            conversation.participants,
            conversation.message,
        ),
        type: ConversationActionTypes.CreateConversation,
    });

export type CreateConversationAction =
      ICreateConversationAction
    | ICreateConversationFulfilledAction
    | ICreateConversationPendingAction
    | ICreateConversationRejectedAction;

export interface IMarkAsReadMeta {
    conversationId: number;
    prevUnread: number;
}

export interface IMarkAsReadAction extends Action {
    meta: IMarkAsReadMeta;
    payload: Promise<AxiosResponse<never>>;
    type: ConversationActionTypes.MarkAsRead;
}

export interface IMarkAsReadPendingAction extends Action {
    meta: IMarkAsReadMeta;
    payload: Promise<AxiosResponse<never>>;
    type: ConversationActionTypes.MarkAsReadPending;
}

export interface IMarkAsReadFulfilledAction extends Action {
    meta: IMarkAsReadMeta;
    payload: AxiosResponse<never>;
    type: ConversationActionTypes.MarkAsReadFulfilled;
}

export interface IMarkAsReadRejectedAction extends Action {
    meta: IMarkAsReadMeta;
    payload: AxiosError<never>;
    type: ConversationActionTypes.MarkAsReadRejected;
}

export type MarkAsReadHandler =
    (conversationId: number, prevUnread?: number, messageId?: number) => IMarkAsReadAction;

export const markAsRead: MarkAsReadHandler = (conversationId, prevUnread, messageId?: number) => ({
    meta: {
        conversationId, prevUnread,
    },
    payload: api.put(
        baseUrl + "/" + conversationId + "/read",
        messageId != null ?
            {
                messageId
            } :
            {}
    ),
    type: ConversationActionTypes.MarkAsRead,
});

export type MarkAsReadAction =
      IMarkAsReadAction
    | IMarkAsReadFulfilledAction
    | IMarkAsReadPendingAction
    | IMarkAsReadRejectedAction;

export interface IAddRemoteConversationAction extends Action {
    conversation: Conversation;
    type: ConversationActionTypes.AddRemoteConversation;
}

export type AddRemoteConversationHandler =
    (conversation: Conversation) => IAddRemoteConversationAction;

export const addRemoteConversation: AddRemoteConversationHandler =
    (conversation) => ({
        conversation,
        type: ConversationActionTypes.AddRemoteConversation,
    });

export interface ILastReadReceivedAction extends Action {
    conversationId: number;
    messageId: number;
    userId: number;
    type: ConversationActionTypes.LastReadReceived;
}

export type LastReadReceivedHandler = (
    conversationId: number,
    messageId: number,
    userId: number,
) => ILastReadReceivedAction;

export const lastReadReceived: LastReadReceivedHandler =
    (conversationId, messageId, userId) => ({
        conversationId,
        messageId,
        type: ConversationActionTypes.LastReadReceived,
        userId,
    });

export interface IUpdateGroupActivityAction extends Action {
    meta: number;
    payload: Promise<AxiosResponse<any>>;
    type: ConversationActionTypes.UpdateGroupActivity;
}

export type UpdateGroupActivityHandler = (groupId: number) => IUpdateGroupActivityAction;

export const updateGroupActivity: UpdateGroupActivityHandler = (groupId) => ({
    meta: groupId,
    payload: api.put(`/api/v1/groups/${groupId}/visited`),
    type: ConversationActionTypes.UpdateGroupActivity,
});

export interface IUpdateGroupActivityActionPending extends Action {
    meta: number;
    payload: Promise<AxiosResponse<any>>;
    type: ConversationActionTypes.UpdateGroupActivityPending;
}

export interface ISetUnreadCountPersonConversations extends Action {
    meta: number;
    type: ConversationActionTypes.SetUnreadCountPersonConversations
}

export type SetUnreadCountPersonConversations = (meta: number) => ISetUnreadCountPersonConversations;

export const setUnreadCountPersonConversations : SetUnreadCountPersonConversations =
    (value: number) => ({
        meta: value,
        type: ConversationActionTypes.SetUnreadCountPersonConversations
    });

export interface ISetUnreadCountGroupConversations extends Action {
    meta: number;
    type: ConversationActionTypes.SetUnreadCountGroupConversations
}

export type SetUnreadCountGroupConversations = (meta: number) => ISetUnreadCountGroupConversations;

export const setUnreadCountGroupConversations : SetUnreadCountGroupConversations =
    (value: number) => ({
        meta: value,
        type: ConversationActionTypes.SetUnreadCountGroupConversations,
    });

export type UpdateGroupActivityAction = IUpdateGroupActivityAction | IUpdateGroupActivityActionPending;

export type ConversationAction = 
    QueryConversationsAction
    | GetConversationAction
    | ISetTabStateAction
    | CreateConversationAction
    | MarkAsReadAction
    | IAddRemoteConversationAction
    | ILastReadReceivedAction
    | UpdateGroupActivityAction
    | ISetUnreadCountPersonConversations
    | ISetUnreadCountGroupConversations;