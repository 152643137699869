import { AxiosResponse } from "axios";
import { CommandBar, IContextualMenuItem, SearchBox } from "@fluentui/react";
import React, { Component } from "react";
import { connect } from "react-redux";
import { localize } from "src/l10n";
import { setConfirmPopup } from "src/popups/actions";
import { IApplicationState } from "src/spintr/reducer";
import { Breadcrumbs, PrioItems, toggleGreyBackground } from "src/ui";
import StandardActionMenu from "src/ui/components/ActionMenu/StandardActionMenu";
import SpintrLoader from "src/ui/components/Loader";
import { getHexFromSpintrColor } from "src/ui/helpers/style";
import { debounce, scrollToTop } from "src/utils";
import CenteredPageHeader from "./CenteredPageHeader";
import TwoColumnListBox from "./TwoColumnListBox";
import "./WikiListView.scss";
import api from "src/spintr/SpintrApi";
import SpintrSearch from "src/ui/components/SpintrList/SpintrSearch";

interface IProps {
    history: any;
    isAdmin: boolean;
    isEditor: boolean;
    restrictWikis?: boolean;
    ariaLabel?: string;
    dispatch?: any;
    instance?: any;
    currentUserId: number;
    isSmallViewMode: boolean;
}

interface IState {
    isLoadingPrioritizedWikis: boolean;
    isLoadingWikis: boolean;
    includeDeleted: boolean;
    prioritizedWikis: any[];
    wikis: any[];
    searchText?: string;
    fontColor: string;
    sortingModeId: string;
}

interface ISortingMode {
    id: string;
    name: string;
}

class WikiListView extends Component<IProps, IState> {
    private allowCreate = !this.props.restrictWikis || this.props.isAdmin || this.props.isEditor;
    private sortingModes: ISortingMode[];

    constructor(props) {
        super(props);

        const fontColor = getHexFromSpintrColor("dark-grey");

        this.sortingModes = [
            { id: "name", name: localize("A-Z") },
            { id: "latestchange", name: localize("SenastAndrad") },
        ];

        this.state = {
            sortingModeId: this.sortingModes[0].id,
            isLoadingPrioritizedWikis: true,
            isLoadingWikis: true,
            includeDeleted: false,
            prioritizedWikis: [],
            wikis: [],
            fontColor
        };
    }

    componentDidMount() {
        this.getPrioritizedWikis();
        this.getWikis();
        this.props.dispatch(toggleGreyBackground(true));
    }

    componentWillUnmount() {
        scrollToTop();
        this.props.dispatch(toggleGreyBackground(false));
    }

    componentDidUpdate() {
        this.allowCreate = !this.props.restrictWikis || this.props.isAdmin || this.props.isEditor;
    }

    getPrioritizedWikis = () => {
        this.setState({
            isLoadingPrioritizedWikis: true,
        }, () => {
            api.get("/api/wikis/pages", {
                params: {
                    take: 100,
                    includeDeleted: this.state.includeDeleted,
                    orderByColumn: "name",
                    isAscending: true,
                    onlyPrioritized: true,
                },
            }).then((response: AxiosResponse) => {
                this.setState({
                    prioritizedWikis: response.data.data.wikis.map(x => { return { ...x, icon: "" }}),
                    isLoadingPrioritizedWikis: false,
                });
            });
        });
    };

    getWikis = () => {
        this.setState({
            isLoadingWikis: true,
            wikis: [],
            sortingModeId: this.sortingModes[0].id,
        }, () => {
            api.get("/api/wikis", {
                params: {
                    includeDeleted: this.state.includeDeleted,
                    isAscending: true,
                    orderByColumn: "name",
                    searchText: this.state.searchText,
                    skip: 0,
                    take: 1000,
                },
            }).then((response) => {
                this.setState({
                    wikis: response.data.items.map(w => {
                        const hasExtendedRights = this.props.isAdmin ||
                            this.props.isEditor ||
                            this.props.currentUserId == w.editor?.id;

                        const canDelete = hasExtendedRights;

                        const canEdit = hasExtendedRights;

                        w.icon = "";

                        return {
                            ...w,
                            renderActionMenu: () => {
                                return (
                                    <StandardActionMenu
                                        canAddToFavourites={true}
                                        canFollow={true}
                                        canShare={false}
                                        canDelete={canDelete}
                                        canEdit={canEdit}
                                        canHide={false}
                                        isDeleted={w.deleted}
                                        objectId={w.id}
                                        onEditClick={() => {
                                            this.props.history.push({
                                                pathname: `/wikis/edit-wiki/${w.id}`,
                                            });
                                        }}
                                        onDeleteClick={() => {
                                            this.props.dispatch(setConfirmPopup({
                                                isOpen: true,
                                                title: localize("RaderaInnehall"),
                                                message:
                                                    localize(w.deleted ?
                                                        "ArDuSakerAttDuVillAterstallaDennaWiki" :
                                                        "ArDuSakerPaAttDuVillTaBortDennaWiki") + "?",
                                                onConfirm: () => {
                                                    this.setState({
                                                        isLoadingWikis: true
                                                    }, () => {
                                                        api.put(`/api/v1/wikis/${w.id}/toggledelete`).then(() => {
                                                            this.getWikis();
                                                        });
                                                    });
                                                },
                                            }));
                                        }}
                                        isFollowing={w.isFollowed}
                                        isFavourite={w.isFavourited}
                                    />
                                )
                            }
                        }
                    }),
                    isLoadingWikis: false
                });
            });
        });
    }

    renderPrioritizedWikis() {
        if (this.state.isLoadingPrioritizedWikis) {
            return <SpintrLoader />;
        }

        if (!this.state.prioritizedWikis ||
            this.state.prioritizedWikis.length === 0) {
            return null;
        }

        return (
            <PrioItems items={this.state.prioritizedWikis} />
        )
    }

    debouncedFetchSearch = debounce(() => this.getWikis(), 500);

    searchEvent = (event: React.ChangeEvent, searchText: string): void => {
        this.setState({
            searchText
        }, () => {
            this.debouncedFetchSearch();
        });
    };

    renderCommandBar() {
        let items = [];

        items.push({
            key: "search",
            onRender: (a, b) => {
                return (
                    <SpintrSearch
                        value={this.state.searchText}
                        onChange={this.searchEvent.bind(this)}
                        placeholder={localize("Sok") + " " + localize("Wikis_small") + "..."} />
                )
            },
        });

        items.push({
            key: "sort",
            text: localize("Sortering"),
            subMenuProps: {
                className: "displayOptions",
                items: this.sortingModes.map((sortingMode) => {
                    return {
                        key: sortingMode.name,
                        text: sortingMode.name,
                        onClick: () => {
                            this.setState({
                                sortingModeId: sortingMode.id,
                                wikis: sortingMode.id === "latestchange" ?
                                    this.state.wikis.sort((a, b) => {
                                        const aDate = a.articles && a.articles.length > 0 ?
                                            new Date(a.articles[0].changedate) :
                                            new Date(2000, 11, 17, 0, 0, 0, 0);

                                        const bDate = b.articles && b.articles.length > 0 ?
                                            new Date(b.articles[0].changedate) :
                                            new Date(2000, 11, 17, 0, 0, 0, 0);

                                        if (aDate > bDate) { return -1; }
                                        if (aDate < bDate) { return 1; }
                                        return 0;
                                    }) :
                                    this.state.wikis.sort((a, b) => {
                                        if (a.name < b.name) { return -1; }
                                        if (a.name > b.name) { return 1; }
                                        return 0;
                                    })
                            });
                        },
                        style: {
                            fontWeight: sortingMode.id == this.state.sortingModeId ? 700 : "initial",
                        }, // supposedly deprecated in favor of styles prop, but it doesn't exist??
                    } as IContextualMenuItem;
                }),
            },
        });

        if (this.allowCreate) {
            items.push({
                key: "options",
                text: localize("Alternativ"),
                subMenuProps: {
                    className: "displayOptions",
                    items: [{
                        key: "includeDeleted",
                        text: localize(
                            this.state.includeDeleted
                                ? "GomBorttagnaOchOpublicerade"
                                : "VisaBorttagnaOchOpublicerade"
                        ),
                        onClick: () => {
                            this.setState((prevState) => ({
                                includeDeleted: !prevState.includeDeleted
                            }), () => {
                                this.getWikis();
                            });
                        },
                    }],
                },
            });
        }

        if (this.allowCreate) {
            items.push({
                key: "add",
                text: localize("SkapaWiki"),
                onClick: () => {
                    this.props.history.push({
                        pathname: "/wikis/create"
                    });
                },
                iconProps: { iconName: "Add" },
                className: "commandBarAddButton",
            });
        }

        return (
            <CommandBar
                className="CommandBar-GrowSearch"
                items={items}
            />
        )
    }

    renderTwoColumnListBox() {
        return (
            <div className="TwoColumnListBoxWrapper">
                <TwoColumnListBox
                    isLoading={this.state.isLoadingWikis}
                    items={this.state.wikis}
                    renderCommandBar={this.renderCommandBar.bind(this)} />
            </div>
        )
    }

    render() {
        return (
            <div>
                <Breadcrumbs
                    displayInstance
                    items={[
                        {
                            key: "",
                            text: localize("Wikis"),
                            link: "/wikis",
                        },
                    ]}
                />
                <div className="WikiListView page-margin-bottom">
                    <CenteredPageHeader
                        title={localize("Wikis")}
                        helpTextId={1}
                    />
                    {
                        this.renderPrioritizedWikis()
                    }
                    {
                        this.renderTwoColumnListBox()
                    }
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: IApplicationState, props) => {
    return {
        ...props,

        isAdmin: state.profile.active.isAdmin,
        isEditor: state.profile.active.isEditor,
        currentUserId: state.profile.active.id,
        restrictWikis: state.instance.get("restrictWikis"),
        instance: state.instance,
        isSmallViewMode: state.ui.isSmallViewMode,
    };
};

export default connect(mapStateToProps)(WikiListView);
