import React, { Component } from "react";
import { SocialFeedType } from "src/social-feed";
import { SpintrSocialFeed } from "src/spintr/components";
import { FillHeight, Scrollable } from "src/ui/components";

interface IProps {
    group: any;
}

class GroupFeedView extends Component<IProps> {
    public render() {
        const { group } = this.props;
        return (
            <div className="GroupFeedView GroupContentView feed">
                <div style={{ maxWidth: 750 }}>
                    <SpintrSocialFeed
                        feedId={group.feedId}
                        feedType={SocialFeedType.Group}
                        galleryId={group.galleryId}
                        groupId={group.id}
                    />
                </div>
            </div>
        );
    }
}

export default GroupFeedView;
