import { Image } from "@fluentui/react";
import React, { Component, CSSProperties, ImgHTMLAttributes } from "react";
import { hitEnterOrSpace } from "src/files/utils";
import "./ImageCore.css";
import PhotoBox from "./PhotoBox/PhotoBox";

interface IProps {
    alt?: string;
    height?: number;
    src: string;
    width?: number;
    style?: CSSProperties;
    openPhotoBoxOnClick?: boolean;
    imageId?: number;
    title?: string;
    samePostOnly?: boolean;
    post?: Spintr.ISocialImage;
    parentPost?: Spintr.ISocialPostBase;
    imageInChat?: boolean;
}

interface IState {
    displayPhotoBox: boolean;
}

class ImageCore extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            displayPhotoBox: false,
        };

        this.onImageClick = this.onImageClick.bind(this);
    }

    public render() {
        const attrs: ImgHTMLAttributes<HTMLImageElement> = {
            src: this.props.src,
            style: this.props.style,
        };

        if (this.props.height) {
            // @ts-ignore
            attrs.height = this.props.height;
        }

        if (this.props.width) {
            // @ts-ignore
            attrs.width = this.props.width;
        }

        let className = "ImageCore";

        if (!!this.props.openPhotoBoxOnClick) {
            className += " clickable";
        }

        return (
            <div className="ImageCore-wrapper">
                <Image
                    tabIndex={0}
                    onKeyUp={(e) => {hitEnterOrSpace(e, this.onImageClick)}}
                    alt={this.props.alt || ""}
                    title={this.props.title || ""}
                    className={className}
                    onClick={this.onImageClick}
                    {...attrs}
                />
                {this.state.displayPhotoBox && (
                    <PhotoBox
                        imageUrl={this.props.src}
                        id={this.props.imageId}
                        imageInChat={this.props.imageInChat}
                        post={this.props.parentPost}
                        samePostOnly={this.props.samePostOnly}
                        onClose={() => {
                            this.setState({
                                displayPhotoBox: false,
                            });
                        }}
                    />
                )}
            </div>
        );
    }

    protected onImageClick() {
        if (!this.props.openPhotoBoxOnClick) {
            return;
        }

        this.setState({
            displayPhotoBox: true,
        });
    }
}

export default ImageCore;
