import React from "react";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import "./AcademyImage.scss";

interface IProps {
    imageUrl: string;
}

const AcademyImage = (props: IProps) => {
    if (!props.imageUrl) {
        return (
            <div className="AcademyImage no-image primaryBGColor">
                <Visage2Icon icon="teacher" color="white" />
            </div>
        )
    }

    return (
        <div className="AcademyImage has-image">
            {props.imageUrl && (
                <img src={props.imageUrl} />
            )}
        </div>
    )
}

export default AcademyImage;
