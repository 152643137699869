import axios from "axios";
import { Checkbox } from "@fluentui/react";
import React, { Component, ReactNode } from "react";
import { connect } from "react-redux";
import { localize } from "src/l10n";
import { updateActiveUser } from "src/profile/actions";
import { setFilters } from "src/social-feed/actions";
import { IApplicationState } from "src/spintr/reducer";
import { ActionMenu } from "src/ui";
import { debounce } from "src/utils";
import "./SocialFeedFilter.scss";
import api from "src/spintr/SpintrApi";
import VisageFilterButton from "src/spintr/components/VisageFilterButton/VisageFilterButton";

interface IProps {
    instance?: any;
    currentUser?: any;
    dispatch?: any;
    filters?: any;
    apps?: any;
}

interface IState {
    hashtags?: any[],
    offices?: any[],
    uberTypes?: any[]
    isDirty: boolean;
}

class SocialFeedFilter extends Component<IProps, IState> {
    private actionMenu = React.createRef<typeof ActionMenu>();
    private _isMounted: boolean = false;

    constructor(props: IProps) {
        super(props);

        this.state = {
            isDirty: false,
            offices: this.getOffices(),
            hashtags: this.getHashtags()
        }
    }

    getHashtags() {
        if (!this.props.filters || !this.props.filters.hashtags || !Array.isArray(this.props.filters.hashtags)) {
            return null;
        }

        return [
            {
                id: 0,
                name: localize("Allt"),
                active: true
            },
            ...this.props.filters.hashtags.filter(h => h.name.length > 1).map(h => {
                return {
                    ...h,
                    active: false
                }
            })
        ];
    }

    setHashtags() {
        if (!this._isMounted) {
            return;
        }

        this.setState({
            hashtags: this.getHashtags()
        });
    }

    getOffices() {
        if (!this.props.filters || !this.props.filters.units || !Array.isArray(this.props.filters.units)) {
            return null;
        }

        return [
            {
                id: 0,
                name: localize("AllaAvdelningar"),
                active: this.props.currentUser.settings.startFeedSorting === "alldepartments"
            },
            ...this.props.filters.units.map(o => {
                return {
                    ...o,
                    active: this.props.currentUser.settings.startFeedSorting === ("time" + o.id),
                    departments: o.departments.map(d => {
                        return {
                            ...d,
                            active: this.props.currentUser.settings.startFeedSorting === ("time" + d.id)
                        }
                    })
                }
            })
        ]
    }

    setOffices() {
        if (!this._isMounted) {
            return;
        }

        this.setState({
            offices: this.getOffices()
        });
    }

    isUberTypeActive(id: number) {
        return this.props.currentUser.settings.startFeedFilters.split(";").includes(id.toString());
    }

    setUberTypes() {
        let uberTypes = [{
            id: 0,
            name: localize("Alla"),
            active: false
        }, {
            id: 3,
            name: localize("SidInlagg"),
            active: false
        }, {
            id: 4,
            name: localize("Bokmarken"),
            active: false
        }, {
            id: 33,
            name: localize("Fragor"),
            active: false
        }, {
            id: 2,
            name: localize("Filer"),
            extraTypes: [25],
            active: false
        }, {
            id: 13,
            name: localize("Bilder"),
            extraTypes: [14],
            active: false
        },
        // {
        //     id: 21,
        //     name: localize('Bloggposter'),
        //     active: false
        // }, 
        {
            id: 46,
            name: localize('Textsidor'),
            active: false
        }, {
            id: 54,
            name: localize('DoldaPoster'),
            active: false
        }];

        for (let ut of uberTypes) {
            ut.active = this.isUberTypeActive(ut.id);
        }

        uberTypes[0].active = true;
        uberTypes[0].active = uberTypes.filter(ut => !ut.active).length === 0;

        if (!this._isMounted) {
            return;
        }

        this.setState({
            uberTypes
        });
    }

    fetch() {
        const requests = [
            api.get("/api/units/simple?forceUsersDepartment=true&onlyWithpages=true"),
            api.get("/api/hashtags"),
        ]

        axios.all(requests).then((responses) => {
            const data = {
                units: responses[0].data,
                hashtags: responses[1].data
            }

            this.props.dispatch(setFilters(data));

            setTimeout(() => {
                this.setOffices();
                this.setHashtags();
            }, 250)
        }).catch(() => { });
    }

    public componentDidMount(): void {
        this._isMounted = true;

        this.fetch();
        this.setOffices();
        this.setHashtags();
        this.setUberTypes();
    }

    componentWillUnmount(): void {
        this._isMounted = false;
    }

    isAppEnabled(id) {
        return !!this.props.apps.items.find((a) => a.id === id && a.enabled);
    }

    getCategories() {
        let categories: Spintr.IActionMenuCategory[] = [{
            items: [{
                onClick: () => { },
                text: "Hashtags",
                subMenuProps: {
                    items: this.state.hashtags.map((d, i) => {
                        return {
                            key: i,
                            text: d.name,
                            title: d.name,
                            className: d.active ? "SocialFeedFilter-active-item" : "",
                            onClick: () => {
                                if (!this._isMounted) {
                                    return;
                                }

                                this.setState({
                                    hashtags: this.state.hashtags.map(h => {
                                        return {
                                            ...h,
                                            active: h.id === d.id
                                        }
                                    }),
                                    isDirty: true
                                }, this.debouncedUpdateSettings);
                            }
                        }
                    })
                },
            }]
        }, {
            items: [{
                onClick: () => { },
                text: localize("Avdelningar"),
                subMenuProps: {
                    items: this.state.offices.map((o, i) => {
                        return {
                            key: o.id,
                            text: o.name,
                            title: o.name,
                            className: o.active ? "SocialFeedFilter-active-item" : "",
                            onClick: () => {
                                if (!this._isMounted) {
                                    return;
                                }

                                this.setState({
                                    offices: this.state.offices.map(office => {
                                        return {
                                            ...office,
                                            active: office.id === o.id,
                                            departments: !office.departments ?
                                                office.departments :
                                                office.departments.map(department => {
                                                    return {
                                                        ...department,
                                                        active: false
                                                    }
                                                })
                                        }
                                    }),
                                    isDirty: true
                                }, this.debouncedUpdateSettings);
                            },
                            subMenuProps: !o.departments || o.departments.length === 0 ?
                                null :
                                {
                                    items: [
                                        o,
                                        ...o.departments
                                    ].map((d, i) => {
                                        return {
                                            key: d.id,
                                            text: d.name,
                                            title: d.name,
                                            className: d.active ? "SocialFeedFilter-active-item" : "",
                                            onClick: () => {
                                                if (!this._isMounted) {
                                                    return;
                                                }

                                                this.setState({
                                                    offices: this.state.offices.map(office => {
                                                        return {
                                                            ...office,
                                                            active: office.id === d.id,
                                                            departments: !office.departments ?
                                                                office.departments :
                                                                office.departments.map(department => {
                                                                    return {
                                                                        ...department,
                                                                        active: department.id === d.id
                                                                    }
                                                                })
                                                        }
                                                    }),
                                                    isDirty: true
                                                }, this.debouncedUpdateSettings);
                                            }
                                        }
                                    })
                                }
                        }
                    })
                },
            }]
        }, {
            items: [{
                onClick: () => { },
                text: localize("Filter"),
                subMenuProps: {
                    items: this.state.uberTypes.map((d, i) => {
                        return {
                            key: i,
                            text: d.name,
                            title: d.name,
                            onClick: () => {

                            },
                            onRender: (item: any, dismissMenu: () => void) => {
                                return (
                                    <div>
                                        <Checkbox
                                            className="SocialFeedFilter-checkbox"
                                            label={d.name}
                                            checked={d.active}
                                            onChange={(
                                                ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
                                                checked?: boolean
                                            ) => {
                                                if (!this._isMounted) {
                                                    return;
                                                }

                                                this.setState({
                                                    uberTypes: this.state.uberTypes.map(ut => {
                                                        if (ut.id === d.id || d.id === 0) {
                                                            return {
                                                                ...ut,
                                                                active: checked
                                                            }
                                                        } else {
                                                            return ut;
                                                        }
                                                    }),
                                                    isDirty: true
                                                }, () => {
                                                    if (d.id !== 0) {
                                                        let allItemsExceptAll = this.state.uberTypes.filter(ut => ut.id !== 0);

                                                        if (allItemsExceptAll.filter(ut => ut.active).length === allItemsExceptAll.length) {
                                                            if (!this._isMounted) {
                                                                return;
                                                            }

                                                            this.setState({
                                                                uberTypes: this.state.uberTypes.map(ut => {
                                                                    if (ut.id === 0) {
                                                                        return {
                                                                            ...ut,
                                                                            active: true
                                                                        }
                                                                    } else {
                                                                        return ut;
                                                                    }
                                                                }),
                                                                isDirty: true
                                                            });
                                                        } else if (allItemsExceptAll.filter(ut => ut.active).length !== allItemsExceptAll.length) {
                                                            if (!this._isMounted) {
                                                                return;
                                                            }

                                                            this.setState({
                                                                uberTypes: this.state.uberTypes.map(ut => {
                                                                    if (ut.id === 0) {
                                                                        return {
                                                                            ...ut,
                                                                            active: false
                                                                        }
                                                                    } else {
                                                                        return ut;
                                                                    }
                                                                }),
                                                                isDirty: true
                                                            });
                                                        }
                                                    }
                                                });
                                            }}
                                        />
                                    </div>
                                )
                            }
                        }
                    })
                },
            }]
        }];

        return categories;
    }

    debouncedUpdateSettings = debounce(() => this.updateSettings(), 500);

    updateSettings() {
        if (!this.state.isDirty) {
            return;
        }

        if (!this._isMounted) {
            return;
        }


        this.setState({
            isDirty: false
        }, () => {
            let startFeedSorting = "alldepartments";
            let activeFeedHashtagId = 0;
            let startFeedFilters = "";

            for (let o of this.state.offices) {
                if (o.active) {
                    if (o.id === 0) {
                        startFeedSorting = "alldepartments";
                    } else {
                        startFeedSorting = "time" + o.id;
                    }
                }

                if (o.departments) {
                    for (let d of o.departments) {
                        if (d.active) {
                            startFeedSorting = "time" + d.id;
                        }
                    }
                }
            }

            for (let ht of this.state.hashtags.filter(h => h.active)) {
                activeFeedHashtagId = ht.id;
            }

            for (let ut of this.state.uberTypes.filter(ut => ut.active)) {
                if (ut.id === 0) {
                    continue;
                }

                if (startFeedFilters !== "") {
                    startFeedFilters += ";";
                }

                startFeedFilters += ut.id;

                if (ut.extraTypes) {
                    for (let et of ut.extraTypes) {
                        startFeedFilters += (";" + et);
                    }
                }
            }

            if (!startFeedFilters ||
                startFeedFilters.length === 0) {
                for (let ut of this.state.uberTypes) {
                    if (ut.id === 0) {
                        continue;
                    }

                    if (startFeedFilters !== "") {
                        startFeedFilters += ";";
                    }

                    startFeedFilters += ut.id;

                    if (ut.extraTypes) {
                        for (let et of ut.extraTypes) {
                            startFeedFilters += (";" + et);
                        }
                    }
                }
            }

            this.props.dispatch(updateActiveUser({
                ...this.props.currentUser,
                settings: {
                    ...this.props.currentUser.settings,
                    startFeedFilters,
                    startFeedSorting,
                    activeFeedHashtagId
                }
            }));

            api.put("/api/users/settings", {
                name: "FilterStart",
                value: startFeedFilters
            });

            api.put("/api/users/settings", {
                name: "SortHappening",
                value: startFeedSorting
            });
        });
    }

    getLabelText() {
        let labelText = localize("AllaInlagg");

        if (!this.state.offices[0].active ||
            this.state.uberTypes.filter(ut => !ut.active).length > 0 ||
            !this.state.hashtags[0].active) {
            labelText = localize("FiltreradVy");
        }

        return labelText;
    }

    getFilterButtonText() {
        let result = localize("Allt");

        if (!!this.state.offices) {
            for (let office of this.state.offices) {
                if (office.active) {
                    result = office.name;
                }

                if (!!office.departments) {
                    for (let department of office.departments) {
                        if (department.active) {
                            result = department.name;
                        }
                    }
                }
            }
        }

        // if (result === localize("AllaAvdelningar")) {
        //     result = localize("Allt");
        // }

        return result;
    }

    public render(): ReactNode {
        if (!this.state.hashtags ||
            !this.state.offices ||
            !this.state.uberTypes) {
            return <div className="SocialFeedFilter"></div>;
        }

        return (
            <div className="SocialFeedFilter">
                <VisageFilterButton
                    inSocialFeed={true}
                    labelText={this.getFilterButtonText()}
                    categories={this.getCategories()}
                    onMenuDismissed={this.debouncedUpdateSettings.bind(this)} />
            </div>
        )

        return (
            <div className="SocialFeedFilter no-user-select">
                <div className="menuWrap">
                    <div className="actions">
                        <div className="icon">
                            <ActionMenu
                                ref={this.actionMenu}
                                categories={this.getCategories()}
                                label={this.getLabelText()}
                                onMenuDismissed={this.debouncedUpdateSettings.bind(this)}
                                icon="ChevronDown"
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: IApplicationState, props) => ({
    ...props,
    instance: state.instance,
    currentUser: state.profile.active,
    filters: state.socialFeed.filters,
    apps: state.app
});

export default connect(mapStateToProps)(SocialFeedFilter);

