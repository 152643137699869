import { Breadcrumb, IBreadcrumbItem, IDividerAsProps } from "@fluentui/react";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { localize } from "src/l10n";
import { UnstyledButton } from ".";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import { setGlobalBreadcrumbs } from "../actions";

export interface IBreadcrumbProps {
    history?: any;
    items: Spintr.IBreadcrumbItem[];
    instanceName?: string;
    displayInstance?: boolean;
    hideInstance?: boolean;
    useGlobalBreadcrumbs?: boolean;
    dispatch?: any;
}

interface IState {
    items: IBreadcrumbItem[];
}

class Breadcrumbs extends PureComponent<IBreadcrumbProps, IState> {
    constructor(props: IBreadcrumbProps) {
        super(props);

        this.state = {
            items: this.getItems()
        }
    }

    componentDidMount(): void {
        if (this.props.useGlobalBreadcrumbs) {
            this.props.dispatch(setGlobalBreadcrumbs(this.getSpintrItems()));
        }
    }

    componentWillUnmount(): void {
        if (this.props.useGlobalBreadcrumbs) {
            this.props.dispatch(setGlobalBreadcrumbs([]));
        }
    }

    componentDidUpdate = (prevProps: IBreadcrumbProps) => {
        if (prevProps.items === this.props.items) {
            return;
        }

        this.setItems();
    };

    getSpintrItems = () => {
        let items = [...this.props.items];

        if (!this.props.hideInstance) {
            items.unshift({
                text: this.props.instanceName,
                key: "-1",
                link: "/",
            });
        }

        return items;
    }

    getItems = () => {
        let items = this.getSpintrItems();

        const fabricItems = items.map(
            (item): IBreadcrumbItem => {
                if (!item.onClick) {
                    return {
                        text: item.text,
                        key: item.key,
                        href: item.link,
                    };
                } else {
                    return item;
                }
            }
        );

        return fabricItems;
    }

    setItems = () => {
        const fabricItems = this.getItems();

        this.setState({
            items: fabricItems,
        });
    };

    _getCustomDivider(dividerProps: IDividerAsProps): JSX.Element {
        return (
            <span aria-hidden="true" style={{ cursor: 'pointer', padding: 5 }}>
                <Visage2Icon icon="arrow-right-3" size="small" />
            </span>
        );
    }

    render = () => {
        if (this.props.useGlobalBreadcrumbs) {
            return null;
        }

        return (
            <Breadcrumb
                dividerAs={this._getCustomDivider}
                onRenderItem={(item: IBreadcrumbItem, defaultRenderer?: any) => {
                    if (item.href && item.href.length > 0) {
                        return (
                            <UnstyledButton onClick={() => {
                                this.props.history.push({
                                    pathname: item.href
                                })
                            }}>{defaultRenderer!(item)}</UnstyledButton>
                        );
                    } else {
                        return defaultRenderer!(item);
                    }
                }}
                overflowAriaLabel={localize("Mer")}
                items={this.state.items}
                styles={{
                    itemLink: { fontSize: 14 },
                    item: { fontSize: 14 },
                }}
            />
        );
    };
}

const mapStateToProps = (state, props) => {
    return {
        ...props,

        instanceName: state.instance.get("name"),
    };
};

// @ts-ignore
export default withRouter(connect(mapStateToProps)(Breadcrumbs));
