import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { localize } from "src/l10n";
import { SpintrTypes } from "src/typings";
import { Label, UnstyledButton } from "src/ui";
import Visage2Icon from "src/visage2/Visage2Icon/Visage2Icon";
import "./NotificationCard.scss";

interface Props {
    instance?: any;
    currentUser?: any;
    history?: any;
    dispatch?: any;
    isSmallViewMode?: boolean;
    notificationCardsEnabled?: boolean;
    operatingInfoEnabled?: boolean;
    operatingInfoCategories?: any;
    hasLoadedOperatingInfo?: boolean;
    notificationsUnreadCount?: number;
    unreadCountGroupConversations?: number;
    unreadCountPersonConversations?: number;
}

interface State {
    hiddenNotifications: any[];
}

class NotificationCard extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            hiddenNotifications: [],
        };
    }

    closeCard = (id) => {
        this.setState({
            hiddenNotifications: [
                ...this.state.hiddenNotifications,
                id
            ]
        });
    };

    getMessages = () => {
        let messages: any[] = [];

        if (this.props.notificationsUnreadCount > 0 && this.state.hiddenNotifications.indexOf("notifications") === -1) {
            messages.push({
                id: "notifications",
                singular: "EnNyNotis",
                plural: "XNyaNotiser",
                iconName: "notification",
                num: this.props.notificationsUnreadCount,
                tabId: "sidebar-tab-notifications",
                url: "/notifications"
            });
        }

        if (this.props.unreadCountPersonConversations > 0 && this.state.hiddenNotifications.indexOf("chat") === -1) {
            messages.push({
                id: "chat",
                singular: "EttNyttMeddelande",
                plural: "XNyaChattmeddelanden",
                iconName: "message",
                num: this.props.unreadCountPersonConversations,
                tabId: "sidebar-tab-messages",
                onClick: () => {
                    const el = document.getElementById("chat-link");

                    if (!!el) {
                        el.click();
                    }
                }
            });
        }

        if (this.props.unreadCountGroupConversations > 0 && this.state.hiddenNotifications.indexOf("group") === -1) {
            messages.push({
                id: "group",
                singular: "EttNyttMeddelande",
                plural: "XNyaChattmeddelanden",
                iconName: "people",
                num: this.props.unreadCountGroupConversations,
                tabId: "sidebar-tab-groups",
                url: "/groups"
            });
        }

        if (this.props.operatingInfoEnabled &&
            !!this.props.operatingInfoCategories &&
            this.props.operatingInfoCategories.length > 0 &&
            !!this.props.operatingInfoCategories[0] &&
            this.props.operatingInfoCategories[0].items.length > 0 &&
            this.state.hiddenNotifications.indexOf("operatingInfo") === -1) {
            messages.push({
                id: "operatingInfo",
                singular: "stAktivaDriftstorningar",
                plural: "X_ACTIVE_OPERATING_INFO",
                iconName: "danger",
                num: this.props.operatingInfoCategories[0]?.items?.length,
                url: "/operatinginfo",
            });
        }

        return messages;
    };

    renderCard = (message) => {
        if (message.num < 1 || !message.num || this.state.hiddenNotifications.includes(message.id)) {
            return null;
        }

        let text =
            message.num > 1 ? localize(message.plural).replace("{{x}}", message.num) : localize(message.singular);

        return (
            <div key={message.id} className="item">
                <div className="NotificationCard">
                    <div className="close-button">
                        <UnstyledButton
                            ariaLabel={localize("Stang")}
                            onClick={() => {
                                this.closeCard(message.id);
                            }}
                            title={localize("Stang")}
                        >
                            <Visage2Icon icon="close-circle" />
                        </UnstyledButton>
                    </div>

                    <div className="IconAndContent">
                        <Visage2Icon icon={message.iconName} className="icon" />
                        <div className="content">
                            <Label size="body-2" className="text">
                                {text}
                            </Label>
                            <Link
                                to={message.url ? message.url : "#"}
                                onClick={() => {
                                    if (message.onClick) {
                                        this.closeCard(message.id);
                                        return message.onClick();
                                    }

                                    this.props.history.push({
                                        pathname: message.url
                                    });

                                    this.closeCard(message.id);
                                }}
                            >
                                <Label size="small-2" className="show">
                                    {localize("Visa")}
                                </Label>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    render = () => {
        if (!this.props.notificationCardsEnabled ||
            this.props.isSmallViewMode ||
            (!!this.props.operatingInfoEnabled && !this.props.hasLoadedOperatingInfo)) {
            return null;
        }

        const messages = this.getMessages();

        if (!messages || messages.length === 0) {
            return null;
        }

        return (
            <div className="NotificationCardContainer pop-box-shadow">
                <div className="notifications">
                    {messages
                        .filter((m) => {
                            return !this.state.hiddenNotifications.includes(m.id);
                        })
                        .map((m) => {
                            return this.renderCard(m);
                        })}
                </div>
            </div>
        );
    };
}

const mapStateToProps = (state, props) => ({
    ...props,
    currentUser: state.profile.active,
    instance: state.instance,
    isSmallViewMode: state.ui.viewMode <= SpintrTypes.ViewMode.PhoneLandscape,
    hasLoadedOperatingInfo: !state.operatingInfo.isLoadingSidebar,
    notificationCardsEnabled: state.profile.active.settings.notificationCardsEnabled,
    operatingInfoEnabled: !!state.instance
        .get("apps")
        .find((a) => a.id === SpintrTypes.SpintrApp.OperatingInfo && a.enabled),
    operatingInfoCategories: state.operatingInfo.currentCategories,
    notificationsUnreadCount: state.notification.unread,
    unreadCountGroupConversations: state.chat.conversations.unreadCountGroupConversations,
    unreadCountPersonConversations: state.chat.conversations.unreadCountPersonConversations
});

export default withRouter(connect(mapStateToProps)(NotificationCard));
