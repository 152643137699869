import React, { Component } from 'react';
import { connect } from "react-redux";
import { fetchPlanning } from 'src/groups/planning-actions';
import { IApplicationState } from "src/spintr/reducer";
import api from 'src/spintr/SpintrApi';
import { Loader } from "src/ui";
import { FillHeight } from "src/ui/components";
import PlanningBoard from "./PlanningBoard";

interface IProps {
    dispatch?: any,
    group: any,
    planning: any;
}

class GroupPlanningView extends Component<IProps> {
    public async componentDidMount() {
        this.props.dispatch(fetchPlanning(api, this.props.group.id))
    }

    render() {
        return (
            <div className="GroupPlanningView">
                <FillHeight>
                    {
                        this.props.planning.isLoading ?
                            <Loader /> :
                            <PlanningBoard groupId={this.props.group.id} categories={this.props.planning.categories} />
                    }
                </FillHeight>
            </div>
        )
    }
}

const mapStateToProps = (state: IApplicationState, props: IProps): IProps => ({
    ...props,
    planning: state.groups.planning,
    group: state.groups.group
});

export default connect(mapStateToProps)(GroupPlanningView);
