import React, { ReactElement } from "react";
import { DrawerHeaderTitleProps } from "./DrawerHeaderTitle.types";
import { LargeBody } from "src/components/Text";

function DrawerHeaderTitle(props: DrawerHeaderTitleProps): ReactElement {
    const action = props.action ? (
        <div className="DrawerHeaderTitle-action">
            {props.action}
        </div>
    ) : null;

    return (
        <div className="DrawerHeaderTitle">
            <LargeBody className="DrawerHeaderTitle-text" as="h2" color="contentDark" size="bodyMedium">
                {props.children}
            </LargeBody>
            {action}
        </div>
    );
}

export default DrawerHeaderTitle;
